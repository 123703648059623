import { lazy } from 'react'

import ProductCreate from './pages/ProductCreate.page'
import ProductUpdate from './pages/ProductUpdate.page'
import ProductSalesHistory from './pages/ProductSalesHistory.page'

import { IRoute } from 'src/bootstrap/routes'

// Enable code splitting
const Products = lazy(() => {
  return import(/* webpackChunkName: "products_page" */ './pages/Products.page')
})

export const productRoutes: IRoute[] = [
  {
    id: 'products',
    path: '/products',
    component: Products,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'product_create',
    path: '/products/create',
    component: ProductCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'product_update',
    path: '/products/:id/update',
    component: ProductUpdate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'products_history',
    path: '/products/history',
    component: ProductSalesHistory,
    requiresAuth: true,
    exact: true,
  },
]

export default productRoutes
