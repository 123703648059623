import Manufacturers from './pages/Manufacturers.page'
import ManufacturerEditor from './pages/ManufacturerEditor.page'

import { IRoute } from 'src/bootstrap/routes'

export const manufacturerRoutes: IRoute[] = [
  {
    id: 'manufacturers',
    path: '/manufacturers',
    component: Manufacturers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'manufacturer_create',
    path: '/manufacturers/create',
    component: ManufacturerEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'manufacturer_update',
    path: '/manufacturers/:id/update',
    component: ManufacturerEditor,
    requiresAuth: true,
    exact: true,
  },
]

export default manufacturerRoutes
