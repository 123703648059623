import React from 'react'

import { Box, Flex } from '@chakra-ui/layout'
import { Text, Badge, Button } from '@chakra-ui/react'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { FiEdit3 } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'

import {
  timestampToDate,
  timestampToTime,
  route,
  formatCurrency,
  undoSlugify,
} from 'src/core/helpers'

import { getPurchaseOrderType } from '../purchaseOrder.helper'
import { PurchaseOrderTag } from '../purchaseOrder.type'
import { IUsePurchaseOrder } from '../hooks/usePurchaseOrder'
import { ExecutePurchaseOrderButtons } from './ExecutePurchaseOrderButtons'

export const PurchaseOrderDetails: React.FC<IUsePurchaseOrder> = (props) => {
  return (
    <>
      <PurchaseOrder {...props} />
      <Type {...props} />
      <Tag {...props} />
      <Source {...props} />
      <Destination {...props} />
      <Amount {...props} />
      <Actions {...props} />
    </>
  )
}

type SectionProps = IUsePurchaseOrder

const PurchaseOrder: React.FC<SectionProps> = ({ purchaseOrder }) => {
  const timetsamp = purchaseOrder?.timestamp || purchaseOrder?.created
  const timestampDiff = differenceInMinutes(
    purchaseOrder?.timestamp,
    purchaseOrder?.created
  )

  return (
    <Box mb={4}>
      <Text variant="tinyTitle">Purchase Order </Text>
      <Text fontWeight="medium">#{purchaseOrder.ref}</Text>
      <Text>
        on {timestampToDate(timetsamp)} @ {timestampToTime(timetsamp)}
      </Text>
      {Math.abs(timestampDiff) > 0 && (
        <Text fontSize="sm" color="critical.600">
          Actual: {timestampToDate(purchaseOrder.created)} @{' '}
          {timestampToTime(purchaseOrder.created)}
        </Text>
      )}
    </Box>
  )
}

const Type: React.FC<SectionProps> = ({ purchaseOrder }) => {
  return (
    <Box mb={4}>
      <Text variant="tinyTitle">Type</Text>
      <Badge
        colorScheme={
          ['Shipment Received', 'Stock Returned'].includes(
            getPurchaseOrderType(purchaseOrder)
          )
            ? 'success'
            : 'critical'
        }
      >
        {getPurchaseOrderType(purchaseOrder)}
      </Badge>
    </Box>
  )
}

const Tag: React.FC<SectionProps> = ({ purchaseOrder }) => {
  return (
    <Box mb={4}>
      <Text variant="tinyTitle">Tag</Text>
      <Badge
        colorScheme={
          purchaseOrder.tag === PurchaseOrderTag.PurchaseOrder
            ? 'orange'
            : 'teal'
        }
      >
        {undoSlugify(purchaseOrder.tag)}
      </Badge>
    </Box>
  )
}

const Source: React.FC<SectionProps> = ({ purchaseOrder }) => {
  return (
    <Box mb={4}>
      <Text variant="tinyTitle">Source</Text>
      <Link
        to={route(`${purchaseOrder.source_type}_update`, {
          id: purchaseOrder.source,
        })}
      >
        <Text fontWeight="medium">{purchaseOrder.source_obj?.name || '-'}</Text>
      </Link>
    </Box>
  )
}

const Destination: React.FC<SectionProps> = ({ purchaseOrder }) => {
  return (
    <Box mb={4}>
      <Text variant="tinyTitle">Destination</Text>

      <Link
        to={route(`${purchaseOrder.destination_type}_update`, {
          id: purchaseOrder.destination_obj?._id,
        })}
      >
        <Text fontWeight="medium">
          {purchaseOrder.destination_obj?.name || '-'}
        </Text>
      </Link>
    </Box>
  )
}

const Amount: React.FC<SectionProps> = ({ purchaseOrder }) => {
  return (
    <Box mb={4}>
      <Text variant="tinyTitle">Amount</Text>
      <Text fontSize="lg" fontWeight="semibold" mb={1}>
        NGN {formatCurrency(purchaseOrder.amount)}
      </Text>
    </Box>
  )
}

const Actions: React.FC<SectionProps> = (props) => {
  const history = useHistory()

  return (
    <Box mb={4}>
      <Flex alignItems="center" flexWrap="wrap" gridGap="10px">
        <Button
          leftIcon={<FiEdit3 />}
          onClick={() => {
            history.push(
              route('purchase_order_update', {
                id: props?.purchaseOrder?._id,
              })
            )
          }}
        >
          Edit
        </Button>

        <ExecutePurchaseOrderButtons {...props} />
      </Flex>
    </Box>
  )
}
