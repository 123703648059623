import React from 'react'

import { Spinner, Flex } from '@chakra-ui/react'
import { FiCreditCard, FiHash } from 'react-icons/fi'
import { useParams, useHistory } from 'react-router-dom'

import {
  Layout,
  ErrorBox,
  FolderTabs,
  FolderTab,
  Card,
  ColumnLayout,
} from 'src/core/components'
import { route } from 'src/core/helpers/route.helper'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'
import {
  LedgerHead,
  LedgerLogs,
  LedgerSubhead,
  VirtualAccount,
} from 'src/applets/ledger'

import DistributorOfficerDetails from '../components/DistributorOfficerDetails'
import { distributorOfficerService } from '../distributorOfficer.service'
import { IDistributorOfficer } from '../distributorOfficer.type'

export enum PageTab {
  Balance = 'balance',
  VirtualAccount = 'virtual-account',
}

const DistributorOfficer: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()

  const { id: distributor_officer_id, tab: tabIndex }: any = useParams()

  const [distributorOfficer, isLoading] = useFetch<IDistributorOfficer>(
    distributorOfficerService,
    'fetchById',
    [distributor_officer_id],
    userCan('view_distributor_officers')
  )

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Distributor Officer'
          : distributorOfficer?.name || 'Distributor officer not found',
        backText: 'Distributor Officers',
        onBack: () => history.push(route('distributor_officers')),
      }}
      permission="view_distributor_officers"
      bodyProps={{ padding: 0, overflowY: 'hidden' }}
    >
      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : (
        <>
          {distributorOfficer ? (
            <ColumnLayout>
              <ColumnLayout.Main>
                <FolderTabs
                  defaultIndex={tabIndex}
                  onChange={(tabIndex) => {
                    history.push(
                      route('distributor_officer', {
                        id: distributorOfficer._id,
                        tab: tabIndex,
                      })
                    )
                  }}
                >
                  <FolderTab
                    index={PageTab.Balance}
                    title="Balance History"
                    leftIcon={<FiCreditCard />}
                  />
                  <FolderTab
                    index={PageTab.VirtualAccount}
                    title="Virtual Account"
                    leftIcon={<FiHash />}
                  />
                </FolderTabs>

                <Card
                  overflow="clip"
                  borderTopLeftRadius={tabIndex === PageTab.Balance ? 0 : null}
                >
                  {tabIndex === PageTab.Balance ? (
                    <LedgerLogs
                      owner={distributorOfficer?._id}
                      head={LedgerHead.DistributorOfficer}
                      subhead={LedgerSubhead.Wallet}
                    />
                  ) : tabIndex === PageTab.VirtualAccount ? (
                    <VirtualAccount
                      parent={distributorOfficer}
                      parentType={LedgerHead.DistributorOfficer}
                    />
                  ) : null}
                </Card>
              </ColumnLayout.Main>

              <ColumnLayout.Right bg="#fff">
                <DistributorOfficerDetails
                  distributorOfficer={distributorOfficer}
                />
              </ColumnLayout.Right>
            </ColumnLayout>
          ) : (
            <ErrorBox
              title="Distributor officer not found!"
              summary="Check the URL and try again."
              my={50}
            />
          )}
        </>
      )}
    </Layout>
  )
}

export default DistributorOfficer
