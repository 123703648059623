import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import CategoryForm from '../components/CategoryForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { CategoryService, CategoryServiceProps } from '../category.service'
import { ICategory } from '../category.type'
import { route } from 'src/core/helpers/route.helper'

const categoryService = new CategoryService()

const CategoryEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: category_id }: any = useParams()

  const permission = category_id ? 'update_category' : 'create_category'

  const [category, isLoading, , setCategory] = useFetch<
    ICategory,
    CategoryServiceProps
  >(
    categoryService,
    'fetchById',
    [category_id],
    !!category_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Category' : category?.name || 'New Category',
        backText: 'Categories',
        onBack: () => history.push(route('categories')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={category_id ? 'update' : 'create'}
        model={category}
        isLoading={isLoading}
      >
        {category_id ? (
          <CategoryForm
            type="update"
            category={category}
            onUpdate={(updatedValues) => {
              setCategory({ ...category, ...updatedValues })
            }}
          />
        ) : (
          <CategoryForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default CategoryEditor
