import React from 'react'
import { Flex, HStack, StackProps, Text } from '@chakra-ui/layout'
import { CSSObject } from '@chakra-ui/styled-system'
import { useTheme } from '@chakra-ui/system'
import { FiFolder, FiX } from 'react-icons/fi'

interface FolderTabProps {
  index?: any
  title?: string
  leftIcon?: JSX.Element
  meta?: string
  isActive?: boolean
  onChange?: VoidFunction
  isClosable?: boolean
}

const TAB_HEIGHT = '38px'

export const FolderTab: React.FC<FolderTabProps> = (props) => {
  const { colors } = useTheme()
  const styleProps: Record<string, CSSObject> = {
    tab: {
      background: props.isActive ? '#fff' : 'transparent',
      borderRadius: '6px 10px 0 0',
      borderWidth: '1px 1px 0 1px',
      borderStyle: 'solid',
      borderColor: props.isActive ? colors.gray['100'] : 'transparent',
      color: colors.gray['600'],
      cursor: 'default',
      alignItems: 'center',
      padding: '8px 14px 8px 12px',
      position: 'relative',
      top: '1px',
      height: TAB_HEIGHT,
      transition: 'all 300ms',
      zIndex: '10',
      ':hover': {
        boxShadow: props.isActive
          ? 'none'
          : '1px 1px 6px rgba(128, 138, 168, 0.1)',
        'svg:first-of-type': {
          color: props.isActive ? colors.primary[600] : colors.gray[500],
        },
      },
      'svg:first-of-type': {
        color: props.isActive ? colors.primary[600] : colors.gray[400],
        transition: 'all 300ms',
      },
    },
  }

  return (
    <Flex css={styleProps.tab as any} onClick={props?.onChange}>
      {props.leftIcon || <FiFolder />}

      <HStack ml={2} mr={props.isClosable ? 3 : 0} spacing={1}>
        <Text
          color={props?.isActive ? 'gray.900' : 'gray.700'}
          fontWeight="regular"
        >
          {props?.title || props?.children}
        </Text>
        {!!props.meta && <Text>- {props.meta}</Text>}
      </HStack>

      {props.isClosable && <FiX />}
    </Flex>
  )
}

FolderTab.defaultProps = {
  isActive: false,
}

interface FolderTabsProps extends StackProps {
  onChange?: (index: any) => void
  defaultIndex?: any
}
export const FolderTabs: React.FC<FolderTabsProps> = ({
  children,
  onChange,
  defaultIndex,
  ...parentProps
}) => {
  return (
    <HStack spacing={0} {...parentProps}>
      {React.Children.map(children, (tab: React.ReactElement, index) => {
        const childProps: FolderTabProps = tab?.props

        return (
          <FolderTab
            {...childProps}
            key={index}
            index={index}
            onChange={
              onChange ? () => onChange(childProps?.index || index) : null
            }
            isActive={defaultIndex === (childProps?.index || index)}
          />
        )
      })}
    </HStack>
  )
}
