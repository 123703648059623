import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

import DefaultUpload from 'src/core/assets/images/default-upload.jpg'

export interface ImageBoxProps extends BoxProps {
  src?: string
}
export const ImageBox: React.FC<ImageBoxProps> = ({
  src,
  width,
  height,
  ...props
}) => (
  <Box
    width={width}
    height={height || width}
    borderColor="gray.100"
    borderStyle="solid"
    flexShrink={0}
    bgSize="contain"
    bgColor="#fff"
    bgPosition="center"
    bgRepeat="no-repeat"
    bgImage={`url(${src ? src : DefaultUpload})`}
    borderRadius={6}
    {...props}
  />
)

ImageBox.defaultProps = {
  width: '40px',
}
