import React from 'react'

import { Button, Flex, Box, Spinner, Text } from '@chakra-ui/react'
import { FiCheck, FiX } from 'react-icons/fi'

import {
  ErrorBox,
  ExportButton,
  FolderTab,
  CardBody,
  IExportableColumn,
  useExportable,
} from 'src/core/components'
import { slugify } from 'src/core/helpers'
import { OrderProduct, QuickProductPicker } from 'src/applets/product'

import { IUsePurchaseOrder } from '../hooks/usePurchaseOrder'
import { IPurchaseOrderItem, PurchaseOrderTag } from '../purchaseOrder.type'
import { useFetch, usePermission } from 'src/core/hooks'
import {
  IWarehouseProduct,
  warehouseService,
  WarehouseServiceProps,
} from 'src/applets/warehouse'
import { getPurchaseOrderConfig } from '../purchaseOrder.helper'

const exportableColumns: IExportableColumn<IPurchaseOrderItem>[] = [
  { label: 'Name', accessor: 'product.name', selected: true },
  {
    label: 'SOH',
    accessor: 'quantity',
    selected: true,
    modifier: (quantity, item) => {
      return (quantity / item.product.case_count).toFixed(1)
    },
  },
  {
    label: 'Cases',
    accessor: 'quantity',
    selected: true,
    modifier: (quantity, item) => {
      return String(Math.floor(quantity / item.product.case_count))
    },
  },
  {
    label: 'Units',
    accessor: 'quantity',
    selected: true,
    modifier: (quantity, item) => {
      return String(quantity % item.product.case_count)
    },
  },
  {
    label: 'Rate',
    accessor: 'amount',
    selected: true,
    modifier: (amount, item) => {
      return String(
        amount ? amount / (item.quantity / item.product.case_count) : 0
      )
    },
  },
  {
    label: 'Amount',
    accessor: 'amount',
    selected: true,
    modifier: (amount) => String(amount),
  },
]

export const PurchaseOrderItems: React.FC<IUsePurchaseOrder> = (props) => {
  const { userCan } = usePermission()

  const {
    purchaseOrder,
    setPurchaseOrder,
    purchaseOrderItems,
    addPurchaseOrderItem,
    updatePurchaseOrderItem,
    deletePurchaseOrderItem,
    isLoadingItems,
    isUpdatingItems,
    // availableStock,
    setAvailableStock,
    outOfStock,
  } = props

  const [receiveMode, setReceiveMode] = React.useState<boolean>(false)

  const [warehouseProducts] = useFetch<
    IWarehouseProduct[],
    WarehouseServiceProps
  >(
    warehouseService,
    'fetchProducts',
    [purchaseOrder?.source_warehouse?._id],
    purchaseOrder?.source_type !== 'distributor'
  )

  const availableProducts = React.useMemo(() => {
    const _availableProducts = {}

    if (warehouseProducts?.length) {
      warehouseProducts.forEach(({ product_id, quantity }) => {
        if (!_availableProducts[product_id]) {
          _availableProducts[product_id] = { quantity }
        }
      })
    }

    return _availableProducts
  }, [warehouseProducts])

  const exportablePurchaseOrderItems = useExportable(
    purchaseOrderItems,
    exportableColumns
  )

  const { type, direction } = getPurchaseOrderConfig(purchaseOrder)

  return (
    <>
      <CardBody
        padding={0}
        opacity={!isLoadingItems && isUpdatingItems ? 0.3 : 1}
      >
        {isLoadingItems && (
          <Flex justifyContent="center" py={50}>
            <Spinner size="sm" color="primary.600" />
          </Flex>
        )}

        {!isLoadingItems && !purchaseOrderItems?.length ? (
          <Box my={50}>
            <ErrorBox summary="No products found" />
          </Box>
        ) : null}

        {!isLoadingItems && purchaseOrderItems?.length ? (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            display="none"
          >
            <FolderTab title={`Products (${purchaseOrderItems.length})`} />

            <Flex alignItems="center">
              {receiveMode ? (
                <>
                  <Text fontSize="sm" mr={3} color="warning.600">
                    Receiving Stock
                  </Text>
                  <Button
                    size="sm"
                    variant="ghost"
                    leftIcon={<FiX size={16} />}
                    onClick={() => setReceiveMode(false)}
                  >
                    Close
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    ml={1}
                    size="sm"
                    variant="ghost"
                    display="none"
                    leftIcon={<FiCheck size={16} />}
                    onClick={() => setReceiveMode(true)}
                    isDisabled={isLoadingItems}
                  >
                    Receive Stock
                  </Button>

                  <ExportButton
                    ml={1}
                    filename={`${slugify(purchaseOrder?.source_obj?.name)}-po`}
                    variant="ghost"
                    exportData={exportablePurchaseOrderItems?.data}
                    exportableColumns={exportablePurchaseOrderItems?.columns}
                    toggleExportableColumn={
                      exportablePurchaseOrderItems?.toggleColumn
                    }
                    isDisabled={!exportablePurchaseOrderItems?.data?.length}
                  />
                </>
              )}
            </Flex>
          </Flex>
        ) : null}

        <Box
          border="1px solid"
          borderColor="gray.100"
          borderBottom={0}
          borderRadius="6px"
          opacity={isLoadingItems ? 0 : 1}
          transition="opacity 600ms"
          transitionDelay="200ms"
        >
          {!isLoadingItems && purchaseOrderItems?.length ? (
            <>
              {purchaseOrderItems.map((item) => (
                <OrderProduct
                  key={item._id}
                  item={item}
                  parent={purchaseOrder}
                  setParent={setPurchaseOrder}
                  updateItem={updatePurchaseOrderItem}
                  deleteItem={deletePurchaseOrderItem}
                  quantityInputProps={{
                    isValueEditable: true,
                    unit: 'case',
                    mpu: 0,
                    mxpu: availableProducts?.[item.product_id]?.quantity,
                    interval: item.product.mpu,
                  }}
                  isEditable={
                    purchaseOrder?.tag === PurchaseOrderTag.PurchaseOrder &&
                    userCan(`create_${type}_${direction}`)
                  }
                  isAcceptable={receiveMode}
                  isAmountEditable
                  outOfStock={!!outOfStock?.[item.product_id]}
                  quantityAvailable={
                    outOfStock?.[item.product_id]?.available?.quantity || 0
                  }
                />
              ))}
            </>
          ) : null}

          <QuickProductPicker
            isShown={
              !isLoadingItems &&
              purchaseOrder?.tag !== PurchaseOrderTag.Receipt &&
              userCan(`create_${type}_${direction}`)
            }
            warehouse={
              purchaseOrder?.source_type !== 'distributor'
                ? purchaseOrder?.source_warehouse?._id
                : '*'
            }
            seller={
              purchaseOrder?.source_type === 'seller'
                ? purchaseOrder?.source_obj?._id
                : purchaseOrder?.destination_type === 'seller'
                ? purchaseOrder?.destination_obj?._id
                : null
            }
            selectedItems={purchaseOrderItems || []}
            addItem={(item) =>
              addPurchaseOrderItem(item).then((values) => {
                setPurchaseOrder(values)
                setAvailableStock?.(null)
              })
            }
            quantityInputProps={{ isValueEditable: true, unit: 'case' }}
            isAmountEditable
          />
        </Box>
      </CardBody>
    </>
  )
}
