import React from 'react'
import { Box, Flex, StackDivider, Text, VStack } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import format from 'date-fns/format'
import { FiCopy, FiPlus, FiRotateCw } from 'react-icons/fi'

import { CardBody, ErrorBox } from 'src/core/components'
import useToast from 'src/core/hooks/useToast'
import { VirtualAccountForm } from './VirtualAccountForm'
import { LedgerHead } from '../ledger.type'
import { parseVirtualAccount } from '../ledger.helper'

interface IProps {
  parent: any
  parentType: LedgerHead
}
export const VirtualAccount: React.FC<IProps> = ({ parent, parentType }) => {
  const { toast } = useToast()

  const [showForm, setShowForm] = React.useState<boolean>(false)

  const virtualAccount = React.useMemo(() => {
    return parseVirtualAccount(parent?.ledger_tags?.virtual_account)
  }, [parent])

  return (
    <CardBody>
      {virtualAccount ? (
        <VStack
          divider={<StackDivider borderColor="gray.50" />}
          alignItems="flex-start"
        >
          <Box w="100%">
            <Text variant="tinyTitle">Account No.</Text>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="2xl" fontWeight="bold">
                {virtualAccount?.account_number}
              </Text>

              <Flex alignItems="center">
                <Button
                  size="sm"
                  colorScheme="primary"
                  variant="ghost"
                  leftIcon={<FiCopy size={16} />}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      virtualAccount?.account_number
                    )
                    toast({
                      status: 'info',
                      description: 'Account number copied!',
                    })
                  }}
                >
                  Copy
                </Button>

                <Button
                  size="sm"
                  colorScheme="gray"
                  variant="ghost"
                  ml={1}
                  leftIcon={<FiRotateCw size={16} />}
                  onClick={() => setShowForm(true)}
                >
                  Refresh
                </Button>
              </Flex>
            </Flex>
          </Box>

          <Box>
            <Text variant="tinyTitle">Account Name</Text>
            <Text fontWeight="medium">{virtualAccount?.account_name}</Text>
          </Box>

          <Box>
            <Text variant="tinyTitle">Bank Name</Text>
            <Text fontWeight="medium">{virtualAccount?.bank?.name}</Text>
          </Box>

          <Box>
            <Text variant="tinyTitle">Updated</Text>
            <Text fontWeight="medium">
              {format(new Date(virtualAccount?.updated_at), 'dd MMM, yyy')}
            </Text>
          </Box>
        </VStack>
      ) : (
        <Box textAlign="center" py={50}>
          <ErrorBox summary="No virtual account found" />
          <Button
            mt={3}
            size="sm"
            variant="outline"
            leftIcon={<FiPlus size={16} />}
            onClick={() => setShowForm(true)}
          >
            Create Virtual Account
          </Button>
        </Box>
      )}

      <VirtualAccountForm
        isOpen={showForm}
        parentId={parent._id}
        parentType={parentType}
        onClose={() => setShowForm(false)}
      />
    </CardBody>
  )
}
