import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import { route } from 'src/core/helpers/route.helper'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { AreaService, AreaServiceProps } from '../area.service'
import { IArea } from '../area.type'
import AreaForm from '../components/AreaForm'

const areaService = new AreaService()

const AreaEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: area_id }: any = useParams()

  const permission = area_id ? 'update_area' : 'signup_area'

  const [area, isLoading, , setArea] = useFetch<IArea, AreaServiceProps>(
    areaService,
    'fetchById',
    [area_id],
    !!area_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Area' : area?.name || 'New Area',
        backText: 'Areas',
        onBack: () => history.push(route('areas')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={area_id ? 'update' : 'create'}
        model={area}
        isLoading={isLoading}
      >
        {area_id ? (
          <AreaForm
            type="update"
            area={area}
            onUpdate={(updatedValues) => {
              setArea({ ...area, ...updatedValues })
            }}
          />
        ) : (
          <AreaForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default AreaEditor
