import { CoreService, CoreServiceProps } from 'src/core/services'
import { IBuyer } from './buyer.type'
import { relativeTime } from 'src/core/helpers/date.helper'
import { formatCurrency } from 'src/core/helpers/money.helper'

export class BuyerService extends CoreService {
  constructor() {
    super('buyer')
  }

  /**
   * Compute buyers order: completed (pending + colpleted),
   * cancelled and cart
   *
   * @param buyer
   */
  private computeBuyerOrders(buyer: IBuyer): any {
    const cacheKey = `order_summary_${buyer._id}`
    const cachedData = this.cache.get(cacheKey)

    if (cachedData) {
      return cachedData
    } else {
      let orderSummary: any = {
        order_count: 0,
        order_value: 0,
        order_average: 0,
        cancelled_count: 0,
        cancelled_value: 0,
        cart_count: 0,
        cart_value: 0,
        first_ordered_on: 0,
        last_ordered_on: 0,
      }

      if (buyer.order_count) {
        orderSummary.first_ordered_on = buyer.order_count.count.total
          ? buyer.order_count.earliest.total
          : 0

        orderSummary.last_ordered_on = buyer.order_count.count.total
          ? buyer.order_count.latest.total
          : 0

        /** Orders (Completed + Pending) */
        orderSummary.order_count =
          buyer.order_group_count.count.completed +
          buyer.order_group_count.count.pending
        orderSummary.order_value =
          buyer.order_count.value.completed + buyer.order_count.value.pending
        orderSummary.order_average = orderSummary.order_count
          ? orderSummary.order_value / orderSummary.order_count
          : '0.00'

        if (buyer.order_count.count.cancelled) {
          /** Cancelled orders */
          orderSummary.cancelled_count = buyer.order_count.count.cancelled
          orderSummary.cancelled_value = buyer.order_count.value.cancelled
        }
      }

      if (buyer.order_group_count && buyer.order_group_count.count.draft) {
        orderSummary.cart_count = buyer.order_group_count.count.draft
        orderSummary.cart_value = buyer.order_group_count.value.draft
      }

      orderSummary = {
        ...orderSummary,
        order_value: formatCurrency(orderSummary.order_value),
        order_average: formatCurrency(orderSummary.order_average),
        cancelled_value: formatCurrency(orderSummary.cancelled_value),
        cart_value: formatCurrency(orderSummary.cart_value),
      }

      this.cache.set(cacheKey, orderSummary)
      return orderSummary
    }
  }

  /**
   * There are four buyer categories I'm thinking of here:
   * 1. new: Joined in the last 7 days, no order
   * 2. first_order: Order group count is === 1
   * 3. new_first_order: Combination of new and first_order
   * 4. regular_weekly: orders / (days / 7) >= 0.8
   * 5. regular_biweekly: orders / (days / 14) >= 0.8
   * 5. regular_triweekly: orders / (days / 21) >= 0.8
   */
  private computeBuyerCategory(buyer: IBuyer): string {
    const joinedSinceDays = Number(relativeTime(buyer.created, true))

    /**
     * Users that existed before May 4, 2020 launch
     * should be condisered as old users
     */
    const launch = new Date('May 4, 2020')
    const launchDays = Number(relativeTime(launch.getTime() / 1000, true))

    if (joinedSinceDays > launchDays) return 'pre_launch'
    else {
      const boundary = 0.8

      const categories = {
        new: false,
        new_active: false,
        regular_weekly: false,
        regular_biweekly: false,
        regular_triweekly: false,
      }

      const { last_ordered_on, order_count } = this.computeBuyerOrders(buyer)
      /** Let's also check most recent order before we judge them */
      const lastOrderDays = Number(relativeTime(last_ordered_on, true))
      const orderCount: any = order_count

      if (joinedSinceDays <= 14) categories.new = true
      if (joinedSinceDays <= 14 && orderCount >= 1) categories.new_active = true

      /** Weekly orders */
      const weeklyOrders = orderCount / (joinedSinceDays / 7)
      if (
        weeklyOrders > boundary &&
        joinedSinceDays > 14 &&
        lastOrderDays < 14
      ) {
        categories.regular_weekly = true
      }

      /** Bi-weekly orders */
      const biweeklyOrders = orderCount / (joinedSinceDays / 14)
      if (
        biweeklyOrders > boundary &&
        joinedSinceDays > 21 &&
        lastOrderDays < 21
      ) {
        categories.regular_biweekly = true
      }

      /** Tri-weekly orders */
      const triweeklyOrders = orderCount / (joinedSinceDays / 21)
      if (
        triweeklyOrders > boundary &&
        joinedSinceDays > 28 &&
        lastOrderDays < 28
      ) {
        categories.regular_triweekly = true
      }

      if (categories.new_active) return 'new_active'
      else if (categories.new) return 'new_inactive'
      else if (categories.regular_weekly) return 'regular_weekly'
      else if (categories.regular_biweekly) return 'regular_biweekly'
      else if (categories.regular_triweekly) return 'regular_triweekly'
      else return 'N/A'
    }
  }

  fetchHistory(): Promise<any> {
    return new Promise((resolve, reject) => {
      const cacheKey = `buyers_history`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .get(`${this.model}/history?show_more=false`)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetch(): Promise<IBuyer[]> {
    let buyers
    return new Promise((resolve, reject) => {
      super
        .fetch()
        .then((data) => {
          buyers = data
          return this.fetchHistory()
        })
        .then((data) => {
          buyers = buyers.map((buyer) => {
            const orderSummary = this.computeBuyerOrders({
              ...buyer,
              ...data.buyers[buyer._id],
            })

            buyer = {
              ...buyer,
              ...orderSummary,
              category: this.computeBuyerCategory(buyer),
              order_items: data.buyers[buyer._id]?.order_items?.count || 0,
            }

            buyer.order_items_average = buyer.order_count
              ? (buyer.order_items / buyer.order_count).toFixed()
              : 0

            return buyer
          })

          resolve(buyers)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  fetchById(buyerId: string): Promise<IBuyer> {
    return new Promise((resolve, reject) => {
      super
        .fetchById(buyerId)
        .then((buyer) => {
          buyer.category = this.computeBuyerCategory(buyer)
          const orderSummary = this.computeBuyerOrders(buyer)

          return resolve({
            ...buyer,
            ...orderSummary,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  fetchDeviceInfo(buyerId): Promise<any> {
    const formData = new FormData()
    /**
     * The user_id should be the buyer_id
     * for some reason. Ask Mr. Igwue.
     **/
    formData.append('user_id', buyerId)

    return new Promise((resolve, reject) => {
      const cacheKey = `buyer_device_${buyerId}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http.post(`device/read_by_user`, formData).then(({ data }) => {
            if (data.code === 200) {
              this.cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchRetention(
    type: 'all' | 'dict',
    payload: {
      period: number
      start_timestamp: number
      end_timestamp: number
      only_covered_buyers: string
      amount: string
    }
  ): Promise<any> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload?.[key])
    })

    return new Promise((resolve, reject) => {
      const cacheKey = `retention_${type}_${Object.values(payload).join('_')}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`buyer/order_retention_${type}`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export const buyerService = new BuyerService()
export type BuyerServiceProps = CoreServiceProps | keyof BuyerService
export default BuyerService
