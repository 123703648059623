import React, { useContext } from 'react'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Tbody } from 'src/core/components/Table'
import { useSort } from 'src/core/hooks'
import { paginate } from 'src/core/helpers/filters.helper'

import { ProductsThead, ProductsTr } from '.'
import { ProductsPageContext } from '../../pages/Products.page'

const ProductsTable: React.FC = () => {
  const {
    filteredProducts: products,
    filters,
    setPage,
    tableRef,
  } = useContext(ProductsPageContext)

  const { getThProps, sorted: sortedProducts } = useSort(products, {
    accessor: 'name',
    dir: 'asc',
  })

  const paginatedProducts = React.useMemo(() => {
    const { data, timestamp } = sortedProducts

    return data || timestamp
      ? paginate(data, filters.page, filters.per_page)
      : []
  }, [filters.page, filters.per_page, sortedProducts])

  return (
    <>
      {!paginatedProducts.length ? (
        <ErrorBox summary="No products found" my={50} />
      ) : (
        <Table tableRef={tableRef} isFit hasBorders={!!filters?.show_details}>
          <ProductsThead getThProps={getThProps} />
          <Tbody>
            {paginatedProducts.map((product, index) => (
              <ProductsTr key={index} index={index} product={product} />
            ))}
          </Tbody>
        </Table>
      )}

      <Pagination
        my={5}
        itemsPerPage={filters.per_page}
        totalItems={products.length}
        currentPage={filters.page}
        onPageChange={(page) => setPage(page)}
      />
    </>
  )
}

export default ProductsTable
