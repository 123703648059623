import { lazy } from 'react'
import { IRoute } from 'src/bootstrap/routes'

// Enable code splitting
const BuyerRetention = lazy(() => {
  return import(
    /* webpackChunkName: "buyer_retention_page" */ './pages/BuyerRetention.page'
  )
})
const BuyerReconciliation = lazy(() => {
  return import(
    /* webpackChunkName: "buyer_retention_page" */ './pages/BuyerReconciliation.page'
  )
})
const SalesOfficerReconciliation = lazy(() => {
  return import(
    /* webpackChunkName: "buyer_retention_page" */ './pages/SalesOfficerReconciliation.page'
  )
})

export const reportRoutes: IRoute[] = [
  {
    id: 'report_buyer_retention',
    path: '/report-buyer-retention',
    component: BuyerRetention,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'report_buyer_reconciliation',
    path: '/report-buyer-reconciliation',
    component: BuyerReconciliation,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'report_sales_officer_reconciliation',
    path: '/report-sales-officer-reconciliation',
    component: SalesOfficerReconciliation,
    requiresAuth: true,
    exact: true,
  },
]

export default reportRoutes
