import { IManufacturer } from 'src/applets/manufacturer'
import { IBrand } from 'src/applets/brand'
import { IUpload } from 'src/applets/upload'

export interface IProduct {
  _id?: string

  name: string
  description: string
  status: string
  /**
   * manufacturer_status && brand_status &&
   * product_status && seller_product_status
   */
  compound_status?: string

  sku_id: string
  case_count: number
  mpu: number
  price: number
  weight?: number
  size?: number
  quantity?: number
  tags?: Record<ProductTag, { value: number }>

  upload_id: string
  upload?: IUpload

  sellers?: any
  manufacturer_id?: string
  manufacturer?: IManufacturer

  brand_id: string
  brand?: IBrand

  readonly created: number
  readonly modified: number
}

export interface IProductLog {
  _id?: string
  tag: string
  value: string
  product_id: string
  readonly created: number
}

export interface ISellerProduct {
  _id?: string
  mpu: number
  price: number
  quantity: number
  status: 'active' | 'inactive'

  seller_id: string

  /** Stored as JSON {discount, market_price} */
  meta: string
}

export interface ISellerProductLog extends Omit<IProductLog, 'product_id'> {
  seller_product_id: string
}

export enum ProductTag {
  LowerUnit = 'LOWER_UNIT',
  HigherUnit = 'HIGHER_UNIT',
  Markup = 'MARKUP',
  Class = 'CLASS',
  // MoreImage = 'MORE_IMAGE',
}

export interface IProductTag {
  _id?: string
  tag: ProductTag
  value: string
  product_id: string
}

export interface IProductFilters {
  status?: '*' | 'active' | 'inactive'
  show_details?: boolean
  page?: number
  per_page?: number
  search?: string
  brands?: '*' | { value: string; label: string }[]
  manufacturers?: '*' | { value: string; label: string }[]
}

export interface IUseFilterProducts {
  filters: IProductFilters
  activeFilters: string[]
  filteredProducts: IProduct[]
  setFilters: (filters: IProductFilters) => void
  setPage: (page: number) => void
}
