import React from 'react'

import { Flex, Text, Box, Spinner, Tag } from '@chakra-ui/react'
import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'

import { ErrorBox } from 'src/core/components/ErrorBox'
import { Card, CardBody, CardHeader } from 'src/core/components/Card'
import { useFetch } from 'src/core/hooks'

import { ISellerProduct, ISellerProductLog } from '../../product.type'
import { ProductService, ProductServiceProps } from '../../product.service'

const productService = new ProductService()

interface IProps {
  sellerProduct: ISellerProduct
}

export const SellerProductLogs: React.FC<IProps> = ({ sellerProduct }) => {
  const [productLogs, isLoading] = useFetch<
    ISellerProductLog[],
    ProductServiceProps
  >(
    productService,
    'fetchSellerProductLogs',
    [sellerProduct?._id],
    !!sellerProduct?._id
  )

  return (
    <Card>
      <CardHeader>
        <Text fontWeight="medium">Change Log</Text>
      </CardHeader>
      <CardBody p={0} maxH="450px" overflowY="scroll">
        {isLoading && (
          <Flex justifyContent="center" py={30}>
            <Spinner size="sm" color="primary.600" />
          </Flex>
        )}

        {!isLoading && !productLogs?.length ? (
          <ErrorBox summary="No product logs found." my={30} />
        ) : null}

        {!isLoading && productLogs?.length ? (
          <>
            {productLogs.map((log) => (
              <Flex
                key={log._id}
                columns={2}
                p="15px 20px"
                borderBottom="1px solid"
                borderColor="gray.100"
              >
                <Box flex={1}>
                  <Tag>{log.tag}</Tag>
                </Box>
                <Box flex={2}>
                  <Text>{log.value}</Text>
                  <Text color="gray.600" fontSize="sm">
                    {format(fromUnixTime(log.created), 'dd MMM yyy @ h:mmaaa')}
                  </Text>
                </Box>
              </Flex>
            ))}
          </>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default SellerProductLogs
