import Login from './pages/Login.page'
import Profile from './pages/Profile.page'

import { IRoute } from 'src/bootstrap/routes'

export const userRoutes: IRoute[] = [
  {
    id: 'auth_login',
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    id: 'profile',
    path: '/profile',
    requiresAuth: true,
    component: Profile,
    exact: true,
  },
]

export default userRoutes
