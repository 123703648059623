import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'
import { route } from 'src/core/helpers/route.helper'

import { AgentService, AgentServiceProps } from '../agent.service'
import { IAgent } from '../agent.type'
import { AgentForm } from '../components/AgentForm'

const agentService = new AgentService()

const AgentEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: agent_id }: any = useParams()

  const permission = agent_id ? 'update_agent' : 'signup_agent'

  const [agent, isLoading, , setAgent] = useFetch<IAgent, AgentServiceProps>(
    agentService,
    'fetchById',
    [agent_id],
    !!agent_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Agent' : agent?.name || 'New Agent',
        backText: 'Agents',
        onBack: () => history.push(route('agents')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={agent_id ? 'update' : 'create'}
        model={agent}
        isLoading={isLoading}
      >
        {agent_id ? (
          <AgentForm
            type="update"
            agent={agent}
            onUpdate={(updatedValues) => {
              setAgent({ ...agent, ...updatedValues })
            }}
          />
        ) : (
          <AgentForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default AgentEditor
