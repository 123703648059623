import { addDays, getUnixTime } from 'date-fns'
import pluralize from 'pluralize'

/**
 * Helper functions for date
 */

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const monthsFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

/**
 *
 * @param type - Period Type
 * @param quantity - Period Quantity
 */
export const computeTTL = (type: string, quantity = 1): string => {
  switch (type) {
    case 'week':
      return (604800 * quantity).toString()
    case 'bi-week':
      return (1209600 * quantity).toString()
    case 'month':
      return (2592000 * quantity).toString()
    case 'quarter':
      return (7776000 * quantity).toString()
    default:
      return (86400 * quantity).toString()
  }
}

/**
 *
 * @param period - Period
 */
export const undoComputeTTL = (period: number): string => {
  switch (period) {
    case 604800:
      return 'week'
    case 1209600:
      return 'bi-week'
    case 2592000:
      return 'month'
    case 7776000:
      return 'quarter'
    default:
      return 'day'
  }
}

export const timestampToDate = (timestamp: any, onlyMonthYear = false): any => {
  if (!timestamp) return

  const date = new Date(timestamp * 1000)
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const day = date.getDate()
  const time = `${!onlyMonthYear ? `${day} ` : ''}${month} ${year}`

  return time
}

export const timestampToTime = (timestamp: any, format = '12h'): string => {
  if (!timestamp) return

  const date = new Date(timestamp * 1000)
  let hours = date.getHours()
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  if (format === '12h') {
    const ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours || 12

    return `${hours}:${minutes}${ampm}`
  }

  return `${hours}:${minutes}:${seconds}`
}

export const timestampToMonth = (timestamp: any): any => {
  if (!timestamp) return
  const date = new Date(timestamp * 1000)
  return monthsFull[date.getMonth()]
}

/**
 * Return date in format DDMMYYYY for excel
 * export filename
 *
 * @param date
 */
export const dateForFilename = (date): string => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  return `${day}${month}${year}`
}

/**
 * Calculate relative time in days, weeks, months & years
 *
 * @param timestamp
 * @param daysOnly - if "true" return only days count
 */
export const relativeTime = (timestamp: number, daysOnly = false): string => {
  if (!timestamp) return '0'

  const today = new Date().getTime() / 1000
  const diffDays = Math.round((today - timestamp) / 86400)

  if (daysOnly) {
    return diffDays.toString()
  } else if (diffDays < 7) {
    /** Calculate hours for less than a dat */
    if (diffDays < 1) {
      const diffHrs = Math.round(((today - timestamp) / 86400) * 24)
      return `${diffHrs} ${pluralize('hr', diffHrs)}`
    }

    return `${diffDays} ${pluralize('day', diffDays)}`
  } else if (diffDays < 28) {
    const diffWeeks = Math.round(diffDays / 7)
    return `${Math.round(diffWeeks)} ${pluralize('week', diffWeeks)}`
  } else if (diffDays < 365) {
    const diffMonths = Math.round(diffDays / 28)
    return `${Math.round(diffMonths)} ${pluralize('month', diffMonths)}`
  }
}

/**
 * Get preset timestamps for days of the week
 *
 * @returns dayOfWeek: number, presetTimestamps: array { label: string, from: number, to: number }
 */
export const getPresetTimestamps = (): {
  dayOfWeek: number
  presetTimestamps: { label: string; from: number; to: number }[]
} => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
  const dayOfWeek = new Date().getDay()
  const presetTimestamps = []

  days.forEach((label, index) => {
    const today = new Date()
    const diff = index - dayOfWeek
    const from_diff = index === 1 ? diff - 3 : diff - 1
    const to_diff = index === 1 ? diff : diff

    const from = getUnixTime(addDays(today.setHours(0, 0, 0, 0), from_diff))
    const to = getUnixTime(addDays(today.setHours(0, 0, 0, 0), to_diff))

    presetTimestamps.push({ label, from, to })
  })

  return { dayOfWeek, presetTimestamps }
}
