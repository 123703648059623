import React from 'react'

import { Spinner, Button, Text, Flex, Box } from '@chakra-ui/react'

import { FormField } from 'src/core/components'
import { OrderContext } from '../../contexts'
import { formatCurrency } from 'src/core/helpers/money.helper'

export const OrderTotal: React.FC<any> = ({ canUpdate }) => {
  const { order, setOrder, updateAmount, isUpdating, isUpdatingItems } =
    React.useContext(OrderContext)

  const [amount, setAmount] = React.useState<number>()
  const [editMode, setEditMode] = React.useState<boolean>(false)

  return (
    <Box p="12px 20px">
      {editMode ? (
        <Flex justifyContent="space-between" width="100%">
          <FormField
            name="quantity"
            type="number"
            prepend="NGN"
            value={amount}
            onChange={({ target }) => {
              setAmount(Number(target.value))
            }}
          />

          <Flex ml={2}>
            {!isUpdating && (
              <Button
                variant="outline"
                onClick={() => {
                  setAmount(order.amount)
                  setEditMode(false)
                }}
              >
                Cancel
              </Button>
            )}

            <Button
              colorScheme="primary"
              ml={2}
              onClick={() => {
                updateAmount(amount).then(() => {
                  setEditMode(false)
                  setOrder({ ...order, amount })
                })
              }}
              isDisabled={
                isUpdating ||
                isNaN(amount) ||
                !amount ||
                amount === order.amount
              }
              isLoading={isUpdating}
              loadingText="Saving"
            >
              Save
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex justifyContent="flex-end">
          {isUpdatingItems || isUpdating ? (
            <Spinner size="sm" color="primary.600" />
          ) : (
            <Text
              fontWeight="semibold"
              textAlign="right"
              justifySelf="flex-end"
              onDoubleClick={() => {
                canUpdate && setAmount(order.amount)
                canUpdate && setEditMode(true)
              }}
            >
              {formatCurrency(order.amount)}
            </Text>
          )}
        </Flex>
      )}
    </Box>
  )
}

export default OrderTotal
