import React from 'react'

import { Flex, Stack, Box, Spinner, useTheme } from '@chakra-ui/react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Role } from 'src/bootstrap/permissions/roles'
import { IStoreState } from 'src/bootstrap/store/types'
import { Layout, ErrorBox } from 'src/core/components'
import { route } from 'src/core/helpers/route.helper'
import { usePermission, useMounted } from 'src/core/hooks'

import {
  ProductForm,
  ProductTags,
  SellerProductForm,
  SellerProductLogs,
} from '../components'
import { IProduct } from '../product.type'
import { ProductService } from '../product.service'

const productService = new ProductService()

const ProductUpdate: React.FC = () => {
  const { sizes } = useTheme()

  const history = useHistory()
  const isMounted = useMounted()
  const { id: product_id }: any = useParams()
  const { userCan } = usePermission()

  const user = useSelector((state: IStoreState) => state.user)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [product, setProduct] = React.useState<IProduct>()
  const [sellerProduct, setSellerProduct] = React.useState<any>()

  React.useEffect(() => {
    const fetchProduct = (): void => {
      productService
        .fetchById(product_id)
        .then((product) => {
          isMounted.current && setProduct(product)

          if (user.role === Role.SellerManager) {
            productService
              .fetchSellerProduct(user.seller_id, product._id)
              .then((sellerProduct) => {
                isMounted.current && setSellerProduct(sellerProduct)
                isMounted.current && setIsLoading(false)
              })
          } else {
            isMounted.current && setIsLoading(false)
          }
        })
        .catch(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('update_product') && fetchProduct()
  }, [userCan, product_id, isMounted, user])

  const header = {
    title: isLoading ? 'Product' : product?.name || 'Product not found',
    backText: 'Products',
    onBack: () => history.push(route('products')),
  }

  return (
    <Layout header={header} permission="update_product">
      {!isLoading && product ? (
        <Stack
          direction={['column', 'column', 'row']}
          spacing={sizes.gutter.md}
        >
          <Box flex={user.role === Role.SellerManager ? 8 / 12 : 7 / 12}>
            <Card>
              <Card.Header>
                <Card.Title>Product Details</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                {user.role === Role.SellerManager ? (
                  <SellerProductForm
                    product={product}
                    seller={user.seller}
                    sellerProduct={sellerProduct}
                  />
                ) : (
                  <ProductForm
                    type="update"
                    product={product}
                    onUpdate={(values) => {
                      setProduct({ ...product, ...values })
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </Box>

          <Box flex={user.role === Role.SellerManager ? 4 / 12 : 5 / 12}>
            <Box position="sticky" top="0">
              {[Role.SellerManager].includes(user.role as any) ? (
                <SellerProductLogs sellerProduct={sellerProduct} />
              ) : (
                <ProductTags product={product} />
              )}
            </Box>
          </Box>
        </Stack>
      ) : null}

      {!isLoading && !product ? (
        <ErrorBox
          title="Product not found!"
          summary="Please check the URL and try again."
          my={50}
        />
      ) : null}

      {isLoading && (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      )}
    </Layout>
  )
}

export default ProductUpdate
