import React from 'react'
import {
  Tr as ChakraTr,
  TableRowProps as ChakraTrProps,
} from '@chakra-ui/table'
import { useTheme } from '@chakra-ui/react'

interface TrProps extends ChakraTrProps {
  isActive?: boolean
  isFlagged?: boolean
}
export const Tr: React.FC<TrProps> = (props) => {
  const { colors } = useTheme()
  const { children, isActive, isFlagged, css, ...rest } = props

  return (
    <ChakraTr
      css={{
        td: {
          backgroundColor: isActive
            ? colors.primary[50]
            : isFlagged
            ? colors.critical[50]
            : colors.white,
        },
        '&:hover td': {
          backgroundColor: isActive
            ? colors.primary[50]
            : isFlagged
            ? colors.critical[50]
            : colors.gray[50],
        },
        ...(css as any),
      }}
      {...rest}
    >
      {children}
    </ChakraTr>
  )
}
