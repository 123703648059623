import React from 'react'

import { Button, Spinner, Flex } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { Card } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout } from 'src/core/components'
import SearchInput from 'src/core/components/Form/SearchInput'
import DistributorOfficersTable from '../components/DistributorOfficersTable'

import useFilter from 'src/core/hooks/useFilter'
import usePermission from 'src/core/hooks/usePermission'

import useFetch from 'src/core/hooks/useFetch'

import {
  DistributorOfficerService,
  DistributorOfficerServiceProps,
} from '../distributorOfficer.service'
import { IDistributorOfficer } from '../distributorOfficer.type'
import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'

const distributorOfficerService = new DistributorOfficerService()

const DistributorOfficers: React.FC = () => {
  const { userCan } = usePermission()

  const { filters, setFilters } = useFilter('distributor_officers')

  const [distributorOfficers, isLoading] = useFetch<
    IDistributorOfficer[],
    DistributorOfficerServiceProps
  >(
    distributorOfficerService,
    'fetch',
    null,
    userCan('view_distributor_officers')
  )

  const filteredDistributorOfficers = React.useMemo(() => {
    let filtered = distributorOfficers || []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((distributorOfficer) => {
          return (
            basicSearch(distributorOfficer.name, filters.search) ||
            basicSearch(distributorOfficer.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [distributorOfficers, filters.search])

  return (
    <Layout
      headerProps={{ title: 'Distributor Officers' }}
      permission="view_distributor_officers"
    >
      <Card>
        <Card.Header>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div>
                {filteredDistributorOfficers
                  ? `${filteredDistributorOfficers.length} ${pluralize(
                      'officer',
                      filteredDistributorOfficers.length
                    )}`
                  : '0 officers'}
              </div>
              <div className="ml-3">
                <SearchInput
                  placeholder="Search officers..."
                  value={filters.search}
                  onChange={(search) => setFilters({ search, page: 1 })}
                  isDisabled={isLoading || !filteredDistributorOfficers}
                />
              </div>
            </div>

            {userCan('signup_distributor_officer') && (
              <div>
                <Link to={route('distributor_officer_signup')}>
                  <Button
                    size="sm"
                    colorScheme="primary"
                    leftIcon={<FiPlus size={16} />}
                  >
                    Create Distributor Officer
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <DistributorOfficersTable
              distributorOfficers={filteredDistributorOfficers}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default DistributorOfficers
