import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import BuyerForm from '../components/BuyerForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { buyerService, BuyerServiceProps } from '../buyer.service'
import { IBuyer } from '../buyer.type'
import { route } from 'src/core/helpers/route.helper'

const BuyerEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: buyer_id }: any = useParams()

  const permission = buyer_id ? 'update_buyer' : 'signup_buyer'

  const [buyer, isLoading, , setBuyer] = useFetch<IBuyer, BuyerServiceProps>(
    buyerService,
    'fetchById',
    [buyer_id],
    !!buyer_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Retailer' : buyer?.name || 'New Retailer',
        backText: 'Retailers',
        onBack: () => history.push(route('buyers')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={buyer_id ? 'update' : 'create'}
        model={buyer}
        isLoading={isLoading}
        isUserModel
      >
        {buyer_id ? (
          <BuyerForm
            type="update"
            buyer={buyer}
            onUpdate={(updatedValues) => {
              setBuyer({ ...buyer, ...updatedValues })
            }}
          />
        ) : (
          <BuyerForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default BuyerEditor
