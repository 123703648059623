import React from 'react'
import { Tag } from '@chakra-ui/tag'

import { Pagination } from 'src/core/components'
import { Table, Td, Th, Thead, Tbody, Tr } from 'src/core/components/Table'
import { formatCurrency, paginate, timestampToDate } from 'src/core/helpers'
import { ILedgerLog } from 'src/applets/ledger'
import { Box, Button } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { LedgerLogForm } from './LedgerLogForm'
import { ILedger } from '../ledger.type'
import { IOrderGroup } from 'src/applets/order'

interface IProps {
  /** Logs */
  orderGroup: IOrderGroup
  ledger: ILedger
  ledgerLogs: ILedgerLog[]
  setLedgerLog?: (log: ILedgerLog) => void
}
export const OrderGroupLedgerLogs: React.FC<IProps> = ({
  orderGroup,
  ledger,
  ledgerLogs,
  setLedgerLog,
}) => {
  const [page, setPage] = React.useState<number>(1)
  const [showForm, setShowForm] = React.useState<boolean>()

  const paginatedLedgerLogs = React.useMemo(() => {
    return ledgerLogs?.length ? paginate(ledgerLogs, page) : []
  }, [page, ledgerLogs])

  const colorCode = (value): string => {
    return value < 0 ? 'critical.600' : value > 0 ? 'success.600' : 'body'
  }

  return (
    <>
      {ledgerLogs?.length ? (
        <Box mb={5}>
          <Table minW="full">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Tag</Th>
                <Th textAlign="right" isNumeric>
                  Value (NGN)
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedLedgerLogs?.map((log, index) => (
                <Tr key={log?._id || index}>
                  <Td>{timestampToDate(log.created)}</Td>
                  <Td>
                    <Tag
                      size="sm"
                      colorScheme={
                        log.tag === 'order'
                          ? 'orange'
                          : log.tag === 'cash'
                          ? 'success'
                          : 'gray'
                      }
                    >
                      {log.tag}
                    </Tag>
                  </Td>

                  <Td color={colorCode(log.value)} isNumeric>
                    {formatCurrency(log.value)}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : null}

      <Pagination
        mb={5}
        totalItems={ledgerLogs?.length}
        currentPage={page}
        onPageChange={setPage}
      />

      <Button
        mb={5}
        size="sm"
        colorScheme="primary"
        leftIcon={<FiPlus size={16} />}
        onClick={() => setShowForm(true)}
      >
        New Deposit
      </Button>
      <LedgerLogForm
        ledger={showForm ? ledger : null}
        onCreate={(ledgerLog) => {
          setLedgerLog && setLedgerLog(ledgerLog)
          setShowForm(false)
        }}
        onClose={() => setShowForm(false)}
        description={orderGroup.ref}
        tag="order"
      />
    </>
  )
}
