import React from 'react'

import { Select } from '@chakra-ui/react'

import { Autocomplete, FilterMenu } from 'src/core/components'
import useFetch from 'src/core/hooks/useFetch'

import { locationService, LocationServiceProps } from '../location.service'
import { ILocationFilters } from '../location.type'

interface IProps {
  filters: ILocationFilters
  setFilters: (filters: ILocationFilters) => void
  activeFilters: string[]
}

export const LocationsTableFilter: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
}) => {
  const [states] = useFetch<any, LocationServiceProps>(
    locationService,
    'fetchStates'
  )

  const [lgaGroups] = useFetch<any, LocationServiceProps>(
    locationService,
    'fetchLGAs'
  )

  const lgas = React.useMemo(() => {
    const lgas_array = []

    if (lgaGroups) {
      lgaGroups.forEach((lgaGroup) => {
        lgaGroup.lgas.forEach((lga) => {
          lgas_array.push({
            value: lga,
            label: lga,
            state: lgaGroup.state,
          })
        })
      })
    }

    return lgas_array
  }, [lgaGroups])

  const resetFilters = (): void => {
    setFilters({
      status: '*',
      lgas: '*',
      states: '*',
    })
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length
            ? `Filtered by: ${activeFilters.join(', ')}`
            : 'Filters'
        }
        resetButtonProps={{ onClick: resetFilters }}
      >
        <FilterMenu.Section label="Status">
          <Select
            size="sm"
            value={filters.status || ''}
            onChange={({ target }: any) => {
              setFilters({ status: target.value, page: 1 })
            }}
          >
            <option value="*">All</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Select>
        </FilterMenu.Section>

        {/* State */}
        <FilterMenu.Section label="State">
          <Autocomplete
            size="sm"
            placeholder="Select state"
            value={filters.states?.[0]}
            options={
              states
                ? states.map((state) => ({
                    value: state.name,
                    label: state.name,
                  }))
                : []
            }
            onChange={(state) => {
              setFilters({ states: [state] })
            }}
          />
        </FilterMenu.Section>

        {/* LGA */}
        <FilterMenu.Section label="LGA">
          <Autocomplete
            size="sm"
            placeholder="Select LGA"
            value={filters.lgas?.[0]}
            options={lgas}
            onChange={(lga) => {
              setFilters({ lgas: [lga] })
            }}
          />
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}
