import React from 'react'

import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/form-control'
import { Box, Stack, StackProps } from '@chakra-ui/layout'

interface FormStackProps extends StackProps {
  /**
   * Form label
   * @type {string}
   */
  label?: string
  /**
   * Form helper text
   * @type {string}
   */
  helperText?: string
  /**
   * Column sizes
   * default is [3, 6]
   * @type {[number, number]}
   */
  columns?: [number, number]
  /**
   * Required field
   * @type {boolean}
   */
  isRequired?: boolean
  /**
   * Stack orientation
   * @type {'horizontal' | 'vertical'}
   */
  orientation?: 'horizontal' | 'vertical'
}
/**
 * FormStack helps you easily create stacked form group
 * elements in the horizontal or vertical direction.
 */
export const FormStack: React.FC<FormStackProps> = ({
  label,
  isRequired,
  helperText,
  columns,
  orientation,
  children,
  ...props
}) => {
  let direction = ['column']
  if (orientation === 'horizontal') {
    direction = ['column', 'column', 'row']
  }

  const leftFlex = orientation === 'horizontal' ? (columns?.[0] ?? 3) / 12 : 1
  const rightFlex = orientation === 'horizontal' ? (columns?.[1] ?? 6) / 12 : 1

  return (
    <Stack my={3} direction={direction as any} {...props}>
      {!!label || !!helperText ? (
        <Box flex={leftFlex}>
          <FormControl mb={0} isRequired={isRequired}>
            {!!label && <FormLabel mb={0}>{label}</FormLabel>}
            {!!helperText && (
              <FormHelperText color="gray.600" mt={1}>
                {helperText}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      ) : null}
      <Box flex={rightFlex}>{children}</Box>
    </Stack>
  )
}

FormStack.defaultProps = {
  orientation: 'horizontal',
}

export default FormStack
