import { CoreService, CoreServiceProps } from 'src/core/services'
import { IBrand } from './brand.type'

const defaultUploadId = process.env.REACT_APP_DEFAULT_UPLOAD_ID

export class BrandService extends CoreService<IBrand> {
  constructor() {
    super('brand')
  }

  fetchByManufacturer(mftrId: string): Promise<IBrand[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `brands_${mftrId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`brand/read_by_manufacturer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(brand: IBrand): Promise<any> {
    delete brand.manufacturer // not needed for api call

    const formData = new FormData()
    formData.append('upload_id', defaultUploadId) // default

    Object.keys(brand).forEach((key) => {
      formData.append(key, brand[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http.post('brand/create', formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(brand: IBrand): Promise<any> {
    delete brand.manufacturer // not needed for api call

    const formData = new FormData()

    Object.keys(brand).forEach((key) => {
      formData.append(key, brand[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http.post('brand/update', formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export const brandService = new BrandService()
export type BrandServiceProps = CoreServiceProps | keyof BrandService
