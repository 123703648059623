import React from 'react'

import { Text, Badge, Button, Spinner, Box, Flex } from '@chakra-ui/react'
import { Table, Card } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'

import {
  Layout,
  ErrorBox,
  SearchInput,
  Pagination,
  ImageBox,
} from 'src/core/components'
import {
  route,
  basicSearch,
  asset,
  quickPluralize,
  paginate,
} from 'src/core/helpers'
import { useFilter, useMounted, usePermission } from 'src/core/hooks'

import { IManufacturer } from '../manufacturer.type'
import { ManufacturerService } from '../manufacturer.service'

const manufacturerService = new ManufacturerService()

const Manufacturers: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [mftrs, setMftrs] = React.useState<IManufacturer[]>()

  const { filters, setFilters } = useFilter('manufacturers')

  React.useEffect(() => {
    const fetchManufacturers = (): void => {
      manufacturerService
        .fetch()
        .then((mftrs) => {
          isMounted.current && setMftrs(mftrs)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_manufacturers') && fetchManufacturers()
  }, [isMounted, userCan])

  const filteredMftrs = React.useMemo(() => {
    let filtered = mftrs || []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((mftr) => {
          return (
            basicSearch(mftr.name, filters.search) ||
            basicSearch(mftr.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [mftrs, filters.search])

  const paginatedManufacturers = React.useMemo(() => {
    return filteredMftrs ? paginate(filteredMftrs, filters.page) : []
  }, [filters.page, filteredMftrs])

  return (
    <Layout
      headerProps={{ title: 'Manufacturers' }}
      permission="view_manufacturers"
    >
      <Card>
        <Card.Header>
          <Flex alignItems="center">
            <Text>{quickPluralize(filteredMftrs, 'manufacturer')}</Text>
            <Box ml={3}>
              <SearchInput
                placeholder="Search manufacturers"
                value={filters.search}
                onChange={(search) => setFilters({ search, page: 1 })}
                isDisabled={isLoading || !filteredMftrs}
              />
            </Box>
          </Flex>

          {userCan('create_manufacturer') && (
            <Link to={route('manufacturer_create')}>
              <Button
                size="sm"
                leftIcon={<FiPlus size={16} />}
                colorScheme="primary"
              >
                Create Manufacturer
              </Button>
            </Link>
          )}
        </Card.Header>

        <Card.Body className="px-0 py-0">
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && !paginatedManufacturers.length ? (
            <ErrorBox summary="No manufacturers found" my={50} />
          ) : null}

          {!isLoading && paginatedManufacturers.length ? (
            <div className="table-wrapper padded">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">--</th>
                    <th>Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedManufacturers.map((manufacturer, index) => (
                    <tr
                      key={manufacturer._id}
                      className="cursor-pointer"
                      onClick={() =>
                        history.push(
                          route('manufacturer_update', {
                            id: manufacturer._id,
                          })
                        )
                      }
                    >
                      <td className="align-middle text-center">
                        {index + 1 + (filters.page - 1) * 10}
                      </td>
                      <td className="align-middle text-center">
                        <div className="d-flex justify-content-center">
                          <ImageBox
                            src={
                              manufacturer.upload
                                ? asset(manufacturer.upload.path)
                                : ''
                            }
                          />
                        </div>
                      </td>
                      <td className="align-middle">{manufacturer.name}</td>
                      <td className="align-middle">
                        <Badge
                          colorScheme={
                            manufacturer.status === 'active'
                              ? 'success'
                              : 'critical'
                          }
                        >
                          {manufacturer.status}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : null}

          <Pagination
            my={5}
            itemsPerPage={filters.per_page}
            totalItems={filteredMftrs.length}
            currentPage={filters.page}
            onPageChange={(page) => setFilters({ page })}
          />
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default Manufacturers
