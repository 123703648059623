import React from 'react'

import { Select } from '@chakra-ui/react'
import { fromUnixTime, getUnixTime, set } from 'date-fns'

import { DateRangeInput, FilterMenu } from 'src/core/components'
import { OrderTag } from '../../order.type'

interface IProps {
  filters: any
  setFilters: (filters: any) => void
  activeFilters: string[]
  isLoading: boolean
  salesOfficerOptions?: { value: string; label: string }[]
}

export const OrderGroupsTableFilter: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
  isLoading,
  salesOfficerOptions,
}) => {
  const [params, setParams] = React.useState<any>(filters)

  /** Watch for when filters are changed */
  const [pristine, setPristine] = React.useState<boolean>(true)

  React.useEffect(() => {
    setPristine(false)
  }, [params])

  const handleFilter = (): void => {
    setFilters({
      ...params,
    })
    setPristine(true)
  }

  const resetFilters = (): void => {
    const tempFilters = {
      tag: '*',
      payment_status: '*',
      sales_officer: '*',
      start_date: null,
      end_date: null,
    }
    setFilters(tempFilters)
    setParams(tempFilters)
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length ? `Filters (${activeFilters.length})` : 'Filters'
        }
        resetButtonProps={{ onClick: resetFilters }}
        refreshButtonProps={{
          onClick: handleFilter,
          isDisabled:
            !params.start_date || !params.end_date || isLoading || pristine,
          isLoading: isLoading,
        }}
      >
        <FilterMenu.Section label="Status">
          <Select
            size="sm"
            value={filters.tag || ''}
            onChange={({ target }) => {
              setFilters({ tag: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value="pencom" label="Pending & Completed" />
            <option value={OrderTag.Pending} label="Pending" />
            <option value={OrderTag.Completed} label="Completed" />
            <option value={OrderTag.Cancelled} label="Cancelled" />
            <option value={OrderTag.Deleted} label="Deleted" />
            <option value={OrderTag.Draft} label="Draft" />
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Payment Status">
          <Select
            size="sm"
            value={filters.payment_status || ''}
            onChange={({ target }) => {
              setFilters({ payment_status: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value="paid" label="Paid" />
            <option value="unpaid" label="Unpaid" />
            <option value="owing" label="Owing" />
          </Select>
        </FilterMenu.Section>

        {salesOfficerOptions?.length ? (
          <FilterMenu.Section label="Delivery Associate">
            <Select
              size="sm"
              value={filters.sales_officer || ''}
              onChange={({ target }) => {
                setFilters({ sales_officer: target.value, page: 1 })
              }}
            >
              <option value="*" label="All" />
              {salesOfficerOptions.map((salesOfficer) => (
                <option key={salesOfficer.value} value={salesOfficer.value}>
                  {salesOfficer.label}
                </option>
              ))}
            </Select>
          </FilterMenu.Section>
        ) : null}

        <FilterMenu.Section label="Date">
          <DateRangeInput
            start={params.start_date}
            end={params.end_date}
            onChange={(start_date, end_date) => {
              setParams({
                ...params,
                start_date: getUnixTime(
                  set(fromUnixTime(start_date), {
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                  })
                ),
                end_date: getUnixTime(
                  set(fromUnixTime(end_date), {
                    hours: 23,
                    minutes: 59,
                    seconds: 0,
                  })
                ),
              })
            }}
          />
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}

export default OrderGroupsTableFilter
