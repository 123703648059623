import React from 'react'

import { Badge, Button, Flex } from '@chakra-ui/react'
import { Table } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import { ErrorBox, Pagination } from 'src/core/components'
import { ISeller } from '../seller.type'

interface IProps {
  sellers: ISeller[]
  filters: any
  setFilters: (filters: any) => void
}

const SellersTable: React.FC<IProps> = ({ sellers, filters, setFilters }) => {
  const history = useHistory()

  const paginatedSellers = React.useMemo(() => {
    return sellers ? paginate(sellers, filters.page) : []
  }, [filters.page, sellers])

  const openSeller = (seller): void => {
    history.push(
      route('seller_update', {
        id: seller._id,
      })
    )
  }

  return (
    <>
      {!paginatedSellers.length ? <ErrorBox summary="No hubs found" /> : null}

      {paginatedSellers.length ? (
        <div className="table-wrapper padded">
          <Table hover responsive>
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Name</th>
                <th>Manager</th>
                <th>Status</th>
                <th>Manufacturers</th>
              </tr>
            </thead>
            <tbody>
              {paginatedSellers.map((seller, index) => (
                <tr key={seller._id}>
                  <td className="align-middle text-center">
                    {index + 1 + (filters.page - 1) * 10}
                  </td>
                  <td className="align-middle">{seller.name}</td>
                  <td className="align-middle">
                    {seller?.manager?.name || '-'}
                  </td>
                  <td className="align-middle">
                    <Badge
                      colorScheme={
                        seller.status === 'active' ? 'success' : 'critical'
                      }
                    >
                      {seller.status}
                    </Badge>
                  </td>
                  <td>
                    <Flex>
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => openSeller(seller)}
                      >
                        View
                      </Button>

                      <Button
                        ml={3}
                        size="sm"
                        variant="outline"
                        onClick={() => {
                          history.push(
                            route('seller_manufacturers', {
                              id: seller._id,
                            })
                          )
                        }}
                      >
                        Manufacturers
                      </Button>
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}

      <Pagination
        my={5}
        itemsPerPage={10}
        totalItems={sellers.length}
        currentPage={filters.page}
        onPageChange={(page) => setFilters({ page })}
      />
    </>
  )
}

export default SellersTable
