// this component will soon be deprecated
import React, { useState } from 'react'

import { Badge, Box, Flex } from '@chakra-ui/layout'
import { Text, Spinner } from '@chakra-ui/react'
import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import { Link } from 'react-router-dom'

import { basicSearch, formatCurrency, route } from 'src/core/helpers'
import { useMounted } from 'src/core/hooks'

import { warehouseService } from '../warehouse.service'
import { IWarehouse } from '../warehouse.type'
import { SearchInput } from 'src/core/components'

interface SectionProps {
  warehouse: IWarehouse
}

const WarehouseLogs: React.FC<SectionProps> = ({ warehouse }) => {
  const isMounted = useMounted()

  const [logs, setLogs] = useState<any[]>()
  const [isLoadingLogs, setIsLoadingLogs] = useState<boolean>(false)

  const [searchText, setSearchText] = React.useState<string>('')

  const filteredLogs = React.useMemo<any[]>(() => {
    if (searchText === '') {
      return logs
    }
    return logs.filter((log) => {
      return (
        basicSearch(log.ref, searchText) ||
        basicSearch(log.product.name, searchText)
      )
    })
  }, [logs, searchText])

  React.useEffect(() => {
    const fetchLogs = (): any => {
      setIsLoadingLogs(true)

      warehouseService.fetchLogs(warehouse._id).then((logs) => {
        if (isMounted.current && logs?.length) {
          logs = logs.reverse()
          setLogs(logs)
        }
        isMounted.current && setIsLoadingLogs(false)
      })
    }

    fetchLogs()
  }, [warehouse._id, isMounted])

  return (
    <Box mb={4}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="tinyTitle">
          Logs ({logs?.length > 50 ? '50+' : logs?.length || 0})
        </Text>

        <Box>
          <SearchInput
            placeholder="Search logs"
            value={searchText}
            onChange={(search) => {
              setSearchText(search)
            }}
            isDisabled={!logs?.length}
          />
        </Box>
      </Flex>
      {isLoadingLogs ? (
        <Box pt={1}>
          <Spinner size="sm" color="primary.600" />
        </Box>
      ) : (
        <>
          {filteredLogs?.length ? (
            filteredLogs.slice(0, 50).map((log) => {
              return (
                <React.Fragment key={log._id}>
                  <Box mt={4}>
                    <Flex mb={1} justifyContent="space-between">
                      <Text pr={3} noOfLines={1} title={log.product.name}>
                        {log.product.name}
                      </Text>

                      <Badge
                        fontSize="sm"
                        colorScheme={
                          log.quantity > 0
                            ? 'success'
                            : log.quantity < 0
                            ? 'critical'
                            : 'gray'
                        }
                      >
                        {(log.quantity / log.product.case_count).toFixed(1)}
                      </Badge>
                    </Flex>

                    <Flex alignItems="center" gridGap={2}>
                      <Text fontSize="sm">
                        <Link
                          to={route('purchase_order', {
                            id: log?.purchase_order?._id,
                          })}
                        >
                          #{log?.ref}
                        </Link>
                      </Text>
                      <Text color="critical.600" fontSize="sm">
                        Buy: {formatCurrency(log?.cost_price)}
                      </Text>
                      <Text color="success.600" fontSize="sm">
                        Sell: {formatCurrency(log?.sell_price)}
                      </Text>
                    </Flex>

                    <Text color="gray.600" fontSize="sm">
                      {format(
                        fromUnixTime(log.created),
                        'dd MMM, yyy @h:mmaaa'
                      )}
                    </Text>
                  </Box>
                </React.Fragment>
              )
            })
          ) : (
            <Text fontSize="sm" color="gray.600">
              No logs found
            </Text>
          )}
        </>
      )}
    </Box>
  )
}

export default WarehouseLogs
