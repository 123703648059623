import { lang } from 'src/bootstrap/lang'

/**
 * Translate string using language filters
 * @param string
 * @returns
 */
export const trans = (string: string, defaultString?: string): string => {
  if (!string) return null

  const parts = string.split('.')
  const model = parts.shift()
  const langString = parts.join('.')

  if (model && langString) {
    return (
      lang?.[model]?.[langString] ||
      defaultString ||
      `__trans error (${string})`
    )
  } else {
    return defaultString || `__trans error (${string})`
  }
}
