import React from 'react'
import { Box, Text, Flex, IconButton } from '@chakra-ui/react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu'
import pluralize from 'pluralize'
import { FiEdit3, FiMoreVertical, FiTrash2 } from 'react-icons/fi'

import { ImageBox } from 'src/core/components'
import { asset, formatCurrency } from 'src/core/helpers'
import { usePermission } from 'src/core/hooks'

import { OrderProductContext } from './OrderProduct'

export const Product: React.FC = () => {
  const orderProductContext = React.useContext(OrderProductContext)

  const { item } = orderProductContext
  const { editMode } = orderProductContext

  return (
    <Flex alignItems="center">
      <ImageBox
        width="32px"
        src={item?.product?.upload ? asset(item?.product?.upload?.path) : ''}
        borderWidth="1px"
      />

      <Flex
        title={item.product.name}
        flexDir="column"
        justifyContent="center"
        ml="10px"
      >
        <Text fontWeight="medium">{item.product.name}</Text>
        <Text
          fontSize="sm"
          color="gray.600"
          display={editMode ? 'block' : 'none'}
          className="meta"
        >
          {item.product.case_count} units / case
        </Text>
      </Flex>
    </Flex>
  )
}

export const Quantity: React.FC = () => {
  const orderProductContext = React.useContext(OrderProductContext)

  const { computed, editMode, acceptMode } = orderProductContext

  const quantityText = React.useMemo(() => {
    const _quantity = []
    const { cases, units } = computed

    if (cases) _quantity.push(`${cases} ${pluralize('case', cases)}`)
    if (units) _quantity.push(`${units} ${pluralize('unit', units)}`)

    return _quantity?.length ? _quantity : ['-']
  }, [computed])

  return (
    <Text textAlign="right">
      {quantityText.join(', ')}
      {!acceptMode && (
        <Text as="span" fontSize="sm" color="gray.600" display="block">
          @ {formatCurrency(editMode ? computed.newRate : computed.oldRate)} /
          case
        </Text>
      )}
    </Text>
  )
}

export const Amount: React.FC = () => {
  const orderProductContext = React.useContext(OrderProductContext)

  const { item, orderProductProps } = orderProductContext
  const { computed } = orderProductContext

  return (
    <Text textAlign="right">
      {!orderProductProps.isAmountEditable &&
        (item.quantity / item.product.case_count) * computed.oldRate !==
          item.amount && (
          <Text as="b" className="text-danger">
            *{' '}
          </Text>
        )}

      {formatCurrency(computed.amount)}
    </Text>
  )
}

export const Options: React.FC = () => {
  const { userCan } = usePermission()

  const orderProductContext = React.useContext(OrderProductContext)

  const { item, editMode, computed, orderProductProps } = orderProductContext
  const { isDeleting, setEditMode, handleDelete } = orderProductContext
  const { setUpdatedQuantity } = orderProductContext
  const { setUpdatedRate } = orderProductContext
  const { setIsPristine } = orderProductContext

  return (
    <>
      {userCan('update_order') && (
        <Box ml="20px">
          {orderProductProps.isEditable ? (
            <Menu autoSelect={false}>
              <MenuButton
                size="sm"
                as={IconButton}
                variant="ghost"
                icon={<FiMoreVertical size={16} />}
                isLoading={isDeleting}
                isDisabled={editMode || isDeleting}
              />

              <MenuList mt="0.1">
                <MenuItem
                  icon={<FiEdit3 size={16} />}
                  onClick={() => {
                    if (orderProductProps.isAmountEditable) {
                      setUpdatedRate(computed.oldRate)
                    }

                    setUpdatedQuantity(item.quantity)
                    setIsPristine(true)
                    setEditMode(true)
                  }}
                >
                  Edit
                </MenuItem>
                {userCan('update_order') && orderProductProps.deleteItem ? (
                  <MenuItem
                    icon={<FiTrash2 size={16} className="text-danger" />}
                    color="critical.600"
                    onClick={handleDelete}
                  >
                    Delete
                  </MenuItem>
                ) : null}
              </MenuList>
            </Menu>
          ) : null}
        </Box>
      )}
    </>
  )
}
