import React from 'react'

import { Select } from '@chakra-ui/react'

import { FilterMenu } from 'src/core/components'
import { BrandAutocomplete } from 'src/applets/brand'
import { ManufacturerAutocomplete } from 'src/applets/manufacturer'
import { IProductFilters } from '../../product.type'

interface ProductsTableFilterProps {
  filters: IProductFilters
  setFilters: (filters: IProductFilters) => void
  activeFilters: string[]
}
export const ProductsTableFilter: React.FC<ProductsTableFilterProps> = ({
  filters,
  setFilters,
  activeFilters,
}) => {
  const resetFilters = (): void => {
    setFilters({
      ...filters,
      manufacturers: '*',
      brands: '*',
      status: '*',
    })
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length
            ? `Filtered by: ${activeFilters.join(', ')}`
            : 'Filters'
        }
        resetButtonProps={{ onClick: resetFilters }}
      >
        <FilterMenu.Section label="Status">
          <Select
            as="select"
            size="sm"
            value={filters.status}
            onChange={({ target }: any) => {
              setFilters({ ...filters, status: target.value })
            }}
          >
            <option value="*" label="All" />
            <option value="active" label="Active" />
            <option value="inactive" label="Inactive" />
          </Select>
        </FilterMenu.Section>

        {/* Manufacturer */}
        <FilterMenu.Section label="Manufacturers">
          <ManufacturerAutocomplete
            value={filters.manufacturers}
            onChange={(manufacturers) => {
              setFilters({
                ...filters,
                manufacturers,
                brands: '*',
              })
            }}
            disableSelectAll={true}
            isMulti
          />
        </FilterMenu.Section>

        <FilterMenu.Section label="Brands">
          <BrandAutocomplete
            value={filters.brands}
            onChange={(brands) => {
              setFilters({ ...filters, brands })
            }}
            manufacturers={
              filters?.manufacturers !== '*' && filters?.manufacturers?.length
                ? filters?.manufacturers.map(({ value }) => value)
                : null
            }
            disableSelectAll={true}
            isMulti
          />
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}

export default ProductsTableFilter
