import React from 'react'

import { Button, ButtonProps, Tooltip } from '@chakra-ui/react'
import { FiMinimize2, FiMaximize2 } from 'react-icons/fi'

type CustomButtonProps = Omit<ButtonProps, 'onClick'>

interface ToggleDetailsButtonProps extends CustomButtonProps {
  label?: string
  tooltipLabel?: string
  isShowing: boolean
  onClick: (arg: boolean) => void
  isDisabled?: boolean
}
export const ToggleDetailsButton: React.FC<ToggleDetailsButtonProps> = ({
  label,
  tooltipLabel,
  isShowing,
  onClick,
  isDisabled,
  ...props
}) => {
  return (
    <Tooltip
      label={
        tooltipLabel || isShowing ? 'Hide some columns' : 'Show all  columns'
      }
    >
      <Button
        size="sm"
        variant="outline"
        isDisabled={isDisabled}
        leftIcon={isShowing ? <FiMinimize2 /> : <FiMaximize2 />}
        onClick={() => onClick(!isShowing)}
        {...props}
      >
        {label || isShowing ? 'Hide Details' : 'Show Details'}
      </Button>
    </Tooltip>
  )
}

export default ToggleDetailsButton
