import React from 'react'

import { Autocomplete, AutocompleteProps } from 'src/core/components'
import { useFetch } from 'src/core/hooks'
import { sortByKey, undoSlugify } from 'src/core/helpers'

import { IWarehouse } from '../warehouse.type'
import { warehouseService } from '../warehouse.service'
interface IProps extends AutocompleteProps {
  exclude?: string[]
  disableSelectAll?: boolean
}
export const WarehouseAutocomplete: React.FC<IProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [warehouses] = useFetch<IWarehouse[]>(warehouseService, 'fetch')

  const warehouseOptions = React.useMemo(() => {
    if (warehouses?.length) {
      const options = []

      if (!props.isMulti && !props.disableSelectAll) {
        options.push({ value: '*', label: 'All Warehouses' })
      }

      const filtered = warehouses
        .filter((warehouse) => {
          return (
            warehouse.status === 'active' &&
            !props?.exclude?.includes(warehouse?._id) &&
            !props?.exclude?.includes(warehouse?.parent_type)
          )
        })
        .map((warehouse) => ({
          value: warehouse._id,
          label: `${warehouse?.parent?.name} (${undoSlugify(
            warehouse.parent_type
          ).toUpperCase()})`,
        }))

      return [...options, ...sortByKey(filtered, 'label')]
    }

    return []
  }, [warehouses, props.isMulti, props.disableSelectAll, props?.exclude])

  return (
    <Autocomplete
      size="sm"
      options={warehouseOptions}
      value={value}
      placeholder={props.placeholder || 'Select Warehouse'}
      onChange={(selected) => onChange(selected)}
      isActive={value?.value !== '*'}
      isDisabled={!warehouseOptions || !warehouseOptions.length}
      {...props}
    />
  )
}
