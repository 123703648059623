import React from 'react'

import { Flex, Box, Spinner } from '@chakra-ui/react'

import { ErrorBox, SearchInput } from 'src/core/components'
import { Card, CardBody, CardHeader } from 'src/core/components/Card'
import {
  useExportable,
  ExportButton,
  IExportableColumn,
} from 'src/core/components/Export'
import { useFetch } from 'src/core/hooks'

import { WarehouseProductsTableFilter } from './WarehouseProductsTableFilter'
import { InventoryReportTable } from '../Inventory'
import { useFilterWarehouseProducts } from '../../hooks/useFilterWarehouseProducts'
import {
  warehouseService,
  WarehouseServiceProps,
} from '../../warehouse.service'
import { IWarehouse, IWarehouseProduct } from '../../warehouse.type'

const exportableColumns: IExportableColumn<IWarehouseProduct>[] = [
  {
    label: 'Products',
    accessor: 'product.name',
    selected: true,
  },
  {
    label: 'Quantity (Cases)',
    accessor: 'quantity',
    selected: true,
    modifier: (quantity, warehouseProduct) => {
      return (quantity / warehouseProduct?.product?.case_count)?.toFixed(1)
    },
  },
]

interface IProps {
  warehouse: IWarehouse
}
export const WarehouseProducts: React.FC<IProps> = (props) => {
  const [warehouseProducts, isLoading] = useFetch<any, WarehouseServiceProps>(
    warehouseService,
    'fetchProducts',
    [props?.warehouse?._id],
    !!props?.warehouse
  )

  const { filters, setFilters, filteredProducts, activeFilters } =
    useFilterWarehouseProducts(warehouseProducts, {
      id: props?.warehouse?._id,
    })

  const exportableProducts = useExportable(filteredProducts, exportableColumns)

  return (
    <>
      <Box padding="20px" overflow="scroll" position="relative" height={'100%'}>
        <Card>
          {isLoading && (
            <CardBody>
              <Flex justifyContent="center" my={50}>
                <Spinner color="primary.600" />
              </Flex>
            </CardBody>
          )}

          {!isLoading && !warehouseProducts?.length ? (
            <CardBody>
              <ErrorBox summary="No products found in warehouse" my={50} />
            </CardBody>
          ) : null}

          {!isLoading && warehouseProducts?.length ? (
            <>
              <CardHeader
                as={Flex}
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex alignItems="center">
                  <SearchInput
                    placeholder="Search products"
                    value={filters.search}
                    onChange={(search) =>
                      setFilters({ ...filters, search, page: 1 })
                    }
                    isDisabled={isLoading || !filteredProducts}
                  />

                  <Box ml={3}>
                    <WarehouseProductsTableFilter
                      filters={filters}
                      setFilters={setFilters}
                      activeFilters={activeFilters}
                    />
                  </Box>
                </Flex>

                <Flex alignItems="center">
                  {/* Export button */}
                  <ExportButton
                    ml={2}
                    filename="suplias-warehouse-products"
                    exportData={exportableProducts?.data}
                    exportableColumns={exportableProducts.columns}
                    toggleExportableColumn={exportableProducts.toggleColumn}
                    isDisabled={!exportableProducts?.data?.length}
                  />
                </Flex>
              </CardHeader>

              <CardBody padding={0} pb={3}>
                <InventoryReportTable
                  entries={filteredProducts}
                  filters={{ ...filters, context: 'current_inventory' }}
                  setFilters={setFilters}
                />
              </CardBody>
            </>
          ) : null}
        </Card>
      </Box>
    </>
  )
}
