import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { ErrorMessage, Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { Autocomplete, FormikField, FormStack } from 'src/core/components'

import useMounted from 'src/core/hooks/useMounted'
import useToast from 'src/core/hooks/useToast'
import { IBuyer } from '../buyer.type'
import { BuyerService } from '../buyer.service'
import { LocationService } from 'src/applets/location'
import { StoreTypeService } from 'src/applets/storeType'

import { route } from 'src/core/helpers/route.helper'

interface BuyerFormProps {
  type?: 'signup' | 'update'
  buyer?: IBuyer
  onUpdate?: (updatedValues: IBuyer) => void
}

const buyerService = new BuyerService()
const locationService = new LocationService()
const storeTypeService = new StoreTypeService()

const BuyerForm: React.FC<BuyerFormProps> = ({ type, buyer, onUpdate }) => {
  const history = useHistory()
  const isMounted = useMounted()
  const { addToast } = useToast()

  const statuses = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ]
  const [locations, setLocations] = React.useState<any[]>([])
  const [storeTypes, setStoreTypes] = React.useState<any[]>([])

  React.useEffect(() => {
    const fetchLocations = (): void => {
      locationService.fetch().then((locations) => {
        const activeLocations = locations
          .filter((location) => location.status === 'active')
          .map((location) => ({
            value: location._id,
            label: location.name,
          }))
        isMounted.current && setLocations(activeLocations)
      })
    }

    const fetchStoreTypes = (): void => {
      storeTypeService.fetch().then((store_types) => {
        const activeStoreTypes = store_types
          .filter((store_type) => store_type.status === 'active')
          .map((store_type) => ({
            value: store_type._id,
            label: store_type.name,
          }))

        isMounted.current && setStoreTypes(activeStoreTypes)
      })
    }

    fetchLocations()
    fetchStoreTypes()
  }, [isMounted])

  const formConfig = {
    initialValues: {
      name: buyer?.name || '',
      person: buyer?.person || '',
      phone: buyer?.phone || '',
      status:
        statuses.find((status) => status.value === buyer?.status) ||
        statuses[0],
      store_type: buyer
        ? {
            value: buyer.store_type._id,
            label: buyer.store_type.name,
          }
        : null,
      location: buyer
        ? {
            value: buyer.location._id,
            label: buyer.location.name,
          }
        : null,
      address: buyer?.address || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Store name field is required.')
        .min(3, 'Store name must be at least 3 characters.'),
      phone: Yup.string()
        .required('Phone number field is required.')
        .matches(/^[0][7-9][0-1][0-9]{8}$/, 'Phone number must be valid.'),
      store_type: Yup.string().required('Store type field is required.'),
      location: Yup.string().required('Location field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        name: values.name,
        person: values.person,
        phone: values.phone,
        address: values.address,
        location_id: values.location.value,
        store_type_id: values.store_type.value,
        status: values.status.value,
      }

      if (type === 'update') {
        finalValues._id = buyer._id
        finalValues.user_id = buyer.user_id
      } else {
        finalValues.hash = 'password' // default
      }

      buyerService[type](finalValues)
        .then((buyer) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Buyer successfully updated.', { appearance: 'success' })
            onUpdate(finalValues)
          } else {
            addToast('Buyer successfully created.', { appearance: 'success' })
            history.push(route('buyer_update', { id: buyer._id }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const columns: [number, number] = [
    type === 'update' ? 4 : 3,
    type === 'update' ? 8 : 6,
  ]

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Phone */}
            <FormStack
              label="Phone"
              helperText="Retailer phone number"
              columns={columns}
              isRequired
            >
              <FormikField
                name="phone"
                type="text"
                prepend="+234"
                readOnly={type === 'update'}
              />
            </FormStack>

            {/* Name */}
            <FormStack
              label="Store Name"
              helperText="Buyer store name"
              columns={columns}
              isRequired
            >
              <FormikField
                name="name"
                type="text"
                isInvalid={touched.name && errors.name ? true : false}
              />
            </FormStack>

            {/* Retailer name */}
            {type === 'update' && (
              <FormStack
                label="Retailer Name"
                columns={columns}
                alignItems="center"
                isRequired
              >
                <FormikField name="person" type="text" />
              </FormStack>
            )}

            {/* Store type */}
            <FormStack
              label="Store Type"
              helperText="Retailer store type / channel"
              columns={columns}
              isRequired
            >
              <Autocomplete
                name="store_type"
                options={storeTypes}
                value={values.store_type}
                placeholder="Select store type"
                onBlur={() => {
                  formik.setFieldTouched('store_type', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('store_type', selected)
                }}
                isInvalid={
                  touched.store_type && errors.store_type ? true : false
                }
                isDisabled={!storeTypes.length}
              />
              <ErrorMessage
                name="store_type"
                component="span"
                className="invalid-feedback"
              />
            </FormStack>

            {/* Location */}
            <FormStack
              label="Store Location"
              helperText="Retailer store location"
              columns={columns}
              isRequired
            >
              <Autocomplete
                name="location"
                options={locations}
                value={values.location}
                placeholder="Select location"
                onBlur={() => {
                  formik.setFieldTouched('location', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('location', selected)
                }}
                isInvalid={touched.location && errors.location ? true : false}
                isDisabled={!locations.length}
              />
              <ErrorMessage
                name="location"
                component="span"
                className="invalid-feedback"
              />
            </FormStack>

            {/* Store address */}
            {type === 'update' && (
              <FormStack
                label="Store Address"
                helperText="Retailer store address"
                columns={columns}
                isRequired
              >
                <FormikField as="textarea" rows={3} name="address" />
              </FormStack>
            )}
          </VStack>

          {/* Status */}
          <FormStack
            label="Status"
            helperText={`Retailer status ${buyer.status}`}
            columns={columns}
            isRequired
          >
            <Autocomplete
              name="status"
              options={statuses}
              value={values.status}
              placeholder="Select status"
              onBlur={() => {
                formik.setFieldTouched('status', true)
              }}
              onChange={(selected) => {
                formik.setFieldValue('status', selected)
              }}
              isInvalid={touched.status && errors.status ? true : false}
            />
            <ErrorMessage
              name="status"
              component="span"
              className="invalid-feedback"
            />
          </FormStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'signup' ? 'Create' : 'Update'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

BuyerForm.defaultProps = {
  type: 'signup',
}

export default BuyerForm
