import React from 'react'

import { Badge, Button, Text } from '@chakra-ui/react'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { useHistory } from 'react-router'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Thead, Tbody, Tr, Th, Td } from 'src/core/components/Table'
import {
  formatCurrency,
  paginate,
  route,
  timestampToDate,
  undoSlugify,
} from 'src/core/helpers'

import {
  IPurchaseOrder,
  IPurchaseOrderFilters,
  PurchaseOrderTag,
} from '../purchaseOrder.type'
import { useSort } from 'src/core/hooks'
import { getPurchaseOrderType } from '../purchaseOrder.helper'

interface IProps {
  purchaseOrders: IPurchaseOrder[]
  filters: IPurchaseOrderFilters
  setFilters: (filters: IPurchaseOrderFilters) => void
  setPage: (page: number) => void
}

const PurchaseOrdersTable: React.FC<IProps> = (props) => {
  const history = useHistory()

  const { purchaseOrders, filters, setPage } = props

  const { getThProps, sorted: sortedPurchaseOrders } = useSort(purchaseOrders, {
    accessor: 'timestamp',
    dir: 'desc',
  })

  const paginatedPurchaseOrders = React.useMemo(() => {
    return sortedPurchaseOrders.data
      ? paginate(sortedPurchaseOrders.data, filters.page, filters.per_page)
      : []
  }, [filters.page, filters.per_page, sortedPurchaseOrders])

  const openPurchaseOrder = (purchaseOrder, routeId = 'purchase_order'): void =>
    history.push(
      route(routeId, {
        id: purchaseOrder._id,
      })
    )

  return (
    <>
      {!paginatedPurchaseOrders.length ? (
        <ErrorBox summary="No purchase orders found" my={50} />
      ) : null}

      {paginatedPurchaseOrders?.length ? (
        <>
          <Table isFit>
            <Thead>
              <Tr>
                <Th textAlign="center">#</Th>
                <Th {...getThProps('created')}>Created</Th>
                <Th {...getThProps('timestamp')}>Date</Th>
                <Th>Ref</Th>
                <Th {...getThProps('tag')}>Tag</Th>
                <Th>Type</Th>
                <Th>Source</Th>
                <Th>Destination</Th>
                <Th {...getThProps('amount')} isNumeric>
                  Amount
                </Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedPurchaseOrders.map((purchaseOrder, index) => {
                const timestampDiff = differenceInMinutes(
                  purchaseOrder?.timestamp,
                  purchaseOrder?.created
                )

                return (
                  <Tr
                    key={purchaseOrder._id}
                    isFlagged={Math.abs(timestampDiff) > 0}
                  >
                    <Td textAlign="center">
                      {index + 1 + (filters.page - 1) * filters.per_page}
                    </Td>
                    <Td>
                      <Text>{timestampToDate(purchaseOrder.created)}</Text>
                    </Td>
                    <Td>
                      <Text>{timestampToDate(purchaseOrder.timestamp)}</Text>
                      {Math.abs(timestampDiff) > 0 && (
                        <Text fontSize="sm" color="critical.600">
                          Actual: {timestampToDate(purchaseOrder.created)}
                        </Text>
                      )}
                    </Td>
                    <Td>#{purchaseOrder.ref}</Td>
                    <Td>
                      <Badge
                        colorScheme={
                          purchaseOrder.tag === PurchaseOrderTag.PurchaseOrder
                            ? 'orange'
                            : 'info'
                        }
                      >
                        {undoSlugify(purchaseOrder.tag)}
                      </Badge>
                    </Td>
                    <Td>
                      <Badge
                        colorScheme={
                          ['Shipment Received', 'Stock Returned'].includes(
                            getPurchaseOrderType(purchaseOrder)
                          )
                            ? 'success'
                            : 'critical'
                        }
                      >
                        {getPurchaseOrderType(purchaseOrder)}
                      </Badge>
                    </Td>
                    <Td>
                      <Text>{purchaseOrder.source_obj?.name || '-'}</Text>
                    </Td>
                    <Td>
                      <Text>{purchaseOrder.destination_obj?.name || '-'}</Text>
                    </Td>
                    <Td isNumeric>{formatCurrency(purchaseOrder.amount)}</Td>
                    <Td>
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => openPurchaseOrder(purchaseOrder)}
                      >
                        View
                      </Button>

                      <Button
                        ml={3}
                        size="sm"
                        variant="outline"
                        onClick={() =>
                          openPurchaseOrder(
                            purchaseOrder,
                            'purchase_order_update'
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>

          <Pagination
            my={5}
            itemsPerPage={filters.per_page}
            totalItems={purchaseOrders.length}
            currentPage={filters.page}
            onPageChange={setPage}
            justifyContent="center"
          />
        </>
      ) : null}
    </>
  )
}

export default PurchaseOrdersTable
