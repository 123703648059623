import { ManagerRole } from 'src/applets/manager'
import { SalesOfficerRole } from 'src/applets/salesOfficer'

export const coreLang = {
  'administrator.administrator': 'Suplias Admin',
  [ManagerRole.Staff]: 'Suplias Staff',
  [ManagerRole.Buyer]: 'Suplias Buyer',
  [ManagerRole.Accountant]: 'Suplias Accountant',
  [ManagerRole.WarehouseOfficer]: 'Suplias Warehouse Officer',
  [ManagerRole.CustomerSupport]: 'Customer Support',
  [SalesOfficerRole.Manager]: 'Hub Manager',
  [SalesOfficerRole.RetailManager]: 'Hub Retail Manager',
  [SalesOfficerRole.Rep]: 'Hub Delivery Associate',
  [SalesOfficerRole.BA]: 'Hub Brand Ambassador',
}
