import React from 'react'
import { useHistory } from 'react-router'

import { useMounted, useQueryParams } from 'src/core/hooks'
import {
  route,
  basicSearch,
  parseQueryParam,
  serializeQueryParam,
  undoSlugify,
} from 'src/core/helpers'

import {
  IPurchaseOrder,
  IPurchaseOrderFilters,
  IUseFilterPurchaseOrders,
} from '../purchaseOrder.type'

export const useFilterPurchaseOrders = (
  purchaseOrders: IPurchaseOrder[]
): IUseFilterPurchaseOrders => {
  const history = useHistory()
  const isMounted = useMounted()

  const [filters, setFilters] = React.useState<IPurchaseOrderFilters>({
    tag: useQueryParams('tag', '*'),
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', 30)),
    search: useQueryParams('search'),
    sources: parseQueryParam(useQueryParams('sources', '')),
    destinations: parseQueryParam(useQueryParams('destinations', '')),
    source_type: useQueryParams('source_type', '*'),
    destination_type: useQueryParams('destination_type', '*'),
  })

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      isMounted.current &&
        setFilters({
          tag: (query.get('tag') || '*') as any,
          source_type: (query.get('source_type') || '*') as any,
          destination_type: (query.get('destination_type') || '*') as any,
          page: Number(query.get('page')) || 1,
          per_page: Number(query.get('per_page')) || 10,
          search: query.get('search') || null,
          sources: parseQueryParam(query.get('sources')),
          destinations: parseQueryParam(query.get('destinations')),
        })
    })
    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const updateQueryParams = React.useCallback(
    ({ sources, destinations, ...params }) => {
      history.push(
        route('purchase_orders', null, {
          ...params,
          page: params.page === 1 ? null : params.page,
          per_page: params.per_page === 10 ? null : params.per_page,
          sources: serializeQueryParam(sources),
          destinations: serializeQueryParam(destinations),
        })
      )
    },
    [history]
  )

  const filteredPurchaseOrders = React.useMemo(() => {
    let filtered = purchaseOrders || []

    if (filtered.length) {
      filtered = filtered.map((purchaseOrder) => {
        return {
          ...purchaseOrder,
          timestamp: purchaseOrder?.timestamp || purchaseOrder?.created,
        }
      })

      if (filters?.tag !== '*') {
        filtered = filtered.filter((purchaseOrder) => {
          return purchaseOrder.tag === filters.tag
        })
      }
      if (filters?.source_type !== '*') {
        filtered = filtered.filter((purchaseOrder) => {
          return purchaseOrder.source_type === filters.source_type
        })
      }
      if (filters?.destination_type !== '*') {
        filtered = filtered.filter((purchaseOrder) => {
          return purchaseOrder.destination_type === filters.destination_type
        })
      }

      if (filters?.sources !== '*' && filters?.sources[0].value !== '*') {
        const list = filters.sources?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ source }) => list.includes(source))
        }
      }

      if (
        filters?.destinations !== '*' &&
        filters?.destinations[0].value !== '*'
      ) {
        const list = filters.destinations?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ destination }) =>
            list.includes(destination)
          )
        }
      }

      if (filters.search) {
        filtered = filtered.filter((purchaseOrder) => {
          return (
            basicSearch(purchaseOrder.ref, filters.search) ||
            basicSearch(purchaseOrder?.source_obj?.name, filters.search) ||
            basicSearch(purchaseOrder?.destination_obj?.name, filters.search)
          )
        })
      }
    }

    return filtered
  }, [
    purchaseOrders,
    filters.search,
    filters.source_type,
    filters.sources,
    filters.destination_type,
    filters.destinations,
    filters.tag,
  ])

  const activeFilters = React.useMemo(() => {
    const activeFilters = []
    const { tag, destinations, sources, source_type, destination_type } =
      filters

    if (tag && tag !== '*') {
      activeFilters.push(`Tag (${undoSlugify(tag)})`)
    }
    if (source_type && source_type !== '*') {
      activeFilters.push(`Source Type (${undoSlugify(source_type)})`)
    }
    if (destination_type && destination_type !== '*') {
      activeFilters.push(`Destination Type (${undoSlugify(destination_type)})`)
    }

    if (sources !== '*') {
      const list = sources?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Source (${list})`)
    }

    if (destinations !== '*') {
      const list = destinations?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Destination (${list})`)
    }

    return activeFilters
  }, [filters])

  return {
    filters,
    activeFilters,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, ...updated, page: 1 })
    },
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
    filteredPurchaseOrders,
  }
}

export default useFilterPurchaseOrders
