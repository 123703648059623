import { IModel } from 'src/core/types'
import { IDistributor } from 'src/applets/distributor'
import { IProduct } from 'src/applets/product'
import { ISalesOfficer } from 'src/applets/salesOfficer'
import { ISeller } from 'src/applets/seller'

export enum WarehouseType {
  Distributor = 'distributor',
  Seller = 'seller',
  DA = 'sales_officer',
}

export interface IWarehouse extends IModel {
  parent_id: string
  parent_type: WarehouseType
  status: 'active' | 'inactive'
  parent?: ISalesOfficer | IDistributor | ISeller
}

export interface IWarehouseProduct extends IModel {
  quantity: number
  timestamp: number
  warehouse: IWarehouse
  product: IProduct
  product_id: string

  // generated on frontend
  quantity_to_send?: number
}

export interface IWarehouseSalesReport {
  product: IProduct
  issued: number
  issued_value: number
  returned: number
  returned_value: number
  revenue: number
  total: number
  quantity: number
}

export interface IWarehouseProductFilters {
  page?: number
  per_page?: number
  search?: string
  brands?: '*' | { value: string; label: string }[]
  manufacturers?: '*' | { value: string; label: string }[]
}

export interface IUseFilterWarehouseProducts {
  filters: IWarehouseProductFilters
  activeFilters: string[]
  filteredProducts: IWarehouseProduct[]
  setFilters: (filters: IWarehouseProductFilters) => void
  setPage: (page: number) => void
}

export enum InventoryReportContext {
  Current = 'current_inventory',
  Received = 'shipment_received',
  Rejected = 'shipment_rejected',
  Issued = 'stock_issued',
  Returned = 'stock_returned',
  Sales = 'sales',
}

export interface InventoryReportFiltersType {
  search?: string
  page?: number
  per_page?: number
  warehouses?: '*' | { value: string; label: string }[]
  products?: '*' | { value: string; label: string }[]
  context?:
    | 'current_inventory'
    | 'shipment_received'
    | 'shipment_rejected'
    | 'stock_issued'
    | 'stock_returned'
    | 'sales'
  from_timestamp?: number
  to_timestamp?: number
}

export interface UseFilterInventoryReportType {
  filters: InventoryReportFiltersType
  activeFilters: string[]
  filteredEntries: any[]
  setFilters: (filters: InventoryReportFiltersType) => void
}
