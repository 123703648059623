import React from 'react'
import { useSelector } from 'react-redux'
import { Text } from '@chakra-ui/react'

import { Layout, Card, CardHeader, CardBody } from 'src/core/components'
import DeviceVersionCharts from '../components/DeviceVersionCharts'
import usePermission from 'src/core/hooks/usePermission'
import { IStoreState } from 'src/bootstrap/store/types'
import { lang } from 'src/bootstrap/lang'

const Dashboard: React.FC = () => {
  const { userCan } = usePermission()
  const user = useSelector((state: IStoreState) => state.user)

  return (
    <Layout headerProps={{ title: 'Dashboard' }}>
      <Card>
        <CardHeader>
          <Text fontWeight="medium">Welcome to Suplias</Text>
        </CardHeader>
        <CardBody>
          I am logged in as <b>{user.name}</b>, with {` `}
          email <b>{user.email}</b> and role <b>{lang.core[user.role]}</b>
        </CardBody>
      </Card>

      {userCan('view_dashboard_charts') && <DeviceVersionCharts />}
    </Layout>
  )
}

export default Dashboard
