import React from 'react'

import cn from 'classnames'
import { format, fromUnixTime } from 'date-fns'

import { Pagination, ErrorBox, PrintStyler } from 'src/core/components'
import { Table, Thead, Tbody, Th, Tr, Td } from 'src/core/components/Table'

import { paginate } from 'src/core/helpers/filters.helper'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { Text } from '@chakra-ui/layout'
// import { IProduct } from 'src/applets/product'

interface IProps {
  items: any
  grandTotal: number
  params: any
  tableRef: any
  tableWrapperRef: any
}

export const OrderSummaryTable: React.FC<IProps> = ({
  items,
  grandTotal,
  params,
  tableRef,
  tableWrapperRef,
}) => {
  const PER_PAGE = items?.length
  const [page, setPage] = React.useState<number>(1)

  const paginatedItems = React.useMemo(() => {
    return items ? paginate(items, page, PER_PAGE) : []
  }, [page, items, PER_PAGE])

  const hideFirstColum = params?.group_by === 'distributor'

  return (
    <>
      {!paginatedItems.length ? (
        <ErrorBox summary="Set filters to fetch order summary" my={50} />
      ) : null}

      {paginatedItems.length ? (
        <>
          <div ref={tableWrapperRef}>
            <PrintStyler />

            <Table tableRef={tableRef} cellPadding="2px" isFit hasBorders>
              <Thead>
                <Tr>
                  <Th colSpan={10}>
                    <Text as="span" fontSize="sm">
                      {params?.sales_officer?.label || ''} PO by{' '}
                      <Text as="span" textTransform="capitalize">
                        {params.group_by}
                      </Text>{' '}
                      for{' '}
                      {format(
                        fromUnixTime(params.from_timestamp),
                        'MMM d; h:mmaaa'
                      )}
                      {' - '}
                      {format(
                        fromUnixTime(params.to_timestamp),
                        'MMM d; h:mmaaa'
                      )}
                    </Text>
                  </Th>
                </Tr>
                <Tr>
                  <Th
                    align="left"
                    textTransform="capitalize"
                    className={cn({ 'print-hidden': hideFirstColum })}
                  >
                    {params.group_by}
                  </Th>
                  <Th align="left">Item</Th>
                  <Th align="center">Cases</Th>
                  <Th align="center">Units</Th>
                  <Th align="center">Cases (Iss.)</Th>
                  <Th align="center">Units (Iss.)</Th>
                  <Th align="center">Cases (Ret.)</Th>
                  <Th align="center">Units (Ret.)</Th>
                  <Th align="right" isNumeric>
                    Hub Price
                  </Th>
                  <Th align="right" isNumeric>
                    Amount
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedItems.map((item, index) => {
                  let retailerPrice = 0

                  if (item.type === 'product') {
                    const { amount, quantity, product } = item
                    // const tags: IProduct['tags'] = product?.tags

                    retailerPrice = amount / (quantity / product.case_count)
                    // distributorPrice = retailerPrice - tags.MARKUP.value
                  }

                  return item.type === 'product' ? (
                    <Tr key={index}>
                      {item.first_row ? (
                        <Td className={cn({ 'print-hidden': hideFirstColum })}>
                          <Text as="b" fontWeight="medium">
                            {item[params.group_by].name}
                          </Text>
                        </Td>
                      ) : (
                        <Td
                          color="transparent"
                          className={cn({
                            'print-hidden': hideFirstColum,
                            'print-transparent': true,
                          })}
                        >
                          -
                        </Td>
                      )}
                      <Td style={{ width: '300px' }}>{item.product.name}</Td>
                      <Td textAlign="center" className="print-center">
                        {Math.floor(item.quantity / item.product.case_count)}
                      </Td>
                      <Td textAlign="center" className="print-center">
                        {item.quantity % item.product.case_count}
                      </Td>
                      {/* <Td isNumeric className="print-right">
                        {distributorPrice !== retailerPrice
                          ? formatCurrency(distributorPrice)
                          : '-'}
                      </Td> */}
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td isNumeric className="print-right">
                        {formatCurrency(retailerPrice)}
                      </Td>
                      <Td isNumeric className="print-right">
                        {formatCurrency(item.amount)}
                      </Td>
                    </Tr>
                  ) : (
                    <Tr key={index} className="active">
                      <Td className={cn({ 'print-hidden': hideFirstColum })}>
                        <Text as="b" fontWeight="medium">
                          {item[params.group_by].name} Total
                        </Text>
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td align="right" isNumeric>
                        <Text as="b" fontWeight="medium">
                          {formatCurrency(item[params.group_by].total)}
                        </Text>
                      </Td>
                    </Tr>
                  )
                })}

                <Tr>
                  <Td colSpan={8}></Td>
                </Tr>
                <Tr className="active">
                  <Td className={cn({ 'print-hidden': hideFirstColum })}>
                    <b className="font-weight-medium">Grand Total</b>
                  </Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td align="right" isNumeric>
                    <b className="font-weight-medium">
                      {formatCurrency(grandTotal)}
                    </b>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </div>

          <Pagination
            my={5}
            itemsPerPage={PER_PAGE}
            totalItems={items?.length}
            currentPage={page}
            onPageChange={(page) => setPage(page)}
          />
        </>
      ) : null}
    </>
  )
}
