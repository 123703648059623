import Warehouses from './pages/Warehouses.page'
import WarehouseEditor from './pages/WarehouseEditor.page'
import WarehouseProducts from './pages/WarehouseProducts.page'
import InventoryManager from './pages/InventoryManager.page'
import InventoryReport from './pages/InventoryReport.page'

import { IRoute } from 'src/bootstrap/routes'

export const warehouseRoutes: IRoute[] = [
  {
    id: 'warehouses',
    path: '/warehouses',
    component: Warehouses,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'warehouse_create',
    path: '/warehouses/create',
    component: WarehouseEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'warehouse_update',
    path: '/warehouses/:id/update',
    component: WarehouseEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'warehouse_products',
    path: '/warehouses/:id/products',
    component: WarehouseProducts,
    requiresAuth: true,
    exact: true,
  },

  {
    id: 'inventory_report',
    path: '/inventory/report',
    component: InventoryReport,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'inventory_manager',
    path: '/inventory/:type/:direction',
    component: InventoryManager,
    requiresAuth: true,
    exact: true,
  },
]

export default warehouseRoutes
