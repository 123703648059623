import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useMounted, useQueryParams } from 'src/core/hooks'
import {
  route,
  basicSearch,
  parseQueryParam,
  serializeQueryParam,
} from 'src/core/helpers'

import { IUseFilterWarehouseProducts } from '../warehouse.type'

export const useFilterWarehouseProducts = (
  products: any,
  routeParams?: any
): IUseFilterWarehouseProducts => {
  const isMounted = useMounted()
  const history = useHistory()

  const [filters, setFilters] = useState<any>({
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', 20)),
    search: useQueryParams('search'),
    manufacturers: parseQueryParam(useQueryParams('manufacturers', '*')),
    brands: parseQueryParam(useQueryParams('brands', '*')),
  })

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      setFilters({
        page: Number(query.get('page')) || 1,
        per_page: Number(query.get('per_page')) || 10,
        search: query.get('search') || null,
        manufacturers: parseQueryParam(query.get('manufacturers')),
        brands: parseQueryParam(query.get('brands')),
      })
    })

    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const updateQueryParams = React.useCallback(
    ({ manufacturers, brands, ...query }) => {
      history.push(
        route('warehouse_products', routeParams, {
          ...query,
          page: query.page === 1 ? null : query.page,
          manufacturers: serializeQueryParam(manufacturers),
          brands: serializeQueryParam(brands),
        })
      )
    },
    [history, routeParams]
  )

  const filteredProducts = React.useMemo(() => {
    let filtered = products || []

    if (filtered.length) {
      if (filters?.brands !== '*') {
        const list = filters.brands?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ product }) => {
            return list.includes(product?.brand?._id)
          })
        }
      } else if (filters?.manufacturers !== '*') {
        const list = filters.manufacturers?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ product }) => {
            return list.includes(product?.manufacturer?._id)
          })
        }
      }

      if (filters.search) {
        filtered = filtered.filter(({ product }) => {
          return (
            basicSearch(product.name, filters.search) ||
            basicSearch(product.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [products, filters])

  const activeFilters = React.useMemo(() => {
    const activeFilters: string[] = []
    const { brands, manufacturers } = filters

    if (brands !== '*') {
      const list = brands?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Brands (${list})`)
    }

    if (manufacturers !== '*') {
      const list = manufacturers?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Manufacturers (${list})`)
    }

    return activeFilters
  }, [filters])

  return {
    filters,
    activeFilters,
    filteredProducts,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, page: 1, ...updated })
    },
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
  }
}
