import React, { useState, useEffect } from 'react'

import { Button, Box, Flex, Text } from '@chakra-ui/react'

import {
  Autocomplete,
  FormField,
  ImageBox,
  IQuantityInput,
  QuantityInput,
} from 'src/core/components'
import { useToast } from 'src/core/hooks'
import { asset, formatCurrency } from 'src/core/helpers'

import { useAvailableProducts } from '../hooks'

interface ProductPickerProps {
  isShown: boolean
  selectedItems: any[]
  /**
   * We'll use the location to fetch order fulfilment data
   * so we know which products to shoe.
   */
  location?: string
  warehouse?: string
  /**
   * We may need to show only brands and products from
   * just one manufacturer.
   */
  manufacturer?: string
  /**
   * If there's a seller, use the seller price
   */
  seller?: string
  addItem: (item) => Promise<any>
  isAmountEditable?: boolean
  quantityInputProps?: IQuantityInput
}

export const QuickProductPicker: React.FC<ProductPickerProps> = (props) => {
  const { addToast } = useToast()

  // Indicators , I guess that's what they are called
  const [isAdding, setIsAdding] = useState<boolean>(false) // for adding a product

  // Selected options (objects), usually with value, label keys
  const [manufacturer] = useState<any>({
    value: '*',
    label: 'All Manufacturers',
  })
  const [brand, setBrand] = useState<any>({
    value: '*',
    label: 'All Brands',
  })
  const [product, setProduct] = useState<any>()

  const [available, isLoading] = useAvailableProducts({
    location: props?.location,
    warehouse: props?.warehouse,
    manufacturer: manufacturer?.value,
    brand: brand?.value,
    seller: props?.seller,
  })

  const [productQuantity, setProductQuantity] = useState<number>()
  const [productRate, setProductRate] = useState<number>()

  const computedProductRate = React.useMemo(() => {
    if (productRate) {
      return productRate
    } else if (product && productQuantity) {
      return product?.sellers?.[props.seller]?.price || product.price
    }

    return 0
  }, [productRate, productQuantity, product, props?.seller])

  const computedProductAmount = React.useMemo(() => {
    if (product) {
      const price = product?.sellers?.[props.seller]?.price || product.price
      return productQuantity * ((productRate || price) / product.case_count)
    }

    return 0
  }, [product, productQuantity, productRate, props?.seller])

  /**
   * Everytime 'manufacturer' or 'brands' changes,
   * reset 'product', 'filteredProducts', 'brand' and 'filteredBrands'
   */
  useEffect(() => {
    setProduct(null)
    setBrand({ value: '*', label: 'All Brands' })
  }, [manufacturer, available?.brands])

  /**
   * Everytime 'brand' or 'products' changes,
   * reset 'product' and 'filteredProducts'
   */
  useEffect(() => {
    setProduct(null)
  }, [brand, available?.products, manufacturer])

  /**
   * Everytime a product changes, reset the
   * product quantity
   */
  useEffect(() => {
    product && setProductQuantity(product.mpu) // reset product quantity
    product && setProductRate(null) // reset product amount
  }, [product])

  /**
   * Select product + prevent duplicate selections
   *
   * @param product
   */
  const handleProductSelect = (product): void => {
    const productInOrder = props.selectedItems.find(
      (item) => item.product._id === product.value
    )

    if (productInOrder) {
      setProduct(null)
      addToast('Product already exists in this order.', { appearance: 'error' })
    } else {
      setProduct(product)
      setProductQuantity(product.mpu)
    }
  }

  return (
    <Box
      bg="white"
      padding="15px 12px"
      borderBottom="1px solid"
      borderColor="gray.100"
      borderRadius="6px"
      display={props.isShown ? 'block' : 'none'}
    >
      <Flex alignItems="flex-start">
        <ImageBox
          src={product?.upload ? asset(product.upload.path) : ''}
          width="34px"
          borderWidth="1px"
        />

        <Box ml="10px" width="100%">
          <Autocomplete
            size="sm"
            options={available?.products}
            value={product}
            placeholder={isLoading ? 'Loading products...' : 'Search products'}
            onChange={handleProductSelect}
            isDisabled={!brand || !available?.products?.length}
            isAsync={available?.products?.length > 500}
            menuPlacement="auto"
          />
        </Box>
      </Flex>

      {product ? (
        <Flex
          pt={3}
          mt={3}
          ml="45px"
          borderTop="1px solid"
          borderColor="gray.100"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <QuantityInput
              mpu={0}
              mxpu={product?.quantity}
              interval={product?.mpu}
              caseCount={product?.case_count}
              value={productQuantity}
              onChange={(value: number) => {
                setProductQuantity(value)
              }}
              {...props.quantityInputProps}
            />

            <Box ml={3}>
              {props.isAmountEditable ? (
                <FormField
                  value={computedProductRate.toString()}
                  onChange={({ target }: any) => {
                    setProductRate(target.value)
                  }}
                  bg="#fff"
                  size="sm"
                  minW="4ch"
                  width={`${String(computedProductRate).length + 3}ch`}
                  prepend="NGN"
                  prependProps={{
                    px: 2,
                  }}
                  append="/ case"
                  appendProps={{
                    px: 2,
                  }}
                  px={2}
                />
              ) : null}
            </Box>

            <Text fontWeight="medium" ml={1}>
              = {formatCurrency(computedProductAmount)}
            </Text>
          </Flex>

          <Flex alignItems="center">
            <Button
              ml={2}
              size="sm"
              colorScheme="primary"
              onClick={() => {
                setIsAdding(true)

                props
                  .addItem({
                    product: product,
                    quantity: productQuantity,
                    amount: computedProductAmount,
                  })
                  .then(() => {
                    setProduct(null)
                  })
                  .finally(() => setIsAdding(false))
              }}
              isDisabled={productQuantity < 1 || isAdding}
              isLoading={isAdding}
            >
              Add
            </Button>
          </Flex>
        </Flex>
      ) : null}
    </Box>
  )
}
