import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/modal'
import { Formik, Form } from 'formik'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

import { FormikField, FormStack } from 'src/core/components'
import useFetch from 'src/core/hooks/useFetch'
import useRefactorObjects from 'src/core/hooks/useRefactorObjects'
import useToast from 'src/core/hooks/useToast'
import { route } from 'src/core/helpers/route.helper'

import { ManufacturerService } from 'src/applets/manufacturer'
import { SalesOfficerService } from 'src/applets/salesOfficer'

import { AreaService } from '../area.service'
import { IArea } from '../area.type'

const areaService = new AreaService()
const manufacturerService = new ManufacturerService()
const salesOfficerService = new SalesOfficerService()
// const sellerService = new SellerService()

interface FormProps {
  area: IArea
  open: boolean
  onOpen: (arg: boolean) => void
}

const AreaDuplicateForm: React.FC<FormProps> = ({ area, open, onOpen }) => {
  const history = useHistory()
  const { addToast } = useToast()

  const [mftrs] = useFetch<any[]>(manufacturerService, 'fetch')
  const mftrOptions = useRefactorObjects(mftrs)

  // const [sellers] = useFetch<any[]>(sellerService, 'fetch')
  // const sellerOptions = useRefactorObjects(sellers)

  const [salesOfficers] = useFetch<any[]>(salesOfficerService, 'fetch')
  const salesOfficerOptions = useRefactorObjects(salesOfficers, [
    ['value', '_id'],
    ['label', 'name'],
    ['seller_id', 'seller_id'],
    ['role', 'role'],
  ])

  const [areaLocations] = useFetch<any[]>(areaService, 'fetchLocations', [
    area?._id,
  ])
  const areaLocationsList = React.useMemo(() => {
    return areaLocations
      ? areaLocations.map((areaLocation) => areaLocation.location_id)
      : []
  }, [areaLocations])

  const formConfig = {
    initialValues: {
      manufacturer: null,
      seller:
        area && area?.seller
          ? {
              value: area.seller._id,
              label: area.seller.name,
            }
          : null,
      sales_officer: null,
      name: area?.name || '',
      status: area?.status || 'active',
    },
    validationSchema: Yup.object({
      manufacturer: Yup.mixed()
        .required('Manufacturer field is required.')
        .nullable(),
      seller: Yup.mixed().required('Distributor field is required.').nullable(),
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const areaValues: any = {
        name: values.name,
        status: values.status,
        phone: area.phone,
        manufacturer_id: values.manufacturer.value,
        seller_id: values.seller.value,
      }

      let newAreaId

      areaService
        .create(areaValues)
        .then((areaId) => {
          newAreaId = areaId

          const salesOfficerValues: any = {
            area_id: newAreaId,
            sales_officer_id: values.sales_officer.value,
            seller_id: values.seller.value,
            status: 'active',
            meta: '-',
          }

          return areaService.updateSalesOfficer('create', salesOfficerValues)
        })
        .then(() => {
          const params = {
            status: 'active',
            area_id: newAreaId,
            manufacturer_id: values.manufacturer.value,
          }

          return areaService.creatBulkLocations(params, areaLocationsList)
        })
        .then(() => {
          setSubmitting(false)

          addToast('Area successfully created.', { appearance: 'success' })
          history.push(route('area_update', { id: newAreaId }))
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const sellerSalesReps = React.useCallback(
    (seller) => {
      return seller
        ? salesOfficerOptions.filter((salesOfficer) => {
            return (
              salesOfficer.role === 'rep' &&
              salesOfficer.seller_id === seller.value
            )
          })
        : []
    },
    [salesOfficerOptions]
  )

  return (
    <Modal isOpen={open ? true : false} onClose={() => onOpen(null)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Duplicate Area</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>
          <Formik
            enableReinitialize={true}
            initialValues={formConfig.initialValues}
            validationSchema={formConfig.validationSchema}
            onSubmit={formConfig.onSubmit}
          >
            {({ values, handleSubmit, ...formik }) => (
              <Form onSubmit={handleSubmit}>
                <VStack
                  divider={<StackDivider borderColor="gray.100" />}
                  spacing={2}
                  align="stretch"
                  mb={5}
                >
                  {/* Name */}
                  <FormStack
                    columns={[4, 8]}
                    label="Name"
                    alignItems="center"
                    isRequired
                  >
                    <FormikField name="name" type="text" />
                  </FormStack>

                  {/* Manufacturer */}
                  <FormStack
                    columns={[4, 8]}
                    label="Manufacturer"
                    alignItems="center"
                    isRequired
                  >
                    <FormikField
                      as="autocomplete"
                      name="manufacturer"
                      options={mftrOptions}
                      placeholder="Select manufacturer"
                      isDisabled={!mftrOptions.length}
                    />
                  </FormStack>

                  {/* Seller */}
                  {/* <Box display="none">
                    <FormStack
                      columns={[4, 8]}
                      label="Distributor"
                      alignItems="center"
                      isRequired
                    >
                      <FormikField
                        as="autocomplete"
                        name="seller"
                        options={sellerOptions}
                        placeholder="Select seller"
                        isDisabled={!sellerOptions.length}
                      />
                    </FormStack>
                  </Box> */}

                  {/* Area sales officer */}
                  <FormStack
                    columns={[4, 8]}
                    label="Sales Rep"
                    alignItems="center"
                    isRequired
                  >
                    <FormikField
                      name="sales_officer"
                      options={sellerSalesReps(values.seller)}
                      value={values.sales_officer}
                      placeholder="Select sales officer"
                      isDisabled={!values.seller || !salesOfficerOptions.length}
                    />
                  </FormStack>
                </VStack>

                <Button
                  type="submit"
                  colorScheme="primary"
                  mt={4}
                  isDisabled={
                    formik.isSubmitting || !formik.dirty || !formik.isValid
                  }
                  isLoading={formik.isSubmitting}
                  loadingText="Duplicating"
                >
                  Duplicate Area
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AreaDuplicateForm
