import React from 'react'

import { Spinner, Flex, Box, Button } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'

import { route } from 'src/core/helpers'
import { useFetch, usePermission } from 'src/core/hooks'

import { IManager } from '../manager.type'
import { managerService, ManagerServiceProps } from '../manager.service'
import useFilterManagers from '../hooks/useFilterMangers'
import ManagersTable from '../components/ManagersTable'
import { ManagersTableFilter } from '../components/ManagersTableFilter'

const Managers: React.FC = () => {
  const { userCan } = usePermission()

  const [managers, isLoading] = useFetch<IManager[], ManagerServiceProps>(
    managerService,
    'fetch',
    [],
    userCan('view_managers')
  )

  const { filters, filteredManagers, activeFilters, setFilters, setPage } =
    useFilterManagers(managers)

  return (
    <Layout
      headerProps={{ title: `Managers (${filteredManagers?.length || '0'})` }}
      permission="view_managers"
    >
      <Card>
        <CardHeader as={Flex} justifyContent="space-between">
          <Flex alignItems="center">
            <SearchInput
              placeholder="Search managers"
              value={filters.search}
              onChange={(search) => setFilters({ search, page: 1 })}
              isDisabled={isLoading || !filteredManagers}
            />

            <Box className="ml-3">
              <ManagersTableFilter
                filters={filters}
                setFilters={setFilters}
                activeFilters={activeFilters}
              />
            </Box>
          </Flex>

          {userCan('signup_manager') && (
            <Link to={route('manager_signup')}>
              <Button
                size="sm"
                colorScheme="primary"
                leftIcon={<FiPlus size={16} />}
              >
                Create Manager
              </Button>
            </Link>
          )}
        </CardHeader>

        <CardBody padding={0} pb={2}>
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <ManagersTable
              managers={filteredManagers}
              filters={filters}
              setFilters={setFilters}
              setPage={setPage}
            />
          )}
        </CardBody>
      </Card>
    </Layout>
  )
}

export default Managers
