import { CoreService, CoreServiceProps } from 'src/core/services'
import { IConfig } from './config.type'

export class ConfigService extends CoreService {
  constructor() {
    super('config')
  }

  fetchByKey(key: string): Promise<IConfig> {
    return new Promise((resolve, reject) => {
      const cacheKey = `${this.model}_${key}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .get(`${this.model}/read_by_key/?key=${key}`)
            .then(({ data }) => {
              if (data.code === 200 && data?.data?.[0]) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export type ConfigServiceProps = CoreServiceProps
export const configService = new ConfigService()
export default ConfigService
