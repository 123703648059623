import React from 'react'

import { Box } from '@chakra-ui/react'
import classNames from 'classnames/bind'
import { useSelector, useDispatch } from 'react-redux'

import {
  FiBarChart2,
  FiBox,
  FiFileText,
  FiHome,
  FiUsers,
  FiMoreHorizontal,
  FiGrid,
  FiShoppingBag,
} from 'react-icons/fi'

import { SidebarHeader } from './SidebarHeader'
import { NavLink, NavGroup } from './SidebarNav'

import { IStoreState } from 'src/bootstrap/store/types'
import { SET_CONFIG } from 'src/bootstrap/store/actionTypes'

import { route } from 'src/core/helpers/route.helper'

import styles from './Sidebar.module.scss'
import { trans } from 'src/core/helpers'

const cn = classNames.bind(styles)

export const Sidebar: React.FC = () => {
  const dispatch = useDispatch()

  const { user, auth, config } = useSelector((state: IStoreState) => state)
  const isAuthenticated = !!auth.token
  const sidebarOpen = config.sidebarOpen

  const toggleSidebar = (): void => {
    dispatch({
      type: SET_CONFIG,
      payload: { sidebarOpen: !sidebarOpen },
    })
  }

  return (
    <>
      {isAuthenticated && (
        <>
          <aside className={cn({ sidebar: true, open: sidebarOpen })}>
            <SidebarHeader
              user={user}
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
            />

            <Box mt={3} px="5px" height="100%" overflow="scroll">
              {!user?._id ? (
                <Box className={cn('nav-dummy')}>
                  {[1, 2, 3, 4, 5, 6, 7].map((value) => (
                    <Box key={value} className={cn('nav-link-dummy')}></Box>
                  ))}
                </Box>
              ) : (
                <>
                  <NavLink icon={<FiHome />} to={route('dashboard')}>
                    Home
                  </NavLink>

                  {/* Orders */}
                  <NavGroup
                    icon={<FiShoppingBag />}
                    text="Orders"
                    permission="view_sidebar_orders"
                  >
                    <NavLink
                      to={route('order_groups')}
                      permission="view_orders"
                    >
                      All Orders
                    </NavLink>
                    <NavLink
                      to={route('pending_orders')}
                      permission="view_orders"
                    >
                      Pending Orders
                    </NavLink>
                    <NavLink
                      to={route('order_summary')}
                      permission="view_orders"
                    >
                      Order Summary
                    </NavLink>
                    <NavLink
                      to={route('order_reconciliation')}
                      permission="view_orders"
                    >
                      Order Reconciliation
                    </NavLink>
                    <NavLink
                      to={route('order_value_summary')}
                      permission="view_orders"
                    >
                      Order Value Summary
                    </NavLink>
                  </NavGroup>

                  {/* Inventory */}
                  <NavGroup
                    text="Inventory"
                    icon={<FiGrid />}
                    permission="view_sidebar_inventory"
                  >
                    <NavLink
                      to={route('manufacturers')}
                      permission="view_manufacturers"
                    >
                      Manufacturers
                    </NavLink>
                    <NavLink
                      to={route('categories')}
                      permission="view_categories"
                    >
                      Categories
                    </NavLink>
                    <NavLink to={route('brands')} permission="view_brands">
                      Brands
                    </NavLink>
                    <NavLink to={route('products')} permission="view_products">
                      Products
                    </NavLink>
                    <NavLink
                      to={route('purchase_orders')}
                      permission="view_purchase_orders"
                    >
                      Purchase Orders
                    </NavLink>
                    <NavLink
                      to={route('warehouses')}
                      permission="view_warehouses"
                    >
                      Warehouses
                    </NavLink>
                    {[
                      { type: 'stock', direction: 'out' },
                      { type: 'stock', direction: 'in' },
                      { type: 'shipment', direction: 'in' },
                      { type: 'shipment', direction: 'out' },
                    ].map((params) => {
                      const key = Object.values(params).join('_')
                      const to = route('inventory_manager', params)

                      return (
                        <NavLink
                          key={key}
                          to={to}
                          permission={`create_${key}`}
                          exact
                        >
                          {trans(`warehouse.${key}`)}
                        </NavLink>
                      )
                    })}
                    <NavLink
                      to={route('inventory_report')}
                      permission="view_warehouses"
                      exact
                    >
                      Reports
                    </NavLink>
                  </NavGroup>

                  {/* Logistics */}
                  <NavGroup
                    permission="view_sidebar_logistics"
                    text="Logistics"
                    icon={<FiBox />}
                  >
                    <NavLink
                      to={route('locations')}
                      permission="view_locations"
                    >
                      Locations
                    </NavLink>
                    <NavLink to={route('areas')} permission="view_areas">
                      Areas
                    </NavLink>
                    <NavLink
                      to={route('order_fulfilment')}
                      permission="view_order_fulfilment"
                    >
                      Fulfilment
                    </NavLink>
                    <NavLink to={route('sellers')} permission="view_sellers">
                      Hubs
                    </NavLink>
                    <NavLink
                      to={route('distributors')}
                      permission="view_distributors"
                    >
                      Distributors
                    </NavLink>
                  </NavGroup>

                  {/* People */}
                  <NavGroup
                    permission="view_sidebar_people"
                    text="People"
                    icon={<FiUsers />}
                  >
                    <NavLink to={route('buyers')} permission="view_buyers">
                      Retailers
                    </NavLink>

                    <NavLink to={route('managers')} permission="view_managers">
                      Managers
                    </NavLink>
                    <NavLink
                      to={route('sales_officers')}
                      permission="view_sales_officers"
                    >
                      Sales Officers
                    </NavLink>
                    <NavLink
                      to={route('distributor_officers')}
                      permission="view_distributor_officers"
                    >
                      Distributor Officers
                    </NavLink>
                    <NavLink to={route('agents')} permission="view_agents">
                      Agents
                    </NavLink>
                  </NavGroup>

                  {/* Reports */}
                  <NavGroup
                    text="Reports"
                    icon={<FiBarChart2 />}
                    permission="view_sidebar_reports"
                  >
                    <NavLink
                      to={route('report_buyer_retention')}
                      permission="view_reports"
                    >
                      Buyer Retention
                    </NavLink>
                    <NavLink
                      to={route('report_buyer_reconciliation')}
                      permission="view_reports"
                    >
                      Buyer Reconciliation
                    </NavLink>
                    <NavLink
                      to={route('report_sales_officer_reconciliation')}
                      permission="view_reports"
                    >
                      DA Reconciliation
                    </NavLink>
                  </NavGroup>

                  {/* Other */}
                  <NavGroup
                    permission="view_sidebar_more"
                    text="More"
                    icon={<FiMoreHorizontal />}
                  >
                    <NavLink to={route('cohorts')} permission="view_cohorts">
                      Cohorts
                    </NavLink>

                    <NavLink
                      to={route('store_types')}
                      permission="view_store_types"
                    >
                      Store Types
                    </NavLink>

                    <NavLink
                      to={route('notification_wizard')}
                      permission="send_notification"
                    >
                      Notifications
                    </NavLink>

                    <NavLink
                      to={route('config_wizard')}
                      permission="update_config"
                    >
                      Config Wizard
                    </NavLink>

                    <NavLink to={route('tic_tac_toe')}>Game Break</NavLink>
                  </NavGroup>
                </>
              )}

              <Box my={100}></Box>
            </Box>
          </aside>

          {/* Mini sidebar */}
          <aside
            className={`${styles.sidebar} ${styles.mini} ${
              !sidebarOpen ? styles.open : ''
            }`}
          >
            <SidebarHeader
              user={user}
              sidebarOpen={sidebarOpen}
              toggleSidebar={toggleSidebar}
            />

            <Box mt={5} position="relative">
              <NavLink
                to={route('dashboard')}
                icon={<FiHome size={18} />}
                overlayText="Dashboard"
              />

              <NavLink
                to={route('buyers')}
                icon={<FiUsers size={18} />}
                permission="view_buyers"
                overlayText="Buyers"
              />

              <NavLink
                to={route('pending_orders')}
                icon={<FiFileText size={18} />}
                permission="view_orders"
                overlayText="Orders"
              />
            </Box>
          </aside>
        </>
      )}
    </>
  )
}

export default Sidebar
