import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik, ErrorMessage } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import {
  Autocomplete,
  FormikField,
  FormikRadioGroup,
  FormStack,
} from 'src/core/components'

import useFetch from 'src/core/hooks/useFetch'
import useToast from 'src/core/hooks/useToast'

import DistributorOfficerService from '../distributorOfficer.service'
import {
  IDistributor,
  DistributorService,
  DistributorServiceProps,
} from 'src/applets/distributor'
import { IDistributorOfficer } from '../distributorOfficer.type'

import { refactorObjects } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'

interface IProps {
  type?: 'signup' | 'update'
  distributorOfficer?: IDistributorOfficer
  onUpdate?: (updatedValues: IDistributorOfficer) => void
}

const distributorOfficerService = new DistributorOfficerService()
const distributorService = new DistributorService()

const DistributorOfficerForm: React.FC<IProps> = ({
  type,
  distributorOfficer,
  onUpdate,
}) => {
  const history = useHistory()
  const { addToast } = useToast()

  const [distributors] = useFetch<IDistributor[], DistributorServiceProps>(
    distributorService,
    'fetch'
  )
  const distributorOptions = refactorObjects(distributors)

  const formConfig = {
    initialValues: {
      name: distributorOfficer?.name || '',
      phone: distributorOfficer?.phone || '',
      email: distributorOfficer?.email || '',
      status: distributorOfficer?.status || 'active',
      role: distributorOfficer?.role || '',
      distributor: distributorOfficer
        ? {
            value: distributorOfficer.distributor_id,
            label: distributorOfficer.distributor.name,
          }
        : null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      phone: Yup.string()
        .required('Phone number field is required.')
        .matches(
          /^[0][7-9][0-1][0-9]{8}$/,
          'Phone number must be valid. Ex. 08174381261'
        ),
      email: Yup.string().required('Email field is required.'),
      status: Yup.string().required('Status field is required.'),
      role: Yup.string().required('Role field is required.'),
      distributor: Yup.mixed().required('Distributor field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        status: values.status,
        role: values.role,
        distributor_id: values.distributor.value,
      }

      if (type === 'update') {
        finalValues._id = distributorOfficer._id
        finalValues.user_id = distributorOfficer.user_id
      } else {
        finalValues.hash = 'password' // default
      }

      distributorOfficerService[type](finalValues)
        .then((distributorOfficer) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Distributor Officer successfully updated.', {
              appearance: 'success',
            })
            onUpdate(finalValues)
          } else {
            addToast('Distributor Officer successfully created.', {
              appearance: 'success',
            })
            history.push(
              route('distributor_officer_update', {
                id: distributorOfficer._id,
              })
            )
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const columns: [number, number] = [
    type === 'update' ? 4 : 3,
    type === 'update' ? 8 : 6,
  ]

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" columns={columns} isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Distributor */}
            <FormStack label="Distributor" columns={columns} isRequired>
              <Autocomplete
                name="distributor"
                options={distributorOptions}
                value={values.distributor}
                placeholder="Select distributor"
                onBlur={() => {
                  formik.setFieldTouched('distributor', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('distributor', selected)
                }}
                isInvalid={touched.distributor && !!errors.distributor}
                isDisabled={!distributorOptions.length}
              />
              <ErrorMessage
                name="distributor"
                component="span"
                className="invalid-feedback"
              />
            </FormStack>

            {/* Phone */}
            <FormStack
              label="Phone"
              helperText="Distributor officer phone number"
              columns={columns}
              isRequired
            >
              <FormikField
                name="phone"
                type="text"
                prepend="+234"
                placeholder="ex. 08149108781"
                readOnly={type === 'update'}
              />
            </FormStack>

            {/* Name */}
            <FormStack
              label="Name"
              helperText="Distributor officer name"
              columns={columns}
              isRequired
            >
              <FormikField
                name="name"
                type="text"
                placeholder="ex. John Afolabi"
              />
            </FormStack>

            {/* Email */}
            <FormStack
              label="Email"
              helperText="Distributor officer email"
              columns={columns}
              isRequired
            >
              <FormikField
                name="email"
                type="email"
                isDisabled={type === 'update'}
              />
            </FormStack>

            {/* Role */}
            <FormStack
              label="Name"
              helperText="Distributor officer role"
              columns={columns}
              isRequired
            >
              <FormikField as="select" name="role">
                <option value="">Select role</option>
                <option value="manager">Manager</option>
                <option value="rep">Rep</option>
              </FormikField>
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'signup' ? 'Create' : 'Update'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

DistributorOfficerForm.defaultProps = {
  type: 'signup',
}

export default DistributorOfficerForm
