import { CoreService, CoreServiceProps } from 'src/core/services'
import { IManufacturer } from './manufacturer.type'

const defaultUploadId = process.env.REACT_APP_DEFAULT_UPLOAD_ID

export class ManufacturerService extends CoreService<IManufacturer> {
  constructor() {
    super('manufacturer')
  }

  create(manufacturer: IManufacturer): Promise<string> {
    const formData = new FormData()
    formData.append('upload_id', defaultUploadId) // default

    Object.keys(manufacturer).forEach((key) => {
      formData.append(key, manufacturer[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http.post('manufacturer/create', formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export const manufacturerService = new ManufacturerService()
export type ManufacturerServiceProps =
  | CoreServiceProps
  | keyof ManufacturerService
export default ManufacturerService
