import React from 'react'

import { Button, Spinner, Flex } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { Card } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import CategoriesTable from '../components/CategoriesTable'

import useFetch from 'src/core/hooks/useFetch'
import useFilter from 'src/core/hooks/useFilter'
import usePermission from 'src/core/hooks/usePermission'

import { ICategory } from '../category.type'
import { CategoryService, CategoryServiceProps } from '../category.service'

import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'

const categoryService = new CategoryService()

const Categories: React.FC = () => {
  const { userCan } = usePermission()

  const { filters, setFilters } = useFilter('categories')

  const [categories, isLoading] = useFetch<ICategory[], CategoryServiceProps>(
    categoryService,
    'fetch',
    null,
    userCan('view_categories')
  )

  const filteredCategories = React.useMemo(() => {
    let filtered = categories || []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((category) => {
          return (
            basicSearch(category.name, filters.search) ||
            basicSearch(category.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [categories, filters.search])

  return (
    <Layout headerProps={{ title: 'Categories' }} permission="view_categories">
      <Card>
        <Card.Header>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div>
                {filteredCategories
                  ? `${filteredCategories.length} ${pluralize(
                      'category',
                      filteredCategories.length
                    )}`
                  : '0 categories'}
              </div>
              <div className="ml-3">
                <SearchInput
                  placeholder="Search categories"
                  value={filters.search}
                  onChange={(search) => setFilters({ search, page: 1 })}
                  isDisabled={isLoading || !filteredCategories}
                />
              </div>
            </div>

            {userCan('create_category') && (
              <Link to={route('category_create')}>
                <Button
                  size="sm"
                  leftIcon={<FiPlus size={16} />}
                  colorScheme="primary"
                >
                  Create Category
                </Button>
              </Link>
            )}
          </div>
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <CategoriesTable
              categories={filteredCategories}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default Categories
