import { IProduct } from 'src/applets/product'
import { IDistributor } from 'src/applets/distributor'
import { ISeller } from 'src/applets//seller'
import { IModel } from 'src/core/types'
import { IWarehouse } from '../warehouse'

export enum PurchaseOrderTag {
  PurchaseOrder = 'purchase_order',
  Receipt = 'receipt',
}

export enum PurchaseOrderPartyType {
  Distributor = 'distributor',
  SalesOfficer = 'sales_officer',
  Seller = 'seller',
}

export interface IPurchaseOrder extends IModel {
  ref: string
  tag: PurchaseOrderTag
  meta: string
  amount?: number
  timestamp: number

  // Relationships
  source: string
  source_type: PurchaseOrderPartyType
  source_obj?: ISeller | IDistributor
  source_warehouse?: IWarehouse
  destination: string
  destination_type: PurchaseOrderPartyType
  destination_obj?: ISeller | IDistributor
  destination_warehouse?: IWarehouse
}

export interface IPurchaseOrderItem extends IModel {
  amount: number
  quantity: number
  quantity_accepted: number

  // Relationships
  purchase_order?: IPurchaseOrder
  purchase_order_id: string
  product?: IProduct
  product_id: string
}

export interface IPurchaseOrderFilters {
  tag?: '*' | PurchaseOrderTag
  page?: number
  per_page?: number
  search?: string
  source_type?: '*' | PurchaseOrderPartyType
  destination_type?: '*' | PurchaseOrderPartyType
  sources?: '*' | { value: string; label: string }[]
  destinations?: '*' | { value: string; label: string }[]
}
export interface IUseFilterPurchaseOrders {
  filters: IPurchaseOrderFilters
  activeFilters: string[]
  filteredPurchaseOrders: IPurchaseOrder[]
  setFilters: (filters: IPurchaseOrderFilters) => void
  setPage: (page: number) => void
}
export interface IPurchaseOrderStock {
  in_stock: any[]
  out_of_stock: any[]
}

export type IPurchaseOrderStockRecord = Record<
  string,
  {
    requested: IPurchaseOrderItem
    available: null | {
      _id: string
      warehouse_id: string
      product_id: string
      timestamp: number
      quantity: number
      product: IProduct
    }
  }
>

export interface IPurchaseOrderStockDict {
  source: string
  destination: string
  out_of_stock?: IPurchaseOrderStockRecord
  products?: IPurchaseOrderStockRecord
}

export type IPurchaseOrderConfig = {
  type: 'shipment' | 'stock'
  direction: 'in' | 'out'
}
