import NotificationWizard from './pages/NotificationWizard.page'

import { IRoute } from 'src/bootstrap/routes'

export const notificationRoutes: IRoute[] = [
  {
    id: 'notification_wizard',
    path: '/notification/wizard',
    component: NotificationWizard,
    requiresAuth: true,
    exact: true,
  },
]

export default notificationRoutes
