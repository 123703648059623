import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Form, Formik } from 'formik'

import * as Yup from 'yup'

import { FormikField, FormikRadioGroup, FormStack } from 'src/core/components'
import useToast from 'src/core/hooks/useToast'
import { IProduct, ISellerProduct } from '../../product.type'
import { ProductService } from '../../product.service'
import { ISeller } from 'src/applets/seller'

const productService = new ProductService()

interface IProps {
  product?: IProduct
  seller?: ISeller
  sellerProduct?: ISellerProduct
}

export const SellerProductForm: React.FC<IProps> = ({
  product,
  seller,
  ...props
}) => {
  const { addToast } = useToast()

  const [sellerProduct, setSellerProduct] = React.useState<ISellerProduct>(
    props.sellerProduct
  )

  React.useEffect(() => {
    /**
     * Seller product meta is stored as
     * JSON, sometimes
     */
    const unserializeMeta = (): void => {
      let meta
      try {
        meta = JSON.parse(sellerProduct?.meta)
      } catch (error) {
        meta = null
      }

      setMeta(meta)
    }

    unserializeMeta()
  }, [sellerProduct])

  const type = React.useMemo(() => {
    return sellerProduct ? 'update' : 'create'
  }, [sellerProduct])

  const [meta, setMeta] = React.useState<any>()

  const formConfig = {
    initialValues: {
      status: sellerProduct?.status || 'active',
      mpu: sellerProduct?.mpu || product.mpu,
      price: sellerProduct?.price || product.price,
      quantity: sellerProduct?.quantity || -1,
      discount: meta?.discount || '',
      market_price: meta?.market_price || '',
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Status field is required.'),
      mpu: Yup.number()
        .test(
          'case-count-is-multiple',
          `Case count (${product.case_count}) must be multiple of MPU`,
          function (value) {
            return product.case_count % value === 0
          }
        )
        .required('MPU field is required.'),
      price: Yup.number().required('Price field is required.'),
      quantity: Yup.number().required('Quantity field is required.'),
      discount: Yup.number(),
      market_price: Yup.number(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      /**
       * Meta needs to be store as JSON
       */
      const meta: any = {}
      if (values.discount) meta.discount = values.discount
      if (values.market_price) meta.market_price = values.market_price

      const finalValues: any = {
        status: values.status,
        mpu: values.mpu,
        price: values.price,
        quantity: values.quantity,
        seller_id: seller._id,
        product_id: product._id,
        meta: JSON.stringify(meta),
      }

      if (type === 'update') {
        finalValues._id = sellerProduct._id
      }

      productService
        .updateSellerProduct(type, finalValues)
        .then(() => {
          setSubmitting(false)
          setSellerProduct(finalValues)
          addToast(`Product successfully updated.`, { appearance: 'success' })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
        })
    },
  }

  const calculateDiscount = React.useCallback(
    (discount: string, price: number) => {
      /**
       * Discount string will look like "original=2000"
       * so we'll need to match just the numbers
       */
      const matches = discount.match(/[\d]+$/)
      if (matches?.length) {
        const originalPrice = Number(matches[0])
        return ((originalPrice - price) / originalPrice) * 100
      }

      return false
    },
    []
  )

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* MPU */}
            <FormStack
              label="MPU"
              helperText="Minimum purchasable unit"
              isRequired
            >
              <FormikField name="mpu" type="number" placeholder={product.mpu} />
            </FormStack>

            {/* Price */}
            <FormStack label="Price" isRequired>
              <FormikField
                name="price"
                type="number"
                prepend="NGN"
                placeholder={product.price}
              />
            </FormStack>

            {/* Quantity */}
            <FormStack
              label="Quantity"
              helperText="Available product quantity"
              isRequired
            >
              <FormikField name="quantity" type="number" />
            </FormStack>

            {/* Discount */}
            <FormStack label="Discount" helperText="Discount percentage (%)">
              <FormikField
                name="discount"
                type="number"
                append="%"
                placeholder={`Calculate with "original=15000"`}
                onBlur={() => {
                  if (/^original[=][\d]+$/.test(values.discount)) {
                    const discount = calculateDiscount(
                      values.discount,
                      values.price
                    )
                    if (discount) {
                      formik.setFieldValue(
                        'discount',
                        Number(discount).toFixed()
                      )
                    }
                  }
                }}
              />
            </FormStack>

            {/* Market Price */}
            <FormStack label="Market Price">
              <FormikField
                name="market_price"
                type="number"
                prepend="NGN"
                placeholder={product.price}
              />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={4}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
            loadingText="Updating"
          >
            Update Product
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default SellerProductForm
