import React from 'react'
import { Button } from '@chakra-ui/button'
import { Flex, HStack, Text } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { Tag } from '@chakra-ui/tag'
import { Stat, StatLabel, StatNumber } from '@chakra-ui/stat'
import { FiPlus } from 'react-icons/fi'

import { CardHeader, ErrorBox, Pagination } from 'src/core/components'
import { Table, Td, Th, Thead, Tbody, Tr } from 'src/core/components/Table'
import {
  formatCurrency,
  paginate,
  route,
  timestampToDate,
} from 'src/core/helpers'
import useFetch from 'src/core/hooks/useFetch'
import {
  ledgerService,
  LedgerServiceProps,
} from 'src/applets/ledger/ledger.service'
import {
  ILedger,
  ILedgerLog,
  LedgerHead,
  LedgerSubhead,
} from 'src/applets/ledger'
import { LedgerLogForm } from './LedgerLogForm'
import { InventoryReportContext } from 'src/applets/warehouse'
import { Link } from 'react-router-dom'

interface IProps {
  /** User ID */
  owner: string
  head: LedgerHead
  subhead: LedgerSubhead
}
export const LedgerLogs: React.FC<IProps> = (props) => {
  const [showForm, setShowForm] = React.useState<boolean>(false)
  const [page, setPage] = React.useState<number>(1)
  const [balance, setBalance] = React.useState<number>(0)

  const ledgerPayload = {
    head: props.head,
    subhead: props.subhead,
    owner: props.owner,
  }

  const [ledger, isLoadingLedger] = useFetch<ILedger, LedgerServiceProps>(
    ledgerService,
    'fetchByOwnerSpecific',
    [ledgerPayload]
  )

  // set balance on ledger load
  React.useEffect(() => {
    setBalance(ledger?.balance)
  }, [ledger])

  const [ledgerLogs, isLoadingLedgerLogs, , setLedgerLogs] = useFetch<
    ILedgerLog[],
    LedgerServiceProps
  >(ledgerService, 'fetchLogs', [ledger?._id], !!ledger?._id)

  const isLoading = isLoadingLedger || isLoadingLedgerLogs

  const paginatedLedgerLogs = React.useMemo(() => {
    return ledgerLogs?.length ? paginate(ledgerLogs, page) : []
  }, [page, ledgerLogs])

  const colorCode = (value): string => {
    return value < 0 ? 'critical.600' : value > 0 ? 'success.600' : 'body'
  }

  return (
    <>
      {!!ledger && (
        <CardHeader>
          <HStack justifyContent="space-around">
            <Stat>
              <StatLabel color="gray.600">Balance</StatLabel>
              <StatNumber color={colorCode(balance)}>
                NGN {formatCurrency(balance || 0)}
              </StatNumber>
            </Stat>

            <Stat>
              <StatLabel color="gray.600">Logs</StatLabel>
              <StatNumber>{ledgerLogs?.length || '0'}</StatNumber>
            </Stat>

            <Button
              size="sm"
              colorScheme="primary"
              leftIcon={<FiPlus size={16} />}
              onClick={() => setShowForm(true)}
            >
              New Deposit
            </Button>
            <LedgerLogForm
              ledger={showForm ? ledger : null}
              onCreate={(ledgerLog) => {
                setBalance(balance + ledgerLog.value)
                setLedgerLogs([ledgerLog, ...ledgerLogs])
                setShowForm(false)
              }}
              onClose={() => setShowForm(false)}
            />
          </HStack>
        </CardHeader>
      )}

      {isLoading && (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      )}

      {!isLoading && !ledgerLogs?.length ? (
        <ErrorBox summary={`No logs found`} my={50} />
      ) : null}

      {!isLoading && ledgerLogs?.length ? (
        <>
          <Table minW="full">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Tag</Th>
                <Th>Description</Th>
                <Th textAlign="right" isNumeric>
                  Value (NGN)
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedLedgerLogs?.map((log) => (
                <Tr key={log?._id}>
                  <Td>{timestampToDate(log.created)}</Td>
                  <Td>
                    <Tag
                      size="sm"
                      colorScheme={
                        log.tag === 'order'
                          ? 'orange'
                          : log.tag === 'cash'
                          ? 'success'
                          : 'gray'
                      }
                    >
                      {log.tag}
                    </Tag>
                  </Td>

                  <Td wordBreak="break-word">
                    {Object.values(InventoryReportContext).includes(log.tag) ? (
                      <>
                        <Link
                          to={route(
                            'purchase_order',
                            { id: log.description },
                            { is_ref: 'true' }
                          )}
                        >
                          <Text>{'PO #' + log.description}</Text>
                        </Link>
                      </>
                    ) : (
                      <>
                        {log.description.indexOf('|') < 0 &&
                        log.description.matchAll(/[A-Z0-9]/g) &&
                        log.description.split('').length === 8 ? (
                          <>
                            <Link
                              to={route(
                                'order_group',
                                { id: log.description },
                                { is_ref: 'true' }
                              )}
                            >
                              <Text>{'Order #' + log.description}</Text>
                            </Link>
                          </>
                        ) : (
                          log.description.split('|').join(' | ')
                        )}
                      </>
                    )}
                  </Td>

                  <Td color={colorCode(log.value)} isNumeric>
                    {formatCurrency(log.value)}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      ) : null}

      <Pagination
        my={5}
        totalItems={ledgerLogs?.length}
        currentPage={page}
        onPageChange={setPage}
      />
    </>
  )
}
