import React from 'react'
import { Box, Button, IconButton, Flex } from '@chakra-ui/react'

import { FormField, QuantityInput } from 'src/core/components'
import { OrderProductContext } from './OrderProduct'
import { FiCheck, FiX } from 'react-icons/fi'

export const EditForm: React.FC = () => {
  const orderProductContext = React.useContext(OrderProductContext)

  const { item, isUpdating, orderProductProps } = orderProductContext
  const { computed, setEditMode, handleUpdate } = orderProductContext
  const { updatedQuantity, setUpdatedQuantity } = orderProductContext
  const { updatedRate, setUpdatedRate } = orderProductContext
  const { isPristine, setIsPristine } = orderProductContext

  return (
    <Flex
      justifyContent="space-between"
      borderTop="1px solid"
      borderColor="gray.100"
      mt="15px"
      ml="42px"
      pt="10px"
    >
      <Flex alignItems="center">
        {/* Item quantity - editable */}
        <QuantityInput
          value={updatedQuantity}
          mpu={item.product.mpu}
          caseCount={item.product.case_count}
          onChange={(value: number) => {
            setIsPristine(false)
            setUpdatedQuantity(value)
          }}
          {...orderProductProps.quantityInputProps}
        />

        {orderProductProps.isAmountEditable && (
          <Box mx={3} maxW="180px">
            <FormField
              value={updatedRate || computed.oldRate}
              onChange={({ target }: any) => {
                setIsPristine(false)
                setUpdatedRate(target.value)
              }}
              bg="#FFFFFF"
              size="sm"
              prepend="NGN"
              prependProps={{
                px: 2,
              }}
              append="/ case"
              appendProps={{
                px: 2,
              }}
              textAlign="center"
              px={2}
            />
          </Box>
        )}
      </Flex>

      <Box>
        <Button
          size="sm"
          variant="outline"
          onClick={() => {
            setUpdatedQuantity(0)
            setUpdatedRate(0)
            setEditMode(false)
          }}
          isDisabled={isUpdating}
        >
          Cancel
        </Button>

        <Button
          ml={3}
          size="sm"
          colorScheme="primary"
          onClick={handleUpdate}
          isDisabled={updatedQuantity < 1 || isPristine || isUpdating}
          isLoading={isUpdating}
          loadingText={'Updating'}
        >
          Update
        </Button>
      </Box>
    </Flex>
  )
}

export const AcceptForm: React.FC = () => {
  const orderProductContext = React.useContext(OrderProductContext)

  const { item, isUpdating, orderProductProps } = orderProductContext
  const { setEditMode, handleUpdate } = orderProductContext
  const { isPristine, setIsPristine } = orderProductContext
  const { updatedQuantityAccepted, setUpdatedQuantityAccepted } =
    orderProductContext

  const [accepted, setAccepted] = React.useState<number>(0)

  React.useEffect(() => {
    !!accepted && handleUpdate()
  }, [accepted]) // eslint-disable-line

  const acceptRequestedQuantity = (): void => {
    setIsPristine(false)
    setUpdatedQuantityAccepted(item.quantity)
    setAccepted(item.quantity)
  }

  return (
    <Flex justifyContent="space-between">
      <IconButton
        mr={1}
        size="sm"
        variant="ghost"
        aria-label="Submit"
        icon={<FiCheck />}
        colorScheme="success"
        onClick={acceptRequestedQuantity}
        isDisabled={!!updatedQuantityAccepted || isUpdating}
        isLoading={isUpdating}
      />

      <QuantityInput
        value={updatedQuantityAccepted}
        mpu={item.product.mpu}
        caseCount={item.product.case_count}
        onChange={(value: number) => {
          setIsPristine(false)
          setAccepted(0)
          setUpdatedQuantityAccepted(value)
        }}
        {...orderProductProps.quantityInputProps}
      />

      <Flex alignItems="center">
        <IconButton
          ml={3}
          size="sm"
          aria-label="Submit"
          icon={<FiCheck />}
          colorScheme="primary"
          onClick={handleUpdate}
          isDisabled={isPristine || isUpdating}
          isLoading={isUpdating}
        />

        <IconButton
          ml={3}
          size="sm"
          variant="outline"
          icon={<FiX />}
          aria-label="Cancel"
          onClick={() => {
            setUpdatedQuantityAccepted(item.quantity_accepted)
            setEditMode(false)
            setIsPristine(true)
          }}
          isDisabled={isUpdating}
        />
      </Flex>
    </Flex>
  )
}
