import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

import {
  Autocomplete,
  FormikField,
  FormikRadioGroup,
  FormStack,
} from 'src/core/components'

import useFetch from 'src/core/hooks/useFetch'
import useToast from 'src/core/hooks/useToast'

import { IArea } from '../area.type'
import { AreaService } from '../area.service'
import { ManufacturerService } from 'src/applets/manufacturer'
import { SalesOfficerService } from 'src/applets/salesOfficer'
import { SellerService } from 'src/applets/seller'

import { route } from 'src/core/helpers/route.helper'
import { refactorObjects } from 'src/core/helpers/filters.helper'

const areaService = new AreaService()
const manufacturerService = new ManufacturerService()
const salesOfficerService = new SalesOfficerService()
const sellerService = new SellerService()

interface AreaFormProps {
  type?: string
  area?: IArea
  onUpdate?: (updatedValues: IArea) => void
}

const AreaForm: React.FC<AreaFormProps> = ({ type, area, onUpdate }) => {
  const history = useHistory()
  const { addToast } = useToast()

  const [mftrs] = useFetch<any[]>(manufacturerService, 'fetch')
  const mftrOptions = refactorObjects(mftrs)

  const [sellers] = useFetch<any[]>(sellerService, 'fetch')
  const sellerOptions = refactorObjects(sellers)

  const [rawSalesOfficers] = useFetch<any[]>(salesOfficerService, 'fetch')
  const salesOfficers = React.useMemo(() => {
    return rawSalesOfficers
      ? rawSalesOfficers.map((salesOfficer) => ({
          value: salesOfficer._id,
          label: salesOfficer.name,
          seller_id: salesOfficer.seller_id,
          role: salesOfficer.role,
        }))
      : []
  }, [rawSalesOfficers])

  const [
    areaSalesOfficer,
    loading, // eslint-disable-line
    error, // eslint-disable-line
    onUpdateSalesOfficer,
  ] = useFetch<any>(areaService, 'fetchSalesOfficer', [area?._id], !!area)

  const formConfig = {
    initialValues: {
      manufacturer: area
        ? {
            value: area.manufacturer._id,
            label: area.manufacturer.name,
          }
        : null,
      seller:
        area && area?.seller
          ? {
              value: area.seller._id,
              label: area.seller.name,
            }
          : null,
      sales_officer:
        area && areaSalesOfficer
          ? {
              value: areaSalesOfficer.sales_officer._id,
              label: areaSalesOfficer.sales_officer.name,
            }
          : null,
      name: area?.name || '',
      status: area?.status || 'active',
      phone: area?.phone || '',
    },
    validationSchema: Yup.object({
      manufacturer: Yup.mixed()
        .required('Manufacturer field is required.')
        .nullable(),
      seller: Yup.mixed().required('Distributor field is required.').nullable(),
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      phone: Yup.string()
        .required('Phone number field is required.')
        .matches(/^[0][7-9][0-1][0-9]{8}$/, 'Phone number must be valid.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const areaValues: any = {
        name: values.name,
        status: values.status,
        phone: values.phone,
        manufacturer_id: values.manufacturer.value,
        seller_id: values.seller.value,
      }

      if (type === 'update') {
        areaValues._id = area._id
      }

      let newAreaId

      areaService[type](areaValues)
        .then((areaId) => {
          newAreaId = areaId

          let salesOfficerUpdateType = 'create'

          const salesOfficerValues: any = {
            area_id: area?._id || newAreaId,
            sales_officer_id: values.sales_officer.value,
            seller_id: values.seller.value,
            status: 'active',
            meta: '-',
          }

          if (areaSalesOfficer?._id) {
            salesOfficerUpdateType = 'update'
            salesOfficerValues._id = areaSalesOfficer._id
          }

          return areaService.updateSalesOfficer(
            salesOfficerUpdateType,
            salesOfficerValues
          )
        })
        .then(() => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Area successfully updated.', {
              appearance: 'success',
            })
            onUpdate({
              ...areaValues,
              seller: {
                ...area.seller,
                _id: values.seller.value,
                name: values.seller.label,
              },
            })
            onUpdateSalesOfficer({
              ...areaSalesOfficer,
              sales_officer: {
                ...areaSalesOfficer?.sales_officer,
                _id: values.sales_officer.value,
                name: values.sales_officer.label,
              },
            })
          } else {
            addToast('Area successfully created.', {
              appearance: 'success',
            })
            history.push(route('area_update', { id: newAreaId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const sellerSalesReps = React.useCallback(
    (seller) => {
      return seller
        ? salesOfficers.filter((salesOfficer) => {
            return (
              salesOfficer.role === 'rep' &&
              salesOfficer.seller_id === seller.value
            )
          })
        : []
    },
    [salesOfficers]
  )

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Area status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Name */}
            <FormStack label="Name" helperText="Name of area" isRequired>
              <FormikField
                name="name"
                type="text"
                placeholder="ex. John Afolabi"
              />
            </FormStack>

            {/* Phone */}
            <FormStack label="Phone" helperText="Area phone number" isRequired>
              <FormikField
                name="phone"
                type="text"
                prepend="+234"
                placeholder="ex. 08149108781"
                readOnly={type === 'update'}
              />
            </FormStack>

            {/* Manufacturer */}
            <FormStack
              label="Manufacturer"
              helperText="Area manufacturer"
              isRequired
            >
              <Autocomplete
                name="manufacturer"
                options={mftrOptions}
                value={values.manufacturer}
                placeholder="Select manufacturer"
                onBlur={() => {
                  formik.setFieldTouched('manufacturer', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('manufacturer', selected)
                }}
                isInvalid={
                  touched.manufacturer && errors.manufacturer ? true : false
                }
                isDisabled={!mftrOptions.length || type === 'update'}
              />
            </FormStack>

            {/* Seller */}
            <FormStack
              label="Distributor"
              helperText="Area distributor"
              isRequired
            >
              <Autocomplete
                name="seller"
                options={sellerOptions}
                value={values.seller}
                placeholder="Select seller"
                onBlur={() => {
                  formik.setFieldTouched('seller', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('seller', selected)
                  formik.setFieldValue('sales_officer', null)
                }}
                isInvalid={touched.seller && errors.seller ? true : false}
                isDisabled={!sellerOptions.length}
              />
            </FormStack>

            {/* Sales officer */}
            <FormStack label="Sales Rep" helperText="Area sales rep" isRequired>
              <Autocomplete
                name="manager"
                options={sellerSalesReps(values.seller)}
                value={values.sales_officer}
                placeholder="Select sales officer"
                onBlur={() => {
                  formik.setFieldTouched('sales_officer', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('sales_officer', selected)
                }}
                isInvalid={
                  touched.sales_officer && errors.sales_officer ? true : false
                }
                isDisabled={!values.seller || !salesOfficers.length}
              />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={4}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Area' : 'Update Area'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

AreaForm.defaultProps = {
  type: 'create',
}

export default AreaForm
