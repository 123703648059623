import { CoreService, CoreServiceProps } from 'src/core/services'
import { IManager } from './manager.type'

export class ManagerService extends CoreService<IManager> {
  constructor() {
    super('manager')
  }
}

export default ManagerService
export const managerService = new ManagerService()
export type ManagerServiceProps = CoreServiceProps
