import React from 'react'

import { Box, Flex } from '@chakra-ui/layout'
import { Text, Button } from '@chakra-ui/react'
import { FiEdit3 } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { route } from 'src/core/helpers'
import { IWarehouse } from '../warehouse.type'
import WarehouseLogs from './WarhouseLogs'
import { usePermission } from 'src/core/hooks'

interface IProps {
  warehouse: IWarehouse
}
const WarehouseDetails: React.FC<IProps> = ({ warehouse }) => {
  const { userCan } = usePermission()
  return (
    <>
      <WarehouseInfo warehouse={warehouse} />
      <WarehouseActions warehouse={warehouse} />
      {userCan('view_warehouse_logs') && (
        <WarehouseLogs warehouse={warehouse} />
      )}
    </>
  )
}

interface SectionProps {
  warehouse: IWarehouse
}

const WarehouseInfo: React.FC<SectionProps> = ({ warehouse }) => {
  return (
    <>
      <Box mb={4}>
        <Text variant="tinyTitle">Warehouse Owner</Text>
        <Text>{warehouse?.parent?.name || '-'}</Text>
      </Box>

      <Box mb={4}>
        <Text variant="tinyTitle">Warehouse Type</Text>
        <Text textTransform="capitalize">{warehouse?.parent_type || '-'}</Text>
      </Box>
    </>
  )
}

const WarehouseActions: React.FC<SectionProps> = ({ warehouse }) => {
  const history = useHistory()

  return (
    <Box mb={4}>
      <Flex alignItems="center">
        <Button
          size="sm"
          leftIcon={<FiEdit3 />}
          onClick={() => {
            history.push(
              route('warehouse_update', {
                id: warehouse._id,
              })
            )
          }}
        >
          Edit
        </Button>
      </Flex>
    </Box>
  )
}

export default WarehouseDetails
