import React, { useContext } from 'react'

import { Flex, Avatar, Text, Checkbox } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Role } from 'src/bootstrap/permissions/roles'
import { ImageBox } from 'src/core/components'
import { Thead, Tr, Th, Td } from 'src/core/components/Table'
import { asset } from 'src/core/helpers/file.helper'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { route } from 'src/core/helpers/route.helper'
import { IStoreState } from 'src/bootstrap/store/types'

import { ForeignerProductsContext } from './ForeignerProducts'

export const ForeignerProductsThead: React.FC<any> = ({ getThProps }) => {
  const user = useSelector((state: IStoreState) => state.user)

  return (
    <Thead>
      <Tr>
        <Th></Th>
        <Th>#</Th>
        <Th>--</Th>

        {/* Name: sortable */}
        <Th isSticky {...getThProps('name')}>
          Name
        </Th>

        <Th title="Manufacturer, Brand, Product, Seller Product">
          Status (MBPS)
        </Th>
        <Th>Case Count</Th>
        {user.role === Role.Admin && (
          <>
            <Th>MPU</Th>
            <Th>Price (NGN)</Th>
          </>
        )}
        <Th>Sellers</Th>
      </Tr>
    </Thead>
  )
}

export const ForeignerProductsTr: React.FC<any> = ({
  product,
  index,
  page,
  filters,
}) => {
  const isSelected = product.foreigner.status === 'active'

  const user = useSelector((state: IStoreState) => state.user)
  const { toggleProductStatus } = useContext(ForeignerProductsContext)

  return (
    <Tr key={product._id}>
      <Td lineHeight={0}>
        <Checkbox
          id={product._id}
          name={product.name}
          onChange={() => toggleProductStatus(product)}
          isChecked={isSelected}
        />
      </Td>
      <Td>{index + 1 + (page - 1) * filters.per_page}</Td>
      <Td>
        <ImageBox
          src={product?.upload ? asset(product?.upload?.path) : ''}
          borderWidth="1px"
        />
      </Td>
      <Td isSticky>
        <Link
          to={route('product_update', {
            id: product._id,
          })}
          title={product.name}
        >
          <Text isTruncated maxW={280} title={product.name}>
            {product.name}
          </Text>
        </Link>
      </Td>

      <Td>
        <Flex justifyContent="center">
          <Avatar
            name="M"
            size="2xs"
            bg={
              product.manufacturer.status === 'active'
                ? 'success.600'
                : 'critical.600'
            }
          />

          <Avatar
            name="B"
            size="2xs"
            ml={2}
            bg={
              product.brand.status === 'active' ? 'success.600' : 'critical.600'
            }
          />

          <Avatar
            name="P"
            size="2xs"
            ml={2}
            bg={product.status === 'active' ? 'success.600' : 'critical.600'}
          />

          <Avatar
            name="S"
            size="2xs"
            ml={2}
            bg={
              product.seller_status === 'active'
                ? 'success.600'
                : 'critical.600'
            }
          />
        </Flex>
      </Td>

      <Td textAlign="center">{product.case_count}</Td>

      {user?.role === Role.Admin && (
        <>
          <Td textAlign="center">{product.mpu}</Td>
          <Td fontWeight="medium">{formatCurrency(product.price)}</Td>
        </>
      )}

      <Td>
        {product?.sellers?.length
          ? product?.sellers.map(({ seller, price }) => (
              <Text as="span" key={seller._id}>
                {seller.name} @{' '}
                <Text as="span" fontWeight="medium">
                  {formatCurrency(price)}
                </Text>
              </Text>
            ))
          : '-'}
      </Td>
    </Tr>
  )
}
