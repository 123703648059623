import React from 'react'
import { IBuyer } from 'src/applets/buyer'
import { ILocation } from 'src/applets/location'

import { IRoutePlan } from '../routePlan.type'

type StateProps = { isChecked: boolean; isIndeterminate: boolean }

interface IUseRoutePlanner {
  isLoading: boolean
  locations: ILocation[]
  buyers: (IBuyer & { route_plan: IRoutePlan; has_changed: boolean })[]
  toggleLocationStatus: (
    location: ILocation,
    type: 'select' | 'deselect'
  ) => void
  toggleBuyerStatus: (buyer: IBuyer) => void
  getLocationStateProps: (location: ILocation) => StateProps
  /** Reset to pristine state */
  resetBuyers: (buyerIds: string[]) => void
}
type IHook = (allBuyers: IBuyer[], routePlans: IRoutePlan[]) => IUseRoutePlanner

export const useRoutePlanner: IHook = (allBuyers, routePlans) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [locations, setLocations] = React.useState<ILocation[]>()
  const [buyers, setBuyers] = React.useState<IUseRoutePlanner['buyers']>()

  /**
   * Generate dictionary of locations from allBuyers
   * Find matching routePlans for each buyer
   * Add "has_changed" to each buyer to track changes
   */
  React.useEffect(() => {
    if (allBuyers?.length) {
      /* Dictionary of locations found in allBuyers */
      const locationsDict = {}

      const tempBuyers = allBuyers.map((buyer) => {
        if (!locationsDict[buyer?.location?._id]) {
          locationsDict[buyer?.location?._id] = buyer?.location
        }

        const routePlanMatch = routePlans?.length
          ? routePlans.find((routePlan) => {
              return buyer._id === routePlan.buyer_id
            })
          : null

        return {
          ...buyer,
          route_plan: routePlanMatch
            ? { ...routePlanMatch, status: 'active' }
            : { status: 'inactive' },
          has_changed: false,
        }
      }) as any

      setLocations(Object.values(locationsDict))
      setBuyers(tempBuyers)
      setIsLoading(false)
    }
  }, [allBuyers, routePlans])

  /** Reset to pristine state */
  const resetBuyers = (buyerIds): void => {
    const tempBuyers = buyers.map((buyer) => {
      return buyerIds.includes(buyer._id)
        ? { ...buyer, has_changed: false }
        : buyer
    })

    setBuyers(tempBuyers)
  }

  const toggleBuyerStatus = (buyer): void => {
    const tempBuyers = buyers.map((_buyer: any) => {
      if (_buyer._id === buyer._id) {
        _buyer.has_changed = true
        _buyer.route_plan.status =
          _buyer?.route_plan?.status === 'active' ? 'inactive' : 'active'
      }
      return _buyer
    })

    setBuyers(tempBuyers)
  }

  const toggleLocationStatus = (
    location,
    type: 'select' | 'deselect'
  ): void => {
    const tempBuyers = buyers.map((buyer: any) => {
      if (buyer.location._id === location._id) {
        buyer.has_changed = true
        buyer.route_plan.status = type === 'select' ? 'active' : 'inactive'
      }
      return buyer
    })

    setBuyers(tempBuyers)
  }

  const getLocationStateProps = (location): StateProps => {
    const all = buyers.filter((buyer) => buyer.location._id === location._id)
    const active = all.filter(({ route_plan }: any) => {
      return route_plan?.status === 'active'
    })

    return {
      isChecked: active?.length === all?.length,
      isIndeterminate: active?.length && active?.length !== all?.length,
    }
  }

  return {
    isLoading,
    locations,
    buyers,
    toggleBuyerStatus,
    toggleLocationStatus,
    getLocationStateProps,
    resetBuyers,
  }
}
