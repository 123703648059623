import React from 'react'

import useFetch from 'src/core/hooks/useFetch'
import useOrderItems, { IUseOrderItems } from './useOrderItems'
import useUpdateOrder, { IUseUpdateOrder } from './useUpdateOrder'
import { orderService, OrderServiceProps } from '../services'
import { IOrder } from '../order.type'

export interface IUseOrder extends IUseOrderItems, IUseUpdateOrder {
  order?: IOrder
  setOrder?: (order: IOrder) => void
  isLoading?: boolean
}

export const useOrder = (orderId: string): IUseOrder => {
  React.useEffect(() => {
    console.log()
  })

  const [order, isLoadingOrder, , setOrder] = useFetch<IOrder>(
    orderService,
    'fetchById',
    [orderId],
    !!orderId
  )
  const [fulfilmentMap, isLoadingFulfilment] = useFetch<any, OrderServiceProps>(
    orderService,
    'fetchRouteFulfilment',
    [order?.buyer?.location_id],
    !!order?._id
  )

  const orderUpdateHook = useUpdateOrder(order)
  const orderItemsHook = useOrderItems(order, fulfilmentMap)

  const isLoading = isLoadingOrder || isLoadingFulfilment

  return {
    ...orderItemsHook,
    ...orderUpdateHook,

    order,
    setOrder: (values) => {
      setOrder({ ...order, ...values })
    },

    isLoading,
  }
}

export default useOrder
