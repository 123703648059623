import React from 'react'
import { useHistory } from 'react-router'

import useMounted from './useMounted'
import useQueryParams from './useQueryParams'
import { route } from 'src/core/helpers/route.helper'

export const useFilter = (routeId: string, defaults?: any): any => {
  const history = useHistory()
  const isMounted = useMounted()

  const [filters, setFilters] = React.useState<any>({
    status: useQueryParams('status', '*'),
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', 10)),
    search: useQueryParams('search'),
    ...defaults,
  })

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      isMounted.current &&
        setFilters({
          status: query.get('status') || '*',
          page: Number(query.get('page')) || 1,
          per_page: Number(query.get('per_page')) || 10,
          search: query.get('search') || null,
        })
    })
    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const updateQueryParams = React.useCallback(
    (params) => {
      history.push(
        route(routeId, null, {
          ...params,
          page: params.page === 1 ? null : params.page,
          per_page: params.per_page === 10 ? null : params.per_page,
        })
      )
    },
    [history, routeId]
  )

  const resetFilters = React.useCallback(
    (filters) => {
      updateQueryParams({
        ...filters,
        status: '*',
        region: '*',
        state: '*',
        page: 1,
        per_page: 10,
      })
    },
    [updateQueryParams]
  )

  const activeFilters = React.useMemo(() => {
    const activeFilters = []
    const { status } = filters

    if (status && status !== '*') {
      activeFilters.push(`Status (${status})`)
    }

    return activeFilters.length
      ? `Filtered by: ${activeFilters.join(', ')}`
      : 'Filters'
  }, [filters])

  return {
    filters,
    activeFilters,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, ...updated })
    },
    resetFilters: () => {
      resetFilters(filters)
    },
  }
}

export default useFilter
