import Categories from './pages/Categories.page'
import CategoryEditor from './pages/CategoryEditor.page'
import CategoryProducts from './pages/CategoryProducts.page'

import { IRoute } from 'src/bootstrap/routes'

export const categoryRoutes: IRoute[] | any[] = [
  {
    id: 'categories',
    path: '/categories',
    component: Categories,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'category_create',
    path: '/categories/create',
    component: CategoryEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'category_update',
    path: '/categories/:id/update',
    component: CategoryEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'category_products',
    path: '/categories/:id/products',
    component: CategoryProducts,
    requiresAuth: true,
    exact: true,
  },
]

export default categoryRoutes
