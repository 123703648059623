import React from 'react'

import { Box, HStack, Select, Tooltip } from '@chakra-ui/react'
import { format, fromUnixTime } from 'date-fns'

import { FilterMenu, Autocomplete, DateRangeInput } from 'src/core/components'

import useFetch from 'src/core/hooks/useFetch'
import { getPresetTimestamps } from 'src/core/helpers'

import {
  ISalesOfficer,
  SalesOfficerRole,
  salesOfficerService,
  SalesOfficerServiceProps,
} from 'src/applets/salesOfficer'
import { ISeller, sellerService, SellerServiceProps } from 'src/applets/seller'

interface IProps {
  fetch: (params: any) => void
  isFetching: boolean
}

export const OrderSummaryTableFilter: React.FC<IProps> = ({
  fetch,
  isFetching,
}) => {
  const { dayOfWeek, presetTimestamps } = getPresetTimestamps()
  const [filters, setFilters] = React.useState<any>({
    group_by: 'distributor',
    seller: null,
    sales_officer: null,
    from_timestamp: presetTimestamps[dayOfWeek].from,
    to_timestamp: presetTimestamps[dayOfWeek].to,
  })

  const [sellers] = useFetch<ISeller[], SellerServiceProps>(
    sellerService,
    'fetch'
  )
  const sellerOptions = React.useMemo(() => {
    return sellers?.length
      ? sellers.map((seller) => {
          return { value: seller._id, label: seller.name }
        })
      : []
  }, [sellers])

  const [salesOfficers] = useFetch<ISalesOfficer[], SalesOfficerServiceProps>(
    salesOfficerService,
    'fetch'
  )
  const salesOfficerOptions = React.useMemo(() => {
    return salesOfficers?.length && filters?.seller
      ? salesOfficers
          .filter((salesOfficer) => {
            return (
              salesOfficer.status === 'active' &&
              salesOfficer.role === SalesOfficerRole.Rep &&
              salesOfficer.seller_id === filters?.seller.value
            )
          })
          .map((salesOfficer) => {
            return { value: salesOfficer._id, label: salesOfficer.name }
          })
      : []
  }, [salesOfficers, filters?.seller])

  const resetFilters = (): void => {
    setFilters({
      seller: null,
      sales_officer: null,
      start_date: null,
      end_date: null,
    })
  }

  const activeFilters = React.useMemo(() => {
    const activeFilters = []
    const { group_by, seller, sales_officer } = filters

    if (group_by) {
      activeFilters.push(`Group By (${group_by})`)
    }

    if (seller) {
      activeFilters.push(`Seller (${seller.label})`)
    }

    if (sales_officer && sales_officer?.value !== '*') {
      activeFilters.push(`Sales Officer (${sales_officer.label})`)
    }

    return activeFilters.length
      ? `Filtered by: ${activeFilters.join(', ')}`
      : 'Filters'
  }, [filters])

  return (
    <>
      <FilterMenu
        toggleButtonContent={activeFilters}
        resetButtonProps={{ onClick: resetFilters }}
        refreshButtonProps={{
          disabled:
            isFetching ||
            !filters.seller ||
            !filters.from_timestamp ||
            !filters.to_timestamp,
          onClick: () => fetch({ ...filters, refreshed: new Date() }),
          isLoading: isFetching,
        }}
      >
        <FilterMenu.Section label="Group By">
          <Select
            size="sm"
            value={filters.group_by}
            onChange={({ target }) => {
              setFilters({ ...filters, group_by: target.value })
            }}
          >
            <option value="distributor">Distributor</option>
            <option value="buyer">Buyer</option>
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Seller">
          <Autocomplete
            size="sm"
            options={sellerOptions}
            value={filters.seller}
            onChange={(seller) => setFilters({ ...filters, seller })}
            placeholder="Select seller"
            isDisabled={!sellerOptions?.length}
          />
        </FilterMenu.Section>

        <FilterMenu.Section label="Sales Officer">
          <Autocomplete
            size="sm"
            options={[
              { value: '*', label: 'All Sales Officers' },
              ...salesOfficerOptions,
            ]}
            value={filters.salesOfficer}
            onChange={(sales_officer) => {
              setFilters({ ...filters, sales_officer })
            }}
            placeholder="Select sales officer"
            isDisabled={!salesOfficerOptions?.length}
          />
        </FilterMenu.Section>

        <FilterMenu.Section label="Date">
          <HStack mb={2} spacing="5px" overflow="auto">
            {presetTimestamps.map((day: any, index) => {
              const isActive =
                filters.from_timestamp === day.from &&
                filters.to_timestamp === day.to

              return (
                <Tooltip
                  key={index}
                  label={`${format(
                    fromUnixTime(day.from),
                    'MMM d; h:mmaaa'
                  )} - ${format(fromUnixTime(day.to), 'MMM d; h:mmaaa')}`}
                >
                  <Box
                    key={index}
                    bg={isActive ? 'primary.50' : 'gray.50'}
                    border="1px solid"
                    borderRadius={99999}
                    borderColor={isActive ? 'primary.100' : 'gray.100'}
                    color={isActive ? 'primary.600' : ''}
                    cursor="default"
                    padding="2px 8px"
                    onClick={() =>
                      setFilters({
                        ...filters,
                        from_timestamp: day.from,
                        to_timestamp: day.to,
                      })
                    }
                  >
                    {dayOfWeek === index ? 'Today' : day.label}
                  </Box>
                </Tooltip>
              )
            })}
          </HStack>
          <DateRangeInput
            start={filters.from_timestamp}
            end={filters.to_timestamp}
            onChange={(from_timestamp, to_timestamp) =>
              setFilters({ ...filters, from_timestamp, to_timestamp })
            }
            showTimeSelect
          />
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}
