export enum ManagerRole {
  Staff = 'manager.staff',
  Accountant = 'manager.accountant',
  Buyer = 'manager.buyer',
  WarehouseOfficer = 'manager.warehouse_officer',
  CustomerSupport = 'manager.customer_support',
}

export interface IManager {
  _id?: string
  user_id?: string

  name: string
  phone: number
  email: string
  status: 'active' | 'inactive'
  role: ManagerRole
}

export interface IManagerFilters {
  status?: '*' | 'active' | 'inactive'
  role?: '*' | ManagerRole
  page?: number
  per_page?: number
  search?: string
}
export interface IUseFilterManagers {
  filters: IManagerFilters
  activeFilters: string[]
  filteredManagers: IManager[]
  setFilters: (filters: IManagerFilters) => void
  setPage: (page: number) => void
}
