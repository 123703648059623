import { ISeller } from 'src/applets/seller'
import { ILedger, LedgerSubhead, ILedgerTagsDict } from 'src/applets/ledger'

export enum SalesOfficerRole {
  Manager = 'sales_officer.manager',
  RetailManager = 'sales_officer.rem',
  Rep = 'sales_officer.rep',
  BA = 'sales_officer.ba',
  CS = 'sales_officer.cs',
}

export interface ISalesOfficer {
  _id?: string
  user_id?: string
  phone: string
  name: string
  email: string
  status: 'active' | 'inactive'
  role: SalesOfficerRole
  hash?: string
  seller?: ISeller
  seller_id: string
  seller_area_id: string
  ledgers: Record<LedgerSubhead, ILedger>
  ledger_tags: ILedgerTagsDict
  wallet_balance: number
}

export interface ISalesOfficerFilters {
  status?: '*' | 'active' | 'inactive'
  sellers?: '*' | { value: string; label: string }[]
  role?: '*' | SalesOfficerRole
  search?: string
  page?: number
}
