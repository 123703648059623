import React from 'react'

import { useHistory } from 'react-router-dom'

import { route } from 'src/core/helpers/route.helper'
import useFetch from 'src/core/hooks/useFetch'
import useMounted from 'src/core/hooks/useMounted'

import useOrderGroupItems, { IUseOrderGroupItems } from './useOrderGroupItems'
import useUpdateOrder, { IUseUpdateOrder } from './useUpdateOrder'
import { orderGroupService, OrderServiceProps } from '../services'
import { IOrderGroup, OrderTag } from '../order.type'
import { beautifyOrderGroup } from '../order.helper'
import { useAvailableProducts } from 'src/applets/product'

export interface IUseOrderGroup extends IUseOrderGroupItems, IUseUpdateOrder {
  orderGroup: IOrderGroup
  setOrderGroup: (values) => void
  completeOrder: () => void
  isLoading: boolean
  isCompleting: boolean
  triggerRefresh: () => void
}

export const useOrderGroup = (
  orderGroupId: string,
  isRef = false
): IUseOrderGroup => {
  const history = useHistory()
  const isMounted = useMounted()
  // used to trigger order group refresh
  const [refreshedAt, setRefreshedAt] = React.useState<Date>()

  const [orderGroup, isLoadingOrderGroup, , setOrderGroup] =
    useFetch<IOrderGroup>(
      orderGroupService,
      isRef ? 'fetchByRef' : 'fetchById',
      [orderGroupId, refreshedAt],
      !!orderGroupId
    )

  const [fulfilmentMap, isLoadingFulfilment] = useFetch<any, OrderServiceProps>(
    orderGroupService,
    'fetchRouteFulfilment',
    [orderGroup?.buyer?.location_id],
    !!orderGroup?._id
  )

  const [, isLoadingAvailable] = useAvailableProducts({
    location: orderGroup?.buyer?.location_id,
  })

  const orderUpdateHook = useUpdateOrder(orderGroup, true)
  const orderGroupItemsHook = useOrderGroupItems(orderGroup, fulfilmentMap)

  /**
   * To complete an order all you have to do is
   * change the tag of the order group from 'draft' to 'pending'
   */
  const [isCompleting, setIsCompleting] = React.useState<boolean>(false)

  const completeOrder = (): void => {
    setIsCompleting(true)

    orderUpdateHook
      .updateTag(OrderTag.Pending)
      .then(() => {
        // redirect to buyers orders
        history.push(
          `${route('buyer', {
            id: orderGroup?.buyer?._id,
            tab: 'orders',
          })}`
        )
      })
      .finally(() => {
        isMounted.current && setIsCompleting(false)
      })
  }

  const isLoading =
    (isLoadingOrderGroup || isLoadingFulfilment || isLoadingAvailable) &&
    !refreshedAt

  return {
    ...orderUpdateHook,
    ...orderGroupItemsHook,
    isUpdating: orderUpdateHook.isUpdating || isLoading,
    isLoading,

    orderGroup: beautifyOrderGroup(orderGroup),
    setOrderGroup: (values) => {
      setOrderGroup({ ...orderGroup, ...values })
    },
    completeOrder,
    isCompleting,

    triggerRefresh: () => {
      setRefreshedAt(new Date())
    },
  }
}

export default useOrderGroup
