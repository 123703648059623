import React from 'react'

import { Button, Spinner, Flex } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import { Table, Card, Badge } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'

import { Layout, Pagination, ErrorBox, SearchInput } from 'src/core/components'

import useMounted from 'src/core/hooks/useMounted'
import usePermission from 'src/core/hooks/usePermission'

import { CohortService } from '../cohort.service'
import { ICohort } from '../cohort.type'

import { paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'

const cohortService = new CohortService()

const Cohorts: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [cohorts, setCohorts] = React.useState<ICohort[]>()

  const [searchTerm, setSearchTerm] = React.useState<string>()
  const [page, setPage] = React.useState(1)

  React.useEffect(() => {
    const fetchCohorts = (): void => {
      cohortService
        .fetch()
        .then((cohorts) => {
          isMounted.current && setCohorts(cohorts)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    fetchCohorts()
  }, [isMounted])

  const filteredCohorts = React.useMemo(() => {
    setPage(1)

    let filteredCohorts = cohorts || []

    if (filteredCohorts.length) {
      if (searchTerm) {
        filteredCohorts = filteredCohorts.filter((cohort) => {
          return (
            basicSearch(cohort.title, searchTerm) ||
            basicSearch(cohort.status, searchTerm)
          )
        })
      }
    }

    return filteredCohorts
  }, [cohorts, searchTerm])

  const paginatedCohorts = React.useMemo(() => {
    return filteredCohorts ? paginate(filteredCohorts, page) : []
  }, [page, filteredCohorts])

  return (
    <Layout headerProps={{ title: 'Cohorts' }} permission="view_cohorts">
      <Card>
        <Card.Header>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div>
                {filteredCohorts
                  ? `${filteredCohorts.length} ${pluralize(
                      'cohort',
                      filteredCohorts.length
                    )}`
                  : '0 cohorts'}
              </div>
              <div className="ml-3">
                <SearchInput
                  placeholder="Search cohorts"
                  value={searchTerm}
                  onChange={setSearchTerm}
                  isDisabled={isLoading || !filteredCohorts}
                />
              </div>
            </div>

            {userCan('create_cohort') && (
              <div>
                <Link to={route('cohort_create')}>
                  <Button
                    size="sm"
                    leftIcon={<FiPlus size={16} />}
                    colorScheme="primary"
                  >
                    Create Cohort
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {!isLoading && paginatedCohorts.length ? (
            <>
              <div className="table-wrapper padded">
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="sticky">Title</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedCohorts.map((cohort, index) => (
                      <tr key={cohort._id}>
                        <td className="align-middle">
                          {index + 1 + (page - 1) * 10}
                        </td>
                        <td className="align-middle">{cohort.title}</td>
                        <td className="align-middle">{cohort.description}</td>
                        <td className="align-middle">
                          <Badge
                            className="text-capitalize"
                            variant={
                              cohort.status === 'active' ? 'success' : 'danger'
                            }
                          >
                            {cohort.status}
                          </Badge>
                        </td>
                        <td className="align-middle">
                          <Link
                            to={route('cohort_update', {
                              id: cohort._id,
                            })}
                            className="action-link"
                          >
                            Edit
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <Pagination
                my={5}
                itemsPerPage={10}
                totalItems={filteredCohorts.length}
                currentPage={page}
                onPageChange={(page) => setPage(page)}
              />
            </>
          ) : null}

          {!isLoading && !paginatedCohorts.length ? (
            <ErrorBox summary="No cohorts found" my={50} />
          ) : null}

          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default Cohorts
