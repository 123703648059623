import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import { FormikField, FormikRadioGroup, FormStack } from 'src/core/components'

import useFetch from 'src/core/hooks/useFetch'
import useToast from 'src/core/hooks/useToast'

import { ISalesOfficer, SalesOfficerRole } from '../salesOfficer.type'
import { ISeller, SellerService, SellerServiceProps } from 'src/applets/seller'

import { SalesOfficerService } from '../salesOfficer.service'

import { refactorObjects } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import { trans } from 'src/core/helpers'

interface IProps {
  type?: 'signup' | 'update'
  salesOfficer?: ISalesOfficer
  onUpdate?: (updatedValues: ISalesOfficer) => void
}

const salesOfficerService = new SalesOfficerService()
const sellerService = new SellerService()

const SalesOfficerForm: React.FC<IProps> = ({
  type,
  salesOfficer,
  onUpdate,
}) => {
  const history = useHistory()
  const { addToast } = useToast()

  const [sellers] = useFetch<ISeller[], SellerServiceProps>(
    sellerService,
    'fetch'
  )
  const sellerOptions = refactorObjects(sellers)

  const formConfig = {
    initialValues: {
      name: salesOfficer?.name || '',
      phone: salesOfficer?.phone || '',
      email: salesOfficer?.email || '',
      status: salesOfficer?.status || 'active',
      role: salesOfficer?.role || '',
      seller: salesOfficer
        ? { value: salesOfficer.seller_id, label: salesOfficer.seller.name }
        : null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      phone: Yup.string()
        .required('Phone number field is required.')
        .matches(
          /^[0][7-9][0-1][0-9]{8}$/,
          'Phone number must be valid. Ex. 08174381261'
        ),
      email: Yup.string().required('Email field is required.'),
      status: Yup.string().required('Status field is required.'),
      role: Yup.string().required('Role field is required.'),
      seller: Yup.mixed().required('Seller field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        status: values.status,
        role: values.role,
        seller_id: values.seller.value,
      }

      if (type === 'update') {
        finalValues._id = salesOfficer._id
        finalValues.user_id = salesOfficer.user_id
      } else {
        finalValues.hash = 'password' // default
      }

      salesOfficerService[type](finalValues)
        .then((salesOfficer) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Sales Officer successfully updated.', {
              appearance: 'success',
            })
            onUpdate(finalValues)
          } else {
            addToast('Sales Officer successfully created.', {
              appearance: 'success',
            })
            history.push(
              route('sales_officer_update', { id: salesOfficer._id })
            )
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const columns: [number, number] = [
    type === 'update' ? 4 : 3,
    type === 'update' ? 8 : 6,
  ]

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" columns={columns} isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Seller */}
            <FormStack
              label="Hub"
              helperText="Sales officer hub"
              columns={columns}
              isRequired
            >
              <FormikField
                as="autocomplete"
                name="seller"
                options={sellerOptions}
                // value={values.seller}
                placeholder="Select seller"
                isDisabled={!sellerOptions.length}
              />
            </FormStack>

            {/* Phone */}
            <FormStack
              label="Phone"
              helperText="Sales officer phone number"
              columns={columns}
              isRequired
            >
              <FormikField
                name="phone"
                type="text"
                prepend="+234"
                placeholder="ex. 08149108781"
                readOnly={type === 'update'}
              />
            </FormStack>

            {/* Name */}
            <FormStack
              columns={columns}
              label="Name"
              helperText="Sales officer name"
              isRequired
            >
              <FormikField
                name="name"
                type="text"
                placeholder="ex. John Afolabi"
              />
            </FormStack>

            {/* Email */}
            <FormStack
              label="Email"
              columns={columns}
              helperText="Sales officer email address"
              isRequired
            >
              <FormikField name="email" type="email" />
            </FormStack>

            {/* Role */}
            <FormStack
              label="Role"
              columns={columns}
              helperText="Sales officer role"
              isRequired
            >
              <FormikField as="select" name="role">
                <option value="">Select role</option>
                {Object.values(SalesOfficerRole).map((value) => (
                  <option key={value} value={value}>
                    {trans(`sales_officer.${value}`)}
                  </option>
                ))}
              </FormikField>
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={4}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'signup'
              ? 'Create Sales Officer'
              : 'Update Sales Officer'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

SalesOfficerForm.defaultProps = {
  type: 'signup',
}

export default SalesOfficerForm
