import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'

import { IExportableColumn } from 'src/core/components'
import {
  InventoryReportContext,
  InventoryReportFiltersType,
} from './warehouse.type'

export const getExportableColumns = (
  filters: InventoryReportFiltersType
): IExportableColumn[] => {
  if (filters.context === InventoryReportContext.Current) {
    return [
      {
        label: 'Warehouse',
        accessor: '_id',
        modifier: () => {
          return typeof filters?.warehouses !== 'string'
            ? filters?.warehouses?.[0]?.label.replace(/\([A-Z\s]+\)/, '')
            : '-'
        },
        selected: true,
      },
      {
        label: 'Product',
        accessor: 'product.name',
        selected: true,
      },
      {
        label: 'Quantity (Cases)',
        accessor: 'quantity',
        selected: true,
        modifier: (quantity, entry) => {
          return (quantity / entry?.product?.case_count)?.toFixed(1)
        },
      },
      {
        label: 'Value',
        accessor: 'total',
        selected: true,
        modifier: (value) => value?.toFixed(2),
      },
    ]
  }

  if (filters.context === InventoryReportContext.Sales) {
    return [
      {
        label: 'Product',
        accessor: 'product.name',
        selected: true,
      },
      {
        label: 'Issued Quantity (Cases)',
        accessor: 'issued',
        selected: true,
        modifier: (quantity, entry) => {
          return (quantity / entry?.product?.case_count)?.toFixed(1)
        },
      },
      {
        label: 'Issued Value',
        accessor: 'issued_value',
        selected: true,
      },
      {
        label: 'Returned Quantity (Cases)',
        accessor: 'returned',
        selected: true,
        modifier: (quantity, entry) => {
          return (quantity / entry?.product?.case_count)?.toFixed(1)
        },
      },
      {
        label: 'Returned Value',
        accessor: 'returned_value',
        selected: true,
      },
      {
        label: 'Quantity (Cases)',
        accessor: 'quantity',
        selected: true,
        modifier: (quantity, entry) => {
          return (quantity / entry?.product?.case_count)?.toFixed(1)
        },
      },
      {
        label: 'Value',
        accessor: 'total',
        selected: true,
        modifier: (value) => value?.toFixed(2),
      },
      {
        label: 'Revenue',
        accessor: 'revenue',
        selected: true,
      },
    ]
  }

  let significantOther = null

  switch (filters.context) {
    case InventoryReportContext.Received:
      significantOther = {
        header: 'Manufacturer/Distributor',
        accessor: 'purchase_order.source_obj.name',
      }
      break
    case InventoryReportContext.Rejected:
      significantOther = {
        header: 'Manufacturer/Distributor',
        accessor: 'purchase_order.destination_obj.name',
      }
      break
    case InventoryReportContext.Issued:
      significantOther = {
        header: 'DA',
        accessor: 'purchase_order.destination_obj.name',
      }
      break
    case InventoryReportContext.Returned:
      significantOther = {
        header: 'DA',
        accessor: 'purchase_order.source_obj.name',
      }
      break
    default:
      significantOther = null
      break
  }

  return [
    {
      label: 'Date',
      accessor: 'created',
      selected: true,
      modifier: (date) => {
        return format(fromUnixTime(date), 'dd/MM/yyyy')
      },
    },
    {
      label: 'Warehouse',
      accessor: '_id',
      modifier: () => {
        return typeof filters?.warehouses !== 'string'
          ? filters?.warehouses?.[0]?.label.replace(/\([A-Z\s]+\)/, '')
          : '-'
      },
      selected: true,
    },
    {
      label: significantOther?.header,
      accessor: significantOther?.accessor,
      selected: true,
    },
    {
      label: 'Product',
      accessor: 'product.name',
      selected: true,
    },
    {
      label: 'Quantity (Cases)',
      accessor: 'quantity',
      selected: true,
      modifier: (quantity, entry) => {
        return (quantity / entry?.product?.case_count)?.toFixed(1)
      },
    },
    {
      label: 'Price',
      accessor: 'cost_price',
      selected: true,
      modifier: (costPrice) => costPrice?.toFixed(2),
    },
    {
      label: 'Value',
      accessor: 'cost_price',
      selected: true,
      modifier: (costPrice, entry) => {
        const { quantity, product } = entry
        return (costPrice * (quantity / product?.case_count))?.toFixed(2)
      },
    },
    {
      label: 'Ref.',
      accessor: 'ref',
      selected: false,
    },
  ]
}
