import React from 'react'

import { Button, Spinner, Flex, Box } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { Table, Card } from 'react-bootstrap'
import { FiPlus, FiPieChart } from 'react-icons/fi'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { Layout, SearchInput, Pagination, ErrorBox } from 'src/core/components'
import useMounted from 'src/core/hooks/useMounted'
import usePermission from 'src/core/hooks/usePermission'

import { AgentService } from '../agent.service'
import { IAgent } from '../agent.type'
import { userCan } from 'src/bootstrap/store/user/userActions'
import { timestampToDate } from 'src/core/helpers/date.helper'
import { paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'

const agentService = new AgentService()

const Agents: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const [agents, setAgents] = React.useState<IAgent[]>()

  const [searchTerm, setSearchTerm] = React.useState<string>()
  const [page, setPage] = React.useState(1)

  React.useEffect(() => {
    const fetchAgents = (): void => {
      agentService
        .fetch()
        .then((agents) => {
          isMounted.current && setAgents(agents)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_agents') && fetchAgents()
  }, [isMounted, userCan])

  const filteredAgents = React.useMemo(() => {
    setPage(1)

    let filteredAgents = agents || []

    if (filteredAgents.length) {
      if (searchTerm) {
        filteredAgents = filteredAgents.filter((agent) => {
          const dateCreated = agent.created && timestampToDate(agent.created)
          return (
            basicSearch(agent.name, searchTerm) ||
            basicSearch(agent.phone, searchTerm) ||
            basicSearch(agent.location.name, searchTerm) ||
            (dateCreated && basicSearch(dateCreated, searchTerm))
          )
        })
      }
    }

    return filteredAgents
  }, [agents, searchTerm])

  const paginatedAgents = React.useMemo(() => {
    return filteredAgents ? paginate(filteredAgents, page) : []
  }, [page, filteredAgents])

  return (
    <Layout headerProps={{ title: 'Agents' }} permission="view_agents">
      <Card>
        <Card.Header>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="mr-3">
                {filteredAgents
                  ? `${filteredAgents.length} ${pluralize(
                      'agent',
                      filteredAgents.length
                    )}`
                  : '0 agents'}
              </div>

              <SearchInput
                placeholder="Search agents"
                value={searchTerm}
                onChange={setSearchTerm}
                isDisabled={isLoading || !filteredAgents}
              />
            </div>

            <div>
              <Link to={route('agents_report')}>
                <Button
                  size="sm"
                  leftIcon={<FiPieChart size={16} />}
                  variant="outline"
                >
                  Report
                </Button>
              </Link>

              {userCan('signup_agent') && (
                <Link to={route('agent_signup')} className="ml-2">
                  <Button
                    size="sm"
                    leftIcon={<FiPlus size={16} />}
                    colorScheme="primary"
                  >
                    Create Agent
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {!isLoading && paginatedAgents.length ? (
            <>
              <Box className="table-wrapper padded">
                <Table className="table-fit" hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="sticky">Name</th>
                      <th>Status</th>
                      <th>Phone</th>
                      <th>Location</th>
                      <th>Bank</th>
                      <th>Joined</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedAgents.map((agent, index) => (
                      <tr key={agent._id}>
                        <td className="align-middle">
                          {index + 1 + (page - 1) * 10}
                        </td>
                        <td className="sticky align-middle">{agent.name}</td>
                        <td className="align-middle">
                          {agent.user_id ? (
                            'Signed Up'
                          ) : (
                            <Link to={`${route('agent_signup')}?type=mini`}>
                              Created
                            </Link>
                          )}
                        </td>
                        <td className="align-middle">{agent.phone}</td>
                        <td className="align-middle">{agent.location.name}</td>
                        <td className="align-middle">
                          {agent.bank_name || '-'}
                        </td>
                        <td className="align-middle">
                          {agent.created
                            ? timestampToDate(agent.created)
                            : 'N/A'}
                        </td>
                        <td className="align-middle">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() =>
                              history.push(
                                route('agent_update', {
                                  id: agent._id,
                                })
                              )
                            }
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>

              <Pagination
                my={5}
                itemsPerPage={10}
                totalItems={filteredAgents.length}
                currentPage={page}
                onPageChange={(page) => setPage(page)}
              />
            </>
          ) : null}

          {!isLoading && !paginatedAgents.length ? (
            <Box my={50}>
              <ErrorBox summary="No agents found" />
            </Box>
          ) : null}

          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default connect(() => ({}), { userCan })(Agents)
