import {
  IPurchaseOrderConfig,
  PurchaseOrderPartyType,
} from './purchaseOrder.type'

export const purchaseOrderFlows = {
  shipment: [PurchaseOrderPartyType.Distributor, PurchaseOrderPartyType.Seller],
  stock: [PurchaseOrderPartyType.Seller, PurchaseOrderPartyType.SalesOfficer],
}

export const purchaseOrderConfigs = {
  shipment: {
    in: purchaseOrderFlows.shipment,
    out: purchaseOrderFlows.shipment.slice().reverse(),
  },
  stock: {
    in: purchaseOrderFlows.stock.slice().reverse(),
    out: purchaseOrderFlows.stock,
  },
}

export const getPurchaseOrderType = (purchaseOrder): string => {
  const { shipment, stock } = purchaseOrderConfigs
  const config = [purchaseOrder.source_type, purchaseOrder.destination_type]

  const isEqual = (a: string[], b: string[]): boolean => {
    return a[0] === b[0] && a[1] === b[1]
  }

  if (isEqual(config, shipment.in)) {
    return 'Shipment Received'
  } else if (isEqual(config, shipment.out)) {
    return 'Shipment Rejected'
  } else if (isEqual(config, stock.out)) {
    return 'Stock Issued'
  } else if (isEqual(config, stock.in)) {
    return 'Stock Returned'
  }
}

export const getPurchaseOrderConfig = (purchaseOrder): IPurchaseOrderConfig => {
  const config = { type: null, direction: null }
  const { shipment, stock } = purchaseOrderConfigs

  if (purchaseOrder) {
    const flow = [purchaseOrder.source_type, purchaseOrder.destination_type]

    const isEqual = (a: string[], b: string[]): boolean => {
      return a[0] === b[0] && a[1] === b[1]
    }

    if (isEqual(flow, shipment.in)) {
      config.type = 'shipment'
      config.direction = 'in'
    } else if (isEqual(flow, shipment.out)) {
      config.type = 'shipment'
      config.direction = 'out'
    } else if (isEqual(flow, stock.out)) {
      config.type = 'stock'
      config.direction = 'out'
    } else if (isEqual(flow, stock.in)) {
      config.type = 'stock'
      config.direction = 'in'
    }
  }

  return config
}
