import React from 'react'
import { Grid, Box, BoxProps, useTheme, GridProps } from '@chakra-ui/react'

const RightColumn: React.FC<BoxProps> = (props) => {
  const { sizes } = useTheme()

  return (
    <Box
      position="sticky"
      top={0}
      borderLeft="1px solid"
      borderColor="gray.100"
      overflowY="auto"
      height={`calc(100vh - ${sizes.layout.headerHeight})`}
      padding={sizes.gutter.md}
      {...props}
    >
      {props.children}
    </Box>
  )
}

const MainColumn: React.FC<BoxProps> = (props) => {
  const { sizes } = useTheme()

  return (
    <Box
      padding={sizes.gutter.md}
      borderRight="0px solid"
      borderColor="gray.100"
      overflow="auto"
      height={`calc(100vh - ${sizes.layout.headerHeight})`}
      {...props}
    >
      {props.children}
    </Box>
  )
}

interface ColumnLayoutProps extends GridProps {
  templateColumns?: string
}
export class ColumnLayout extends React.Component<ColumnLayoutProps, any> {
  static Main = MainColumn
  static Right = RightColumn

  render(): JSX.Element {
    const { children, ...props } = this.props
    return (
      <Box as={Grid} templateColumns="2fr 1fr" {...props}>
        {children}
      </Box>
    )
  }
}
