import cache from 'src/core/helpers/cache.helper'
import { http } from 'src/core/helpers/http.helper'

export class WalletService {
  fetchBanks(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('bank_list')

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('spend/get_bank_list').then(({ data }) => {
            if (data.code === 200) {
              cache.set('bank_list', data.data.data)
              resolve(data.data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default WalletService
