import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { NavLink as RouterNavLink, useHistory } from 'react-router-dom'

import usePermission from 'src/core/hooks/usePermission'
import useClickOutside from 'src/core/hooks/useClickOutside'
import { useTheme } from '@chakra-ui/system'

const NAV_LINK_HEIGHT = 32
const getCurrentPath = (link, exact): string => {
  const path = exact ? link : link.split('/')[1]
  return path
}
const getLinkPath = (link, exact): string => {
  const path = exact ? link : link.split('/')?.[1]
  return path
}

interface NavParentProps {
  icon?: JSX.Element
  text?: string
  isOpen?: boolean
  permission?: string
  onClick?: VoidFunction
}
export const NavParent: React.FC<NavParentProps> = (props) => {
  const { colors } = useTheme()

  const styles = {
    container: {
      cursor: 'default',
      color: props.isOpen ? colors.white : colors.gray['500'],
      svg: {
        color: props.isOpen ? colors.white : colors.gray['500'],
        transition: 'color 300ms',
      },
      '&:hover': {
        color: colors.white,
        svg: {
          color: colors.white,
        },
      },
      transition: 'color 300ms',
    },
    chevron: {
      transform: props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
      transition: 'transform 300ms',
    },
  }

  return (
    <Flex
      px={4}
      height={NAV_LINK_HEIGHT + 'px'}
      alignItems="center"
      justifyContent="space-between"
      css={styles.container}
      onClick={props.onClick}
    >
      <Flex alignItems="center">
        {props.icon || null}

        <Text as="span" ml={3}>
          {props.text}
        </Text>

        {props.children}
      </Flex>

      <Box css={styles.chevron}>
        <FiChevronRight size={16} />
      </Box>
    </Flex>
  )
}

interface NavLinkProps {
  as?: any
  to?: string
  icon?: JSX.Element
  text?: string
  permission?: string
  overlayText?: string
  onClick?: VoidFunction
  exact?: boolean
}
export const NavLink: React.FC<NavLinkProps> = (props) => {
  const { colors } = useTheme()
  const history = useHistory()
  const [active, setActive] = React.useState<boolean>(false)

  React.useEffect(() => {
    const currentPath = getCurrentPath(location?.pathname, props.exact)
    const linkPath = getLinkPath(props.to, props.exact)
    setActive(currentPath === linkPath)

    const stopListening = history.listen(() => {
      const currentPath = getCurrentPath(location?.pathname, props.exact)
      const linkPath = getLinkPath(props.to, props.exact)
      setActive(currentPath === linkPath)
    })

    return () => {
      stopListening()
    }
  }, [history, props.to, props.exact])

  const styles = {
    container: {
      color: active ? colors.secondary.dodgerBlue : colors.gray['500'],
      svg: {
        color: active ? colors.secondary.dodgerBlue : colors.gray['500'],
        transition: 'color 300ms',
      },
      height: NAV_LINK_HEIGHT + 'px',
      alignItems: 'center',
      justifyContent: props.icon && props.overlayText ? 'center' : 'inherit',
      '&:hover': {
        color: active ? colors.secondary.dodgerBlue : colors.white,
        svg: {
          color: active ? colors.secondary.dodgerBlue : colors.white,
        },
      },
      transition: 'all 300ms',
    },
  }

  return (
    <Flex
      as={props.as || RouterNavLink}
      to={props.to || ''}
      px={props.icon && props.overlayText ? 0 : 4}
      my={props.icon && props.overlayText ? 3 : 0}
      ml={props.icon && props.overlayText ? '10px' : 0}
      position="relative"
      css={styles.container}
      onClick={props.onClick}
    >
      {props.icon && props.overlayText ? props.icon : props.icon || null}

      <Text
        as="span"
        fontWeight={active ? 'medium' : 'regular'}
        ml={props.icon ? 3 : '26px'}
      >
        {props.text || props.children}
      </Text>
    </Flex>
  )
}

interface NavGroup {
  icon?: JSX.Element
  text: string
  permission: string
}
/**
 * Sidebar parent with child links
 */
export const NavGroup: React.FC<NavGroup> = ({
  icon,
  text,
  children,
  permission,
}) => {
  const history = useHistory()
  const boxRef = React.useRef<any>()

  const { userCan } = usePermission()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [forceOpen, setForceOpen] = React.useState<boolean>(false)
  const [linksCount, setLinksCount] = React.useState<number>(0)

  useClickOutside(boxRef, () => {
    setIsOpen(false)
  })

  React.useEffect(() => {
    const reviewLinks = (): void => {
      const childLinks = React.Children.toArray(children)

      const enabledLinks = childLinks.filter(({ props }: any) => {
        return userCan(props.permission)
      })

      const hasActiveLinks = enabledLinks.some(({ props }: any) => {
        const currentPath = getCurrentPath(location?.pathname, props.exact)
        const linkPath = getLinkPath(props.to, props.exact)

        return currentPath === linkPath
      })

      setLinksCount(enabledLinks?.length)
      setForceOpen(hasActiveLinks)
    }

    children && reviewLinks()

    const stopListening = history.listen(() => {
      reviewLinks()
    })

    return () => {
      stopListening()
    }
  }, [children, history, userCan])

  const styles = {
    links: {
      height: isOpen || forceOpen ? linksCount * NAV_LINK_HEIGHT : 0 + 'px',
      transition: `opacity 200ms cubic-bezier(0.75, -0.25, 0.25, 0.75), height 400ms cubic-bezier(0.75, -0.25, 0.25, 0.75)`,
    },
  }

  return userCan(permission) ? (
    <Box ref={boxRef}>
      <NavParent
        icon={icon || null}
        text={text}
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen || forceOpen}
      />

      <Box css={styles.links} overflowY="hidden">
        {React.Children.map(children, (link: React.ReactElement, index) => {
          const { children, ...props } = link?.props

          return userCan(props.permission) ? (
            <NavLink key={index} {...props}>
              {children}
            </NavLink>
          ) : null
        })}
      </Box>
    </Box>
  ) : null
}
