import React from 'react'

import { Autocomplete, AutocompleteProps } from 'src/core/components'
import { sortByKey } from 'src/core/helpers'
import { useFetch } from 'src/core/hooks'

import { storeTypeService } from '../storeType.service'
import { IStoreType } from '../storeType.type'

interface IProps extends AutocompleteProps {
  disableSelectAll?: boolean
}

export const StoreTypeAutocomplete: React.FC<IProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [storeTypes] = useFetch<IStoreType[]>(storeTypeService, 'fetch')
  const storeTypeOptions = React.useMemo(() => {
    if (storeTypes?.length) {
      const options = []

      if (!props.isMulti && !props.disableSelectAll) {
        options.push({ value: '*', label: 'All Store Types' })
      }

      const filtered = storeTypes
        .filter((storeType) => storeType.status === 'active')
        .map((storeType) => ({
          value: storeType._id,
          label: storeType.name,
        }))

      return [...options, ...sortByKey(filtered, 'label')]
    }

    return []
  }, [storeTypes, props.disableSelectAll, props.isMulti])

  return (
    <Autocomplete
      size="sm"
      options={storeTypeOptions}
      value={value || ''}
      placeholder={props.placeholder || 'Select store type'}
      onChange={(selected) => onChange(selected)}
      isActive={value && value.value !== '*' ? true : false}
      isDisabled={!storeTypeOptions || !storeTypeOptions.length}
      {...props}
    />
  )
}

export default StoreTypeAutocomplete
