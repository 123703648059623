import React, { useState } from 'react'

import { getUnixTime, set, subDays } from 'date-fns'
import { useHistory } from 'react-router-dom'

import {
  basicSearch,
  route,
  sortByKey,
  timestampToDate,
} from 'src/core/helpers'
import { useMounted, useQueryParams } from 'src/core/hooks'

import { beautifyOrderGroup } from '../order.helper'
import { IOrderGroup } from '../order.type'

const DEFAULT_DATE_RANGE = 4

export const useFilterOrders = (
  orders: any,
  routeId = 'orders',
  routeParams = {},
  defaultDates = false
): any => {
  const history = useHistory()
  const isMounted = useMounted()

  const date = React.useMemo(() => {
    const today = getUnixTime(new Date())
    const oneWeekAgo = getUnixTime(
      set(subDays(new Date(), DEFAULT_DATE_RANGE), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
    )

    return {
      today: defaultDates ? today : null,
      one_week_ago: defaultDates ? oneWeekAgo : null,
    }
  }, [defaultDates])

  const [filters, setFilters] = useState<any>({
    tag: useQueryParams('tag', '*'),
    payment_status: useQueryParams('payment_status', '*'),
    show_details: useQueryParams('show_details', false),
    start_date: Number(useQueryParams('start_date', date.one_week_ago)),
    end_date: Number(useQueryParams('end_date', date.today)),
    sales_officer: useQueryParams('sales_officer', '*'),
    page: Number(useQueryParams('page', 1)),
    search: useQueryParams('search'),
  })

  const updateQueryParams = React.useCallback(
    (params) => {
      history.push(
        route(routeId, routeParams, {
          ...params,
          page: params.page === 1 ? null : params.page,
        })
      )
    },
    [history, routeId, routeParams]
  )

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      // console.log('it ran second', query.get('start_date'))

      setFilters({
        tag: query.get('tag') || '*',
        payment_status: query.get('payment_status') || '*',
        show_details: query.get('show_details') || false,
        start_date: Number(query.get('start_date')) || null,
        end_date: Number(query.get('end_date')) || null,
        sales_officer: query.get('sales_officer') || '*',
        page: Number(query.get('page')) || 1,
        search: query.get('search') || null,
      })
    })

    return () => {
      stopListening() // stop listening for route change
    }
  }, [history, isMounted])

  const filteredOrders = React.useMemo(() => {
    let filtered: IOrderGroup[] = orders || []

    if (filtered.length) {
      filtered = filtered.map((order) => {
        return beautifyOrderGroup(order)
      })

      if (filters?.tag !== '*') {
        const { tag } = filters
        if (tag === 'pencom') {
          filtered = filtered.filter((order) => {
            return order.tag === 'pending' || order.tag === 'completed'
          })
        } else {
          filtered = filtered.filter((order) => order.tag === tag)
        }
      } else {
        filtered = filtered.filter((order) => {
          return order.tag !== 'deleted' && order.tag !== 'draft'
        })
      }

      if (filters?.payment_status !== '*') {
        const { payment_status } = filters
        filtered = filtered.filter((order) => {
          if (payment_status === 'owing') {
            return order.tag === 'completed' && order.outstanding < 0
          } else {
            return payment_status === 'paid'
              ? order.outstanding >= 0
              : order.outstanding < 0
          }
        })
      }

      if (filters?.sales_officer !== '*') {
        const { sales_officer } = filters
        filtered = filtered.filter((order) => {
          return order.sales_officer?._id === sales_officer
        })
      }

      const { start_date, end_date } = filters
      if (start_date && end_date) {
        filtered = filtered.filter((order) => {
          const accessor = routeId === 'order_groups' ? 'modified' : 'created'
          return (
            order[accessor] >= Number(start_date) &&
            order[accessor] <= Number(end_date) + 86400
          )
        })
      }

      if (filters.search) {
        const query = filters.search
        filtered = filtered.filter((order) => {
          return (
            basicSearch(order.ref, query) ||
            basicSearch(order.buyer.name, query) ||
            basicSearch(order.buyer.phone, query) ||
            basicSearch(timestampToDate(order.created), query)
          )
        })
      }
    }

    return sortByKey(filtered, 'modified', 'desc')
  }, [orders, filters, routeId])

  const activeFilters = React.useMemo(() => {
    const activeFilters: string[] = []

    if (filters?.tag !== '*') {
      activeFilters.push(`Status (${filters.tag})`)
    }

    if (filters?.payment_status !== '*') {
      activeFilters.push(`Payment`)
    }

    if (filters?.sales_officer !== '*') {
      activeFilters.push(`DA`) // devliver associate
    }

    const { start_date, end_date } = filters
    if (start_date && end_date) {
      activeFilters.push(
        `Date (${timestampToDate(start_date)} - ${timestampToDate(end_date)})`
      )
    }

    return activeFilters
  }, [filters])

  const filtersSetter = React.useCallback(
    (updated) => {
      // console.log(filters)
      updateQueryParams({ ...filters, ...updated, page: 1 })
    },
    [filters, updateQueryParams]
  )

  return {
    filters,
    activeFilters,
    filteredOrders,
    setFilters: filtersSetter,
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
  }
}

export default useFilterOrders
