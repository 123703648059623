import React, { useContext } from 'react'

import { Badge, Flex, Avatar, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { ImageBox, StatusSwitch } from 'src/core/components'
import { Thead, Tr, Th, Td } from 'src/core/components/Table'

import { Role } from 'src/bootstrap/permissions/roles'

import { IStoreState } from 'src/bootstrap/store/types'
import { asset } from 'src/core/helpers/file.helper'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { route } from 'src/core/helpers/route.helper'

import { ProductsPageContext } from '../../pages/Products.page'
import { productService } from '../../product.service'

export const ProductsThead: React.FC<any> = ({ getThProps }) => {
  const { filters } = useContext(ProductsPageContext)

  const user = useSelector((state: IStoreState) => state.user)

  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th textAlign="center">--</Th>

        {/* Name: sortable */}
        <Th className="sticky" {...getThProps('name')}>
          Name
        </Th>

        <Th title="Manufacturer, Brand, Product, Seller Product">
          Status (MBPS)
        </Th>
        <Th textAlign="center">Case Count</Th>
        {user.role === Role.Admin && (
          <>
            <Th textAlign="center">MPU</Th>
            <Th isNumeric>Price (NGN)</Th>
          </>
        )}

        {filters.show_details && (
          <>
            <Th colSpan={2} textAlign="center">
              Manufacturer
            </Th>
            <Th colSpan={2} textAlign="center">
              Brand
            </Th>

            <Th>Sellers</Th>
          </>
        )}

        <Th></Th>
      </Tr>

      {filters.show_details && (
        <Tr>
          <Th></Th>
          <Th></Th>
          <Th isSticky></Th>
          <Th></Th>
          <Th></Th>
          <Th></Th>
          <Th></Th>

          {/* Manufacturer */}
          <Th>Name</Th>
          <Th>Status</Th>

          {/* Brand */}
          <Th>Name</Th>
          <Th>Status</Th>

          <Th></Th>

          <Th></Th>
        </Tr>
      )}
    </Thead>
  )
}

export const ProductsTr: React.FC<any> = ({ product, index }) => {
  const user = useSelector((state: IStoreState) => state.user)
  const { products, setProducts, filters } = useContext(ProductsPageContext)

  return (
    <Tr>
      <Td>{index + 1 + (filters.page - 1) * filters.per_page}</Td>
      <Td>
        <Flex justifyContent="center">
          <ImageBox
            src={product.upload ? asset(product.upload.path) : ''}
            borderWidth="1px"
          />
        </Flex>
      </Td>
      <Td isSticky>
        <Link
          to={route('product_update', {
            id: product._id,
          })}
          title={product.name}
        >
          <Text isTruncated maxW={280} title={product.name}>
            {product.name}
          </Text>
        </Link>
      </Td>

      <Td>
        <Flex justifyContent="center">
          <Avatar
            name="M"
            size="2xs"
            bg={
              product.manufacturer.status === 'active'
                ? 'success.600'
                : 'critical.600'
            }
          />

          <Avatar
            name="B"
            size="2xs"
            ml={2}
            bg={
              product.brand.status === 'active' ? 'success.600' : 'critical.600'
            }
          />

          <Avatar
            name="P"
            size="2xs"
            ml={2}
            bg={product.status === 'active' ? 'success.600' : 'critical.600'}
          />

          <Avatar
            name="S"
            size="2xs"
            ml={2}
            bg={
              product.seller_status === 'active'
                ? 'success.600'
                : 'critical.600'
            }
          />
        </Flex>
      </Td>

      <Td textAlign="center">{product.case_count}</Td>

      {user?.role === Role.Admin && (
        <>
          <Td textAlign="center">{product.mpu}</Td>
          <Td fontWeight="medium">{formatCurrency(product.price)}</Td>
        </>
      )}

      {filters.show_details && (
        <>
          {/* Manufacturer */}
          <Td>
            <Link
              to={route('manufacturer_update', {
                id: product.manufacturer._id,
              })}
            >
              {product.manufacturer.name}
            </Link>
          </Td>
          <Td>
            <Badge
              colorScheme={
                product.manufacturer.status === 'active'
                  ? 'success'
                  : 'critical'
              }
            >
              {product.manufacturer.status}
            </Badge>
          </Td>

          {/* Brand */}
          <Td>
            <Link
              to={route('brand_update', {
                id: product.brand._id,
              })}
            >
              {product.brand.name}
            </Link>
          </Td>

          <Td>
            <Badge
              colorScheme={
                product.brand.status === 'active' ? 'success' : 'critical'
              }
            >
              {product.brand.status}
            </Badge>
          </Td>

          {/* Sellers */}
          <Td>
            {product?.sellers?.length
              ? product?.sellers.map(({ seller, price }) => (
                  <Text as="span" key={seller._id}>
                    {seller.name} @{' '}
                    <Text as="span" fontWeight="medium">
                      {formatCurrency(price)}
                    </Text>
                  </Text>
                ))
              : '-'}
          </Td>
        </>
      )}

      <Td>
        <StatusSwitch
          index={index}
          label="Product"
          item={product}
          list={products}
          setList={setProducts}
          service={productService}
          permission="update_product"
        />
      </Td>
    </Tr>
  )
}
