import React from 'react'

import { Flex, Spinner, Switch, Text } from '@chakra-ui/react'

import { ImageBox } from 'src/core/components/Image/ImageBox'

import useMounted from 'src/core/hooks/useMounted'
import useToast from 'src/core/hooks/useToast'
import { IManufacturer } from 'src/applets/manufacturer'
import { ISeller } from '../seller.type'

import { SellerService } from '../seller.service'
import { asset } from 'src/core/helpers/file.helper'

const sellerService = new SellerService()

interface IProps {
  seller: ISeller
  manufacturer: IManufacturer
  sellerManufacturer: any
}

const SellerManufacturerToggle: React.FC<IProps> = ({
  seller,
  manufacturer,
  ...props
}) => {
  const isMounted = useMounted()
  const { addToast } = useToast()

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)
  const [sellerManufacturer, setSellerManufacturer] = React.useState<any>()

  React.useEffect(() => {
    setSellerManufacturer(props.sellerManufacturer)
  }, [props.sellerManufacturer])

  const updateSellerManufacturer = (): void => {
    setIsUpdating(true)

    const status =
      sellerManufacturer?.status === 'active' ? 'inactive' : 'active'

    const values: any = {
      seller_id: seller._id,
      manufacturer_id: manufacturer._id,
      status,
    }

    let type = 'create'
    if (sellerManufacturer) {
      type = 'update'
      values._id = sellerManufacturer._id
    }

    sellerService
      .updateManufacturer(type, values)
      .then((_id) => {
        const updated = { _id, ...sellerManufacturer, status }
        isMounted.current && setSellerManufacturer(updated)

        addToast('Manufacturer successfully updated.', {
          appearance: 'success',
        })
      })
      .catch((error) => {
        addToast(error.message, { appearance: 'error' })
        throw error
      })
      .finally(() => {
        isMounted.current && setIsUpdating(false)
      })
  }

  return (
    <>
      <Flex
        bg={sellerManufacturer?.status !== 'active' ? 'gray.50' : 'white'}
        border="1px solid"
        borderColor="gray.100"
        borderRadius="10px"
        padding="20px"
        minH="100%"
        transition="all 400ms"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center" mr={3}>
          <ImageBox
            src={manufacturer.upload ? asset(manufacturer.upload.path) : ''}
            borderRadius={99999}
          />

          <Text ml={2} fontWeight="medium">
            {manufacturer.name}
          </Text>
        </Flex>

        {isUpdating ? (
          <Flex>
            <Spinner size="sm" color="primary.600" />
          </Flex>
        ) : (
          <Switch
            id={manufacturer._id}
            size="sm"
            name={manufacturer._id}
            isChecked={sellerManufacturer?.status === 'active'}
            onChange={() => updateSellerManufacturer()}
          />
        )}
      </Flex>
    </>
  )
}

export default SellerManufacturerToggle
