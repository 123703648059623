import {
  Box,
  Flex,
  HStack,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router'
import { LedgerHead, ledgerService, LedgerSubhead } from 'src/applets/ledger'
import { OrderGroupLedgerLogs } from 'src/applets/ledger/components/OrderGroupLedgerLogs'
import {
  Card,
  CardBody,
  CardHeader,
  Column,
  ColumnLayout,
  ErrorBox,
  Layout,
  SearchInput,
} from 'src/core/components'
import { formatCurrency, route, timestampToDate } from 'src/core/helpers'
import { useFetch, useMounted } from 'src/core/hooks'

export const OrderReconciliation: React.FC = () => {
  const history = useHistory()
  const query = new URLSearchParams(history.location.search).get('ref')
  const isMounted = useMounted()
  const [refresh, setRefresh] = React.useState<number>(0)
  const [orderRef, setOrderRef] = React.useState(query)

  const [orderGroupLog, isLoading, error] = useFetch(
    ledgerService,
    'fetchOrderGroupReconciliation',
    [orderRef, refresh],
    !!orderRef
  )

  const updateQueryParams = React.useCallback(
    (ref) => {
      history.push(route('order_reconciliation', null, { ref }))
    },
    [history]
  )

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      isMounted.current && setOrderRef(query.get('ref'))
    })
    return () => {
      stopListening() // stop listening for route change
    }
  }, [history, isMounted])

  return (
    <Layout
      header={{ title: 'Order Reconciliation' }}
      bodyProps={{ p: 0, overflow: 'auto' }}
    >
      <ColumnLayout templateColumns="4fr 1fr" m={0} p={0}>
        <ColumnLayout.Main>
          <Card>
            <CardHeader as={Flex} alignItems="center">
              <SearchInput
                placeholder="Search order ref"
                value={orderRef}
                onChange={(search) => updateQueryParams(search)}
                isDisabled={false}
              />
            </CardHeader>
            {isLoading ? (
              <Flex justifyContent="center" py={50}>
                <Spinner color="primary.600" />
              </Flex>
            ) : orderGroupLog && !error ? (
              <>
                {/* Retailer and DA Info */}
                <CardBody>
                  <HStack
                    as={Flex}
                    divider={<StackDivider />}
                    alignItems="start"
                    w="full"
                  >
                    <Column alignItems="flex-start" w="0.5">
                      <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        Retailer
                      </Text>
                      <Box>
                        <Text variant="tinyTitle">Name</Text>
                        <Text as="p">{orderGroupLog.buyer.name}</Text>
                      </Box>
                      <Box>
                        <Text variant="tinyTitle">Phone</Text>
                        <Text as="p">{orderGroupLog.buyer.phone}</Text>
                      </Box>
                      <Box>
                        <Text variant="tinyTitle">Address</Text>
                        <Text as="p">{orderGroupLog.buyer.address}</Text>
                      </Box>
                      <Box>
                        <Text variant="tinyTitle">Balance</Text>
                        <Text>
                          {formatCurrency(
                            orderGroupLog.buyer?.ledgers?.wallet?.balance
                          )}
                        </Text>
                      </Box>
                    </Column>
                    <Column alignItems="flex-start" w="0.5">
                      <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        Delivery Associate
                      </Text>
                      <Box>
                        <Text variant="tinyTitle">Name</Text>
                        <Text as="p">{orderGroupLog.sales_officer.name}</Text>
                      </Box>
                      <Box>
                        <Text variant="tinyTitle">Phone</Text>
                        <Text as="p">{orderGroupLog.sales_officer.phone}</Text>
                      </Box>
                      <Box>
                        <Text variant="tinyTitle">Balance</Text>
                        <Text as="p">
                          {formatCurrency(
                            orderGroupLog.sales_officer.wallet_balance
                          )}
                        </Text>
                      </Box>
                    </Column>
                  </HStack>
                </CardBody>

                {/* Logs */}
                <CardBody>
                  <HStack
                    as={Flex}
                    divider={<StackDivider />}
                    w="full"
                    align="start"
                  >
                    <Column w="0.5">
                      <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        Retailer Logs
                      </Text>
                      <OrderGroupLedgerLogs
                        ledger={
                          orderGroupLog.buyer?.ledgers?.wallet || {
                            head: LedgerHead.Buyer,
                            subhead: LedgerSubhead.Wallet,
                            owner: orderGroupLog.buyer._id,
                            balance: 0,
                          }
                        }
                        ledgerLogs={orderGroupLog.buyer_ledger_logs}
                        orderGroup={orderGroupLog.order}
                        setLedgerLog={() => setRefresh((prev) => prev + 1)}
                      />
                    </Column>
                    <Column w="0.5">
                      <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        DA Logs
                      </Text>
                      <OrderGroupLedgerLogs
                        ledger={
                          orderGroupLog.sales_officer?.ledgers?.wallet || {
                            head: LedgerHead.SalesOfficer,
                            subhead: LedgerSubhead.Wallet,
                            owner: orderGroupLog.sales_officer._id,
                            balance: 0,
                          }
                        }
                        ledgerLogs={orderGroupLog.sales_officer_ledger_logs}
                        orderGroup={orderGroupLog.order}
                        setLedgerLog={() => setRefresh((prev) => prev + 1)}
                      />
                    </Column>
                  </HStack>
                </CardBody>
              </>
            ) : (
              <Flex justifyContent="center" py={50}>
                <ErrorBox
                  summary={
                    error?.message || 'No order found. Search by order ref.'
                  }
                />
              </Flex>
            )}
          </Card>
        </ColumnLayout.Main>
        <ColumnLayout.Right>
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : orderGroupLog && !error ? (
            <>
              <VStack align="start" divider={<StackDivider />}>
                <Box>
                  <Text variant="tinyTitle">Order Ref:</Text>
                  <Text as="p" fontSize="2xl" fontWeight="semibold">
                    {orderGroupLog.order.ref}
                  </Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Date</Text>
                  <Text as="p">
                    {timestampToDate(orderGroupLog.order.modified)}
                  </Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Amount</Text>
                  <Text as="p" fontWeight="semibold">
                    {formatCurrency(orderGroupLog.amount)}
                  </Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Charges</Text>
                  <Text as="p" fontWeight="semibold">
                    {formatCurrency(orderGroupLog.charges)}
                  </Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Discount</Text>
                  <Text
                    as="p"
                    color={
                      orderGroupLog.order.discount < 0 ? 'danger.600' : 'body'
                    }
                  >
                    {formatCurrency(orderGroupLog.order.discount)}
                  </Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Discount Reason</Text>
                  <Text as="p">{orderGroupLog.order.discount_reason}</Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Payment Method</Text>
                  <Text as="p">
                    {orderGroupLog.order.payment_method === 'cash_transfer'
                      ? 'Cash & Transfer'
                      : orderGroupLog.order.payment_method ||
                        orderGroupLog.payment_method ||
                        '-'}
                  </Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Cash</Text>
                  <Text as="p">{formatCurrency(orderGroupLog.order.cash)}</Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Transfer</Text>
                  <Text as="p">
                    {formatCurrency(orderGroupLog.order.transfer)}
                  </Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Paid</Text>
                  <Text as="p">{formatCurrency(orderGroupLog.order.paid)}</Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Outstanding</Text>
                  <Text as="p">
                    {formatCurrency(orderGroupLog.order.outstanding)}
                  </Text>
                </Box>
                <Box>
                  <Text variant="tinyTitle">Source</Text>
                  <Text as="p">{orderGroupLog.order.source}</Text>
                </Box>
              </VStack>
            </>
          ) : (
            <Flex justifyContent="center" py={50}>
              <ErrorBox
                summary={
                  error?.message || 'No order found. Search by order ref.'
                }
              />
            </Flex>
          )}
        </ColumnLayout.Right>
      </ColumnLayout>
    </Layout>
  )
}
