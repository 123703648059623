import { FormLabel, Radio, RadioGroup } from '@chakra-ui/react'
import { useField } from 'formik'
import React from 'react'

type Props = {
  name: string
  label?: string
  options: { label: string; value: string }[]
  isRequired?: boolean
}

export const FormikRadioGroup: React.FC<Props> = ({
  label,
  isRequired,
  options,
  ...props
}) => {
  const [field] = useField(props)
  return (
    <>
      {!!label && (
        <FormLabel mb={0} isRequired={isRequired}>
          {label}
        </FormLabel>
      )}

      <RadioGroup {...field} id={props.name} {...props}>
        {options.map(({ label, value }, index) => (
          <Radio
            key={value}
            {...field}
            value={value}
            colorScheme="primary"
            ml={index ? 3 : 0}
          >
            {label}
          </Radio>
        ))}
      </RadioGroup>
    </>
  )
}

export default FormikRadioGroup
