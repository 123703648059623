import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import SalesOfficerForm from '../components/SalesOfficerForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import {
  SalesOfficerService,
  SalesOfficerServiceProps,
} from '../salesOfficer.service'
import { ISalesOfficer } from '../salesOfficer.type'
import { route } from 'src/core/helpers/route.helper'

const salesOfficerService = new SalesOfficerService()

const SalesOfficerEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: sales_officer_id }: any = useParams()

  const permission = sales_officer_id
    ? 'update_sales_officer'
    : 'signup_sales_officer'

  const [salesOfficer, isLoading, , setSalesOfficer] = useFetch<
    ISalesOfficer,
    SalesOfficerServiceProps
  >(
    salesOfficerService,
    'fetchById',
    [sales_officer_id],
    !!sales_officer_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Sales Officer'
          : salesOfficer?.name || 'New Sales Officer',
        backText: 'Sales Officers',
        onBack: () => history.push(route('sales_officers')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={sales_officer_id ? 'update' : 'create'}
        model={salesOfficer}
        isLoading={isLoading}
        isUserModel
      >
        {sales_officer_id ? (
          <SalesOfficerForm
            type="update"
            salesOfficer={salesOfficer}
            onUpdate={(updatedValues) => {
              setSalesOfficer({ ...salesOfficer, ...updatedValues })
            }}
          />
        ) : (
          <SalesOfficerForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default SalesOfficerEditor
