import React from 'react'

import { Autocomplete, AutocompleteProps } from 'src/core/components'
import { sortByKey } from 'src/core/helpers'
import { useFetch } from 'src/core/hooks'

import { brandService } from '../brand.service'
import { IBrand } from '../brand.type'

interface IProps extends AutocompleteProps {
  /**
   * Filter brands by manufacturers
   */
  manufacturers?: string[]
  disableSelectAll?: boolean
}

export const BrandAutocomplete: React.FC<IProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [brands] = useFetch<IBrand[]>(brandService, 'fetch')
  const brandOptions = React.useMemo(() => {
    if (brands?.length) {
      const options = []

      if (!props.isMulti && !props.disableSelectAll) {
        options.push({ value: '*', label: 'All Brands' })
      }

      const filtered = brands
        .filter((brand) => {
          if (props?.manufacturers?.length) {
            return (
              brand.status === 'active' &&
              props.manufacturers.includes(brand?.manufacturer_id)
            )
          }

          return brand.status === 'active'
        })
        .map((location) => ({
          value: location._id,
          label: location.name,
        }))

      return [...options, ...sortByKey(filtered, 'label')]
    }

    return []
  }, [brands, props.isMulti, props.disableSelectAll, props.manufacturers])

  return (
    <Autocomplete
      size="sm"
      options={brandOptions}
      value={value}
      placeholder="Select brand"
      onChange={(selected) => onChange(selected)}
      isActive={value && value.value !== '*' ? true : false}
      isDisabled={!brandOptions || !brandOptions.length}
      {...props}
    />
  )
}
