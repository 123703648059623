import { IBuyer } from 'src/applets/buyer'
import { ISeller } from 'src/applets/seller'
import { ISalesOfficer } from 'src/applets/salesOfficer'
import { IProduct } from 'src/applets/product'
import { IManufacturer } from 'src/applets/manufacturer'

export enum OrderTag {
  Pending = 'pending',
  Delivered = 'delivered',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Deleted = 'deleted',
  Draft = 'draft',
}

export enum DeliveryStatus {
  DeliveredOnTime = 'delivered_on_time',
  DeliveredLate = 'delivered_late',
  NotDelivered = 'not_delivered',
}

export enum DeliveryIssue {
  CustomerUnavailable = 'customer_unavailable',
  RepVanUnavailable = 'rep_van_unavailable',
  OrderRejected = 'order_rejected',
  ProductUnavailable = 'product_unavailable',
}

export enum DeliveryHandler {
  Betty = 'betty',
  Suplias = 'suplias',
}

export interface IOrder {
  _id?: string
  ref: string

  tag: OrderTag
  amount: number

  created?: number
  modified?: number

  order_group_ref?: string

  buyer?: IBuyer
  sales_officer?: ISalesOfficer
  sales_officer_id?: string

  seller?: ISeller
  seller_id?: string

  manufacturer?: IManufacturer
  manufacturer_id?: string

  meta?: any

  /** For order groups */
  orders?: any
  charges?: any
}

export interface IOrderItem {
  _id?: string

  order_id: string
  product?: IProduct
  product_id: string

  amount: number
  quantity: number

  /** For order group items */
  order_group_id?: string
}

export interface IOrderGroup extends IOrder {
  orders?: any
  charges?: any
  meta_obj?: any

  buyer_name: string
  buyer_is_new: boolean
  location_name: string
  sales_officer_name: string

  source?: string
  payment_method?: 'cash' | 'transfer' | 'cash_transfer'
  cases?: number
  manufacturers?: number

  discount?: number
  discount_reason?: string
  returns?: number
  returns_reason?: string
  comment?: string

  /** Calculated amounts */
  subtotal?: number
  total?: number
  delivery_fee?: number
  paid?: number
  cash?: number
  transfer?: number
  outstanding?: number
}

export interface IOrderGroupItem extends IOrderItem {
  order_group_id?: string
}
