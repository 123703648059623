import React from 'react'
import { Flex } from '@chakra-ui/layout'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'

export const TableHead: React.FC<any> = ({
  header,
  order,
  className,
  ...rest
}) => {
  className = order
    ? `active ${className || ''}`
    : `sortable ${className || ''}`

  return (
    <th className={className.trim()} {...rest}>
      <Flex alignItems="center">
        <span>{header}</span>
        <Flex flexDirection="column" className="th-arrows">
          <BsCaretUpFill
            size={8}
            className={order === 'desc' ? 'active' : ''}
          />
          <BsCaretDownFill
            size={8}
            className={order === 'asc' ? 'active' : ''}
          />
        </Flex>
      </Flex>
    </th>
  )
}

export default TableHead
