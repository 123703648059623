import React from 'react'

import { Button, Box, Text } from '@chakra-ui/react'
import { Dropdown } from 'react-bootstrap'
import { BsFilter } from 'react-icons/bs'

import { LocationAutocomplete } from 'src/applets/location'
import { ManufacturerAutocomplete } from 'src/applets/manufacturer'

interface IProps {
  filters: any
  setFilters: (filters: any) => void
}

const FulfilmentFilters: React.FC<IProps> = ({ filters, setFilters }) => {
  const resetFilters = (): void => {
    setFilters({
      manufacturers: null,
      locations: null,
    })
  }

  return (
    <>
      <Dropdown className="filter-dropdown">
        <Dropdown.Toggle
          as={Button}
          size="sm"
          variant="ghost"
          colorScheme="primary"
          leftIcon={<BsFilter size={24} />}
        >
          Filters
        </Dropdown.Toggle>

        <Dropdown.Menu className="wide">
          {/* Manufacturers */}
          <Box my={15}>
            <Text as="label" fontSize="md" fontWeight="medium" mb={1}>
              Manufacturers
            </Text>
            <ManufacturerAutocomplete
              placeholder="Select manufacturers"
              value={filters.manufacturers}
              onChange={(manufacturers) => {
                setFilters({ ...filters, manufacturers })
              }}
              isMulti
            />
          </Box>

          {/* Locations */}
          <Box mb={15}>
            <Text as="label" fontSize="md" fontWeight="medium" mb={1}>
              Locations
            </Text>
            <LocationAutocomplete
              placeholder="Select locations"
              value={filters.locations}
              onChange={(locations) => {
                setFilters({ ...filters, locations })
              }}
              isMulti
            />
          </Box>

          <Box mt={30} mb={15}>
            <Button
              size="sm"
              variant="outline"
              title="Reset Filters"
              onClick={resetFilters}
            >
              Reset
            </Button>
          </Box>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default FulfilmentFilters
