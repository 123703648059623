import React from 'react'

import { Badge, Button, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Thead, Tbody, Td, Tr, Th } from 'src/core/components/Table'
import { paginate, route, undoSlugify } from 'src/core/helpers'

import { IWarehouse } from '../warehouse.type'

interface IProps {
  warehouses: IWarehouse[]
  filters: any
  setFilters: (filters: any) => void
}
export const WarehousesTable: React.FC<IProps> = (props) => {
  const history = useHistory()
  const { warehouses, filters, setFilters } = props

  const paginatedWarehouses = React.useMemo(() => {
    return warehouses ? paginate(warehouses, filters.page) : []
  }, [filters.page, warehouses])

  const openWarehouse = (warehouse): void => {
    history.push(
      route('warehouse_update', {
        id: warehouse._id,
      })
    )
  }

  return (
    <>
      {!paginatedWarehouses.length ? (
        <ErrorBox summary="No warehouses found" my={50} />
      ) : null}

      {paginatedWarehouses.length ? (
        <Table isFit>
          <Thead>
            <Tr>
              <Th textAlign="center">#</Th>
              <Th>Owner</Th>
              <Th>Type</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedWarehouses.map((warehouse, index) => (
              <Tr key={warehouse._id}>
                <Td textAlign="center">
                  {index + 1 + (filters.page - 1) * 10}
                </Td>
                <Td>
                  <Text
                    cursor="default"
                    onClick={() => openWarehouse(warehouse)}
                  >
                    {warehouse?.parent?.name || '-'}
                  </Text>
                </Td>
                <Td>
                  <Badge colorScheme="gray" mt={1}>
                    {undoSlugify(warehouse?.parent_type) || '-'}
                  </Badge>
                </Td>
                <Td>
                  <Badge
                    colorScheme={
                      warehouse.status === 'active' ? 'success' : 'critical'
                    }
                  >
                    {warehouse.status}
                  </Badge>
                </Td>
                <Td>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() =>
                      history.push(
                        route('warehouse_products', {
                          id: warehouse._id,
                        })
                      )
                    }
                  >
                    Products
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : null}

      <Pagination
        my={5}
        itemsPerPage={10}
        totalItems={warehouses.length}
        currentPage={filters.page}
        onPageChange={(page) => setFilters({ page })}
      />
    </>
  )
}
