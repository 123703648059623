import { Role, supliasStaff, everyone } from './roles'

import { categoryPermissions } from 'src/applets/category'
import { purchaseOrderPermissions } from 'src/applets/purchaseOrder'
import { warehousePermissions } from 'src/applets/warehouse'

export interface IPermission {
  /** Unique permission identifier */
  id: string
  /** Roles tied to permission */
  roles: Role[]
}

export const permissions: IPermission[] = [
  ...categoryPermissions,
  ...purchaseOrderPermissions,
  ...warehousePermissions,

  // Sidemenu
  {
    id: 'view_sidebar_orders',
    roles: [...supliasStaff, Role.SellerManager, Role.CustomerSupport],
  },
  { id: 'view_sidebar_inventory', roles: [...everyone, Role.CustomerSupport] },
  { id: 'view_sidebar_logistics', roles: [...supliasStaff] },
  { id: 'view_sidebar_partners', roles: [] },
  { id: 'view_sidebar_people', roles: [...supliasStaff, Role.CustomerSupport] },
  { id: 'view_sidebar_reports', roles: [...supliasStaff] },
  { id: 'view_sidebar_more', roles: [...everyone] },

  // Agents
  { id: 'view_agents', roles: [Role.Admin] },
  { id: 'signup_agent', roles: [Role.Admin] },

  // Areas
  { id: 'view_areas', roles: [Role.Admin] },
  { id: 'create_area', roles: [Role.Admin] },
  { id: 'update_area', roles: [Role.Admin] },

  // Brands
  { id: 'view_brands', roles: [...supliasStaff] },
  { id: 'create_brand', roles: [...supliasStaff] },
  { id: 'update_brand', roles: [...supliasStaff] },

  // Buyers
  { id: 'view_buyers', roles: [...supliasStaff, Role.CustomerSupport] },
  { id: 'view_buyer_cart', roles: [...supliasStaff, Role.CustomerSupport] },
  { id: 'signup_buyer', roles: [...supliasStaff, Role.CustomerSupport] },
  { id: 'update_buyer', roles: [...supliasStaff, Role.CustomerSupport] },

  // Buyer Tickets
  { id: 'view_buyer_tickets', roles: [Role.Admin] },
  { id: 'create_buyer_ticket', roles: [Role.Admin] },
  { id: 'update_buyer_ticket', roles: [Role.Admin] },

  // Cohorts
  { id: 'view_cohorts', roles: [Role.Admin] },
  { id: 'create_cohort', roles: [Role.Admin] },
  { id: 'update_cohort', roles: [Role.Admin] },

  // Config
  { id: 'update_config', roles: [Role.Admin] },

  // Dashboard
  { id: 'view_dashboard_charts', roles: [...supliasStaff] },

  // Distributors
  { id: 'view_distributors', roles: [...supliasStaff] },
  { id: 'create_distributor', roles: [...supliasStaff] },
  { id: 'update_distributor', roles: [...supliasStaff] },

  // Distributor Officers
  { id: 'view_distributor_officers', roles: [...supliasStaff] },
  { id: 'signup_distributor_officer', roles: [...supliasStaff] },
  { id: 'update_distributor_officer', roles: [...supliasStaff] },

  // Orders
  {
    id: 'view_order',
    roles: [...supliasStaff, Role.SellerManager, Role.CustomerSupport],
  },
  {
    id: 'view_orders',
    roles: [...supliasStaff, Role.SellerManager, Role.CustomerSupport],
  },
  { id: 'view_order_fulfilment', roles: [...supliasStaff] },
  { id: 'create_order', roles: [...supliasStaff] },
  { id: 'update_order', roles: [...supliasStaff] },
  { id: 'update_order_tag', roles: [...supliasStaff] },

  // Locations
  { id: 'view_locations', roles: [Role.Admin] },
  { id: 'create_location', roles: [Role.Admin] },
  { id: 'update_location', roles: [Role.Admin] },

  // Managers
  { id: 'view_managers', roles: [Role.Admin] },
  { id: 'signup_manager', roles: [Role.Admin] },
  { id: 'update_manager', roles: [Role.Admin] },

  // Manufacturers
  { id: 'view_manufacturers', roles: [Role.Admin] },
  { id: 'create_manufacturer', roles: [Role.Admin] },
  { id: 'update_manufacturer', roles: [Role.Admin] },

  // Notifications
  { id: 'send_notification', roles: [...supliasStaff] },

  // Products
  {
    id: 'view_products',
    roles: [...supliasStaff, Role.SellerManager, Role.CustomerSupport],
  },
  { id: 'create_product', roles: [...supliasStaff] },
  { id: 'update_product', roles: [...supliasStaff, Role.SellerManager] },

  // Reports
  { id: 'view_reports', roles: [...supliasStaff] },

  // Sales Officers
  { id: 'view_sales_officers', roles: [...supliasStaff] },
  { id: 'signup_sales_officer', roles: [...supliasStaff] },
  { id: 'update_sales_officer', roles: [...supliasStaff] },

  // Sellers
  { id: 'view_sellers', roles: [...supliasStaff] },
  { id: 'create_seller', roles: [...supliasStaff] },
  { id: 'update_seller', roles: [...supliasStaff] },

  // Store types
  { id: 'view_store_types', roles: [Role.Admin] },
  { id: 'create_store_type', roles: [Role.Admin] },
  { id: 'update_store_type', roles: [Role.Admin] },
]
