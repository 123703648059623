import React from 'react'

import { VStack, Grid, StackDivider, Button } from '@chakra-ui/react'
import getUnixTime from 'date-fns/getUnixTime'
import { Formik, Form } from 'formik'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

import { DateInput, FormikField, FormStack } from 'src/core/components'
import { useToast } from 'src/core/hooks'
import { route } from 'src/core/helpers'

import { purchaseOrderService } from '../purchaseOrder.service'
import {
  IPurchaseOrder,
  PurchaseOrderTag,
  PurchaseOrderPartyType,
} from '../purchaseOrder.type'
import {
  PurchaseOrderPartyAutocomplete,
  PurchaseOrderPartyAutocompleteType,
} from './PurchaseOrderPartyAutoComplete'
import { subDays } from 'date-fns'

interface PurchaseOrderFormType {
  action?: 'create' | 'update'
  initialValues?: {
    source_type?: PurchaseOrderPartyType
    destination_type?: PurchaseOrderPartyType
  }
  purchaseOrder?: IPurchaseOrder
  onCreate?: (purchaseOrderId: string) => void
  onUpdate?: (brand: IPurchaseOrder) => void
}

export const partyTypeOptions = [
  { label: 'Distributor', value: PurchaseOrderPartyType.Distributor },
  { label: 'Hub', value: PurchaseOrderPartyType.Seller },
  { label: 'Delivery Associate', value: PurchaseOrderPartyType.SalesOfficer },
]

export const PurchaseOrderForm: React.FC<PurchaseOrderFormType> = (props) => {
  const { action, purchaseOrder, initialValues, onUpdate, onCreate } = props

  if (action === 'update' && !purchaseOrder) {
    throw new Error('Purchase order form requires purchaseOrder object')
  }

  const history = useHistory()
  const { toast } = useToast()

  const sourceType = purchaseOrder?.source_type || initialValues?.source_type
  const destinationType =
    purchaseOrder?.destination_type || initialValues?.destination_type

  const returnDate = React.useMemo<null | number>(() => {
    const timestamp = getUnixTime(subDays(new Date(), 1))
    return sourceType === PurchaseOrderPartyType.SalesOfficer &&
      destinationType === PurchaseOrderPartyType.Seller
      ? timestamp
      : null
  }, [destinationType, sourceType])

  const formConfig = {
    initialValues: {
      timestamp: purchaseOrder?.timestamp || getUnixTime(new Date()),
      meta: purchaseOrder?.meta || '{}',
      tag: purchaseOrder?.tag || PurchaseOrderTag.PurchaseOrder,
      source_type: partyTypeOptions.find(({ value }) => value === sourceType),
      destination_type: partyTypeOptions.find(
        ({ value }) => value === destinationType
      ),
      source: purchaseOrder?.source
        ? {
            value: purchaseOrder?.source_obj?._id,
            label: purchaseOrder?.source_obj?.name,
          }
        : null,
      destination: purchaseOrder?.destination
        ? {
            value: purchaseOrder?.destination_obj?._id,
            label: purchaseOrder?.destination_obj?.name,
          }
        : null,
    },
    validationSchema: Yup.object({
      tag: Yup.string().required('Tag field is required.'),
      timestamp: Yup.string().required('Timestamp field is required.'),
      meta: Yup.string().required('Meta field is required.'),
      source: Yup.mixed().required('Seller field is required.').nullable(),
      destination: Yup.mixed()
        .test(
          'source-destination-match',
          'Source and destination cannot be the same.',
          function (destination) {
            return destination?.value !== this.parent.source?.value
          }
        )
        .required('Distributor field is required.')
        .nullable(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        ref: purchaseOrder?.ref || '*',
        timestamp: values.timestamp,
        tag: values.tag,
        meta: values.meta,
        source_type: values.source_type.value,
        source: values.source.value,
        destination_type: values.destination_type.value,
        destination: values.destination.value,
        amount: purchaseOrder?.amount || '0',
      }

      if (action === 'update') {
        finalValues._id = purchaseOrder._id
      }

      purchaseOrderService[action](finalValues)
        .then((newPurchaseOrderId) => {
          setSubmitting(false)

          if (action === 'update') {
            toast({
              description: 'Purchase order successfully updated.',
              status: 'success',
            })
            onUpdate({
              ...finalValues,
              source_obj: {
                _id: values.source.value,
                name: values.source.label,
              },
              destination_obj: {
                _id: values.destination.value,
                name: values.destination.label,
              },
            })
          } else {
            toast({
              description: 'Purchase order successfully created.',
              status: 'success',
            })
            if (onCreate) {
              onCreate(newPurchaseOrderId)
            } else {
              history.push(route('purchase_order', { id: newPurchaseOrderId }))
            }
          }
        })
        .catch((error) => {
          setSubmitting(false)
          toast({
            description: error.message,
            status: 'error',
          })
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ values, handleSubmit, ...formik }) => {
        const getEntityAutoCompleteProps = (
          party: string,
          party_name?: string
        ): PurchaseOrderPartyAutocompleteType => {
          return {
            size: 'md',
            name: `${party}`,
            value: values[party],
            placeholder: `Select ${party_name || party}`,
            onBlur: () => {
              formik.setFieldTouched(`${party}`, true)
            },
            onChange: (selected) => {
              formik.setFieldValue(`${party}`, selected)
            },
            isDisabled: purchaseOrder?.tag === PurchaseOrderTag.Receipt,
            disableSelectAll: true,
          }
        }

        return (
          <Form onSubmit={handleSubmit}>
            <VStack
              divider={<StackDivider borderColor="gray.100" />}
              spacing={5}
              align="stretch"
              my={5}
            >
              {/* Date */}
              <FormStack label="Date" alignItems="center" isRequired>
                <DateInput
                  size="md"
                  value={returnDate || values.timestamp}
                  onChange={(timestamp) => {
                    formik.setFieldValue('timestamp', timestamp)
                  }}
                  isDisabled={
                    purchaseOrder?.tag === PurchaseOrderTag.Receipt ||
                    !!returnDate
                  }
                  showTimeSelect
                  format="MMM dd, yyyy - h:mm aa"
                />
              </FormStack>

              {/* Source */}
              <FormStack
                label={
                  initialValues?.source_type
                    ? values.source_type?.label || 'From'
                    : 'From'
                }
                helperText="Where are you moving stock from?"
                alignItems="center"
                isRequired
              >
                <Grid
                  templateColumns={
                    !initialValues?.source_type ? '1fr 2fr' : '1fr'
                  }
                  gap={3}
                >
                  {!initialValues?.source_type && (
                    <FormikField
                      as="autocomplete"
                      name="source_type"
                      options={partyTypeOptions}
                      placeholder={`Select type`}
                      helperText="Distributor, Hub or Delivery Associate"
                      onChange={(selected) => {
                        formik.setFieldValue('source_type', selected)
                        formik.setFieldValue('source', null)
                      }}
                      isDisabled={
                        purchaseOrder?.tag === PurchaseOrderTag.Receipt
                      }
                    />
                  )}

                  <PurchaseOrderPartyAutocomplete
                    partyType={values.source_type?.value}
                    {...getEntityAutoCompleteProps(
                      'source',
                      values.source_type?.label
                    )}
                  />
                </Grid>
              </FormStack>

              {/* Destination */}
              <FormStack
                label={
                  initialValues?.destination_type
                    ? values.destination_type?.label || 'To'
                    : 'To'
                }
                helperText="Where are you moving stock to?"
                alignItems="center"
                isRequired
              >
                <Grid
                  templateColumns={
                    !initialValues?.destination_type ? '1fr 2fr' : '1fr'
                  }
                  gap={3}
                >
                  {!initialValues?.destination_type && (
                    <FormikField
                      as="autocomplete"
                      name="destination_type"
                      options={partyTypeOptions}
                      placeholder={`Select type`}
                      helperText="Distributor, Hub or Delivery Associate"
                      onChange={(selected) => {
                        formik.setFieldValue('destination_type', selected)
                        formik.setFieldValue('destination', null)
                      }}
                      isDisabled={
                        purchaseOrder?.tag === PurchaseOrderTag.Receipt
                      }
                    />
                  )}

                  <PurchaseOrderPartyAutocomplete
                    partyType={values.destination_type?.value}
                    {...getEntityAutoCompleteProps(
                      'destination',
                      values.destination_type?.label
                    )}
                  />
                </Grid>
              </FormStack>
            </VStack>

            <Button
              type="submit"
              colorScheme="primary"
              mt={5}
              isDisabled={
                formik.isSubmitting || !formik.dirty || !formik.isValid
              }
              isLoading={formik.isSubmitting}
            >
              {action === 'create' ? 'Save & Add Products' : 'Update'}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

PurchaseOrderForm.defaultProps = {
  action: 'create',
}
