import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import SellerForm from '../components/SellerForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { SellerService, SellerServiceProps } from '../seller.service'
import { ISeller } from '../seller.type'
import { route } from 'src/core/helpers/route.helper'

const sellerService = new SellerService()

const SellerEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: seller_id }: any = useParams()

  const permission = seller_id ? 'update_seller' : 'create_seller'

  const [seller, isLoading, , setSeller] = useFetch<
    ISeller,
    SellerServiceProps
  >(sellerService, 'fetchById', [seller_id], !!seller_id && userCan(permission))

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Seller' : seller?.name || 'New Seller',
        backText: 'Sellers',
        onBack: () => history.push(route('sellers')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={seller_id ? 'update' : 'create'}
        model={seller}
        isLoading={isLoading}
      >
        {seller_id ? (
          <SellerForm
            type="update"
            seller={seller}
            onUpdate={(updatedValues) => {
              setSeller({ ...seller, ...updatedValues })
            }}
          />
        ) : (
          <SellerForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default SellerEditor
