import { CoreService } from 'src/core/services'

export class DeviceService extends CoreService {
  constructor() {
    super('device')
  }

  fetch(): Promise<any> {
    const formData = new FormData()
    formData.append('key', 'version')

    return new Promise((resolve, reject) => {
      const cacheKey = `devices`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http.post(`device/read_all_dict`, formData).then(({ data }) => {
            if (data.code === 200) {
              this.cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchFragmentation(): Promise<any> {
    return new Promise((resolve, reject) => {
      const cacheKey = `devices`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http.get(`device/fragmentation`).then(({ data }) => {
            if (data.code === 200) {
              const in_zone = data.data['in_zone']
              const out_of_zone = data.data['out_of_zone']

              let in_zone_count = 0
              let out_of_zone_count = 0

              let devices_data = []

              if (typeof in_zone === 'object') {
                Object.keys(in_zone).forEach((version) => {
                  const version_code = version

                  if (version !== 'unknown') {
                    const version_parts = [
                      version[0],
                      Number(version[1] + version[2]),
                      Number(version[3] + version[4]),
                    ]

                    version = version_parts.join('.')
                  }

                  devices_data.push({
                    name: version,
                    value: Object.keys(in_zone[version_code]).length,
                  })
                })

                devices_data = devices_data.sort((a, b) =>
                  a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                )

                in_zone_count = Number(
                  Object.values(in_zone).reduce(
                    (a: number, version): number => {
                      const user_count = Object.keys(version).length
                      return a + user_count
                    },
                    0
                  )
                )
              }

              if (typeof out_of_zone === 'object') {
                out_of_zone_count = Number(Object.keys(out_of_zone).length)
              }

              const final_data = {
                zones: [
                  { name: 'In Zone', value: in_zone_count },
                  { name: 'Out of Zone', value: out_of_zone_count },
                ],
                in_zone_devices: devices_data,
              }

              this.cache.set(cacheKey, final_data)
              resolve(final_data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default DeviceService
