import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik, ErrorMessage } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

import {
  Autocomplete,
  FormikField,
  FormikRadioGroup,
  FormStack,
  ImageUploader,
} from 'src/core/components'
import { useFetch, useToast } from 'src/core/hooks'
import { route } from 'src/core/helpers'

import { manufacturerService } from 'src/applets/manufacturer'
import { uploadService } from 'src/applets/upload'
import { brandService } from '../brand.service'
import { IBrand } from '../brand.type'
import useRefactorObjects from 'src/core/hooks/useRefactorObjects'

interface BrandFormProps {
  type?: 'create' | 'update'
  brand?: IBrand
  onUpdate?: (brand: IBrand) => void
}
const BrandForm: React.FC<BrandFormProps> = ({ type, brand, onUpdate }) => {
  const history = useHistory()
  const { addToast } = useToast()

  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const [mftrs] = useFetch(manufacturerService, 'fetch')
  const mftrOptions = useRefactorObjects(mftrs)

  const formConfig = {
    initialValues: {
      manufacturer: brand
        ? {
            value: brand.manufacturer._id,
            label: brand.manufacturer.name,
          }
        : null,
      name: brand?.name || '',
      status: brand?.status || 'active',
    },
    validationSchema: Yup.object({
      manufacturer: Yup.mixed()
        .required('Manufacturer field is required.')
        .nullable(),
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        name: values.name,
        status: values.status,
        manufacturer_id: values.manufacturer.value,
      }

      if (type === 'update') {
        finalValues._id = brand._id
        finalValues.upload_id = brand.upload_id
      }

      brandService[type](finalValues)
        .then((brandId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Brand successfully updated.', { appearance: 'success' })
            onUpdate(values)
          } else {
            addToast('Brand successfully created.', { appearance: 'success' })
            history.push(route('brand_update', { id: brandId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const onUpload = (uploadData): Promise<void> => {
    return new Promise((resolve) => {
      uploadService
        .upload(uploadData, `brand_${brand._id}`)
        .then((uploadId) => {
          const updatedBrand = {
            _id: brand._id,
            name: brand.name,
            status: brand.status,
            manufacturer_id: brand.manufacturer_id,
            upload_id: uploadId,
          }

          brandService
            .update(updatedBrand)
            .then(() => {
              setIsUploading(false)

              onUpdate(updatedBrand)
              addToast('Image upload successful.', {
                appearance: 'success',
              })
              resolve()
            })
            .catch((error) => {
              setIsUploading(false)
              addToast(error.message, { appearance: 'error' })
              resolve()
            })
        })
        .catch((error) => {
          setIsUploading(false)
          addToast(error.message, { appearance: 'error' })
          resolve()
        })
    })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Image */}
            {type === 'update' && brand ? (
              <FormStack label="Image" helperText="Brand logo">
                <ImageUploader
                  uploadPath={brand.upload ? brand.upload.path : ''}
                  onUpload={onUpload}
                />
              </FormStack>
            ) : null}

            {/* Brand status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Name */}
            <FormStack label="Name" helperText="Name of brand" isRequired>
              <FormikField name="name" type="text" placeholder="ex. Durex" />
            </FormStack>

            {/* Manufacturer */}
            <FormStack
              label="Manufacturer"
              helperText="Brand manufacturer"
              isRequired
            >
              <Autocomplete
                name="manufacturer"
                options={mftrOptions}
                value={values.manufacturer}
                placeholder="Select manufacturer"
                onBlur={() => {
                  formik.setFieldTouched('manufacturer', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('manufacturer', selected)
                }}
                isInvalid={
                  touched.manufacturer && errors.manufacturer ? true : false
                }
                isDisabled={!mftrOptions?.length}
              />
              <ErrorMessage
                name="manufacturer"
                component="span"
                className="invalid-feedback"
              />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={
              formik.isSubmitting ||
              !formik.dirty ||
              !formik.isValid ||
              isUploading
            }
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Brand' : 'Update Brand'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

BrandForm.defaultProps = {
  type: 'create',
}

export default BrandForm
