import React from 'react'

import { useHistory, useParams } from 'react-router'

import { Layout } from 'src/core/components'
import { ForeignerProducts, useDistributorProducts } from 'src/applets/product'

import useFetch from 'src/core/hooks/useFetch'
import usePermission from 'src/core/hooks/usePermission'

import {
  distributorService,
  DistributorServiceProps,
} from '../distributor.service'
import { IDistributor } from '../distributor.type'
import { route } from 'src/core/helpers/route.helper'

const DistributorProducts: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: distributor_id }: any = useParams()

  const [distributor] = useFetch<IDistributor, DistributorServiceProps>(
    distributorService,
    'fetchById',
    [distributor_id],
    userCan('update_distributor')
  )

  const { allProducts, distributorProducts } =
    useDistributorProducts(distributor)

  return (
    <Layout
      headerProps={{
        title: `${distributor?.name || 'Distributor'} Products`,
        backText: 'Distributors',
        onBack: () => history.push(route('distributors')),
      }}
    >
      <ForeignerProducts
        allProducts={allProducts}
        foreigner={{
          ...distributor,
          products: distributorProducts,
          model: 'distributor',
          service: distributorService,
        }}
      />
    </Layout>
  )
}

export default DistributorProducts
