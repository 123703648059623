import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { FiBox, FiCheck, FiGitMerge, FiSend } from 'react-icons/fi'

import { Card, CardBody } from 'src/core/components/Card'
// import { trans } from 'src/core/helpers'

const Step: React.FC<any> = ({ isCurrent, isDone, ...props }) => {
  return (
    <Flex
      mx={4}
      width="200px"
      flexDir="column"
      alignItems="center"
      opacity={isDone ? 0.5 : 1}
      color={isCurrent || isDone ? 'success.600' : 'gray.600'}
    >
      {isDone ? <FiCheck size={18} /> : props.icon}
      <Text textAlign="center" fontSize="sm" lineHeight="16px" mt={1}>
        {props.label}
      </Text>
    </Flex>
  )
}

const Divider: React.FC<any> = (props) => {
  return (
    <Box
      height="4px"
      width="100%"
      opacity={0.4}
      bgGradient={
        props.isComplete
          ? 'linear(to-r, success.500, success.500)'
          : props.inProgress
          ? 'linear(to-r, success.500, gray.300)'
          : 'linear(to-r, gray.300, gray.300)'
      }
    />
  )
}

export const InventoryManagerSteps: React.FC<any> = (props) => {
  const { isCreate, isAddProducts, isComplete } = props

  return (
    <Card>
      <CardBody>
        <Flex alignItems="center" justifyContent="space-between">
          <Step
            icon={<FiGitMerge size={18} />}
            isDone={isAddProducts || isComplete}
            isCurrent={isCreate}
            label="Transfer Details"
          />

          <Divider
            inProgress={isCreate}
            isComplete={isAddProducts || isComplete}
          />

          <Step
            icon={<FiBox size={18} />}
            isDone={isComplete}
            isCurrent={isAddProducts}
            label="Add Products"
          />

          <Divider inProgress={isAddProducts} isComplete={isComplete} />

          <Step
            icon={<FiSend size={18} />}
            label="Complete Transfer"
            isDone={isComplete}
          />
        </Flex>
      </CardBody>
    </Card>
  )
}
