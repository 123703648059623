import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import StoreTypeForm from '../components/StoreTypeForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { StoreTypeService, StoreTypeServiceProps } from '../storeType.service'
import { IStoreType } from '../storeType.type'
import { route } from 'src/core/helpers/route.helper'

const storeTypeService = new StoreTypeService()

const StoreTypeEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: store_type_id }: any = useParams()

  const permission = store_type_id ? 'update_store_type' : 'create_store_type'

  const [storeType, isLoading, , setStoreType] = useFetch<
    IStoreType,
    StoreTypeServiceProps
  >(
    storeTypeService,
    'fetchById',
    [store_type_id],
    !!store_type_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Store Type' : storeType?.name || 'New Store Type',
        backText: 'Store Types',
        onBack: () => history.push(route('store_types')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={store_type_id ? 'update' : 'create'}
        model={storeType}
        isLoading={isLoading}
      >
        {store_type_id ? (
          <StoreTypeForm
            type="update"
            storeType={storeType}
            onUpdate={(updatedValues) => {
              setStoreType({ ...storeType, ...updatedValues })
            }}
          />
        ) : (
          <StoreTypeForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default StoreTypeEditor
