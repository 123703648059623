import React from 'react'

import {
  Spinner,
  Flex,
  Box,
  useTheme,
  Text,
  SimpleGrid,
} from '@chakra-ui/react'
import { Card } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, SearchInput, ErrorBox } from 'src/core/components'
import SellerManufacturerToggle from '../components/SellerManufacturerToggle'

import useMounted from 'src/core/hooks/useMounted'
import usePermission from 'src/core/hooks/usePermission'

import { ManufacturerService } from 'src/applets/manufacturer'
import { SellerService } from '../seller.service'
import { IManufacturer } from 'src/applets/manufacturer'
import { ISeller } from '../seller.type'
import { sortByKey } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'

const sellerService = new SellerService()
const manufacturerService = new ManufacturerService()

const SellerManufacturers: React.FC = () => {
  const { sizes } = useTheme()
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const { id: seller_id }: any = useParams()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [seller, setSeller] = React.useState<ISeller>()
  const [manufacturers, setManufacturers] = React.useState<IManufacturer[]>()

  const [searchTerm, setSearchTerm] = React.useState<string>('')

  React.useEffect(() => {
    const fetchSeller = sellerService.fetchById(seller_id)
    const fetchManufacturers = manufacturerService.fetch()
    const fetchSellerManufacturers = sellerService.fetchManufacturers(seller_id)

    if (userCan('update_seller')) {
      Promise.all([fetchSeller, fetchManufacturers, fetchSellerManufacturers])
        .then(([seller, manufacturers, sellerManufacturers]) => {
          isMounted.current && setSeller(seller)

          /**
           * Store seller manufacturers as object with
           * "manufacturer_id" as key. We'll need this when
           * looping through the manufacturers array to show
           * <SellerManufacturerToggle />
           */
          const sellerManufacturersObj = {}
          if (sellerManufacturers) {
            sellerManufacturers.forEach((sellerManufacturer) => {
              sellerManufacturersObj[sellerManufacturer.manufacturer_id] =
                sellerManufacturer
            })
          }

          manufacturers = manufacturers.map((manufacturer) => {
            return {
              ...manufacturer,
              seller_manufacturer: sellerManufacturersObj?.[manufacturer._id],
              seller_manufacturer_status:
                sellerManufacturersObj?.[manufacturer._id]?.status === 'active'
                  ? 1
                  : 0,
            }
          })

          manufacturers = sortByKey(
            manufacturers,
            'seller_manufacturer_status',
            'desc'
          )

          isMounted.current && setManufacturers(manufacturers)
        })
        .catch(() => {
          isMounted.current && setIsLoading(false)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }
  }, [userCan, seller_id, isMounted])

  const filteredManufacturers = React.useMemo(() => {
    let filtered = manufacturers || []

    if (filtered.length) {
      if (searchTerm) {
        filtered = filtered.filter((manufacturer) => {
          return basicSearch(manufacturer.name, searchTerm)
        })
      }
    }

    return filtered
  }, [manufacturers, searchTerm])

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Seller (Manufacturers)'
          : `${seller?.name || 'Seller not found'} (Manufacturers)`,
        backText: 'Sellers',
        onBack: () => history.push(route('sellers')),
      }}
      permission="update_seller"
    >
      <Card>
        <Card.Header>
          <Flex alignItems="center">
            <Text mr={3}>Distributor Manufacturers</Text>
            <SearchInput
              placeholder="Search manufacturers..."
              value={searchTerm}
              onChange={(searchTerm) => setSearchTerm(searchTerm)}
              isDisabled={isLoading || !manufacturers?.length}
            />
          </Flex>
        </Card.Header>
        <Card.Body>
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner variant="primary" />
            </Flex>
          )}

          {!isLoading && (!seller || !filteredManufacturers?.length) ? (
            <ErrorBox summary="Manufacturer not found" my={50} />
          ) : null}

          {!isLoading && seller && filteredManufacturers?.length ? (
            <SimpleGrid columns={[1, 2, 4]} spacing={sizes.gutter.sm}>
              {filteredManufacturers.map((manufacturer) => (
                <Box key={manufacturer._id}>
                  <SellerManufacturerToggle
                    seller={seller}
                    manufacturer={manufacturer}
                    sellerManufacturer={manufacturer.seller_manufacturer}
                  />
                </Box>
              ))}
            </SimpleGrid>
          ) : null}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default SellerManufacturers
