import { Button, HStack, StackProps } from '@chakra-ui/react'
import {
  Pagination as UnstyledPagination,
  PaginationItem as UnstyledPaginationItem,
  PaginationProps as UnstyledPaginationProps,
} from '@unstyled-react/pagination'
import React from 'react'

export type PaginationBaseProps = UnstyledPaginationProps &
  Omit<StackProps, 'onChange'>

const PaginationBase: React.FC<PaginationBaseProps> = (props) => {
  return (
    <UnstyledPagination
      as={HStack}
      spacing="10px"
      pageSize={10}
      justifyContent="center"
      itemLabels={{ first: false, last: false }}
      renderItem={(item) => {
        const isFirstOrPrevious =
          ['first', 'previous'].includes(item.type) && props?.page === 1
        const isNextOrLast =
          ['next', 'last'].includes(item.type) && props?.page === props.count
        const isDisabled = isFirstOrPrevious || isNextOrLast

        return (
          <UnstyledPaginationItem // eslint-disable-next-line
            {...item}
            as={Button as any}
            size="sm"
            variant={item.active ? 'solid' : 'outline'}
            colorScheme={item.active ? 'gray' : 'gray'}
            borderRadius="md"
            transition="none"
            isDisabled={isDisabled}
          />
        )
      }}
      {...props}
    />
  )
}

type PaginationProps = {
  itemsPerPage: number
  totalItems: number
  currentPage: number
  onPageChange: (page: number) => void
}

export const Pagination: React.FC<
  PaginationProps & Omit<StackProps, 'onChange'>
> = ({
  currentPage,
  itemsPerPage,
  onPageChange,
  totalItems,
  ...rest
}: PaginationProps) => {
  return (
    <PaginationBase
      count={Math.ceil(totalItems / itemsPerPage)}
      page={currentPage}
      pageSize={itemsPerPage}
      onChange={onPageChange}
      {...rest}
    />
  )
}
