import React from 'react'
import {
  Table as ChakraTable,
  TableProps as ChakraTableProps,
} from '@chakra-ui/table'
import { Box } from '@chakra-ui/layout'

interface TableProps extends ChakraTableProps {
  tableRef?: any
  hasBorders?: boolean
  isFit?: boolean
}
export const Table: React.FC<TableProps> = ({
  tableRef,
  isFit,
  hasBorders,
  children,
  ...props
}) => {
  return (
    <Box ref={tableRef} overflowX="scroll">
      <ChakraTable
        css={{
          tableLayout: isFit ? 'auto' : 'unset',
          width: isFit ? 'auto' : 'inherit',
        }}
        {...props}
      >
        <TableContext.Provider value={{ isFit, hasBorders }}>
          {children}
        </TableContext.Provider>
      </ChakraTable>
    </Box>
  )
}

export const TableContext = React.createContext<TableProps>({})
