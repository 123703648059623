import React from 'react'

import { Flex, Spinner, Box } from '@chakra-ui/react'

import { Layout, ExportButton, PrintButton } from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'
import useFetch from 'src/core/hooks/useFetch'

import {
  OrderValueSummaryTable,
  OrderValueSummaryTableFilter,
} from '../components'
import { OrderGroupService, OrderGroupServiceProps } from '../services'
import { OrderTag } from '../order.type'

const orderGroupService = new OrderGroupService()

const OrderValueSummary: React.FC = () => {
  const tableRef = React.useRef()
  const tableWrapperRef = React.useRef()

  const [params, setParams] =
    React.useState<{
      sales_by: 'manufacturer' | 'brand' | 'product'
      tag: OrderTag
      seller: { value: string; label: string }
      from_timestamp: number
      to_timestamp: number
    }>()

  const [salesData, isLoading, , setSalesData] = useFetch<
    any,
    OrderGroupServiceProps
  >(orderGroupService, 'fetchSellerSales', [params], !!params)

  const items = React.useMemo(() => {
    const salesBy = params?.sales_by || 'product'
    let data = salesData?.items || []
    let refined = []

    if (data?.length) {
      data = data.map((item) => {
        item.type = salesBy
        return item
      })

      const dictionary = {}

      data.forEach((item) => {
        if (dictionary[item[salesBy]._id]) {
          dictionary[item[salesBy]._id].push(item)
        } else {
          dictionary[item[salesBy]._id] = [{ ...item, first_row: true }]
        }
      })

      Object.entries(dictionary).forEach(([, itemsArray]: any[]) => {
        refined = [...refined, ...itemsArray]
      })
    }

    return refined
  }, [salesData, params?.sales_by])

  return (
    <Layout headerProps={{ title: 'Order Value Summary' }}>
      <Card>
        <CardHeader as={Flex} justifyContent="space-between">
          <OrderValueSummaryTableFilter
            fetch={(params) => {
              setSalesData(null)
              setParams(params)
            }}
            isFetching={isLoading}
          />

          <Flex>
            <PrintButton
              documentRef={tableWrapperRef}
              isDisabled={!items?.length}
            />

            <Box ml={2}>
              <ExportButton
                filename="order-value-summary"
                tableRef={tableRef}
                isDisabled={!items?.length}
              />
            </Box>
          </Flex>
        </CardHeader>
        <CardBody className="p-0">
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <OrderValueSummaryTable
              items={items}
              grandTotal={salesData?.total}
              params={params}
              tableRef={tableRef}
              tableWrapperRef={tableWrapperRef}
            />
          )}
        </CardBody>
      </Card>
    </Layout>
  )
}

export default OrderValueSummary
