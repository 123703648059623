import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import WarehouseForm from '../components/WarehouseForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { WarehouseService, WarehouseServiceProps } from '../warehouse.service'
import { IWarehouse } from '../warehouse.type'
import { route } from 'src/core/helpers/route.helper'

const warehouseService = new WarehouseService()

const WarehouseEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: warehouse_id }: any = useParams()

  const permission = warehouse_id ? 'update_warehouse' : 'create_warehouse'

  const [warehouse, isLoading, , setWarehouse] = useFetch<
    IWarehouse,
    WarehouseServiceProps
  >(
    warehouseService,
    'fetchById',
    [warehouse_id],
    !!warehouse_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Warehouse'
          : warehouse?.parent?.name || 'New Warehouse',
        backText: 'Warehouses',
        onBack: () => history.push(route('warehouses')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={warehouse_id ? 'update' : 'create'}
        model={warehouse}
        isLoading={isLoading}
      >
        {warehouse_id ? (
          <WarehouseForm
            type="update"
            warehouse={warehouse}
            onUpdate={(updatedValues) => {
              setWarehouse({ ...warehouse, ...updatedValues })
            }}
          />
        ) : (
          <WarehouseForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default WarehouseEditor
