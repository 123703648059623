import React from 'react'

import {
  Avatar,
  Button,
  Box,
  Text,
  useTheme,
  Divider,
  IconButton,
  Flex,
} from '@chakra-ui/react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu'
import {
  FiUser,
  FiToggleLeft,
  FiChevronDown,
  FiChevronsLeft,
} from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { Role } from 'src/bootstrap/permissions/roles'

import { doLogout } from 'src/bootstrap/store/auth/authActions'
import { trans } from 'src/core/helpers/lang.helper'
import { route } from 'src/core/helpers/route.helper'
import { IUser } from 'src/applets/user'

interface SidebarHeaderProps {
  user: IUser
  sidebarOpen: boolean
  toggleSidebar: VoidFunction
}

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  user,
  sidebarOpen,
  toggleSidebar,
}) => {
  const { colors } = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const organization = React.useMemo(() => {
    return user.role === Role.SellerManager
      ? user?.seller?.name || 'Distributor'
      : 'Suplias'
  }, [user])

  const styleProps = {
    toggleButton: {
      p: '1px 10px',
      bg: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#212831',
      borderRadius: 99999,
      color: colors.white,
      leftIcon: <Avatar size="xs" name={organization} />,
      rightIcon: <FiChevronDown size={14} color={colors.gray['600']} />,
      _hover: { bg: '#0f1a36' },
      _focus: { bg: '#0f1a36' },
      _expanded: { bg: '#0f1a36' },
    },
  }

  return (
    <Box px={3} pb={3}>
      {sidebarOpen ? (
        <Flex alignItems="center" justifyContent="space-between">
          <Menu autoSelect={false}>
            <MenuButton as={Button} mr={2} {...styleProps.toggleButton}>
              <Text isTruncated>{organization}</Text>
            </MenuButton>
            <MenuList>
              <MenuItem minH="48px" _hover={{ bg: 'white' }}>
                <Box w="100%">
                  <Text fontWeight="medium" isTruncated>
                    {user.name}
                  </Text>
                  <Text color="gray.600" fontSize="sm" isTruncated>
                    {user.email}
                  </Text>
                  <Text color="gray.600" fontSize="sm" isTruncated>
                    {trans(`core.${user.role}`)}
                  </Text>
                  <Divider color="gray.200" mt={3} />
                </Box>
              </MenuItem>
              <MenuItem
                minH="40px"
                icon={<FiUser size={16} />}
                onClick={() => {
                  history.push(route('profile'))
                }}
              >
                My Profile
              </MenuItem>
              <MenuItem
                minH="40px"
                icon={<FiToggleLeft size={16} />}
                color="critical.600"
                onClick={() => dispatch(doLogout())}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>

          <IconButton
            aria-label="Toggle sidebar"
            size="sm"
            variant="ghost"
            bg="transparent"
            color="gray.500"
            border={`1px solid #212831`}
            icon={<FiChevronsLeft size={16} />}
            _hover={{ bg: '#0f1a36' }}
            onClick={toggleSidebar}
          />
        </Flex>
      ) : (
        <Button
          as={Button}
          p="1px 10px"
          bg="transparent"
          border={`1px solid #212831`}
          borderRadius={99999}
          color={colors.white}
          leftIcon={<Avatar size="xs" name={organization} />}
          rightIcon={<FiChevronDown size={14} color={colors.gray['600']} />}
          _hover={{ bg: '#0f1a36' }}
          _focus={{ bg: '#0f1a36' }}
          _expanded={{ bg: '#0f1a36' }}
          onClick={toggleSidebar}
        />
      )}
    </Box>
  )
}

export default SidebarHeader
