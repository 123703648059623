import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { Role } from 'src/bootstrap/permissions/roles'
import { IStoreState } from 'src/bootstrap/store/types'
import {
  FormikField,
  FormikRadioGroup,
  FormStack,
  ImageUploader,
} from 'src/core/components'
import { route } from 'src/core/helpers'
import { useFetch, useToast } from 'src/core/hooks'

import { brandService } from 'src/applets/brand'
import { uploadService } from 'src/applets/upload'

import { productService } from '../product.service'
import { IProduct } from '../product.type'
import useRefactorObjects from 'src/core/hooks/useRefactorObjects'

const DEFAULT_UPLOAD_ID = process.env.REACT_APP_DEFAULT_UPLOAD_ID

interface IProps {
  type?: 'create' | 'update'
  product?: IProduct
  onUpdate?: (values: IProduct) => void
}

export const ProductForm: React.FC<IProps> = ({ type, product, onUpdate }) => {
  const history = useHistory()
  const { toast } = useToast()

  const user = useSelector((state: IStoreState) => state.user)

  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const [brands] = useFetch(brandService, 'fetch', [])
  const brandOptions = useRefactorObjects(brands)

  const formConfig = {
    initialValues: {
      brand: product
        ? {
            value: product.brand._id,
            label: product.brand.name,
          }
        : null,
      name: product?.name || '',
      description: product?.description || '',
      status: product?.status || 'active',
      sku_id: product?.sku_id || '',
      case_count: product?.case_count || '',
      mpu: product?.mpu || '',
      price: product?.price || '',
      weight: product?.weight || '0',
      size: product?.weight || '0',
    },
    validationSchema: Yup.object({
      brand: Yup.mixed().required('Brand field is required.').nullable(),
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      description: Yup.string()
        .required('Description name field is required.')
        .min(10, 'Description name must be at least 10 characters.'),
      status: Yup.string().required('Status field is required.'),
      sku_id: Yup.string().required('SKU ID field is required.'),
      case_count: Yup.number().required('Case count field is required.'),
      mpu: Yup.number()
        .test(
          'case-count-is-multiple',
          'Case count must be multiple of MPU',
          function (value) {
            return this.parent.case_count % value === 0
          }
        )
        .required('MPU field is required.'),
      price: Yup.number().required('Price field is required.'),
      weight: Yup.number().required('Weight field is required'),
      size: Yup.number().required('Size field is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        name: values.name,
        description: values.description,
        status: values.status,
        sku_id: values.sku_id,
        case_count: values.case_count,
        mpu: values.mpu,
        price: values.price,
        brand_id: values.brand.value,
        weight: values.weight,
        size: values.size,
      }

      if (type === 'update') {
        finalValues._id = product._id
        finalValues.upload_id = product.upload_id
      } else {
        finalValues.upload_id = DEFAULT_UPLOAD_ID
      }

      productService[type]({ ...finalValues })
        .then((newProductId) => {
          setSubmitting(false)

          toast({
            description: `Product successfully ${type}d.`,
            status: 'success',
          })

          if (type === 'update') onUpdate({ ...finalValues })
          else history.push(route('product_update', { id: newProductId }))
        })
        .catch((error) => {
          setSubmitting(false)
          toast({ description: error.message, status: 'error' })
        })
    },
  }

  const onUpload = (uploadData): Promise<void> => {
    return new Promise((resolve) => {
      uploadService
        .upload(uploadData, `product_${product._id}`)
        .then((uploadId) => {
          const updatedProduct: any = {
            _id: product._id,
            name: product.name,
            description: product.description,
            status: product.status,
            sku_id: product.sku_id,
            case_count: product.case_count,
            mpu: product.mpu,
            price: product.price,
            brand_id: product.brand_id,
            upload_id: uploadId,
            weight: product.weight,
            size: product.size,
          }

          productService
            .update(updatedProduct)
            .then(() => {
              setIsUploading(false)
              onUpdate(updatedProduct)

              toast({
                status: 'success',
                description: 'Image upload successful.',
              })
              resolve()
            })
            .catch((error) => {
              setIsUploading(false)
              toast({ status: 'error', description: error.message })
              resolve()
            })
        })
        .catch((error) => {
          setIsUploading(false)
          toast({ description: error.messgae, status: 'error' })
          resolve()
        })
    })
  }

  const columns: [number, number] = [
    type === 'update' ? 4 : 3,
    type === 'update' ? 8 : 6,
  ]

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack columns={columns} label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Image */}
            {type === 'update' && product ? (
              <FormStack columns={columns} label="Image">
                <ImageUploader
                  uploadPath={product.upload ? product.upload.path : null}
                  onUpload={onUpload}
                  imageProps={{ borderWidth: '1px' }}
                />
              </FormStack>
            ) : null}

            {/* Brand */}
            <FormStack columns={columns} label="Brand" isRequired>
              <FormikField
                as="autocomplete"
                name="brand"
                options={brandOptions}
                placeholder="Select brand"
                isDisabled={!brandOptions.length}
              />
            </FormStack>

            {/* Name */}
            <FormStack columns={columns} label="Name" isRequired>
              <FormikField name="name" type="text" />
            </FormStack>

            {/* Description */}
            <FormStack columns={columns} label="Description" isRequired>
              <FormikField as="textarea" name="description" rows={3} />
            </FormStack>

            {/* SKU ID */}
            <FormStack columns={columns} label="SKU ID" isRequired>
              <FormikField
                name="sku_id"
                type="text"
                isDisabled={type === 'update' && user?.role !== Role.Admin}
              />
            </FormStack>

            {/* Case count */}
            <FormStack columns={columns} label="Case Count" isRequired>
              <FormikField name="case_count" type="number" />
            </FormStack>

            {/* MPU */}
            <FormStack
              columns={columns}
              label="MPU"
              helperText="Minimum purchasable unit"
              isRequired
            >
              <FormikField
                name="mpu"
                type="number"
                isDisabled={type === 'update' && user?.role !== Role.Admin}
              />
            </FormStack>

            {/* Price */}
            <FormStack columns={columns} label="Price" isRequired>
              <FormikField
                name="price"
                type="number"
                prepend="NGN"
                isDisabled={type === 'update' && user?.role !== Role.Admin}
              />
            </FormStack>

            {/* Weight */}
            <FormStack columns={columns} label="Weight" isRequired>
              <FormikField name="weight" type="number" append="-" />
            </FormStack>

            {/* Size */}
            <FormStack columns={columns} label="Size" isRequired>
              <FormikField name="size" type="number" append="-" />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={4}
            isDisabled={
              formik.isSubmitting ||
              !formik.dirty ||
              !formik.isValid ||
              isUploading
            }
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Product' : 'Update Product'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

ProductForm.defaultProps = {
  type: 'create',
}

export default ProductForm
