import React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'
import { Card } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'

import { Layout, ErrorBox } from 'src/core/components'
import AreaDetails from '../components/AreaDetails'
import AreaLocations from '../components/AreaLocations'

import useMounted from 'src/core/hooks/useMounted'
import usePermission from 'src/core/hooks/usePermission'

import { IArea } from '../area.type'

import { AreaService } from '../area.service'
import { route } from 'src/core/helpers/route.helper'

const areaService = new AreaService()

const Area: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const { id: areaId }: any = useParams()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [area, setArea] = React.useState<IArea>()

  React.useEffect(() => {
    const fetchArea = (): void => {
      areaService
        .fetchById(areaId)
        .then((area) => {
          isMounted.current && setArea(area)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_areas') && fetchArea()
  }, [isMounted, userCan, areaId])

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Area'
          : area
          ? area.name || 'N/A'
          : 'Area not found',
        backText: 'Areas',
        onBack: () => history.push(route('areas')),
      }}
      permission="view_areas"
    >
      {isLoading ? (
        <Flex justifyContent="center" py={5}>
          <Spinner color="primary.600" />
        </Flex>
      ) : (
        <>
          {area ? (
            <Card className="card-container">
              <Card className="card-sidebar">
                <AreaDetails area={area} />
              </Card>

              <Card className="card-main fixed">
                <AreaLocations area={area} />
              </Card>
            </Card>
          ) : (
            <ErrorBox
              title="Area not found!"
              summary="Check the URL and try again."
              my={50}
            />
          )}
        </>
      )}
    </Layout>
  )
}

export default Area
