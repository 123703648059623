export const beautifyProduct = (product): any => {
  const sellers: any[] = product?.sellers ? Object.values(product.sellers) : []

  const isAnySellerActive = sellers.some((seller) => {
    return seller.status === 'active'
  })

  const mftrActive = product.manufacturer.status === 'active'
  const brandActive = product.brand.status === 'active'
  const productActive = product.status === 'active'

  return {
    ...product,
    sellers,
    seller_status: isAnySellerActive ? 'active' : 'inactive',
    compound_status:
      mftrActive && brandActive && productActive && isAnySellerActive
        ? 'active'
        : 'inactive',
  }
}
