import React, { useState, useContext } from 'react'

import { Switch, Badge, Flex, Spinner, Avatar } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { ImageBox } from 'src/core/components'
import { Thead, Tr, Th, Td } from 'src/core/components/Table'

import usePermission from 'src/core/hooks/usePermission'
import useToast from 'src/core/hooks/useToast'
import { ProductsPageContext } from '../../pages/Products.page'

import { ProductService } from '../../product.service'
import { ISellerProduct } from '../../product.type'
import { IStoreState } from 'src/bootstrap/store/types'

import { asset } from 'src/core/helpers/file.helper'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { route } from 'src/core/helpers/route.helper'

const productService = new ProductService()

export const SellerProductsThead: React.FC<any> = ({ getThProps }) => {
  const { filters } = useContext(ProductsPageContext)

  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th textAlign="center">--</Th>

        {/* Name: sortable */}
        <Th {...getThProps('name')} isSticky>
          Name
        </Th>

        <Th title="Manufacturer, Brand, Product, Seller Product">
          Status (MBPS)
        </Th>
        <Th textAlign="center">Case</Th>
        <Th textAlign="center">MPU</Th>
        <Th>Price (NGN)</Th>

        {filters.show_details && (
          <>
            <Th colSpan={3} textAlign="center">
              Product
            </Th>
            <Th colSpan={2} textAlign="center">
              Manufacturer
            </Th>
            <Th colSpan={2} textAlign="center">
              Brand
            </Th>
          </>
        )}

        <Th></Th>
      </Tr>

      {filters.show_details && (
        <Tr>
          <Th></Th>
          <Th></Th>
          <Th isSticky></Th>
          <Th></Th>
          <Th></Th>
          <Th></Th>
          <Th></Th>

          {/* Seller */}
          <Th textAlign="center">MPU</Th>
          <Th>Price</Th>
          <Th>Status</Th>

          {/* Manufacturer */}
          <Th>Name</Th>
          <Th>Status</Th>

          {/* Brand */}
          <Th>Name</Th>
          <Th>Status</Th>

          <Th></Th>
        </Tr>
      )}
    </Thead>
  )
}

export const SellerProductsTr: React.FC<any> = ({ product, index }) => {
  const { addToast } = useToast()
  const { userCan } = usePermission()

  const user = useSelector((state: IStoreState) => state.user)

  const sellerProduct = React.useMemo(() => {
    const currentSeller = product.sellers.find((product) => {
      return product.seller_id === user.seller_id
    })

    return currentSeller || null
  }, [product.sellers, user.seller_id])

  const { products, setProducts, filters } = useContext(ProductsPageContext)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const updateStatus = (): void => {
    setIsUpdating(true)

    let type = 'update'

    const payload: any = {
      mpu: sellerProduct?.mpu || product.mpu,
      price: sellerProduct?.price || product.price,
      quantity: sellerProduct?.quantity || 500,
      seller_id: user.seller_id,
      product_id: product._id,
      meta: sellerProduct?.meta || JSON.stringify({}),
    }

    if (sellerProduct) {
      payload._id = sellerProduct._id
      payload.status =
        sellerProduct?.status === 'active' ? 'inactive' : 'active'
    } else {
      type = 'create'
      payload.status = 'active'
    }

    productService
      .updateSellerProduct(type, payload)
      .then(() => {
        const updatedProducts = products.map((item) => {
          let sellers = item?.sellers ? Object.values(item.sellers) : []

          if (item._id === product._id) {
            if (type === 'create') {
              sellers.push(payload)
            } else {
              sellers = sellers.map((sellerProduct: ISellerProduct) => {
                if (sellerProduct.seller_id === payload.seller_id) {
                  return { ...sellerProduct, ...payload }
                }
                return sellerProduct
              })
            }
          }

          return { ...item, sellers }
        })

        setProducts(updatedProducts)
        addToast('Product status updated', { appearance: 'success' })
      })
      .catch(({ message }) => {
        addToast(message, { appearance: 'error' })
      })
      .finally(() => {
        setIsUpdating(false)
      })
  }

  return (
    <Tr key={product._id}>
      <Td>{index + 1 + (filters.page - 1) * filters.per_page}</Td>
      <Td>
        <Flex justifyContent="center">
          <ImageBox
            src={product.upload ? asset(product.upload.path) : ''}
            borderWidth="1px"
          />
        </Flex>
      </Td>
      <Td isSticky>
        <Link
          to={route('product_update', {
            id: product._id,
          })}
          title={product.name}
        >
          {product.name}
        </Link>
      </Td>

      <Td>
        <Flex justifyContent="center">
          <Avatar
            name="M"
            size="2xs"
            bg={
              product.manufacturer.status === 'active'
                ? 'success.600'
                : 'critical.600'
            }
          />

          <Avatar
            name="B"
            size="2xs"
            ml={2}
            bg={
              product.brand.status === 'active' ? 'success.600' : 'critical.600'
            }
          />

          <Avatar
            name="P"
            size="2xs"
            ml={2}
            bg={product.status === 'active' ? 'success.600' : 'critical.600'}
          />

          <Avatar
            name="S"
            size="2xs"
            ml={2}
            bg={
              sellerProduct?.status === 'active'
                ? 'success.600'
                : 'critical.600'
            }
          />
        </Flex>
      </Td>
      <Td textAlign="center">{product.case_count}</Td>
      <Td textAlign="center">{sellerProduct?.mpu || '-'}</Td>
      <Td fontWeight="medium">
        {formatCurrency(sellerProduct?.price || '-') || '-'}
      </Td>

      {filters.show_details && (
        <>
          {/* Suplias */}
          <Td textAlign="center">{product.mpu}</Td>
          <Td>{formatCurrency(product.price)}</Td>
          <Td>
            <Badge
              colorScheme={product.status === 'active' ? 'success' : 'critical'}
            >
              {product.status}
            </Badge>
          </Td>

          {/* Manufacturer */}
          <Td>
            <Link
              to={route('manufacturer_update', {
                id: product.manufacturer._id,
              })}
            >
              {product.manufacturer.name}
            </Link>
          </Td>
          <Td>
            <Badge
              colorScheme={
                product.manufacturer.status === 'active'
                  ? 'success'
                  : 'critical'
              }
            >
              {product.manufacturer.status}
            </Badge>
          </Td>

          {/* Brand */}
          <Td>
            <Link
              to={route('brand_update', {
                id: product.brand._id,
              })}
            >
              {product.brand.name}
            </Link>
          </Td>

          <Td>
            <Badge
              colorScheme={
                product.brand.status === 'active' ? 'success' : 'critical'
              }
            >
              {product.brand.status}
            </Badge>
          </Td>
        </>
      )}

      <Td>
        {/* Quick product status update */}
        <Flex justifyContent="center">
          {userCan('update_product') && (
            <>
              {isUpdating ? (
                <Spinner size="sm" color="primary.600" />
              ) : (
                <Switch
                  id={`status-switch-${index}`}
                  size="sm"
                  width="auto"
                  isChecked={sellerProduct?.status === 'active'}
                  onChange={updateStatus}
                  css={{ input: { position: 'static !important' } } as any}
                />
              )}
            </>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}
