import React from 'react'

import { Spinner, Flex, SimpleGrid, Box } from '@chakra-ui/react'
import { Card } from 'react-bootstrap'
import useMounted from 'src/core/hooks/useMounted'

import { SearchInput, ErrorBox } from 'src/core/components'
import AreaLocationToggle from './AreaLocationToggle'

import { IArea } from '../area.type'
import { ILocation, LocationService } from 'src/applets/location'
import { AreaService } from '../area.service'
import { sortByKey } from 'src/core/helpers/filters.helper'
import { basicSearch } from 'src/core/helpers/search.helper'

const areaService = new AreaService()
const locationService = new LocationService()

interface IProps {
  area: IArea
}

const AreaLocations: React.FC<IProps> = ({ area }) => {
  const isMounted = useMounted()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [locations, setLocations] = React.useState<ILocation[]>()

  const [searchTerm, setSearchTerm] = React.useState<string>('')

  React.useEffect(() => {
    const fetchLocations = locationService.fetch()
    const fetchAreaLocations = areaService.fetchLocations(area._id)

    Promise.all([fetchLocations, fetchAreaLocations])
      .then(([locations, areaLocations]) => {
        /**
         * Store area locations as object with "location_id"
         * as key. We'll need this when looping through the
         * locations array to show <AreaLocationToggle />
         */
        const areaLocationsObj = {}
        if (areaLocations) {
          areaLocations.forEach((areaLocation) => {
            areaLocationsObj[areaLocation.location_id] = areaLocation
          })
        }

        locations = locations.map((location) => {
          return {
            ...location,
            area_location: areaLocationsObj?.[location._id],
            area_location_status:
              areaLocationsObj?.[location._id]?.status === 'active' ? 1 : 0,
          }
        })

        locations = sortByKey(locations, 'area_location_status', 'desc')

        isMounted.current && setLocations(locations)
      })
      .catch(() => {
        isMounted.current && setIsLoading(false)
      })
      .finally(() => {
        isMounted.current && setIsLoading(false)
      })
  }, [isMounted, area])

  const filteredLocations = React.useMemo(() => {
    let filtered = locations || []

    if (filtered.length) {
      if (searchTerm) {
        filtered = filtered.filter((manufacturer) => {
          return basicSearch(manufacturer.name, searchTerm)
        })
      }
    }

    return filtered
  }, [locations, searchTerm])

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <SearchInput
            placeholder="Search locations..."
            value={searchTerm}
            onChange={(searchTerm) => setSearchTerm(searchTerm)}
            isDisabled={isLoading || !locations?.length}
          />
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && (
          <Flex justifyContent="center" my={50}>
            <Spinner color="primary.600" />
          </Flex>
        )}

        {!isLoading && !filteredLocations?.length ? (
          <ErrorBox summary="Location not found" my={50} />
        ) : null}

        {!isLoading && filteredLocations?.length ? (
          <SimpleGrid columns={[1, 2, 3]} spacing="10px">
            {filteredLocations.map((location) => (
              <Box key={location._id}>
                <AreaLocationToggle
                  area={area}
                  location={location}
                  areaLocation={location.area_location}
                />
              </Box>
            ))}
          </SimpleGrid>
        ) : null}
      </Card.Body>
    </Card>
  )
}

export default AreaLocations
