import React from 'react'

import { Box } from '@chakra-ui/layout'

import { Header, HeaderProps } from './Header'
import { ErrorBox } from '../ErrorBox'
import usePermission from 'src/core/hooks/usePermission'
import { useTheme } from '@chakra-ui/system'
import { BoxProps } from '@chakra-ui/react'

export interface LayoutProps {
  /* __deprecated__ (Use headerProps) */
  header?: HeaderProps
  headerProps?: HeaderProps
  bodyProps?: BoxProps
  permission?: string
  /* __deprecated__ (Use bodyProps to set paddding) */
  hasGutter?: boolean
}

/**
 * Default page layout.
 */
export const Layout: React.FC<LayoutProps> = (props) => {
  const { sizes } = useTheme()
  const { userCan } = usePermission()

  const { permission, hasGutter, children, bodyProps } = props
  const headerProps = props?.headerProps || props?.header

  return (
    <Box overflowX="auto">
      {!permission || (permission && userCan(permission)) ? (
        <Box minW="900px">
          <Header {...headerProps} />
          <Box
            width="100%"
            position="relative"
            padding={hasGutter ? sizes.gutter.md : '0'}
            height={`calc(100vh - ${sizes.layout.headerHeight})`}
            overflowY="auto"
            {...bodyProps}
          >
            {children}
          </Box>
        </Box>
      ) : (
        <ErrorBox type="authorization" my={150} reference={permission} />
      )}
    </Box>
  )
}

Layout.defaultProps = {
  hasGutter: true,
}

export default Layout
