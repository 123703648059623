import React from 'react'
import useFetch from 'src/core/hooks/useFetch'
import {
  usePurchaseOrderItems,
  IUsePurchaseOrderItems,
} from './usePurchaseOrderItems'
import {
  purchaseOrderService,
  PurchaseOrderServiceProps,
} from '../purchaseOrder.service'
import {
  IPurchaseOrder,
  IPurchaseOrderConfig,
  IPurchaseOrderStock,
  // IPurchaseOrderStockDict,
  IPurchaseOrderStockRecord,
  PurchaseOrderTag,
} from '../purchaseOrder.type'
import { useToast } from 'src/core/hooks'
import { useHistory } from 'react-router'
import { route } from 'src/core/helpers'
import { getPurchaseOrderConfig } from '../purchaseOrder.helper'

export interface IUsePurchaseOrder extends IUsePurchaseOrderItems {
  config?: IPurchaseOrderConfig
  purchaseOrder?: IPurchaseOrder
  setPurchaseOrder?: (purchaseOrder: IPurchaseOrder) => void
  isLoading?: boolean
  resetMode?: boolean
  isExecuting?: boolean
  availableStock?: IPurchaseOrderStock
  setAvailableStock?: (arg: any) => void
  setResetMode?: (arg: boolean) => void
  setExecuting?: (arg: boolean) => void
  executePurchaseOrder?: (
    commit: boolean,
    errorOnEmpty: boolean,
    redirectOnSave?: boolean
  ) => void
  outOfStock: IPurchaseOrderStockRecord
}

export const usePurchaseOrder = (
  purchaseOrderId: string,
  isRef = false
): IUsePurchaseOrder => {
  const history = useHistory()
  const { toast } = useToast()
  const [purchaseOrder, isLoading, , setPurchaseOrder] = useFetch<
    IPurchaseOrder,
    PurchaseOrderServiceProps
  >(
    purchaseOrderService,
    !isRef ? 'fetchById' : 'fetchByRef',
    [purchaseOrderId],
    !!purchaseOrderId
  )

  const config = getPurchaseOrderConfig(purchaseOrder)

  const purchaseOrderItemsHook = usePurchaseOrderItems(purchaseOrder)

  const [isExecuting, setExecuting] = React.useState<boolean>(false)
  const [outOfStockDict, setOutOfStock] =
    React.useState<IPurchaseOrderStockRecord>(null)

  const executePurchaseOrder = (
    commit: boolean,
    errorOnEmpty: boolean,
    redirectOnSave = false
  ): void => {
    setExecuting(true)
    setOutOfStock(null)
    purchaseOrderService
      .execute({ _id: purchaseOrderId, commit, error_on_empty: errorOnEmpty })
      .then((data: any) => {
        if (data?.error?.length) {
          // setOutOfStock(data.out_of_stock)
          setExecuting(false)
          toast({
            description: 'Transfer failed.',
            status: 'error',
          })
        } else {
          if (commit) {
            setPurchaseOrder({
              ...purchaseOrder,
              tag: PurchaseOrderTag.Receipt,
            })
          }

          toast({
            description: 'Transfer successful.',
            status: 'success',
          })
          if (redirectOnSave) {
            history.push(
              route('warehouse_products', {
                id: purchaseOrder?.destination_warehouse?._id,
              })
            )
          } else {
            setExecuting(false)
            setPurchaseOrder(null)
            purchaseOrderItemsHook.setPurchaseOrderItems(null)
            history.push(route('inventory_manager', config))
          }
        }
      })
      .catch((error) => {
        setExecuting(false)
        toast({ description: error.message, status: 'error' })
      })
  }

  return {
    ...purchaseOrderItemsHook,

    config,

    purchaseOrder,
    setPurchaseOrder: (updatedPurchaseOrder) => {
      setPurchaseOrder({ ...purchaseOrder, ...updatedPurchaseOrder })
    },

    isLoading,

    isExecuting,
    setExecuting,
    executePurchaseOrder,

    outOfStock: outOfStockDict,
  }
}
