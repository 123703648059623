import React from 'react'

import { useTheme } from '@chakra-ui/react'
import { debounce } from 'lodash'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

import { basicSearch } from 'src/core/helpers'

export interface AutocompleteProps {
  name?: string
  size?: 'sm' | 'md'
  value: any
  options?: any[]
  isInvalid?: boolean
  placeholder?: string
  className?: string
  onBlur?: () => void
  onChange?: (arg: any) => void
  isMulti?: boolean
  isActive?: boolean
  isFocused?: boolean
  isDisabled?: boolean
  isAsync?: boolean
  menuPlacement?: 'top' | 'bottom' | 'auto'
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  size,
  isAsync,
  isInvalid,
  ...props
}) => {
  const { colors } = useTheme()

  const customStyles = {
    container: (styles: any) => ({
      ...styles,
      width: '100%',
    }),
    control: (styles: any) => ({
      ...styles,
      background: props.isDisabled ? colors.gray['50'] : '#fff',
      border: `1px solid ${
        isInvalid ? colors.critical['600'] : colors.gray['200']
      }`,
      borderWidth: isInvalid ? '2px !important' : '1px !important',
      borderRadius: size === 'sm' ? '6px' : '6px',
      paddingTop: size === 'sm' ? '0' : '2px',
      paddingLeft: size === 'sm' ? '3px' : '8px',
      transition: 'all 0.3s',
      minHeight: size === 'sm' ? '28px' : '42px',
      minWidth: '180px',
      ':hover': {
        ...styles[':hover'],
        border: `1px solid ${colors.gray['400']}`,
        cursor: 'default',
      },
      ':focus': {
        ...styles[':focus'],
        border: `1px solid ${colors.primary['600']}`,
        borderWidth: '1px',
      },
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      svg: {
        ...styles['svg'],
        color: colors.gray['400'],
        width: '18px',
        height: '18px',
        position: 'relative',
        right: '1px',
      },
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
    input: (styles: any) => ({
      ...styles,
      color: colors.black,
    }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: colors.gray['600'],
    }),
    menu: (styles: any) => ({
      ...styles,
      border: `1px solid ${colors.gray['200']}`,
      borderRadius: size === 'sm' ? '6px' : '6px',
      boxShadow: 'none',
      padding: '8px',
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: colors.gray['50'],
      margin: '0 2px 0',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      padding: '3px 3px 2px',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? colors.gray['100']
        : isFocused
        ? colors.gray['50']
        : null,
      borderRadius: '6px',
      marginBottom: '4px',
      color: isDisabled ? '#ccc' : colors.black,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled &&
          (isSelected ? colors.primary['600'] : colors.gray['50']),
      },
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: colors.gray['600'],
      /** Adjust vertical position */
      top: '48%',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: colors.black,
      /** Adjust vertical position */
      top: '48%',
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      flexWrap: 'nowrap',
      padding: '1px 8px',
    }),
  }

  const styleProps = {
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: colors.primary['600'],
      },
    }),
    styles: customStyles,
  }

  const { options, ...asyncProps } = props

  return isAsync ? (
    <AsyncSelect
      {...asyncProps}
      {...styleProps}
      loadOptions={debounce((query, callback) => {
        if (query?.trim?.()?.length > 2) {
          const results = options.filter((option) => {
            return basicSearch(option.label, query)
          })

          callback(results)
        }
      }, 250)}
    />
  ) : (
    <Select {...props} {...styleProps} />
  )
}

export default Autocomplete
