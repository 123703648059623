import React from 'react'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Tbody } from 'src/core/components/Table'
import { paginate } from 'src/core/helpers/filters.helper'
import { useSort } from 'src/core/hooks'

import { SalesOfficerThead, SalesOfficerTr } from './SalesOfficersTableParts'
import { ISalesOfficer, ISalesOfficerFilters } from '../salesOfficer.type'

interface IProps {
  salesOfficers: ISalesOfficer[]
  filters: ISalesOfficerFilters
  setPage: (page: number) => void
}

const SalesOfficersTable: React.FC<IProps> = ({
  salesOfficers,
  filters,
  setPage,
}) => {
  const { getThProps, sorted: sortedSalesOfficers } = useSort(salesOfficers, {
    accessor: 'wallet_balance',
    dir: 'asc',
  })

  const paginatedSalesOfficers = React.useMemo(() => {
    const { data } = sortedSalesOfficers
    return data ? paginate(data, filters.page) : []
  }, [filters.page, sortedSalesOfficers])

  return (
    <>
      {!paginatedSalesOfficers.length ? (
        <ErrorBox summary="No sales officers found" my={50} />
      ) : null}

      {paginatedSalesOfficers.length ? (
        <Table isFit>
          <SalesOfficerThead filters={filters} getThProps={getThProps} />
          <Tbody>
            {paginatedSalesOfficers.map((salesOfficer, index) => (
              <SalesOfficerTr
                key={salesOfficer?._id}
                index={index}
                salesOfficer={salesOfficer}
                filters={filters}
              />
            ))}
          </Tbody>
        </Table>
      ) : null}

      <Pagination
        my={5}
        itemsPerPage={10}
        totalItems={salesOfficers.length}
        currentPage={filters.page}
        onPageChange={setPage}
      />
    </>
  )
}

export default SalesOfficersTable
