import Distributor from './pages/Distributor.page'
import Distributors from './pages/Distributors.page'
import DistributorEditor from './pages/DistributorEditor.page'
import DistributorProducts from './pages/DistributorProducts.page'

import { IRoute } from 'src/bootstrap/routes'

export const distributorRoutes: IRoute[] = [
  {
    id: 'distributors',
    path: '/distributors',
    component: Distributors,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'distributor_create',
    path: '/distributors/create',
    component: DistributorEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'distributor_update',
    path: '/distributors/:id/update',
    component: DistributorEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'distributor_products',
    path: '/distributors/:id/products',
    component: DistributorProducts,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'distributor',
    path: '/distributors/:id/:tab',
    component: Distributor,
    requiresAuth: true,
    exact: true,
  },
]

export default distributorRoutes
