import { ComponentClass, FC } from 'react'

import Error404 from 'src/core/pages/404.page'

import { agentRoutes } from 'src/applets/agent'
import { areaRoutes } from 'src/applets/area'
import { brandRoutes } from 'src/applets/brand'
import { buyerRoutes } from 'src/applets/buyer'
import { categoryRoutes } from 'src/applets/category'
import { cohortRoutes } from 'src/applets/cohort'
import { configRoutes } from 'src/applets/config'
import { dashboardRoutes } from 'src/applets/dashboard'
import { distributorRoutes } from 'src/applets/distributor'
import { distributorOfficerRoutes } from 'src/applets/distributorOfficer'
import { locationRoutes } from 'src/applets/location'
import { managerRoutes } from 'src/applets/manager'
import { manufacturerRoutes } from 'src/applets/manufacturer'
import { notificationRoutes } from 'src/applets/notification'
import { orderRoutes } from 'src/applets/order'
import { productRoutes } from 'src/applets/product'
import { purchaseOrderRoutes } from 'src/applets/purchaseOrder'
import { reportRoutes } from 'src/applets/report'
import { salesOfficerRoutes } from 'src/applets/salesOfficer'
import { sellerRoutes } from 'src/applets/seller'
import { storeTypeRoutes } from 'src/applets/storeType'
import { userRoutes } from 'src/applets/user'
import { warehouseRoutes } from 'src/applets/warehouse'

export interface IRoute {
  id: string
  path: string
  component: ComponentClass<any, any> | FC<any>
  requiresAuth?: boolean
  exact?: boolean
}

let validatedRoutes: IRoute[] = []

const registerRouteGroup = (routeGroup: IRoute[]): void => {
  if (routeGroup?.length) {
    validatedRoutes = [...validatedRoutes, ...routeGroup]
  }
}

registerRouteGroup(agentRoutes)
registerRouteGroup(areaRoutes)
registerRouteGroup(brandRoutes)
registerRouteGroup(buyerRoutes)
registerRouteGroup(categoryRoutes)
registerRouteGroup(cohortRoutes)
registerRouteGroup(configRoutes)
registerRouteGroup(dashboardRoutes)
registerRouteGroup(distributorRoutes)
registerRouteGroup(distributorOfficerRoutes)
registerRouteGroup(locationRoutes)
registerRouteGroup(managerRoutes)
registerRouteGroup(manufacturerRoutes)
registerRouteGroup(notificationRoutes)
registerRouteGroup(orderRoutes)
registerRouteGroup(productRoutes)
registerRouteGroup(purchaseOrderRoutes)
registerRouteGroup(reportRoutes)
registerRouteGroup(salesOfficerRoutes)
registerRouteGroup(sellerRoutes)
registerRouteGroup(storeTypeRoutes)
registerRouteGroup(userRoutes)
registerRouteGroup(warehouseRoutes)

export const routes: IRoute[] = [
  ...validatedRoutes,
  {
    id: 'error_404',
    path: '*',
    component: Error404,
  },
]
