import React from 'react'

import {
  Flex,
  Box,
  Text,
  Spinner,
  Select,
  Checkbox,
  Stack,
  Grid,
} from '@chakra-ui/react'

import {
  Layout,
  Pagination,
  SearchInput,
  ErrorBox,
  ExportButton,
} from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'
import PerPageSelect from 'src/core/components/Form/PerPageSelect'
import SalesHistoryTable from '../components/SalesHistoryTable'

import useMounted from 'src/core/hooks/useMounted'
import usePermission from 'src/core/hooks/usePermission'
import { useSort } from 'src/core/hooks'

import { ProductService } from '../product.service'

import { paginate } from 'src/core/helpers/filters.helper'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { basicSearch } from 'src/core/helpers/search.helper'

const productService = new ProductService()

const ProductSalesHistory: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const tableRef = React.useRef<any>()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [products, setProducts] = React.useState<any[]>([])

  const [searchTerm, setSearchTerm] = React.useState<string>()
  const [page, setPage] = React.useState<number>(1)
  const [perPage, setPerPage] = React.useState<number>(10)

  const [worthType, setWorthType] = React.useState<string>('unit_count')

  const year = new Date().getFullYear()

  const [monthOptions, setMonthOptions] = React.useState<any[]>([
    { value: 1, label: 'January', code: 'JAN', checked: true },
    { value: 2, label: 'February', code: 'FEB', checked: true },
    { value: 3, label: 'March', code: 'MAR', checked: true },
    { value: 4, label: 'April', code: 'APR', checked: true },
    { value: 5, label: 'May', code: 'MAY', checked: true },
    { value: 6, label: 'June', code: 'JUN', checked: true },
    { value: 7, label: 'July', code: 'JUL', checked: true },
    { value: 8, label: 'August', code: 'AUG', checked: true },
    { value: 9, label: 'September', code: 'SEP', checked: true },
    { value: 10, label: 'October', code: 'OCT', checked: true },
    { value: 11, label: 'November', code: 'NOV', checked: true },
    { value: 12, label: 'December', code: 'DEC', checked: true },
  ])

  React.useEffect(() => {
    const fetchProductHistory = (): void => {
      productService
        .fetchHistory()
        .then((products) => {
          isMounted.current && setProducts(Object.values(products))
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_products') && fetchProductHistory()
  }, [isMounted, userCan])

  const filteredProducts = React.useMemo(() => {
    setPage(1)

    let filteredProducts = products || []

    if (filteredProducts.length) {
      if (searchTerm) {
        filteredProducts = filteredProducts.filter((product) => {
          return (
            basicSearch(product.name, searchTerm) ||
            basicSearch(product.brand.name, searchTerm) ||
            basicSearch(product.manufacturer.name, searchTerm)
          )
        })
      }
    }

    filteredProducts = filteredProducts.map((product) => {
      const newProduct = {
        _id: product._id,
        name: product.name,
      }

      monthOptions.forEach((month) => {
        if (month.checked) {
          const yearPart = product?.years?.[year]
          const monthPart = yearPart?.months?.[month.value]
          newProduct[month.code] = monthPart?.count || 0

          if (worthType === 'case_count') {
            newProduct[month.code] = (
              newProduct[month.code] / product.case_count
            ).toFixed(1)
          } else if (worthType === 'value') {
            newProduct[month.code] = monthPart?.value || 0
            newProduct[month.code] = formatCurrency(newProduct[month.code])
          }
        }
      })

      return newProduct
    })

    return filteredProducts
  }, [products, searchTerm, monthOptions, year, worthType])

  const { getThProps, sorted: sortedProducts } = useSort(filteredProducts, {
    accessor: 'name',
    dir: 'desc',
  })

  const paginatedProducts = React.useMemo(() => {
    return sortedProducts.data
      ? paginate(sortedProducts.data, page, perPage)
      : []
  }, [page, perPage, sortedProducts])

  const toggleMonthOptions = (value, checked): void => {
    const tempMonthOptions = monthOptions.map((month) => {
      if (value === '*' || value === month.value) {
        month.checked = checked
      }
      return month
    })
    setMonthOptions(tempMonthOptions)
  }

  const selectedMonths = React.useMemo(() => {
    return monthOptions ? monthOptions.filter((month) => month.checked) : []
  }, [monthOptions])

  const monthsAllSelected = React.useMemo(() => {
    return !monthOptions.some((month) => !month.checked)
  }, [monthOptions])

  return (
    <Layout
      headerProps={{ title: 'Products - Sales History' }}
      permission="view_products"
    >
      <Card>
        <CardHeader as={Flex} justifyContent="space-between">
          <Flex alignItems="center">
            <SearchInput
              placeholder="Search products"
              value={searchTerm}
              onChange={setSearchTerm}
              isDisabled={isLoading || !filteredProducts}
            />

            <Box ml={2}>
              <PerPageSelect value={perPage} onChange={setPerPage} />
            </Box>

            <Box ml={2}>
              <Select
                size="sm"
                value={worthType}
                onChange={({ target }) => setWorthType(target.value)}
              >
                <option value="unit_count">Unit Count</option>
                <option value="case_count">Case Count</option>
                <option value="value">Value (NGN)</option>
              </Select>
            </Box>
          </Flex>

          <ExportButton
            filename="suplias-products-history"
            exportData={filteredProducts}
            isDisabled={isLoading || !filteredProducts.length}
          />
        </CardHeader>

        <Grid templateColumns="2fr 1fr">
          <CardBody padding={0} overflow="scroll">
            {isLoading && (
              <Flex justifyContent="center" py={50}>
                <Spinner color="primary.600" />
              </Flex>
            )}

            {!isLoading && !paginatedProducts.length ? (
              <ErrorBox summary="No products history found" my={50} />
            ) : null}

            {!isLoading && paginatedProducts.length ? (
              <>
                <SalesHistoryTable
                  tableRef={tableRef}
                  products={paginatedProducts}
                  months={monthOptions}
                  columnActions={{ getThProps }}
                />

                <Pagination
                  my={5}
                  itemsPerPage={perPage}
                  totalItems={filteredProducts.length}
                  currentPage={page}
                  onPageChange={(page) => setPage(page)}
                />
              </>
            ) : null}
          </CardBody>

          <CardBody borderLeft="1px solid" borderColor="gray.100">
            <Text variant="tinyTitle" textTransform="uppercase">
              Months ({selectedMonths.length} / {monthOptions.length})
            </Text>

            <Stack direction="column" mt={3} spacing={1}>
              <Checkbox
                id="all-months"
                name="all-months"
                onChange={() => toggleMonthOptions('*', !monthsAllSelected)}
                isChecked={monthsAllSelected}
                isIndeterminate={
                  !!(!monthsAllSelected && selectedMonths.length)
                }
              >
                Select all
              </Checkbox>

              {monthOptions.map((month, index) => (
                <Checkbox
                  key={index}
                  id={month.value}
                  name={month.value}
                  className="custom-control-sm mb-1"
                  onChange={() =>
                    toggleMonthOptions(month.value, !month.checked)
                  }
                  isChecked={month.checked}
                >{`${month.label} ${year}`}</Checkbox>
              ))}
            </Stack>
          </CardBody>
        </Grid>
      </Card>
    </Layout>
  )
}

export default ProductSalesHistory
