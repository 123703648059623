import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import { ISeller } from '../seller.type'

import { FormikField, FormikRadioGroup, FormStack } from 'src/core/components'
import useFetch from 'src/core/hooks/useFetch'
import useToast from 'src/core/hooks/useToast'

import {
  IManager,
  ManagerService,
  ManagerServiceProps,
} from 'src/applets/manager'
import { SellerService } from '../seller.service'
import { IStoreState } from 'src/bootstrap/store/types'

import { route } from 'src/core/helpers/route.helper'
import { refactorObjects } from 'src/core/helpers/filters.helper'

const sellerService = new SellerService()
const managerService = new ManagerService()

interface IProps {
  type?: 'create' | 'update'
  seller?: ISeller
  onUpdate?: (updatedValues: ISeller) => void
}

const SellerForm: React.FC<IProps> = ({ type, seller, onUpdate }) => {
  const history = useHistory()
  const { addToast } = useToast()
  const user = useSelector((state: IStoreState) => state.user)

  const [managers] = useFetch<IManager[], ManagerServiceProps>(
    managerService,
    'fetch'
  )
  const managerOptions = refactorObjects(managers)

  const [sellerManager] = useFetch<any>(
    sellerService,
    'fetchManager',
    [seller?._id],
    !!seller
  )

  const formConfig = {
    initialValues: {
      name: seller?.name || '',
      status: seller?.status || 'active',
      address: seller?.address || '',
      manager:
        seller && sellerManager
          ? {
              value: sellerManager.manager_id,
              label: sellerManager.manager.name,
            }
          : null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
      address: Yup.string().required('Address field is required.'),
      manager: Yup.mixed().required('Manager field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const sellerValues: any = {
        name: values.name,
        address: values.address,
        status: values.status,
      }

      if (type === 'update') {
        sellerValues._id = seller._id
        sellerValues.user_id = seller.user_id
      } else {
        sellerValues.user_id = user.user_id
      }

      let newSellerId

      sellerService[type](sellerValues)
        .then((sellerId) => {
          newSellerId = sellerId

          let managerUpdateType = 'create'

          const managerValues: any = {
            seller_id: seller?._id || newSellerId,
            manager_id: values.manager.value,
            status: 'active',
          }

          if (sellerManager?._id) {
            managerUpdateType = 'update'
            managerValues._id = sellerManager._id
          }

          return sellerService.updateManager(managerUpdateType, managerValues)
        })
        .then(() => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Seller successfully updated.', {
              appearance: 'success',
            })
            onUpdate(sellerValues)
          } else {
            addToast('Seller successfully created.', {
              appearance: 'success',
            })
            history.push(route('seller_update', { id: newSellerId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Name */}
            <FormStack label="Name" helperText="Seller name" isRequired>
              <FormikField
                name="name"
                type="text"
                placeholder="ex. Suplias Mushin Hub"
              />
            </FormStack>

            {/* Address */}
            <FormStack
              label="Address"
              helperText="Seller office address"
              isRequired
            >
              <FormikField as="textarea" name="address" type="text" rows={3} />
            </FormStack>

            {/* Manager */}
            <FormStack
              label="Manager"
              helperText="Seller account manager"
              isRequired
            >
              <FormikField
                as="autocomplete"
                name="manager"
                options={managerOptions}
                // value={values.manager}
                placeholder="Select manager"
                isDisabled={!managerOptions.length}
              />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create' : 'Update'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

SellerForm.defaultProps = {
  type: 'create',
}

export default SellerForm
