import React from 'react'
import {
  Th as ChakraTh,
  TableColumnHeaderProps as ChakraThProps,
} from '@chakra-ui/table'
import { Flex, Text, VStack } from '@chakra-ui/layout'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useTheme } from '@chakra-ui/system'
import { TableContext } from './Table'

type CustomChakraThProps = Omit<ChakraThProps, 'sortDir'>

interface ThProps extends CustomChakraThProps {
  isSortable?: boolean
  sortDir?: 'asc' | 'desc'
  isSticky?: boolean
}
export const Th: React.FC<ThProps> = ({
  sortDir,
  isSortable,
  children,
  isSticky,
  ...props
}) => {
  const { colors } = useTheme()
  const tableProps = React.useContext(TableContext)

  return (
    <ChakraTh
      px="20px"
      bg="gray.50"
      borderStyle="solid"
      borderColor="gray.100"
      borderWidth={tableProps.hasBorders ? '0 1px 1px 1px' : '0 0 1px 0'}
      position={isSticky ? 'sticky' : 'static'}
      left={0}
      zIndex={isSticky ? 1 : 0}
      cursor="default"
      css={{
        width: tableProps.isFit ? '1%' : 'inherit',
        whiteSpace: tableProps.isFit ? 'nowrap' : 'normal',
        '&:first-of-type': { borderLeft: 0 },
        '&:last-of-type': { borderRight: 0 },
      }}
      {...props}
    >
      <Flex alignItems="center" py={!isSortable ? '10px' : '0'}>
        <Text
          color={sortDir ? 'gray.800' : 'gray.600'}
          fontWeight="medium"
          fontSize="13px"
          textTransform="capitalize"
          letterSpacing={0}
          lineHeight={0}
          width="100%"
          textAlign={
            props?.textAlign
              ? props?.textAlign
              : props.isNumeric
              ? 'right'
              : 'start'
          }
        >
          {children}
        </Text>
        {isSortable && (
          <VStack ml={2} spacing="-6px">
            <FiChevronUp
              size={14}
              color={sortDir === 'desc' ? colors.gray[800] : colors.gray[400]}
            />
            <FiChevronDown
              size={14}
              color={sortDir === 'asc' ? colors.gray[800] : colors.gray[400]}
            />
          </VStack>
        )}
      </Flex>
    </ChakraTh>
  )
}
