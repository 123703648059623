import Dashboard from './pages/Dashboard.page'

import { IRoute } from 'src/bootstrap/routes'

export const dashboardRoutes: IRoute[] = [
  {
    id: 'dashboard',
    path: '/',
    requiresAuth: true,
    component: Dashboard,
    exact: true,
  },
]

export default dashboardRoutes
