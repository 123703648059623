import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { ErrorBox, Pagination, TableCellMarkIcon } from 'src/core/components'

import { paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'

const FulfilmentTable: React.FC<any> = ({
  matrix,
  filters,
  setFilters,
  tableRef,
}) => {
  const paginated: any = React.useMemo(() => {
    return matrix.data
      ? paginate(matrix.data, filters.page, filters.per_page)
      : []
  }, [filters.page, filters.per_page, matrix])

  return (
    <>
      {!paginated?.length ? (
        <ErrorBox summary="No order fulfilment found" />
      ) : null}

      {paginated?.length ? (
        <div className="table-wrapper padded">
          <Table
            ref={tableRef}
            className={filters.manufacturer ? '' : 'table-fit'}
            bordered
            hover
            responsive
          >
            <thead>
              <tr>
                <th>#</th>
                {filters.type === 'buyers' ? (
                  <>
                    <th className="sticky">Buyer</th>
                    <th>Phone</th>
                    <th>Location</th>

                    {/* Show summary if manufactuer filter is not set */}
                    {!filters.manufacturer ? <th>Summary</th> : null}
                  </>
                ) : (
                  <th className="sticky">Location</th>
                )}

                {matrix.columns.map((manufacturer, index) => (
                  <th key={index} className="text-center">
                    {manufacturer.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginated.map((item, index) => (
                /**
                 * item can be a buyer or location
                 */
                <tr key={index}>
                  <td>{index + 1 + (filters.page - 1) * filters.per_page}</td>
                  {filters.type === 'buyers' ? (
                    <>
                      <td className="sticky font-weight-medium">
                        <Link
                          to={route('buyer_update', {
                            id: item._id,
                          })}
                        >
                          {item.name}
                        </Link>
                      </td>
                      <td>{item.phone}</td>
                      <td>{item?.location?.name || '-'}</td>

                      {!filters.manufacturer ? (
                        <td className="text-center">
                          {item.manufacturers.filter(
                            (manufacturer) => manufacturer.found
                          ).length ? (
                            <span className="text-primary">
                              <TableCellMarkIcon checked />
                            </span>
                          ) : (
                            <TableCellMarkIcon />
                          )}
                        </td>
                      ) : null}
                    </>
                  ) : (
                    /**
                     * Just show location name if we're not
                     * showing the buyers matrix
                     */
                    <td className="sticky font-weight-bold">{item.name}</td>
                  )}

                  {item.manufacturers.map((manufacturer, index) => (
                    <td key={index} className="text-center">
                      {manufacturer.found ? (
                        <TableCellMarkIcon checked />
                      ) : (
                        <TableCellMarkIcon />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}

      <Pagination
        my={5}
        itemsPerPage={filters.per_page}
        totalItems={matrix.data.length}
        currentPage={filters.page}
        onPageChange={(page) => setFilters({ ...filters, page })}
      />
    </>
  )
}

export default FulfilmentTable
