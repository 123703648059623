import React from 'react'

import { SellerService } from 'src/applets/seller'
import { IProduct } from '../product.type'
import { ProductService } from '../product.service'
import useMounted from 'src/core/hooks/useMounted'
import usePermission from 'src/core/hooks/usePermission'

const sellerService = new SellerService()
const productService = new ProductService()

export const useProducts = (
  entity: 'global' | 'seller' = 'global',
  entityId?: string
): [IProduct[], React.Dispatch<any>, boolean] => {
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [products, setProducts] = React.useState<IProduct[]>()

  React.useEffect(() => {
    const fetchProducts = (): void => {
      try {
        productService.fetch().then((products) => {
          if (entity === 'seller') {
            sellerService
              .fetchManufacturers(entityId)
              .then((sellerManufacturers) => {
                const mftrs = sellerManufacturers
                  .filter((mftr) => mftr.status === 'active')
                  .map((mftr) => mftr.manufacturer_id)

                products = products.filter((product) => {
                  return mftrs.includes(product.manufacturer._id)
                })

                isMounted.current && setProducts(products)
                isMounted.current && setIsLoading(false)
              })
          } else {
            isMounted.current && setProducts(products)
            isMounted.current && setIsLoading(false)
          }
        })
      } catch (error) {
        isMounted.current && setIsLoading(false)
      }
    }

    if (userCan('view_products')) {
      if (entity === 'seller') entityId && fetchProducts()
      else fetchProducts()
    }
  }, [userCan, isMounted, entity, entityId])

  return [products, setProducts, isLoading]
}

export default useProducts
