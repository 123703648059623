import React, { useState } from 'react'
import { Flex, Spinner, Text, Box } from '@chakra-ui/react'

import { Layout, Card, CardBody, CardHeader } from 'src/core/components'
import { ExportButton, useExportable } from 'src/core/components/Export'
import {
  formatCurrency,
  quickPluralize,
  sortByKey,
  trans,
} from 'src/core/helpers'
import { useMounted, usePermission } from 'src/core/hooks'

import { InventoryReportTable } from '../components/Inventory'
import { useFilterInventoryReport } from '../hooks/useFilterInventoryReport'
import { warehouseService } from '../warehouse.service'
import { InventoryReportFilter } from './InventoryReportFilter'
import { InventoryReportContext } from '../warehouse.type'
import { getExportableColumns } from '../warehouse.helper'
import { warehouseLang } from '..'

const ReportSummary: React.FC<{
  label: string
  value: number
  sku: number
  count: number
}> = ({ label, value, sku, count }) => {
  return (
    <>
      <Box>
        <Text as="span" fontWeight="medium">
          {label}
        </Text>
        {': '}
        <Text as="span">Value (NGN {formatCurrency(value || 0)}),</Text>{' '}
        <Text as="span">{quickPluralize(sku || 0, 'SKU')},</Text>{' '}
        <Text as="span">{quickPluralize(count || 0, 'case')}</Text>
      </Box>
    </>
  )
}

const InventoryReport: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [entries, setEntries] = useState<any[]>()
  const [currentTotal, setCurrentTotal] = useState<number>()
  const [currentSkus, setCurrentSkus] = useState<number>()

  const { filters, setFilters, activeFilters, filteredEntries } =
    useFilterInventoryReport(entries)

  React.useEffect(() => {
    const fetchCurrentInventory = (): void => {
      setIsLoading(true)

      const warehouseId =
        typeof filters.warehouses !== 'string'
          ? filters.warehouses?.[0]?.value
          : null

      warehouseService
        .getValue(warehouseId)
        .then((warehouse: any) => {
          if (isMounted.current) {
            setEntries(warehouse.products)
            setCurrentTotal(warehouse.total)
            setCurrentSkus(warehouse.skus)
          }
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    const fetchReportEntries = (): void => {
      setIsLoading(true)

      const params = {
        from_timestamp: filters.from_timestamp,
        to_timestamp: filters.to_timestamp,
        context: filters.context,
        warehouse_id:
          typeof filters.warehouses !== 'string'
            ? filters.warehouses?.[0]?.value
            : null,
      }

      warehouseService
        .fetchReports(params)
        .then((entries) => {
          // get summary
          if (
            [
              'shipment_received',
              'shipment_rejected',
              'stock_issued',
              'stock_returned',
            ].includes(filters.context)
          ) {
            const uniqEntries = entries.reduce((prev, curr) => {
              const output = [...prev]
              if (!output.includes(curr.product_id)) {
                output.push(curr.product_id)
              }
              return output
            }, [])
            const total = entries.reduce((prev, curr) => {
              return (
                prev +
                (curr.cost_price * curr.quantity) / curr.product.case_count
              )
            }, 0)
            isMounted.current && setCurrentSkus(uniqEntries?.length)
            isMounted.current && setCurrentTotal(total)
          }
          isMounted.current && setEntries(sortByKey(entries, 'created', 'desc'))
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    const fetchSalesReport = (): void => {
      setIsLoading(true)

      const params = {
        from_timestamp: filters.from_timestamp,
        to_timestamp: filters.to_timestamp,
        context: filters.context,
        warehouse_id:
          typeof filters.warehouses !== 'string'
            ? filters.warehouses?.[0]?.value
            : null,
      }

      warehouseService
        .fetchSalesReport(params)
        .then((data) => {
          isMounted.current && setCurrentTotal(data.total)
          isMounted.current && setCurrentSkus(data.products.length)
          isMounted.current && setEntries(data.products)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    if (
      userCan('view_warehouses') &&
      filters.context &&
      filters.warehouses !== '*' &&
      filters.warehouses?.length
    ) {
      if (filters.context === InventoryReportContext.Current) {
        fetchCurrentInventory()
      } else if (filters.context === 'sales') {
        fetchSalesReport()
      } else {
        fetchReportEntries()
      }
    } else {
      isMounted.current && setEntries(null)
    }
  }, [
    userCan,
    isMounted,
    filters.from_timestamp,
    filters.to_timestamp,
    filters.context,
    filters.warehouses,
  ])

  const cases = React.useMemo<number>(() => {
    return entries?.length
      ? parseFloat(
          entries
            .reduce((prev, entry) => {
              return prev + entry.quantity / entry.product.case_count
            }, 0)
            .toFixed(2)
        )
      : 0
  }, [entries])

  const exportableColumns = React.useMemo(() => {
    return getExportableColumns(filters)
  }, [filters])

  const exportable = useExportable(filteredEntries, exportableColumns)

  return (
    <Layout
      headerProps={{
        title: `${trans(`warehouse.${filters?.context}`, 'Inventory Report')}`,
      }}
      permission="view_warehouses"
    >
      <Card>
        <CardHeader as={Flex} justifyContent="space-between">
          <InventoryReportFilter
            filters={filters}
            setFilters={setFilters}
            activeFilters={activeFilters}
            isLoading={isLoading}
            resetEntries={() => {
              setEntries(null)
              setCurrentTotal(null)
              setCurrentSkus(null)
            }}
          />

          <ExportButton
            ml={2}
            filename="suplias-inventory-report"
            exportData={exportable?.data}
            exportableColumns={exportable?.columns}
            toggleExportableColumn={exportable?.toggleColumn}
            isDisabled={isLoading || !exportable.data?.length}
          />
        </CardHeader>

        {filters.context === 'current_inventory' && (
          <CardHeader>
            <ReportSummary
              label="Inventory"
              value={currentTotal}
              sku={currentSkus}
              count={cases}
            />
          </CardHeader>
        )}

        {filters.context === 'sales' && (
          <CardHeader>
            <ReportSummary
              label="Total Sales"
              value={currentTotal}
              sku={currentSkus}
              count={cases}
            />
          </CardHeader>
        )}

        {[
          'shipment_received',
          'shipment_rejected',
          'stock_issued',
          'stock_returned',
        ].includes(filters.context) && (
          <CardHeader>
            <ReportSummary
              label={`${warehouseLang[filters.context]}`}
              value={currentTotal}
              sku={currentSkus}
              count={cases}
            />
          </CardHeader>
        )}

        <CardBody padding={0}>
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <InventoryReportTable
              entries={filteredEntries}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </CardBody>
      </Card>
    </Layout>
  )
}

export default InventoryReport
