import React from 'react'

import { useHistory, useParams } from 'react-router'

import { Layout } from 'src/core/components'
import { ForeignerProducts, useProducts } from 'src/applets/product'

import useFetch from 'src/core/hooks/useFetch'
import usePermission from 'src/core/hooks/usePermission'

import { categoryService, CategoryServiceProps } from '../category.service'
import { ICategory } from '../category.type'
import { route } from 'src/core/helpers/route.helper'

const CategoryProducts: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: category_id }: any = useParams()

  const [category] = useFetch<ICategory, CategoryServiceProps>(
    categoryService,
    'fetchById',
    [category_id],
    userCan('update_category')
  )
  const [allProducts] = useProducts()

  const [categoryProducts] = useFetch<any[], CategoryServiceProps>(
    categoryService,
    'fetchProducts',
    [category?._id],
    !!category
  )

  return (
    <Layout
      headerProps={{
        title: `${category?.name || 'Category'} Products`,
        backText: 'Categories',
        onBack: () => history.push(route('categories')),
      }}
    >
      <ForeignerProducts
        allProducts={allProducts}
        foreigner={{
          ...category,
          products: categoryProducts,
          model: 'category',
          service: categoryService,
        }}
      />
    </Layout>
  )
}

export default CategoryProducts
