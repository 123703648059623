import PurchaseOrder from './pages/PurchaseOrder.page'
import PurchaseOrders from './pages/PurchaseOrders.page'
import PurchaseOrderEditor from './pages/PurchaseOrderEditor.page'

import { IRoute } from 'src/bootstrap/routes'

export const purchaseOrderRoutes: IRoute[] = [
  {
    id: 'purchase_orders',
    path: '/purchase-orders',
    component: PurchaseOrders,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'purchase_order_create',
    path: '/purchase-orders/create',
    component: PurchaseOrderEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'purchase_order_update',
    path: '/purchase-orders/:id/update',
    component: PurchaseOrderEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'purchase_order',
    path: '/purchase-orders/:id',
    component: PurchaseOrder,
    requiresAuth: true,
    exact: true,
  },
]

export default purchaseOrderRoutes
