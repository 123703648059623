import React from 'react'
import { Select, Flex } from '@chakra-ui/react'

interface PerPageSelectProps {
  value: number
  onChange: (value: number) => void
  options?: number[]
  isDisabled?: boolean
}

export const PerPageSelect: React.FC<PerPageSelectProps> = ({
  value,
  onChange,
  options,
  isDisabled,
}) => (
  <Flex alignItems="center">
    <Select
      size="sm"
      name="per_page"
      value={value || 10}
      onChange={({ target }) => onChange(Number(target.value))}
      isDisabled={isDisabled}
    >
      {options?.length
        ? options.map((perPage, index) => (
            <option key={index} value={perPage}>
              {perPage} per page
            </option>
          ))
        : null}
    </Select>
  </Flex>
)

PerPageSelect.defaultProps = {
  options: [10, 20, 50, 100, 200],
  isDisabled: false,
}

export default PerPageSelect
