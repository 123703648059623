import Cohorts from './pages/Cohorts.page'
import CohortEditor from './pages/CohortEditor.page'

import { IRoute } from 'src/bootstrap/routes'

export const cohortRoutes: IRoute[] = [
  {
    id: 'cohorts',
    path: '/cohorts',
    component: Cohorts,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'cohort_create',
    path: '/cohorts/create',
    component: CohortEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'cohort_update',
    path: '/cohorts/:id/update',
    component: CohortEditor,
    requiresAuth: true,
    exact: true,
  },
]

export default cohortRoutes
