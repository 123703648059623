import React from 'react'

import { Stack, Spinner, Flex, Text, Box, useTheme } from '@chakra-ui/react'

import { ErrorBox } from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'
import { UserPasswordForm } from 'src/applets/user'

interface EditorLayoutProps {
  type?: 'create' | 'update'
  model?: any
  /**
   * Models with a user_id property usually
   * require a password change form
   */
  isUserModel?: boolean
  isLoading?: boolean
}
/**
 * Layout for editor pages
 * An editor page is a combination of create and edit pages.
 *
 * @props EditorLayoutProps
 */
export const EditorLayout: React.FC<EditorLayoutProps> = (props) => {
  const { type, model, isLoading, isUserModel, children: modelForm } = props
  const { sizes } = useTheme()
  const showPasswordForm = isUserModel && type === 'update'

  return (
    <Stack direction={['column', 'column', 'row']} spacing={sizes.gutter.md}>
      <Box flex={(showPasswordForm ? 8 : 12) / 12}>
        <Card>
          <CardBody pt={0}>
            {isLoading ? (
              <Flex justifyContent="center" pt={70} pb={50}>
                <Spinner color="primary.600" />
              </Flex>
            ) : null}

            {type === 'create' || (!isLoading && model) ? modelForm : null}

            {type === 'update' && !isLoading && !model ? (
              <Box pt={70} pb={50}>
                <ErrorBox summary="Model not found" />
              </Box>
            ) : null}
          </CardBody>
        </Card>
      </Box>
      {showPasswordForm ? (
        <Box flex={4 / 12}>
          <Card>
            <CardHeader>
              <Text fontWeight="medium">Change Password</Text>
            </CardHeader>
            <CardBody className="pt-0">
              <UserPasswordForm user={model} />
            </CardBody>
          </Card>
        </Box>
      ) : null}
    </Stack>
  )
}

EditorLayout.defaultProps = {
  type: 'create',
}
