import DistributorOfficer from './pages/DistributorOfficer.page'
import DistributorOfficers from './pages/DistributorOfficers.page'
import DistributorOfficerEditor from './pages/DistributorOfficerEditor.page'

import { IRoute } from 'src/bootstrap/routes'

export const distributorOfficerRoutes: IRoute[] = [
  {
    id: 'distributor_officers',
    path: '/distributor-officers',
    component: DistributorOfficers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'distributor_officer_signup',
    path: '/distributor-officers/signup',
    component: DistributorOfficerEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'distributor_officer_update',
    path: '/distributor-officers/:id/update',
    component: DistributorOfficerEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'distributor_officer',
    path: '/distributor-officers/:id/:tab',
    component: DistributorOfficer,
    requiresAuth: true,
    exact: true,
  },
]

export default distributorOfficerRoutes
