import React from 'react'
import { Button } from '@chakra-ui/react'

import { AlertDialog } from 'src/core/components'
import { trans } from 'src/core/helpers'
import { usePermission } from 'src/core/hooks'

import { getPurchaseOrderConfig } from '../purchaseOrder.helper'
import { IUsePurchaseOrder } from '../hooks/usePurchaseOrder'
import { PurchaseOrderTag } from '../purchaseOrder.type'

type Type = React.FC<IUsePurchaseOrder>
export const ExecutePurchaseOrderButtons: Type = (props) => {
  const {
    purchaseOrder,
    purchaseOrderItems,
    executePurchaseOrder,
    isExecuting,
    isUpdatingItems,
  } = props

  const { userCan } = usePermission()
  const [redirectOnSave, setRedirectOnSave] = React.useState<boolean>(false)
  const [confirmConfirm, setConfirmConfirm] = React.useState<boolean>(false)

  const { type, direction } = getPurchaseOrderConfig(purchaseOrder)

  return purchaseOrderItems?.length &&
    userCan(`create_${type}_${direction}`) &&
    purchaseOrder?.tag !== PurchaseOrderTag.Receipt ? (
    <>
      <Button
        mr={3}
        variant="outline"
        isLoading={isExecuting}
        isDisabled={isExecuting || isUpdatingItems}
        onClick={() => {
          setConfirmConfirm(true)
          setRedirectOnSave(true)
        }}
      >
        {trans(`warehouse.${type}_${direction}`)} & View
      </Button>

      <Button
        colorScheme="success"
        isLoading={isExecuting}
        isDisabled={isExecuting || isUpdatingItems}
        onClick={() => setConfirmConfirm(true)}
      >
        {trans(`warehouse.${type}_${direction}`)}
      </Button>

      <AlertDialog
        isOpen={confirmConfirm}
        headerText={`${trans(`warehouse.${type}_${direction}`)}?`}
        bodyText={`This action cannot be reversed. Are you sure you want to continue?`}
        confirmButton={{
          text: `Yes, ${trans(`warehouse.${type}_${direction}`)}`,
          action: () => {
            executePurchaseOrder(
              true,
              `${type}_${direction}` !== 'shipment_in',
              redirectOnSave
            )
            setConfirmConfirm(false)
            setRedirectOnSave(false)
          },
        }}
        cancelButton={{
          text: 'Cancel',
          action: () => {
            setConfirmConfirm(false)
            setRedirectOnSave(false)
          },
        }}
      />
    </>
  ) : null
}
