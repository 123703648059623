import React from 'react'

import { Flex, Spinner, Box } from '@chakra-ui/react'

import { Layout, ExportButton, PrintButton } from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'
import useFetch from 'src/core/hooks/useFetch'

import { OrderSummaryTable, OrderSummaryTableFilter } from '../components'
import { OrderGroupService, OrderGroupServiceProps } from '../services'

const orderGroupService = new OrderGroupService()

const OrderSummary: React.FC = () => {
  const tableRef = React.useRef()
  const tableWrapperRef = React.useRef()

  const [params, setParams] =
    React.useState<{
      group_by: 'distributor' | 'buyer'
      seller: { value: string; label: string }
      sales_officer: { value: string; label: string }
      from_timestamp: number
      to_timestamp: number
    }>()

  const [po, isLoading, , setPo] = useFetch<any, OrderGroupServiceProps>(
    orderGroupService,
    'fetchPO',
    [params],
    !!params
  )

  const items = React.useMemo(() => {
    const groupBy = params?.group_by || 'distributor'
    let data = po?.items || []
    let refined = []

    if (data?.length) {
      data = data.map((item) => {
        item.type = 'product'
        item[groupBy] = { _id: '*', ...po?.[`${groupBy}s`][item[groupBy]] }
        return item
      })

      const dictionary = {}

      data.forEach((item) => {
        if (dictionary[item[groupBy]._id]) {
          dictionary[item[groupBy]._id].push(item)
        } else {
          dictionary[item[groupBy]._id] = [{ ...item, first_row: true }]
        }
      })

      Object.entries(dictionary).forEach(([id, itemsArray]: any[]) => {
        refined = [
          ...refined,
          ...itemsArray,
          { type: 'subtotal', [groupBy]: po?.[`${groupBy}s`][id] },
        ]
      })
    }

    return refined
  }, [po, params?.group_by])

  return (
    <Layout headerProps={{ title: 'Order Summary' }}>
      <Card>
        <CardHeader as={Flex} justifyContent="space-between">
          <OrderSummaryTableFilter
            fetch={(params) => {
              setPo(null)
              setParams(params)
            }}
            isFetching={isLoading}
          />

          <Flex ml={3}>
            <PrintButton
              documentRef={tableWrapperRef}
              isDisabled={!items?.length}
            />

            <Box ml={2}>
              <ExportButton
                filename="purchase-order"
                tableRef={tableRef}
                isDisabled={!items?.length}
              />
            </Box>
          </Flex>
        </CardHeader>
        <CardBody className="p-0">
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <OrderSummaryTable
              items={items}
              grandTotal={po?.grand_total}
              params={params}
              tableRef={tableRef}
              tableWrapperRef={tableWrapperRef}
            />
          )}
        </CardBody>
      </Card>
    </Layout>
  )
}

export default OrderSummary
