import React from 'react'

import { Badge, Button, Flex, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router'

import { Pagination, ErrorBox, ImageBox } from 'src/core/components'
import { Table, Thead, Tbody, Th, Tr, Td } from 'src/core/components/Table'
import { paginate, route, asset } from 'src/core/helpers'

import { ICategory } from '../category.type'

interface IProps {
  categories: ICategory[]
  filters: any
  setFilters: (filters: any) => void
}

const CategoriesTable: React.FC<IProps> = ({
  categories,
  filters,
  setFilters,
}) => {
  const history = useHistory()

  const paginatedCategories = React.useMemo(() => {
    return categories ? paginate(categories, filters.page) : []
  }, [filters.page, categories])

  const openCategory = (category, routeId = 'category_update'): void => {
    history.push(
      route(routeId, {
        id: category._id,
      })
    )
  }

  return (
    <>
      {!paginatedCategories.length ? (
        <ErrorBox summary="No categories found" my={50} />
      ) : null}

      {paginatedCategories.length ? (
        <Table isFit>
          <Thead>
            <Tr>
              <Th className="text-center">#</Th>
              <Th className="text-center">--</Th>
              <Th>Name</Th>
              <Th>Status</Th>
              <Th>Products</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedCategories.map((category, index) => (
              <Tr key={category._id}>
                <Td textAlign="center">
                  {index + 1 + (filters.page - 1) * 10}
                </Td>
                <Td textAlign="center">
                  <Flex justifyContent="center">
                    <ImageBox
                      src={category?.upload ? asset(category.upload.path) : ''}
                      width="60px"
                    />
                  </Flex>
                </Td>
                <Td>
                  <Text cursor="default">{category.name}</Text>
                  <Text color="gray.600" fontSize="sm">
                    {category.meta}
                  </Text>
                </Td>
                <Td>
                  <Badge
                    colorScheme={
                      category.status === 'active' ? 'success' : 'critical'
                    }
                  >
                    {category.status}
                  </Badge>
                </Td>
                <Td>
                  <Flex alignItems="center">
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => openCategory(category)}
                    >
                      Edit
                    </Button>

                    <Button
                      ml={3}
                      size="sm"
                      variant="outline"
                      onClick={() =>
                        openCategory(category, 'category_products')
                      }
                    >
                      Products
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : null}

      <Pagination
        my={5}
        itemsPerPage={10}
        totalItems={categories.length}
        currentPage={filters.page}
        onPageChange={(page) => setFilters({ page })}
      />
    </>
  )
}

export default CategoriesTable
