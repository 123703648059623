import React from 'react'

import { Text, Select } from '@chakra-ui/react'
import { IBrandFilters } from '../brand.type'
import { ManufacturerAutocomplete } from 'src/applets/manufacturer'
import { FilterMenu } from 'src/core/components'

interface IProps {
  filters: IBrandFilters
  setFilters: (filters: IBrandFilters) => void
  activeFilters: string[]
}

const BrandsTableFilter: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
}) => {
  const resetFilters = (): void => {
    setFilters({
      status: '*',
      manufacturers: '*',
    })
  }

  const activeFiltersText = activeFilters.length
    ? `Filtered by: ${activeFilters.join(', ')}`
    : 'Filters'

  return (
    <>
      <FilterMenu
        toggleButtonProps={{
          title: activeFiltersText,
        }}
        toggleButtonContent={
          <Text textTransform="capitalize" isTruncated>
            {activeFiltersText}
          </Text>
        }
        resetButtonProps={{
          onClick: resetFilters,
        }}
      >
        <FilterMenu.Section label="Status">
          <Select
            size="sm"
            value={filters.status || ''}
            onChange={({ target }: any) => {
              setFilters({ status: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value="active" label="Active" />
            <option value="inactive" label="Inactive" />
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Manufacturers">
          <ManufacturerAutocomplete
            value={filters.manufacturers}
            onChange={(manufacturers) => {
              setFilters({ manufacturers })
            }}
            isMulti
          />
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}

export default BrandsTableFilter
