import React from 'react'
import { useHistory } from 'react-router'

import { useMounted, useQueryParams } from 'src/core/hooks'
import {
  route,
  basicSearch,
  parseQueryParam,
  serializeQueryParam,
} from 'src/core/helpers'

import {
  ILocation,
  ILocationFilters,
  IUseFilterLocations,
} from '../location.type'

export const useFilterLocations = (
  locations: ILocation[]
): IUseFilterLocations => {
  const history = useHistory()
  const isMounted = useMounted()

  const [filters, setFilters] = React.useState<ILocationFilters>({
    status: useQueryParams('status', '*'),
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', 30)),
    search: useQueryParams('search'),
    states: parseQueryParam(useQueryParams('states', '')),
    lgas: parseQueryParam(useQueryParams('lgas', '')),
  })

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      isMounted.current &&
        setFilters({
          status: (query.get('status') || '*') as any,
          page: Number(query.get('page')) || 1,
          per_page: Number(query.get('per_page')) || 10,
          search: query.get('search') || null,
          states: parseQueryParam(query.get('states')),
          lgas: parseQueryParam(query.get('lgas')),
        })
    })
    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const updateQueryParams = React.useCallback(
    ({ lgas, states, ...params }) => {
      history.push(
        route('locations', null, {
          ...params,
          page: params.page === 1 ? null : params.page,
          per_page: params.per_page === 10 ? null : params.per_page,
          states: serializeQueryParam(states),
          lgas: serializeQueryParam(lgas),
        })
      )
    },
    [history]
  )

  const filteredLocations = React.useMemo(() => {
    let filtered = locations || []

    if (filtered.length) {
      if (filters?.status !== '*') {
        filtered = filtered.filter((location) => {
          return location.status === filters.status
        })
      }

      if (filters?.lgas !== '*') {
        const list = filters.lgas?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ lga }) => list.includes(lga))
        }
      } else if (filters?.states !== '*') {
        const list = filters.states?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ state }) => list.includes(state))
        }
      }

      if (filters.search) {
        filtered = filtered.filter((location) => {
          return (
            basicSearch(location.name, filters.search) ||
            basicSearch(location.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [locations, filters.search, filters.lgas, filters.states, filters.status])

  const activeFilters = React.useMemo(() => {
    const activeFilters = []
    const { status, states, lgas } = filters

    if (status && status !== '*') {
      activeFilters.push(`Status (${status})`)
    }

    if (lgas !== '*') {
      const list = lgas?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`LGA (${list})`)
    } else if (states !== '*') {
      const list = states?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`State (${list})`)
    }

    return activeFilters
  }, [filters])

  return {
    filters,
    activeFilters,
    setFilters: (updated) => {
      updateQueryParams({ page: 1, ...filters, ...updated })
    },
    filteredLocations,
  }
}

export default useFilterLocations
