import React from 'react'

import { Spinner, Flex } from '@chakra-ui/react'
import { FiCreditCard, FiHash } from 'react-icons/fi'
import { useParams, useHistory } from 'react-router-dom'

import {
  Layout,
  ErrorBox,
  FolderTabs,
  FolderTab,
  Card,
  ColumnLayout,
} from 'src/core/components'
import { route } from 'src/core/helpers/route.helper'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'
import {
  LedgerHead,
  LedgerLogs,
  LedgerSubhead,
  VirtualAccount,
} from 'src/applets/ledger'

import SalesOfficerDetails from '../components/SalesOfficerDetails'
import { salesOfficerService } from '../salesOfficer.service'
import { ISalesOfficer } from '../salesOfficer.type'

export enum PageTab {
  Balance = 'balance',
  VirtualAccount = 'virtual-account',
}

const SalesOfficer: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()

  const { id: sales_officer_id, tab: tabIndex }: any = useParams()

  const [salesOfficer, isLoading] = useFetch<ISalesOfficer>(
    salesOfficerService,
    'fetchById',
    [sales_officer_id],
    userCan('view_sales_officers')
  )

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Sales Officer'
          : salesOfficer?.name || 'Sales officer not found',
        backText: 'Sales Officers',
        onBack: () => history.push(route('sales_officers')),
      }}
      permission="view_sales_officers"
      bodyProps={{ padding: 0, overflowY: 'hidden' }}
    >
      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : (
        <>
          {salesOfficer ? (
            <ColumnLayout>
              <ColumnLayout.Main>
                <FolderTabs
                  defaultIndex={tabIndex}
                  onChange={(tabIndex) => {
                    history.push(
                      route('sales_officer', {
                        id: salesOfficer._id,
                        tab: tabIndex,
                      })
                    )
                  }}
                >
                  <FolderTab
                    index={PageTab.Balance}
                    title="Balance History"
                    leftIcon={<FiCreditCard />}
                  />
                  <FolderTab
                    index={PageTab.VirtualAccount}
                    title="Virtual Account"
                    leftIcon={<FiHash />}
                  />
                </FolderTabs>

                <Card
                  overflow="clip"
                  borderTopLeftRadius={tabIndex === PageTab.Balance ? 0 : null}
                >
                  {tabIndex === PageTab.Balance ? (
                    <LedgerLogs
                      owner={salesOfficer?._id}
                      head={LedgerHead.SalesOfficer}
                      subhead={LedgerSubhead.Wallet}
                    />
                  ) : tabIndex === PageTab.VirtualAccount ? (
                    <VirtualAccount
                      parent={salesOfficer}
                      parentType={LedgerHead.SalesOfficer}
                    />
                  ) : null}
                </Card>
              </ColumnLayout.Main>

              <ColumnLayout.Right bg="#fff">
                <SalesOfficerDetails salesOfficer={salesOfficer} />
              </ColumnLayout.Right>
            </ColumnLayout>
          ) : (
            <ErrorBox
              title="Sales officer not found!"
              summary="Check the URL and try again."
              my={50}
            />
          )}
        </>
      )}
    </Layout>
  )
}

export default SalesOfficer
