import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import { route } from 'src/core/helpers'
import { usePermission, useFetch } from 'src/core/hooks'

import {
  purchaseOrderService,
  PurchaseOrderServiceProps,
} from '../purchaseOrder.service'
import { IPurchaseOrder } from '../purchaseOrder.type'
import { PurchaseOrderForm } from '../components/PurchaseOrderForm'

const PurchaseOrderEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: purchase_order_id }: any = useParams()

  const permission = purchase_order_id
    ? 'update_purchase_order'
    : 'create_purchase_order'

  const [purchaseOrder, isLoading, , setPurchaseOrder] = useFetch<
    IPurchaseOrder,
    PurchaseOrderServiceProps
  >(
    purchaseOrderService,
    'fetchById',
    [purchase_order_id],
    !!purchase_order_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Purchase Order'
          : purchaseOrder?.ref || 'New Purchase Order',
        backText: 'Purchase Orders',
        onBack: () => history.push(route('purchase_orders')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={purchase_order_id ? 'update' : 'create'}
        model={purchaseOrder}
        isLoading={isLoading}
      >
        {purchase_order_id ? (
          <PurchaseOrderForm
            action="update"
            purchaseOrder={purchaseOrder}
            onUpdate={(updated) => {
              setPurchaseOrder({ ...purchaseOrder, ...updated })
            }}
          />
        ) : (
          <PurchaseOrderForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default PurchaseOrderEditor
