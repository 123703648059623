import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button, Input } from '@chakra-ui/react'
import * as Papa from 'papaparse'
import pluralize from 'pluralize'
import { FiUpload } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import xlsx from 'xlsx'

import { route } from 'src/core/helpers/route.helper'
import useToast from 'src/core/hooks/useToast'
import { ProductService } from '../product.service'
import { IProduct } from '../product.type'

const productService = new ProductService()

const ProductsUpload: React.FC<{ brand: { _id: string; name: string } }> = ({
  brand,
}) => {
  const history = useHistory()

  const { addToast } = useToast()
  const fileInput = React.useRef<any>()

  const [isUploading, setIsUploading] = React.useState<boolean>(false)
  const [products, setProducts] = React.useState<IProduct[]>()

  const [templateItems] = React.useState<any[]>([
    {
      brand_name: brand.name,
      sku_id: 'sample-product',
      name: 'Sample Product',
      description: 'Sample Product Description',
      case_count: 12,
      mpu: 12,
      price: 2500,
      weight: 0,
      size: 0,
    },
  ])

  const exportTemplate = React.useCallback((items): void => {
    const fileName = 'products-template-all-stores'
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(items)
    const wb: xlsx.WorkBook = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(wb, ws, 'Products')
    xlsx.writeFile(wb, `${fileName}.xlsx`)
  }, [])

  const onUpload = (): void => {
    setIsUploading(true)

    productService
      .createBulk(products, 120)
      .then(({ succeeded, failed }) => {
        setIsUploading(false)
        addToast(
          `${succeeded} products uploaded successfully, ${failed} failed.`,
          { appearance: 'success' }
        )

        history.push(route('products'))
      })
      .catch((error: any) => {
        setIsUploading(false)
        addToast(error.message, { appearance: 'error' })
      })
  }

  const _parseFile = (csv: any): void => {
    Papa.parse(csv, {
      delimiter: '',
      newline: '',
      quoteChar: '"',
      escapeChar: '"',
      headers: true,
      complete: ({ data }) => {
        try {
          const products = []

          for (let i = 0; i < data.length; i++) {
            /**
             * Two things:
             * 1. Let's skip the first row, it's usuall the header
             * 2. If we have less that the expected columns, skip
             */
            if (i !== 0 && data[i].length === 9) {
              if (
                data[i][1] !== '' &&
                data[i][2] !== '' &&
                data[i][3] !== '' &&
                data[i][4] !== '' &&
                data[i][5] !== '' &&
                data[i][6] !== '' &&
                data[i][7] !== '' &&
                data[i][8] !== ''
              ) {
                products.push({
                  brand_id: brand._id,
                  sku_id: data[i][1],
                  name: data[i][2],
                  description: data[i][3],
                  case_count: data[i][4],
                  mpu: data[i][5],
                  /** remove comma and new line */
                  price: data[i][6].replace(/[\s+,]/g, ''),
                  weight: data[i][7],
                  size: data[i][8],
                })
              }
            }

            if (i === data.length - 1) setProducts(products)
          }
        } catch (error) {
          addToast(error.message, { appearance: 'error' })
          throw error
        }
      },
    })

    fileInput.current.value = null // reset file input
  }

  const handleFileChange = (event): void => {
    const files = event.target.files || event.dataTransfer.files
    if (!files.length) return

    _parseFile(files[0])
  }

  return (
    <Box textAlign="center" my={50}>
      <Flex justifyContent="center" color="gray.600">
        <FiUpload size={36} strokeWidth={1.2} />
      </Flex>
      <Box>
        {products && products.length ? (
          <Text mt={2}>
            <b>{products.length}</b> {pluralize('product', products.length)}{' '}
            found in file.
          </Text>
        ) : products && !products.length ? (
          <Text mt={2} color="critical.600">
            No products found, please check the file and try again.
          </Text>
        ) : (
          <Text mt={2}>Upload {brand.name} products from a .csv file</Text>
        )}
      </Box>
      <Box mt={2}>
        <Input
          type="file"
          className="hidden-input"
          accept=".csv"
          onChange={handleFileChange}
          ref={fileInput}
        />

        <Button
          size="sm"
          variant="outline"
          onClick={() => fileInput.current.click()}
          isDisabled={isUploading}
        >
          Open CSV File
        </Button>

        <Button
          size="sm"
          colorScheme="success"
          ml={2}
          onClick={() => onUpload()}
          isDisabled={isUploading || !products || !products.length}
          isLoading={isUploading}
          loadingText="Uploading"
        >
          Upload {pluralize('Products', products && products.length)}
        </Button>

        {templateItems && templateItems.length && (
          <Box mt={3}>
            <Text
              role="button"
              cursor="default"
              fontSize="sm"
              color="primary.600"
              onClick={() => exportTemplate(templateItems)}
              onKeyDown={() => exportTemplate(templateItems)}
            >
              Download Sample ({templateItems.length}{' '}
              {pluralize('Row', templateItems.length)})
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ProductsUpload
