import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import CohortForm from '../components/CohortForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { CohortService, CohortServiceProps } from '../cohort.service'
import { ICohort } from '../cohort.type'
import { route } from 'src/core/helpers/route.helper'

const cohortService = new CohortService()

const CohortEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: cohort_id }: any = useParams()

  const permission = cohort_id ? 'update_cohort' : 'create_cohort'

  const [cohort, isLoading, , setCohort] = useFetch<
    ICohort,
    CohortServiceProps
  >(cohortService, 'fetchById', [cohort_id], !!cohort_id && userCan(permission))

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Cohort' : cohort?.title || 'New Cohort',
        backText: 'Cohorts',
        onBack: () => history.push(route('cohorts')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={cohort_id ? 'update' : 'create'}
        model={cohort}
        isLoading={isLoading}
      >
        {cohort_id ? (
          <CohortForm
            type="update"
            cohort={cohort}
            onUpdate={(updatedValues) => {
              setCohort({ ...cohort, ...updatedValues })
            }}
          />
        ) : (
          <CohortForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default CohortEditor
