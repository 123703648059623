import { IBuyer } from '../buyer/buyer.type'
import { ISalesOfficer } from '../salesOfficer/salesOfficer.type'

export enum RoutePlanDayOfWeek {
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
}

export enum RoutePlanWeekOfMonth {
  Every = '0',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
}

export interface IRoutePlan {
  _id?: string
  day: RoutePlanDayOfWeek
  /** Week of month */
  index: RoutePlanWeekOfMonth

  sales_officer_id: string
  sales_officer?: ISalesOfficer

  buyer_id: string
  buyer?: IBuyer
}
