import React, { useState, useEffect } from 'react'

import { Button, Box, Flex, Text, Input } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/modal'
import pluralize from 'pluralize'

import {
  Autocomplete,
  ImageBox,
  IQuantityInput,
  QuantityInput,
} from 'src/core/components'
import { useToast } from 'src/core/hooks'
import { asset, formatCurrency } from 'src/core/helpers'

import { useAvailableProducts } from '../hooks'

interface ProductPickerProps {
  isOpen?: boolean
  selectedItems: any[]
  /**
   * We'll use the location to fetch order fulfilment data
   * so we know which products to shoe.
   */
  location?: string
  /**
   * We may need to show only brands and products from
   * just one manufacturer.
   */
  manufacturer?: string
  addItem: (item) => Promise<any>
  closeModal: VoidFunction
  isAmountEditable?: boolean
  quantityInputProps?: IQuantityInput
}

export const ProductPicker: React.FC<ProductPickerProps> = (props) => {
  const { addToast } = useToast()

  // Indicators , I guess that's what they are called
  const [isAdding, setIsAdding] = useState<boolean>(false) // for adding a product

  // Selected options (objects), usually with value, label keys
  const [manufacturer, setManufacturer] = useState<any>()
  const [brand, setBrand] = useState<any>()
  const [product, setProduct] = useState<any>()

  const [available, isLoading] = useAvailableProducts({
    location: props.location,
    manufacturer: manufacturer?.value,
    brand: brand?.value,
  })

  const [productQuantity, setProductQuantity] = useState<number>()
  const [productAmount, setProductAmount] = useState<number>()

  const computedProductAmount = React.useMemo(() => {
    if (productAmount) {
      return productAmount
    } else if (product && productQuantity) {
      return productQuantity * (product.price / product.case_count)
    }

    return 0
  }, [productAmount, productQuantity, product])

  /**
   * Everytime 'manufacturer' or 'brands' changes,
   * reset 'product', 'filteredProducts', 'brand' and 'filteredBrands'
   */
  useEffect(() => {
    setProduct(null)
    setBrand(null)
  }, [manufacturer, available?.brands])

  /**
   * Everytime 'brand' or 'products' changes,
   * reset 'product' and 'filteredProducts'
   */
  useEffect(() => {
    setProduct(null)
  }, [brand, available?.products, manufacturer])

  /**
   * Everytime a product changes, reset the
   * product quantity
   */
  useEffect(() => {
    product && setProductQuantity(product.mpu) // reset product quantity
    product && setProductAmount(null) // reset product amount
  }, [product])

  /**
   * Select product + prevent duplicate selections
   *
   * @param product
   */
  const handleProductSelect = (product): void => {
    const selectedProducts = props.selectedItems.map((item) => item.product._id)

    if (selectedProducts.includes(product.value)) {
      setProduct(null)
      addToast('Product already exists in this order.', { appearance: 'error' })
    } else {
      setProduct(product)
      setProductQuantity(product.mpu)
    }
  }

  const quantityText = React.useMemo(() => {
    const _quantity = []

    if (product) {
      const value = productQuantity || product.mpu
      const cases = Math.floor(value / product.case_count)
      const units = value % product.case_count

      if (cases) _quantity.push(`${cases} ${pluralize('case', cases)}`)
      if (units) _quantity.push(`${units} ${pluralize('unit', units)}`)
    }

    return _quantity.join(', ')
  }, [productQuantity, product])

  return (
    <Modal isOpen={props.isOpen} onClose={props.closeModal}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Add Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody minH="75vh">
          <Autocomplete
            options={
              props.manufacturer
                ? available?.manufacturers?.filter((manufacturer) => {
                    return manufacturer.value === props?.manufacturer
                  })
                : available?.manufacturers
            }
            value={manufacturer}
            placeholder={
              isLoading ? 'Loading manufacturers...' : 'Select manufacturer'
            }
            onChange={(selected) => setManufacturer(selected)}
          />
          <Box mt={3}>
            <Autocomplete
              options={available?.brands}
              value={brand}
              placeholder={isLoading ? 'Loading brands...' : 'Select brand'}
              onChange={(selected) => setBrand(selected)}
              isDisabled={!manufacturer || !available?.brands?.length}
            />
          </Box>
          <Box my={3}>
            <Autocomplete
              options={available?.products}
              value={product}
              placeholder={
                isLoading ? 'Loading products...' : 'Search products'
              }
              onChange={handleProductSelect}
              isDisabled={!brand || !available?.products?.length}
              isAsync={brand?.value === '*'}
            />
          </Box>
          {product ? (
            <>
              <Flex justifyContent="center" mt={4} mb={2}>
                <ImageBox
                  src={product?.upload ? asset(product.upload.path) : ''}
                  width="60px"
                  borderWidth="1px"
                />
              </Flex>

              <Text fontSize="lg" textAlign="center" fontWeight="medium">
                {product.name}
              </Text>
              <Text textAlign="center" color="gray.600">
                {product.case_count} {pluralize('unit', product.case_count)} /
                case ( NGN {formatCurrency(product.price)})
              </Text>

              <Box className="text-center" margin="10px auto 0" maxW="170px">
                <Flex justifyContent="center">
                  <QuantityInput
                    mpu={product.mpu}
                    caseCount={product.case_count}
                    value={productQuantity}
                    onChange={(value: number) => {
                      setProductQuantity(value)
                    }}
                    {...props.quantityInputProps}
                  />
                </Flex>

                <Text mt={1} mb={3}>
                  {quantityText}
                </Text>

                {props.isAmountEditable ? (
                  <Flex alignItems="center">
                    <Text as="h5" fontSize="lg" fontWeight="bold">
                      NGN{' '}
                    </Text>
                    <Input
                      px={2}
                      ml={1}
                      type="number"
                      value={computedProductAmount.toString()}
                      onChange={({ target }: any) => {
                        setProductAmount(target.value)
                      }}
                      fontSize="lg"
                      fontWeight="bold"
                      width="auto"
                    />
                  </Flex>
                ) : (
                  <Text as="h5" fontSize="lg" fontWeight="bold">
                    NGN{' '}
                    {formatCurrency(
                      productQuantity * (product.price / product.case_count)
                    )}
                  </Text>
                )}
              </Box>

              <Button
                mt={5}
                width="100%"
                colorScheme="primary"
                onClick={() => {
                  setIsAdding(true)

                  props
                    .addItem({
                      product: product,
                      quantity: productQuantity,
                      amount:
                        productAmount ||
                        productQuantity * (product.price / product.case_count),
                    })
                    .then(() => {
                      setProduct(null)
                      props.closeModal()
                    })
                    .finally(() => setIsAdding(false))
                }}
                isDisabled={isAdding}
                isLoading={isAdding}
                loadingText="Adding Product"
              >
                Add Product
              </Button>
            </>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ProductPicker
