import StoreTypes from './pages/StoreTypes.page'
import StoreTypeEditor from './pages/StoreTypeEditor.page'

import { IRoute } from 'src/bootstrap/routes'

export const storeTypeRoutes: IRoute[] = [
  {
    id: 'store_types',
    path: '/store-types',
    component: StoreTypes,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'store_type_create',
    path: '/store-types/create',
    component: StoreTypeEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'store_type_update',
    path: '/store-types/:id/update',
    component: StoreTypeEditor,
    requiresAuth: true,
    exact: true,
  },
]

export default storeTypeRoutes
