import useProducts from './useProducts'
import useFetch from 'src/core/hooks/useFetch'

import {
  distributorService,
  DistributorServiceProps,
} from 'src/applets/distributor'

interface IUseDistributorProductsHook {
  allProducts: any[]
  distributorProducts: any[]
}

export const useDistributorProducts = (
  distributor
): IUseDistributorProductsHook => {
  const [distributorSeller] = useFetch<any, DistributorServiceProps>(
    distributorService,
    'fetchSeller',
    [distributor?._id],
    !!distributor
  )

  const [allProducts] = useProducts('seller', distributorSeller?.seller_id)

  const [distributorProducts] = useFetch<any[], DistributorServiceProps>(
    distributorService,
    'fetchProducts',
    [distributor?._id],
    !!distributor
  )

  return {
    allProducts,
    distributorProducts,
  }
}

export default useDistributorProducts
