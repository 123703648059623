import React from 'react'

import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import differenceWith from 'lodash/differenceWith'

import useMounted from './useMounted'

export const useFetch = <T = any, S = string>(
  service: any,
  method: S,
  params: any[] = null,
  condition = true
): [T, boolean, any, React.Dispatch<T>] => {
  const isMounted = useMounted()

  params = !params?.length ? null : params

  const [data, setData] = React.useState<T>()
  const [error, setError] = React.useState<any>()
  const [isLoading, setIsLoading] = React.useState<boolean>(condition)

  const paramsRef = React.useRef<any[]>()

  React.useEffect(() => {
    const fetchData = (): void => {
      setIsLoading(true)
      setData(undefined)
      setError(undefined)

      const call = params?.length
        ? service[method](...params)
        : service[method]()

      call
        .then((data) => {
          isMounted.current && setData(data)
        })
        .catch((error) => {
          isMounted.current && setError(error)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    /**
     * Deep check to see if params are different
     */
    if (params) {
      const paramsDifference = isEmpty(
        differenceWith(params, paramsRef.current, isEqual)
      )

      if (!paramsDifference) {
        paramsRef.current = params
        condition && fetchData()
      }
    } else {
      condition && fetchData()
    }
  }, [isMounted, condition, method, service, params])

  return [data, isLoading, error, setData]
}

export default useFetch
