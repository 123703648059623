import React, { useContext } from 'react'

import { Flex } from '@chakra-ui/react'
import { ErrorBox, Pagination, PerPageSelect } from 'src/core/components'
import { CardHeader } from 'src/core/components/Card'
import { useExportable, ExportButton } from 'src/core/components/Export'
import { Table, Tbody } from 'src/core/components/Table'
import { useSort } from 'src/core/hooks'
import { paginate } from 'src/core/helpers/filters.helper'

import { ForeignerProductsContext } from './ForeignerProducts'
import ProductsTableFilter from '../ProductsTable/ProductsTableFilter'
import {
  ForeignerProductsThead,
  ForeignerProductsTr,
} from './ForeignerProductsTableParts'
import useFilterProducts from '../../hooks/useFilterProducts'
import { formatCurrency } from 'src/core/helpers'

const exportableColumns = [
  { label: 'Name', accessor: 'name', selected: true },
  { label: 'Manufacturer', accessor: 'manufacturer.name', selected: true },
  { label: 'Brand', accessor: 'brand.name', selected: true },
  { label: 'Cases', accessor: 'case_count', selected: true },
  { label: 'Price', accessor: 'price', selected: true },
  {
    label: 'Sellers',
    accessor: 'sellers',
    selected: true,
    modifier: (sellers) => {
      return sellers?.length
        ? sellers
            .map(({ seller, price }) => {
              return `${seller.name} @ ${formatCurrency(price)}`
            })
            .join(', ')
        : '-'
    },
  },
]

export const ForeignerProductsTable: React.FC = () => {
  const { products: rawProducts, foreigner } = useContext(
    ForeignerProductsContext
  )

  const tableRef = React.useRef()

  const {
    filters,
    setFilters,
    activeFilters,
    filteredProducts: products,
    setPage,
  } = useFilterProducts(rawProducts, `${foreigner?.model}_products`, {
    id: foreigner?._id,
  })

  const exportable = useExportable(products, exportableColumns)

  const { getThProps, sorted: sortedProducts } = useSort(products, {
    accessor: 'name',
    dir: 'asc',
  })

  const paginatedProducts = React.useMemo(() => {
    const { data, timestamp } = sortedProducts

    return data || timestamp
      ? paginate(data, filters.page, filters.per_page)
      : []
  }, [filters.page, filters.per_page, sortedProducts])

  return (
    <>
      <CardHeader as={Flex} justifyContent="space-between" alignItems="center">
        <ProductsTableFilter
          filters={filters}
          setFilters={setFilters}
          activeFilters={activeFilters}
        />

        <Flex alignItems="center">
          <PerPageSelect
            value={filters.per_page}
            options={[10, 20, 50, 75, 100, 150, 200, 250, 300]}
            onChange={(per_page) => setFilters({ ...filters, per_page })}
          />

          {/* Export button */}
          <ExportButton
            ml={2}
            filename="suplias-products"
            exportData={exportable?.data}
            exportableColumns={exportable.columns}
            toggleExportableColumn={exportable.toggleColumn}
            isDisabled={!exportable?.data?.length}
          />
        </Flex>
      </CardHeader>

      {!paginatedProducts.length ? (
        <ErrorBox summary="No products found" my={50} />
      ) : (
        <Table tableRef={tableRef} isFit hasBorders>
          <ForeignerProductsThead getThProps={getThProps} />
          <Tbody>
            {paginatedProducts.map((product, index) => (
              <ForeignerProductsTr
                key={index}
                index={index}
                page={filters.page}
                product={product}
                filters={filters}
              />
            ))}
          </Tbody>
        </Table>
      )}

      <Pagination
        my={5}
        itemsPerPage={filters.per_page}
        totalItems={products.length}
        currentPage={filters.page}
        onPageChange={setPage}
      />
    </>
  )
}
