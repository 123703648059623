import OrderService, { OrderServiceProps } from './order.service'
import { IOrderGroup, IOrderGroupItem } from '../order.type'

export class OrderGroupService extends OrderService<
  IOrderGroup,
  IOrderGroupItem
> {
  constructor() {
    super('order_group')
  }

  fetchPO({ group_by, ...payload }): Promise<any> {
    const formData = new FormData()

    formData.append('from_timestamp', payload.from_timestamp)
    formData.append('to_timestamp', payload.to_timestamp)
    formData.append('seller_id', payload.seller.value)
    formData.append('sales_officer', payload?.sales_officer?.value || '*')
    formData.append('distributor', '*')

    return new Promise((resolve, reject) => {
      const cacheKey = `po_${payload.from_timestamp}_${payload.to_timestamp}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        const endpoint = group_by === 'buyer' ? 'purchase_orders_buyers' : null
        try {
          this.http
            .post(`${this.model}/${endpoint || 'purchase_orders'}`, formData)
            .then(({ data }) => {
              let result: any = {}

              if (data.code === 200) {
                const items = []

                if (data?.data?.orders) {
                  Object.values(data?.data?.orders).forEach((group: any) => {
                    Object.values(group.items).forEach((item: any) => {
                      items.push({
                        manufacturer: group?.manufacturer,
                        buyer: item?.order_group?.buyer_id,
                        ...item,
                      })
                    })
                  })

                  // this.cache.set(cacheKey, { ...data.data, items })
                  result = { ...data.data, items }
                }

                resolve(result)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchSellerSales({ sales_by, ...payload }): Promise<any> {
    const formData = new FormData()

    formData.append('timestamp_min', payload.from_timestamp)
    formData.append('timestamp_max', payload.to_timestamp)
    formData.append('seller_id', payload.seller.value)
    formData.append('tags[]', payload.tag)
    formData.append('query', '*')

    return new Promise((resolve, reject) => {
      const cacheKey = `seller_sales_${payload.seller.value}_${payload.from_timestamp}_${payload.to_timestamp}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        const endpoint =
          payload.seller.value === '*' ? 'sales_of_all_custom_special' : null
        try {
          this.http
            .post(
              `${this.model}/${
                endpoint || 'sales_of_all_custom_special_seller'
              }`,
              formData
            )
            .then(({ data }) => {
              let result: any = {}

              if (data.code === 200) {
                const items = []

                if (data?.data?.[`${sales_by}s`]) {
                  Object.values(data?.data?.[`${sales_by}s`]).forEach(
                    (item: any) => {
                      items.push(item)
                    }
                  )

                  // this.cache.set(cacheKey, { ...data.data, items })
                  result = { ...data.data, items }
                }

                resolve(result)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateMeta(
    orderGroupId,
    payload: { tag: string; value: any; meta: any }[]
  ): Promise<void> {
    const formData = new FormData()

    payload.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        formData.append(`${key}s[]`, entry[key])
      })
      formData.append(
        'update_amounts_too',
        entry.tag === 'discount' ? 'true' : 'false'
      )
    })

    formData.append('order_group_id', orderGroupId)

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`order_group_meta/upsert_many`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve()
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  fetchLogs(orderGroupId): Promise<any> {
    const formData = new FormData()
    formData.append('order_group_id', orderGroupId)

    return new Promise((resolve, reject) => {
      const cacheKey = `order_group_logs_${orderGroupId}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`order_group_log/read_by_order_group`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export const orderGroupService = new OrderGroupService()
export type OrderGroupServiceProps = OrderServiceProps | keyof OrderGroupService
export default OrderGroupService
