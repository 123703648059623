import React from 'react'

import { Button, Flex, Spinner, Box } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import { route } from 'src/core/helpers/route.helper'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'
import { useFetch, usePermission } from 'src/core/hooks'

import SalesOfficersTable from '../components/SalesOfficersTable'
import SalesOfficersTableFilter from '../components/SalesOfficersTableFilter'
import useFilterSalesOfficers from '../hooks/useFilterSalesOfficers'
import {
  salesOfficerService,
  SalesOfficerServiceProps,
} from '../salesOfficer.service'
import { ISalesOfficer } from '../salesOfficer.type'

const SalesOfficers: React.FC = () => {
  const { userCan } = usePermission()

  const [salesOfficers, isLoading] = useFetch<
    ISalesOfficer[],
    SalesOfficerServiceProps
  >(salesOfficerService, 'fetch', null, userCan('view_sales_officers'))

  const { filteredSalesOfficers, filters, setFilters, activeFilters, setPage } =
    useFilterSalesOfficers(salesOfficers)

  return (
    <Layout
      headerProps={{
        title: `Sales Officers (${filteredSalesOfficers?.length || '0'})`,
      }}
      permission="view_sales_officers"
    >
      <Card>
        <CardHeader
          as={Flex}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <SearchInput
              value={filters.search}
              onChange={(search) => setFilters({ search, page: 1 })}
              isDisabled={isLoading || !filteredSalesOfficers}
            />

            <Box mx={3}>
              <SalesOfficersTableFilter
                filters={filters}
                setFilters={setFilters}
                activeFilters={activeFilters}
              />
            </Box>
          </Flex>

          {userCan('signup_sales_officer') && (
            <Link to={route('sales_officer_signup')}>
              <Button
                size="sm"
                leftIcon={<FiPlus size={16} />}
                colorScheme="primary"
              >
                Create Sales Officer
              </Button>
            </Link>
          )}
        </CardHeader>

        <CardBody padding={0} pb={2}>
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <SalesOfficersTable
              salesOfficers={filteredSalesOfficers}
              filters={filters}
              setPage={setPage}
            />
          )}
        </CardBody>
      </Card>
    </Layout>
  )
}

export default SalesOfficers
