import React from 'react'
import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'

const defaultOptions: UseToastOptions = {
  position: 'bottom-right',
}

interface IUseToast {
  toast: (options: UseToastOptions) => void
  /**
   * __deprecated__
   * Use toast instead
   */
  addToast: (
    message: string,
    options: {
      appearance?: UseToastOptions['status']
      autoDismiss?: boolean
    }
  ) => void
  closeAllToasts: VoidFunction
}

export const useToast = (): IUseToast => {
  const chakraToast = useChakraToast()

  const toast: IUseToast['toast'] = React.useCallback(
    (options) => {
      chakraToast({ ...defaultOptions, ...options })
    },
    [chakraToast]
  )

  /**
   * __deprecated__
   * Use toast instead
   * @param message
   * @param options - {appearance}
   */
  const addToast: IUseToast['addToast'] = React.useCallback(
    (message, options) => {
      chakraToast({
        ...defaultOptions,
        description: message,
        status: options?.appearance,
        duration: options?.autoDismiss === false ? null : 6000,
      })
    },
    [chakraToast]
  )

  const closeAllToasts = React.useCallback((): void => {
    chakraToast.closeAll()
  }, [chakraToast])

  return { toast, addToast, closeAllToasts }
}

export default useToast
