import React from 'react'

import {
  Button,
  ButtonProps,
  Tooltip,
  Box,
  SimpleGrid,
  Checkbox,
} from '@chakra-ui/react'
import { Menu, MenuButton, MenuList } from '@chakra-ui/menu'
import { FiDownload } from 'react-icons/fi'
import xlsx from 'xlsx'

import { slugify } from 'src/core/helpers/filters.helper'
import { IExportableColumn } from './useExportable'

interface ExportButtonProps extends ButtonProps {
  label?: string
  filename: string
  tableRef?: any
  exportData?: any[]
  isDisabled?: boolean
  exportableColumns?: IExportableColumn[]
  toggleExportableColumn?: (label: string) => void
}

export const ExportButton: React.FC<ExportButtonProps> = ({
  label,
  filename,
  tableRef,
  exportData,
  isDisabled,
  exportableColumns,
  toggleExportableColumn,
  ...props
}) => {
  if (!exportData && !tableRef) throw new Error('Export data required')

  const exportToExcel = (): void => {
    // console.log(exportData)

    const ws: xlsx.WorkSheet = exportData
      ? xlsx.utils.json_to_sheet(exportData)
      : xlsx.utils.table_to_sheet(tableRef.current)

    const wb: xlsx.WorkBook = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1')
    xlsx.writeFile(wb, `${slugify(filename)}.xlsx`)
  }

  const buttonProps = {
    size: 'sm',
    variant: 'outline',
    leftIcon: <FiDownload size={16} />,
    isDisabled: isDisabled || (!tableRef && !exportData),
    ...props,
  }

  return (
    <>
      {exportableColumns?.length ? (
        <Menu>
          <Tooltip label="Download as Excel">
            <MenuButton as={Button} {...buttonProps}>
              {label || 'Export'}
            </MenuButton>
          </Tooltip>

          <MenuList align="right" zIndex={90}>
            <Box my="15px" mx="20px">
              <SimpleGrid columns={2} spacing={2}>
                {exportableColumns.map((column, index) => (
                  <Checkbox
                    key={index}
                    isChecked={column.selected}
                    onChange={() => {
                      toggleExportableColumn(column.label)
                    }}
                  >
                    {column.label}
                  </Checkbox>
                ))}
              </SimpleGrid>

              <Button
                {...buttonProps}
                bg="primary.50"
                colorScheme="primary"
                mt={5}
                ml={0}
                onClick={exportToExcel}
                isFullWidth
              >
                Export Selected Columns
              </Button>
            </Box>
          </MenuList>
        </Menu>
      ) : (
        <Tooltip label="Download as Excel">
          <Button {...buttonProps} onClick={exportToExcel}>
            {label || 'Export'}
          </Button>
        </Tooltip>
      )}
    </>
  )
}

export default ExportButton
