import React from 'react'

import {
  VStack,
  StackDivider,
  Checkbox,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react'
import { Formik, Field } from 'formik'
import pluralize from 'pluralize'
import { Form } from 'react-bootstrap'

import * as Yup from 'yup'

import { FormikField, FormStack } from 'src/core/components'
import useMounted from 'src/core/hooks/useMounted'
import useToast from 'src/core/hooks/useToast'
import { BuyerService } from 'src/applets/buyer'
import { NotificationService } from '../notification.service'
import { INotification } from '../notification.type'

const buyerService = new BuyerService()
const notificationService = new NotificationService()

const NotificationForm: React.FC = () => {
  const { addToast } = useToast()
  const isMounted = useMounted()

  const [buyers, setBuyers] = React.useState<any[]>([])
  const [selectedBuyer, setSelectedBuyer] = React.useState<any>(null)

  React.useEffect(() => {
    const fetchBuyers = (): void => {
      buyerService.fetch().then((buyers) => {
        const tempBuyers = buyers.map((buyer) => ({
          value: buyer.phone,
          label: `${buyer.name || 'N/A'} (${buyer.phone})`,
        }))

        isMounted.current && setBuyers(tempBuyers)
      })
    }

    fetchBuyers()
  }, [isMounted])

  const formConfig = {
    initialValues: {
      commit: true,
      send_to_all: false,
      send_sms_too: true,
      title: '',
      message: '',
      exclude: '',
      include: '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Title field is required.')
        .min(3, 'Title must be at least 3 characters.'),
      message: Yup.string()
        .required('Message field is required.')
        .min(3, 'Message must be at least 3 characters.')
        .max(160, 'Message must be at most 160 characters.'),
      exclude: Yup.string().matches(
        /^[0][7-9][0-1][0-9]{8}(,\s?[0][7-9][0-1][0-9]{8})*$/, // /^\+\d{11}(,\+\d{11})*$/, for +234...
        'Exclude should contain only valid phone numbers, comma separated'
      ),
      include: Yup.string().when('send_to_all', {
        is: true,
        then: Yup.string().nullable(),
        otherwise: Yup.string()
          .required('Recipients field is required')
          .min(11, 'Recipients must contain at least 1 valid phone number.')
          .matches(
            /^[0][7-9][0-1][0-9]{8}(,\s?[0][7-9][0-1][0-9]{8})*$/, // /^\+\d{11}(,\+\d{11})*$/, for +234...
            'Recipients must contain only valid phone numbers, comma separated'
          ),
      }),
    }),
    onSubmit: (values, { resetForm, setSubmitting }) => {
      setSubmitting(true)
      const finalValues: INotification = {
        filter_type: '*',
        filter_value: '*',
        title: values.title,
        message: values.message,
        exclude: values.send_to_all ? values.exclude || '-' : '-',
        include: !values.send_to_all ? values.include || '-' : '*',
        commit: values.commit,
        send_sms_too: values.send_sms_too,
      }

      notificationService
        .sendToBuyers({ ...finalValues })
        .then(() => {
          resetForm()
          setSubmitting(false)
          addToast('Notification sent successfully.', { appearance: 'success' })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  /**
   * Format list of comma separated phone numbers
   */
  const formatPhoneNumbers = React.useCallback((recipients: string): string => {
    /** Replace new lines with comma */
    recipients = recipients.split('\n').join(',')

    /** Remove white spaces */
    recipients = recipients.replace(/ /g, '')

    /** Split by comma */
    let recipients_array = recipients.split(',')

    /** Fix the phone numbers */
    recipients_array = recipients_array.map((recipient) => {
      if (/^[7-9][0-1][0-9]{8}$/.test(recipient)) {
        // if 8029104491
        return recipient.padStart(11, '0')
      } else if (/^\+?234[7-9][0-1][0-9]{8}$/.test(recipient)) {
        // if 2348029104491 or +2348029104491
        return recipient.replace(/^\+?234/g, '0')
      }

      return recipient
    })

    /** No empty values */
    recipients_array = recipients_array.filter((n) => n !== '')

    /** Return unique array */
    return Array.from(new Set(recipients_array)).join(', ')
  }, [])

  /**
   * Add phone number to list
   */
  const addNumberToList = React.useCallback(
    (recipients: string, phone: string): string => {
      setSelectedBuyer(null)

      if (recipients.length > 0) {
        return formatPhoneNumbers(`${recipients}, ${phone}`)
      }

      return phone
    },
    [formatPhoneNumbers]
  )

  /**
   * Count numbers in a string of comma separated
   * phone numbers
   */
  const countPhoneNumbers = React.useCallback(
    (numbers: any, label = 'recipient'): string => {
      /** Remove white spaces and split by comma */
      numbers = numbers.replace(/ /g, '').split(',')
      /** Remove emoty values */
      const number_count = numbers.filter((n) => n !== '').length

      return `${number_count} ${pluralize(label, number_count)}`
    },
    []
  )

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Notification options */}
            <FormStack columns={[4, 8]} label="Options">
              <Flex>
                <Field
                  as={Checkbox}
                  id="send_to_all"
                  name="send_to_all"
                  isChecked={values.send_to_all}
                >
                  Send to all buyers
                </Field>

                <Field
                  as={Checkbox}
                  id="send_push"
                  ml={3}
                  isChecked={true}
                  isDisabled
                >
                  Push
                </Field>

                <Field
                  as={Checkbox}
                  id="send_sms_too"
                  name="send_sms_too"
                  ml={3}
                  isChecked={values.send_sms_too}
                >
                  SMS
                </Field>
              </Flex>
            </FormStack>

            {/* Numbers to exclude */}
            {values.send_to_all ? (
              <FormStack
                columns={[4, 8]}
                label="Exclude"
                helperText="Phone numbers to exclude, comma separated"
              >
                <FormikField
                  as="textarea"
                  name="exclude"
                  rows={3}
                  onBlur={(event) => {
                    formik.handleBlur(event)
                    setTimeout(() => {
                      formik.setFieldValue(
                        'exclude',
                        formatPhoneNumbers(values.exclude)
                      )
                    }, 10)
                  }}
                />
                <Text color="gray.600" mt={1}>
                  {countPhoneNumbers(values.exclude, 'excluded number')}
                </Text>
              </FormStack>
            ) : (
              <>
                {/* Numbers to include */}
                <FormStack
                  columns={[4, 8]}
                  label="Recipients"
                  helperText="Recipient phone numbers, comma separated"
                >
                  <FormikField
                    as="textarea"
                    name="include"
                    rows={3}
                    onBlur={(event) => {
                      formik.handleBlur(event)
                      setTimeout(() => {
                        formik.setFieldValue(
                          'include',
                          formatPhoneNumbers(values.include)
                        )
                      }, 10)
                    }}
                  />
                  <Text className="text-muted d-block mt-1">
                    {countPhoneNumbers(values.include)}
                  </Text>

                  {/* Add numbers from buyers list */}
                  <Flex alignItems="center" mt={3}>
                    <FormikField
                      as="autocomplete"
                      size="sm"
                      name="buyer"
                      options={buyers}
                      value={selectedBuyer}
                      placeholder="Add buyer"
                      onChange={(selected) => {
                        setSelectedBuyer(selected)
                      }}
                      isDisabled={!buyers || !buyers.length}
                    />

                    {selectedBuyer ? (
                      <Button
                        size="sm"
                        variant="outline"
                        width="100%"
                        ml={2}
                        onClick={() => {
                          const recipients = addNumberToList(
                            values.include, // list
                            selectedBuyer.value // number
                          )
                          formik.setFieldValue('include', recipients)
                        }}
                      >
                        Add
                      </Button>
                    ) : null}
                  </Flex>
                </FormStack>
              </>
            )}

            {/* Notification title */}
            <FormStack
              columns={[4, 8]}
              label="Title"
              helperText="Title of notification"
              isRequired
            >
              <FormikField name="title" />
            </FormStack>

            {/* Notification message */}
            <FormStack
              columns={[4, 8]}
              label="Message"
              helperText="Body of notification"
              isRequired
            >
              <FormikField as="textarea" name="message" rows={3} />
              <Text color="gray.600" fontSize="sm">
                {values.message.length}/160
              </Text>
            </FormStack>
          </VStack>

          <Flex justifyContent="space-between" mt={4}>
            {/* Reset button */}
            <Button
              variant="outline"
              onClick={() => formik.resetForm()}
              isDisabled={formik.isSubmitting || !formik.dirty}
            >
              Reset Form
            </Button>

            <Button
              type="submit"
              colorScheme="primary"
              isDisabled={
                formik.isSubmitting || !formik.dirty || !formik.isValid
              }
              isLoading={formik.isSubmitting}
              loadingText="Sending"
            >
              Send Notification
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default NotificationForm
