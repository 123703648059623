import React from 'react'

import { Box, Button, SimpleGrid } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal'
import { getUnixTime } from 'date-fns'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { ledgerService } from '../ledger.service'
import { FormikField } from 'src/core/components'
import { ILedger, ILedgerLog, LedgerLogTag } from '../ledger.type'
import useToast from 'src/core/hooks/useToast'

interface IProps {
  ledger: ILedger
  onCreate: (ledgerLog: ILedgerLog) => void
  onClose: VoidFunction
  description?: string
  tag?: string
}

export const LedgerLogForm: React.FC<IProps> = ({
  ledger,
  onCreate,
  onClose,
  description,
  tag,
}) => {
  const { toast } = useToast()

  const formConfig = {
    initialValues: {
      value: '',
      description: description || '',
      tag: tag || '',
    },
    validationSchema: Yup.object({
      value: Yup.number().required('Value field is required'),
      description: Yup.string().required('Description field is required'),
      tag: Yup.string().required('Tag field is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues = {
        ...values,
        ledger_id: ledger?._id,
      }

      if (!ledger?._id) {
        Object.keys(ledger).forEach((key) => {
          finalValues[key] = ledger[key]
        })
      }

      ledgerService
        .deposit(finalValues)
        .then((ledgerLogId) => {
          setSubmitting(false)
          onCreate({
            _id: ledgerLogId,
            created: getUnixTime(new Date()),
            ...finalValues,
          })

          toast({
            status: 'success',
            description: 'Deposit successful.',
          })
        })
        .catch(({ message }) => {
          setSubmitting(false)
          toast({
            status: 'error',
            description: message,
          })
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Modal
          size="lg"
          isOpen={ledger ? true : false}
          onClose={() => onClose()}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>New Deposit</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <SimpleGrid columns={[1, 2]} spacing="15px">
                {/* tag */}
                <Box>
                  <FormikField
                    as="select"
                    name="tag"
                    label="Tag"
                    data-testid="tag"
                  >
                    <option value="">Select tag</option>
                    {Object.values(LedgerLogTag).map((tag) => (
                      <option key={tag} value={tag}>
                        {tag}
                      </option>
                    ))}
                  </FormikField>
                </Box>

                {/* value */}
                <Box>
                  <FormikField
                    type="number"
                    name="value"
                    label="Value"
                    prepend="NGN"
                    data-testid="value"
                  />
                </Box>
              </SimpleGrid>

              {/* comment */}
              <Box mt={5}>
                <FormikField
                  label="Description"
                  name="description"
                  data-testid="description"
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" onClick={() => onClose()}>
                Cancel
              </Button>

              <Button
                ml={3}
                colorScheme="primary"
                isDisabled={
                  formik.isSubmitting || !formik.dirty || !formik.isValid
                }
                onClick={() => handleSubmit()}
                isLoading={formik.isSubmitting}
                data-testid="button"
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Formik>
  )
}
