import { ILedgerTag } from './ledger.type'

type ParseVirtualAccount = (virtualAccount: ILedgerTag) => {
  bank: { name: string }
  account_name: string
  account_number: string
  created_at: string
  updated_at: string
}
export const parseVirtualAccount: ParseVirtualAccount = (virtualAccount) => {
  if (virtualAccount?.value) {
    try {
      return JSON.parse(virtualAccount.value)
    } catch {
      return null
    }
  }

  return null
}
