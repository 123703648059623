import React from 'react'

import { Button, Flex, Box, Spinner } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { FiPlus } from 'react-icons/fi'

import {
  ErrorBox,
  ExportButton,
  FolderTab,
  CardBody,
} from 'src/core/components'
import { slugify, formatCurrency } from 'src/core/helpers'
import { usePermission } from 'src/core/hooks'
import { OrderProduct, ProductPicker } from 'src/applets/product'

import OrderGroupTotal from './OrderGroupTotal'
import { OrderGroupContext } from '../../contexts'

export const OrderGroupItems: React.FC = () => {
  const { userCan } = usePermission()

  const [pickerModal, setPickerModal] = React.useState<boolean>(false)

  const {
    orderGroup,
    setOrderGroup,
    updateAmount,
    isUpdating,
    orderGroupItems,
    completeOrder,
    addOrderGroupItem,
    updateOrderGroupItem,
    deleteOrderGroupItem,
    isLoadingItems,
    isUpdatingItems,
    isCompleting,
  } = React.useContext(OrderGroupContext)

  const canUpdateOrder = userCan('update_order')

  const exportableOrderGroupItems = React.useMemo(() => {
    let exportable: any = orderGroupItems || []

    if (exportable?.length) {
      exportable = exportable.map((item) => {
        return {
          Item: item.product.name,
          Cases: Math.floor(item.quantity / item.product.case_count),
          Units: item.quantity % item.product.case_count,
          Rate: formatCurrency(item.product.price),
          Amount: formatCurrency(item.amount),
        }
      })
    }

    return exportable
  }, [orderGroupItems])

  return (
    <>
      <CardBody
        opacity={(!isLoadingItems && isUpdatingItems) || isUpdating ? 0.7 : 1}
      >
        {isLoadingItems && (
          <Flex justifyContent="center" py={50}>
            <Spinner size="sm" color="primary.600" />
          </Flex>
        )}

        {!isLoadingItems && !orderGroupItems?.length ? (
          <Box my={50}>
            <ErrorBox summary="Oops! No items in cart" />

            <Flex justifyContent="center" mt={3}>
              <Button
                size="sm"
                variant="outline"
                leftIcon={<FiPlus size={16} />}
                onClick={() => setPickerModal(true)}
                isDisabled={isLoadingItems || isCompleting}
              >
                Add Product
              </Button>
            </Flex>
          </Box>
        ) : null}

        {!isLoadingItems && orderGroupItems?.length ? (
          <>
            <Flex alignItems="center" justifyContent="space-between">
              <FolderTab
                title="Cart"
                meta={`${orderGroupItems.length} ${pluralize(
                  'Item',
                  orderGroupItems.length
                )}`}
              />

              <Flex>
                <Button
                  size="sm"
                  variant="ghost"
                  leftIcon={<FiPlus size={16} />}
                  onClick={() => setPickerModal(true)}
                  isDisabled={isLoadingItems || isCompleting}
                >
                  Add Product
                </Button>

                <ExportButton
                  filename={`${slugify(orderGroup?.buyer?.name)}-cart`}
                  exportData={exportableOrderGroupItems}
                  isDisabled={!exportableOrderGroupItems?.length}
                  variant="ghost"
                  ml={1}
                />
              </Flex>
            </Flex>

            <Box
              mb={3}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="6px"
            >
              {orderGroupItems.map((item, index) => (
                <OrderProduct
                  key={index}
                  item={item}
                  parent={orderGroup}
                  setParent={setOrderGroup}
                  updateItem={updateOrderGroupItem}
                  deleteItem={deleteOrderGroupItem}
                />
              ))}

              <Box py={3} px={4}>
                <OrderGroupTotal
                  orderGroup={orderGroup}
                  setOrderGroup={setOrderGroup}
                  updateAmount={updateAmount}
                  canUpdate={canUpdateOrder}
                />
              </Box>
            </Box>

            {/* Complete order button */}
            <Button
              width="100%"
              colorScheme="success"
              onClick={completeOrder}
              isDisabled={isCompleting}
              isLoading={isCompleting}
              loadingText="Completing Order"
            >
              Complete Order
            </Button>
          </>
        ) : null}
      </CardBody>

      {orderGroup.buyer ? (
        <ProductPicker
          isOpen={pickerModal}
          location={orderGroup.buyer.location_id}
          selectedItems={orderGroupItems || []}
          addItem={(item) =>
            addOrderGroupItem(item).then((values) => {
              setOrderGroup(values)
            })
          }
          closeModal={() => setPickerModal(false)}
        />
      ) : null}
    </>
  )
}

export default OrderGroupItems
