import React from 'react'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Tbody } from 'src/core/components/Table'
import { paginate } from 'src/core/helpers/filters.helper'
import { useSort } from 'src/core/hooks'

import { OrderGroupsThead, OrderGroupsTr } from './OrderGroupsTableParts'
import { OrderGroupsTableContext } from '../../contexts'

export const OrderGroupsTable: React.FC = () => {
  const { orderGroups, filters, setPage, parent } = React.useContext(
    OrderGroupsTableContext
  )

  const { getThProps, sorted: sortedOrderGroups } = useSort(orderGroups, {
    accessor: 'modified',
    dir: 'desc',
  })

  const paginatedOrderGroups = React.useMemo(() => {
    return sortedOrderGroups.data
      ? paginate(sortedOrderGroups.data, filters.page)
      : []
  }, [filters.page, sortedOrderGroups])

  return (
    <>
      {!paginatedOrderGroups.length ? (
        <ErrorBox summary="No orders found" my={50} />
      ) : null}

      {paginatedOrderGroups.length ? (
        <Table isFit>
          <OrderGroupsThead
            parent={parent}
            filters={filters}
            getThProps={getThProps}
          />
          <Tbody>
            {paginatedOrderGroups.map((orderGroup, index) => (
              <OrderGroupsTr
                index={index}
                key={orderGroup._id}
                orderGroup={orderGroup}
              />
            ))}
          </Tbody>
        </Table>
      ) : null}

      <Pagination
        my={5}
        itemsPerPage={10}
        totalItems={orderGroups.length}
        currentPage={filters.page}
        onPageChange={(page) => setPage(page)}
      />
    </>
  )
}

export default OrderGroupsTable
