import React from 'react'
import { useHistory } from 'react-router'

import { useMounted, useQueryParams } from 'src/core/hooks'
import { route, basicSearch, trans } from 'src/core/helpers'

import { IManager, IUseFilterManagers } from '../manager.type'

const useFilterManagers = (managers: IManager[]): IUseFilterManagers => {
  const history = useHistory()
  const isMounted = useMounted()

  const [filters, setFilters] = React.useState<any>({
    status: useQueryParams('status', 'active'),
    role: useQueryParams('role', '*'),
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', 30)),
    search: useQueryParams('search'),
  })

  React.useEffect(() => {
    const stopListening = history.listen((manager) => {
      const query = new URLSearchParams(manager.search)

      isMounted.current &&
        setFilters({
          status: query.get('status') || 'active',
          role: query.get('role') || '*',
          page: Number(query.get('page')) || 1,
          per_page: Number(query.get('per_page')) || 10,
          search: query.get('search') || null,
        })
    })
    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const updateQueryParams = React.useCallback(
    ({ ...params }) => {
      history.push(
        route('managers', null, {
          ...params,
          page: params.page === 1 ? null : params.page,
          per_page: params.per_page === 10 ? null : params.per_page,
        })
      )
    },
    [history]
  )

  const filteredManagers = React.useMemo(() => {
    let filtered = managers || []

    if (filtered.length) {
      if (filters?.status !== '*') {
        filtered = filtered.filter((manager) => {
          return manager.status === filters.status
        })
      }

      if (filters?.role !== '*') {
        filtered = filtered.filter((manager) => {
          return manager.role === filters.role
        })
      }

      if (filters.search) {
        filtered = filtered.filter((manager) => {
          return (
            basicSearch(manager.name, filters.search) ||
            basicSearch(manager.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [managers, filters.search, filters.status, filters.role])

  const activeFilters = React.useMemo(() => {
    const activeFilters = []
    const { status } = filters

    if (status && status !== '*') {
      activeFilters.push(`Status (${status})`)
    }

    if (filters?.role !== '*') {
      activeFilters.push(`Role (${trans(`manager.${filters.role}`)})`)
    }

    return activeFilters
  }, [filters])

  return {
    filters,
    activeFilters,
    filteredManagers,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, ...updated, page: 1 })
    },
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
  }
}

export default useFilterManagers
