import React from 'react'

import { Box, Button, SimpleGrid } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { ledgerService } from '../ledger.service'
import { FormikField } from 'src/core/components'
import { ILedgerLog, LedgerHead } from '../ledger.type'
import useToast from 'src/core/hooks/useToast'

interface IProps {
  isOpen?: boolean
  parentType: LedgerHead
  parentId: string
  onCreate?: (ledgerLog: ILedgerLog) => void
  onClose: VoidFunction
}

export const VirtualAccountForm: React.FC<IProps> = ({
  isOpen,
  parentType,
  parentId,
  onClose,
}) => {
  const { toast } = useToast()

  const formConfig = {
    initialValues: {
      first_name: '',
      last_name: '',
      bvn: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('First name field is required'),
      last_name: Yup.string().required('Last name field is required'),
      bvn: Yup.string()
        .max(11, 'BVN must be valid')
        .required('BVN field is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const payload = {
        parent_type: parentType,
        parent_id: parentId,
        ...values,
      }

      ledgerService
        .createVirtualAccount(payload)
        .then(() => {
          toast({
            status: 'success',
            description: 'Virtual account created successfully.',
          })

          window.location.reload()
        })
        .catch(({ message }) => {
          setSubmitting(false)
          toast({
            status: 'error',
            description: message,
          })
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Modal size="lg" isOpen={isOpen} onClose={() => onClose()}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create Virtual Account</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <SimpleGrid columns={[1, 2]} spacing="15px">
                {/* first_name */}
                <Box>
                  <FormikField
                    name="first_name"
                    label="First Name"
                    data-testid="first_name"
                  />
                </Box>

                <Box>
                  <FormikField
                    name="last_name"
                    label="Last Name"
                    data-testid="last_name"
                  />
                </Box>
              </SimpleGrid>

              {/* comment */}
              <Box mt={5}>
                <FormikField
                  type="number"
                  label="BVN"
                  name="bvn"
                  maxLength={11}
                  data-testid="bvn"
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" onClick={() => onClose()}>
                Cancel
              </Button>

              <Button
                ml={3}
                colorScheme="primary"
                isDisabled={
                  formik.isSubmitting || !formik.dirty || !formik.isValid
                }
                onClick={() => handleSubmit()}
                isLoading={formik.isSubmitting}
                data-testid="button"
              >
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Formik>
  )
}
