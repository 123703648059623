import { CoreService, CoreServiceProps } from 'src/core/services'
import { IDistributorOfficer } from './distributorOfficer.type'

export class DistributorOfficerService extends CoreService<IDistributorOfficer> {
  constructor() {
    super('distributor_officer')
  }
}

export type DistributorOfficerServiceProps = CoreServiceProps
export const distributorOfficerService = new DistributorOfficerService()
export default DistributorOfficerService
