import React from 'react'

import { Badge, Button } from '@chakra-ui/react'
import { useHistory } from 'react-router'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Thead, Tbody, Tr, Th, Td } from 'src/core/components/Table'
import { paginate, route } from 'src/core/helpers'

import { ILocation } from '../location.type'

interface PropsType {
  locations: ILocation[]
  filters: any
  setFilters: (filters: any) => void
}

const LocationsTable: React.FC<PropsType> = (props) => {
  const { locations, filters, setFilters } = props
  const history = useHistory()

  const paginatedLocations = React.useMemo(() => {
    return locations ? paginate(locations, filters.page, filters.per_page) : []
  }, [filters.page, filters.per_page, locations])

  const openLocation = (location): void =>
    history.push(
      route('location_update', {
        id: location._id,
      })
    )

  return (
    <>
      {!paginatedLocations.length ? (
        <ErrorBox summary="No locations found" my={50} />
      ) : null}

      {paginatedLocations?.length ? (
        <>
          <Table isFit>
            <Thead>
              <Tr>
                <Th textAlign="center">#</Th>
                <Th>Name</Th>
                <Th>State</Th>
                <Th>LGA</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedLocations.map((location, index) => (
                <Tr key={location._id}>
                  <Td textAlign="center">
                    {index + 1 + (filters.page - 1) * filters.per_page}
                  </Td>
                  <Td>{location.name}</Td>
                  <Td>{location.state}</Td>
                  <Td>{location.lga}</Td>
                  <Td>
                    <Badge
                      colorScheme={
                        location.status === 'active' ? 'success' : 'critical'
                      }
                    >
                      {location.status}
                    </Badge>
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => openLocation(location)}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Pagination
            my={5}
            itemsPerPage={filters.per_page}
            totalItems={locations.length}
            currentPage={filters.page}
            onPageChange={(page) => setFilters({ page })}
            justifyContent="center"
          />
        </>
      ) : null}
    </>
  )
}

export default LocationsTable
