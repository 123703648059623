import React from 'react'
import {
  Td as ChakraTd,
  TableCellProps as ChakraTdProps,
} from '@chakra-ui/table'
import { TableContext } from './Table'

interface TdProps extends ChakraTdProps {
  isSticky?: boolean
}
export const Td: React.FC<TdProps> = ({ children, isSticky, ...props }) => {
  const tableProps = React.useContext(TableContext)

  return (
    <ChakraTd
      px="20px"
      bg="white"
      borderStyle="solid"
      borderColor="gray.100"
      borderWidth={tableProps.hasBorders ? '1px' : '0 0 1px 0'}
      position={isSticky ? 'sticky' : 'static'}
      left={0}
      zIndex={isSticky ? 1 : 0}
      transition="background 300ms"
      css={{
        width: tableProps.isFit ? '1%' : 'inherit',
        whiteSpace: tableProps.isFit ? 'nowrap' : 'normal',
        '&:first-of-type': { borderLeft: 0 },
        '&:last-of-type': { borderRight: 0 },
      }}
      {...props}
    >
      {children}
    </ChakraTd>
  )
}
