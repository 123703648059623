import Locations from './pages/Locations.page'
import LocationEditor from './pages/LocationEditor.page'

import { IRoute } from 'src/bootstrap/routes'

export const locationRoutes: IRoute[] = [
  {
    id: 'locations',
    path: '/locations',
    component: Locations,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'location_create',
    path: '/locations/create',
    component: LocationEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'location_update',
    path: '/locations/:id/update',
    component: LocationEditor,
    requiresAuth: true,
    exact: true,
  },
]

export default locationRoutes
