import { CoreService, CoreServiceProps } from 'src/core/services'
import { ICohort } from './cohort.type'

export class CohortService extends CoreService<ICohort> {
  constructor() {
    super('cohort')
  }

  fetchByManufacturer(mftrId: string): Promise<ICohort[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `cohorts_${mftrId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`cohort/read_by_manufacturer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  addBuyers(cohortId, buyers): Promise<void> {
    const formData = new FormData()
    formData.append('cohort_id', cohortId)

    buyers.forEach((buyer) => {
      formData.append('buyer_id[]', buyer)
    })

    return new Promise((resolve, reject) => {
      try {
        this.http.post(`cohort_buyer/add_bulk`, formData).then(({ data }) => {
          if (data.code === 200) {
            resolve()
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export type CohortServiceProps = CoreServiceProps | keyof CohortService
export default CohortService
