import React, { useState } from 'react'

import { Spinner, Switch, Flex } from '@chakra-ui/react'

import usePermission from 'src/core/hooks/usePermission'
import useToast from 'src/core/hooks/useToast'

interface StatusSwitchProps {
  index: number
  label?: string
  item: any
  list: any[]
  setList: React.Dispatch<any>
  service: any
  permission: string
}
export const StatusSwitch: React.FC<StatusSwitchProps> = ({
  index,
  label,
  item,
  list,
  setList,
  service,
  permission,
}) => {
  const { addToast } = useToast()
  const { userCan } = usePermission()

  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const updateStatus = (): void => {
    setIsUpdating(true)

    const details = {
      _id: item._id,
      status: item.status === 'active' ? 'inactive' : 'active',
    }

    service
      .updateStatus(details)
      .then(() => {
        const updated = list.map((listItem) => {
          if (listItem._id === item._id) {
            listItem.status = details.status
          }
          return listItem
        })

        setList(updated)
        addToast(`${label || 'Item'} status updated`, { appearance: 'success' })
      })
      .catch(({ message }) => {
        addToast(message, { appearance: 'error' })
      })
      .finally(() => {
        setIsUpdating(false)
      })
  }

  return (
    <Flex justifyContent="center">
      {userCan(permission) && (
        <>
          {isUpdating ? (
            <Flex justifyContent="center">
              <Spinner size="sm" color="primary.600" />
            </Flex>
          ) : (
            <Switch
              id={`status-switch-${index}`}
              size="sm"
              width="auto"
              isChecked={item.status === 'active'}
              onChange={updateStatus}
              css={{ input: { position: 'static !important' } } as any}
            />
          )}
        </>
      )}
    </Flex>
  )
}

export default StatusSwitch
