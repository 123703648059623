import React from 'react'

import { Button, Spinner, Flex } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'

import { basicSearch, route } from 'src/core/helpers'
import { useFetch, useFilter, usePermission } from 'src/core/hooks'

import { WarehousesTable } from '../components/WarehousesTable'
import { warehouseService, WarehouseServiceProps } from '../warehouse.service'
import { IWarehouse } from '../warehouse.type'

const Warehouses: React.FC = () => {
  const { userCan } = usePermission()

  const { filters, setFilters } = useFilter('warehouses')

  const [warehouses, isLoading] = useFetch<IWarehouse[], WarehouseServiceProps>(
    warehouseService,
    'fetch',
    null,
    userCan('view_warehouses')
  )

  const filteredWarehouse = React.useMemo(() => {
    let filtered = warehouses || []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((warehouse) => {
          return basicSearch(warehouse?.parent?.name, filters.search)
        })
      }
    }

    return filtered
  }, [warehouses, filters.search])

  return (
    <Layout
      headerProps={{
        title: `Warehouses (${filteredWarehouse?.length || '0'})`,
      }}
      permission="view_warehouses"
    >
      <Card>
        <CardHeader as={Flex} justifyContent="space-between">
          <SearchInput
            placeholder="Search warehouses"
            value={filters.search}
            onChange={(search) => setFilters({ search, page: 1 })}
            isDisabled={isLoading || !filteredWarehouse}
          />

          {userCan('create_warehouse') && (
            <Link to={route('warehouse_create')}>
              <Button
                size="sm"
                leftIcon={<FiPlus size={16} />}
                colorScheme="primary"
              >
                Create Warehouse
              </Button>
            </Link>
          )}
        </CardHeader>
        <CardBody p={0}>
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <WarehousesTable
              warehouses={filteredWarehouse}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </CardBody>
      </Card>
    </Layout>
  )
}

export default Warehouses
