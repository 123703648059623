import React from 'react'

import { formatCurrency, sortByKey } from 'src/core/helpers'
import { IManufacturer } from 'src/applets/manufacturer'
import { IBrand } from 'src/applets/brand'
import { warehouseService } from 'src/applets/warehouse'

import useMounted from 'src/core/hooks/useMounted'
import { productService } from '../product.service'
import { IProduct } from '../product.type'

type UseAvailableProductsParams = {
  location?: string
  warehouse?: string
  manufacturer?: string
  brand?: string
  seller?: string
}

type UseAvailableProductsType = [
  {
    products: (IProduct & { value: string; label: string })[]
    manufacturers: (IManufacturer & { value: string; label: string })[]
    brands: (IBrand & { value: string; label: string })[]
  },
  boolean
]

/** Stores Record<locationId,boolean> */
const hookActivity = {}

export const useAvailableProducts = (
  params?: UseAvailableProductsParams
): UseAvailableProductsType => {
  const isMounted = useMounted()
  const [available, setAvailable] = React.useState<Record<string, any[]>>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    const fetchAll = (): void => {
      productService
        .fetch()
        .then((products) => {
          if (isMounted.current) {
            const manufacturers = {}
            const brands = {}

            products.forEach((product) => {
              if (!manufacturers[product?.manufacturer?._id]) {
                manufacturers[product?.manufacturer?._id] =
                  product?.manufacturer
              }
              if (!brands[product?.brand?._id]) {
                brands[product?.brand?._id] = product?.brand
              }
            })

            const availableManufacturers = sortByKey(
              Object.values(manufacturers),
              'name'
            )
            const availableBrands = sortByKey(Object.values(brands), 'name')

            const _available = {
              manufacturers: [
                { _id: '*', name: 'All Manufacturers' },
                ...availableManufacturers,
              ],
              brands: [{ _id: '*', name: 'All Brands' }, ...availableBrands],
              products,
            }

            setAvailable(_available)
            setIsLoading(false)
            hookActivity[params?.location || params?.warehouse] = false
          }
        })
        .catch(() => [setIsLoading(false)])
    }

    const fetchByLocation = (): void => {
      productService
        .fetchAvailableMore({
          location_id: params?.location,
          page: 0,
          limit: 0,
        })
        .then(({ products, brands, manufacturers }) => {
          if (isMounted.current) {
            const availableManufacturers = manufacturers.filter(
              ({ status }) => status === 'active'
            )
            const availableBrands = brands.filter(
              ({ status }) => status === 'active'
            )

            const _available = {
              manufacturers: [
                { _id: '*', name: 'All Manufacturers' },
                ...availableManufacturers,
              ],
              brands: [{ _id: '*', name: 'All Brands' }, ...availableBrands],
              products: products.filter(({ status }) => status === 'active'),
            }

            setAvailable(_available)
            setIsLoading(false)
            hookActivity[params?.location] = false
          }
        })
        .catch(() => setIsLoading(false))
    }

    const fetchByWarehouse = (): void => {
      warehouseService
        .fetchProducts(params?.warehouse)
        .then((warehouseProducts) => {
          warehouseProducts = warehouseProducts.filter(({ quantity }) => {
            return quantity > 0
          })

          if (isMounted.current) {
            const manufacturers = {}
            const brands = {}

            warehouseProducts.forEach((warehouseProduct) => {
              const { product } = warehouseProduct
              if (!manufacturers[product?.manufacturer?._id]) {
                manufacturers[product?.manufacturer?._id] =
                  product?.manufacturer
              }
              if (!brands[product?.brand?._id]) {
                brands[product?.brand?._id] = product?.brand
              }
            })

            const availableManufacturers = sortByKey(
              Object.values(manufacturers),
              'name'
            )
            const availableBrands = sortByKey(Object.values(brands), 'name')

            const _available = {
              manufacturers: [
                { _id: '*', name: 'All Manufacturers' },
                ...availableManufacturers,
              ],
              brands: [{ _id: '*', name: 'All Brands' }, ...availableBrands],
              products: warehouseProducts.map((warehouseProduct) => {
                return {
                  ...warehouseProduct.product,
                  quantity: warehouseProduct.quantity,
                }
              }),
            }

            setAvailable(_available)
            setIsLoading(false)
            hookActivity[params?.warehouse] = false
          }
        })
        .catch(() => setIsLoading(false))
    }

    if (!hookActivity?.[params?.location || params?.warehouse]) {
      const location = params?.location
      const warehouse = params?.warehouse

      if (location && location !== '*') {
        fetchByLocation()
        hookActivity[params?.location || params?.warehouse] = true
      } else if (warehouse && warehouse !== '*') {
        fetchByWarehouse()
        hookActivity[params?.warehouse] = true
      } else if (location === '*' || warehouse === '*') {
        fetchAll()
        hookActivity[params?.location] = true
      }
    }
  }, [isMounted, params?.location, params?.warehouse])

  const filteredProducts = React.useMemo(() => {
    if (params?.brand && available?.products?.length) {
      let tempProducts = available.products

      if (params?.brand && params?.brand !== '*') {
        tempProducts = tempProducts.filter(({ brand_id, ...product }) => {
          if (params?.location === '*' || params?.warehouse === '*') {
            return brand_id === params?.brand
          }
          const seller =
            product?.seller_product || product?.sellers?.[params?.seller]
          return brand_id === params?.brand && seller?.status === 'active'
        })
      }

      return tempProducts.map((product) => {
        const seller =
          product?.seller_product || product?.sellers?.[params?.seller]
        const price = seller?.price || product?.price

        return {
          ...product,
          value: product._id,
          label: `${product.name} (NGN ${formatCurrency(price)})`,
          mpu: seller?.mpu || product.mpu,
          status: seller?.status || product.status,
          price,
        }
      })
    }

    return []
  }, [
    params?.brand,
    available?.products,
    params?.location,
    params?.seller,
    params?.warehouse,
  ])

  const filteredBrands = React.useMemo(() => {
    if (available?.brands?.length) {
      let tempBrands = available?.brands

      if (params?.manufacturer && params?.manufacturer !== '*') {
        tempBrands = tempBrands.filter((brand) => {
          return brand.manufacturer_id === params?.manufacturer
        })
      }

      return tempBrands.map((brand) => ({
        ...brand,
        value: brand._id,
        label: brand.name,
      }))
    }

    return []
  }, [params?.manufacturer, available?.brands])

  const filteredManufacturers = React.useMemo(() => {
    if (available?.manufacturers?.length) {
      return available.manufacturers.map((manufacturers) => ({
        ...manufacturers,
        value: manufacturers._id,
        label: manufacturers.name,
      }))
    }

    return []
  }, [available?.manufacturers])

  return [
    {
      products: filteredProducts,
      manufacturers: filteredManufacturers,
      brands: filteredBrands,
    },
    isLoading,
  ]
}

export default useAvailableProducts
