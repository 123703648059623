import React from 'react'

import { Button, Flex, Select, Spinner } from '@chakra-ui/react'
import { fromUnixTime, getUnixTime } from 'date-fns'
import { Card, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import {
  Layout,
  ErrorBox,
  ExportButton,
  DateRangeInput,
} from 'src/core/components'

import useMounted from 'src/core/hooks/useMounted'

import { AgentService } from '../agent.service'
import { IStoreState } from 'src/bootstrap/store/types'
import { computeTTL, timestampToDate } from 'src/core/helpers/date.helper'
import { route } from 'src/core/helpers/route.helper'

const agentService = new AgentService()

interface IProps extends RouteComponentProps {
  history: any
}

const Agents: React.FC<IProps> = ({ history }) => {
  const tableRef = React.useRef()
  const isMounted = useMounted()

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [showBuyersList, setShowBuyersList] = React.useState<boolean>(false)
  const [startDate, setStartDate] = React.useState<Date>()
  const [endDate, setEndDate] = React.useState<Date>()
  const [period, setPeriod] = React.useState<string>('day')

  const [sourceData, setSourceData] = React.useState<any>({
    timestreams: [],
    agents: [],
    totals: [],
    cumulative: {},
  })

  React.useEffect(() => {
    const fetchBySource = (): void => {
      if (startDate && endDate && period) {
        setIsLoading(true)

        const startUnix = startDate
          ? (startDate.getTime() / 1000).toString()
          : ''
        const endUnix = endDate ? (endDate.getTime() / 1000).toString() : ''
        const periodTTL = computeTTL(period)

        agentService
          .fetchReportBySource(startUnix, endUnix, periodTTL, 'AGENCY')
          .then(({ timestreams, agents, totals, cumulative }) => {
            isMounted.current &&
              setSourceData({ timestreams, agents, totals, cumulative })
          })
          .finally(() => {
            isMounted.current && setIsLoading(false)
          })
      }
    }

    fetchBySource()
  }, [startDate, period, endDate, isMounted])

  return (
    <Layout
      headerProps={{
        title: 'Report',
        backText: 'Agents',
        onBack: () => history.push(route('agents')),
      }}
    >
      <Card>
        <Card.Header>
          {`${sourceData?.cumulative?.referrals || '0'} Referrals, `}
          {`${sourceData?.cumulative?.buyers || '0'} Buyers, `}
          {`${sourceData?.cumulative?.orders || '0'} Unique Orders`}
        </Card.Header>

        <Card.Header className="">
          <Flex alignItems="center">
            <DateRangeInput
              start={getUnixTime(startDate)}
              end={getUnixTime(endDate)}
              onChange={(start, end) => {
                start && setStartDate(fromUnixTime(start))
                end && setEndDate(fromUnixTime(end))
              }}
            />

            <Select
              size="sm"
              ml={2}
              value={period}
              onChange={({ target }) => setPeriod(target.value)}
            >
              <option value="day">Day</option>
              <option value="week">Week</option>
            </Select>
          </Flex>

          <Flex alignItems="center">
            {sourceData.agents.length ? (
              <Button
                size="sm"
                variant="outline"
                mr={2}
                isDisabled={!sourceData.agents.length || isLoading}
                onClick={() => setShowBuyersList(!showBuyersList)}
              >
                {showBuyersList ? 'Hide Buyers List' : 'Show Buyers List'}
              </Button>
            ) : null}

            {/* Export button */}
            <ExportButton
              filename="agents"
              tableRef={tableRef}
              isDisabled={!sourceData.agents.length || isLoading}
            />
          </Flex>
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && sourceData.agents.length ? (
            <div className="table-wrapper padded">
              <Table
                ref={tableRef}
                className="table-fit"
                bordered
                hover
                responsive
              >
                <thead>
                  <tr className="main">
                    <th className="hidden">-</th>
                    <th colSpan={4} className="sticky hidden">
                      -
                    </th>
                    <th colSpan={2} className="hidden">
                      -
                    </th>
                    <th colSpan={3}>Total</th>
                    {sourceData.timestreams.map((timestream, index) => (
                      <th key={index} colSpan={showBuyersList ? 11 : 3}>
                        {timestampToDate(timestream)}
                      </th>
                    ))}
                  </tr>
                  <tr>
                    <th>#</th>
                    <th colSpan={4} className="sticky">
                      Agent Name
                    </th>
                    <th colSpan={2}>Agent Phone</th>
                    <th>Referrals</th>
                    <th>Sign Ups</th>
                    <th>Unique Orders</th>
                    {sourceData.timestreams.map((_, index) => (
                      <React.Fragment key={index}>
                        <th>Referrals</th>
                        <th>Sign Ups</th>
                        <th>Unique Orders</th>
                        {showBuyersList && <th colSpan={4}>Referrals List</th>}
                        {showBuyersList && <th colSpan={4}>Buyers List</th>}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {sourceData.agents.map((agent, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td colSpan={4} className="sticky">
                        {agent.name}
                      </td>
                      <td colSpan={2}>{agent.phone}</td>

                      <td className="text-center font-weight-bold">
                        {agent.referrals}
                      </td>
                      <td className="text-center font-weight-bold">
                        {agent.buyers}
                      </td>
                      <td className="text-center font-weight-bold">
                        {agent.orders}
                      </td>

                      {agent.timestreams.map((timestream, index) => (
                        <React.Fragment key={index}>
                          <td className="text-center">
                            {timestream.referrals}
                          </td>
                          <td className="text-center">{timestream.buyers}</td>
                          <td className="text-center">{timestream.orders}</td>

                          {/* Referrals list */}
                          {showBuyersList && (
                            <td colSpan={4}>
                              {timestream.referrals ? (
                                timestream.referralsList.map(
                                  (referral, index) => (
                                    <div key={index}>
                                      + {referral} <br />
                                    </div>
                                  )
                                )
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                          )}

                          {/* Buyers list */}
                          {showBuyersList && (
                            <td colSpan={4}>
                              {timestream.buyers ? (
                                timestream.buyersList.map((buyer, index) => (
                                  <div key={index}>
                                    + {buyer.name} ({buyer.phone}) <br />
                                  </div>
                                ))
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                          )}
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}

                  <tr>
                    <td
                      colSpan={sourceData.timestreams.length * 5}
                      className="hidden"
                    ></td>
                  </tr>

                  {/* Cumulative and timestream totals */}
                  <tr>
                    <td className="hidden">-</td>
                    <td colSpan={4} className="sticky  font-weight-bold">
                      Total
                    </td>
                    <td colSpan={2} className="hidden">
                      -
                    </td>

                    <td className="text-center font-weight-bold">
                      {sourceData.cumulative.referrals}
                    </td>
                    <td className="text-center font-weight-bold">
                      {sourceData.cumulative.buyers}
                    </td>
                    <td className="text-center font-weight-bold">
                      {sourceData.cumulative.orders}
                    </td>

                    {sourceData.totals.map((timestream, index) => (
                      <React.Fragment key={index}>
                        <td className="text-center font-weight-bold">
                          {timestream.referrals}
                        </td>
                        <td className="text-center font-weight-bold">
                          {timestream.buyers}
                        </td>
                        <td className="text-center font-weight-bold">
                          {timestream.orders}
                        </td>
                        {/* Buyers list */}
                        {showBuyersList && <td colSpan={4}>-</td>}
                        {/* Referrals list */}
                        {showBuyersList && <td colSpan={4}>-</td>}
                      </React.Fragment>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}

          {!isLoading && !sourceData.agents.length ? (
            <ErrorBox summary="No agent report available" my={50} />
          ) : null}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default connect((state: IStoreState) => ({
  user: state.user,
}))(withRouter(Agents))
