import { Role, supliasStaff } from 'src/bootstrap/permissions/roles'
import { IPermission } from 'src/bootstrap/permissions'

export const warehousePermissions: IPermission[] = [
  { id: 'view_warehouses', roles: [...supliasStaff] },
  { id: 'view_warehouse_logs', roles: [Role.Admin, Role.WarehouseOfficer] },
  { id: 'create_warehouse', roles: [...supliasStaff] },
  { id: 'update_warehouse', roles: [...supliasStaff] },

  // Inventory permissions
  { id: 'create_stock_in', roles: [Role.WarehouseOfficer, Role.Admin] },
  { id: 'create_stock_out', roles: [Role.WarehouseOfficer, Role.Admin] },
  { id: 'create_shipment_in', roles: [Role.WarehouseOfficer, Role.Admin] },
  { id: 'create_shipment_out', roles: [Role.WarehouseOfficer, Role.Admin] },
]
