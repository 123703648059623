import React from 'react'

import { Avatar, Button, Box, Flex, Heading, Text } from '@chakra-ui/react'
import { FiUser, FiPhoneCall } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { ISalesOfficer } from '../salesOfficer.type'
import { route } from 'src/core/helpers/route.helper'

interface IProps {
  salesOfficer: ISalesOfficer
}

const SalesOfficerDetails: React.FC<IProps> = ({ salesOfficer }) => {
  return (
    <>
      <SalesOfficerIntro salesOfficer={salesOfficer} />
    </>
  )
}

interface SectionProps {
  salesOfficer: ISalesOfficer
}

const SalesOfficerIntro: React.FC<SectionProps> = ({ salesOfficer }) => {
  return (
    <Box textAlign="center">
      <Flex justifyContent="center">
        <Avatar name={salesOfficer?.name || 'Buyer'} />
      </Flex>

      <Heading as="h6" size="md" fontWeight="semibold" mt={3}>
        {salesOfficer?.name || salesOfficer?.phone}
      </Heading>

      <Text color="gray.600">{salesOfficer?.phone}</Text>

      <Box mt={2}>
        <Link to={route('sales_officer_update', { id: salesOfficer._id })}>
          <Button size="sm" leftIcon={<FiUser size={14} />} mr={3}>
            Edit
          </Button>
        </Link>

        <a href={`tel:+234${salesOfficer?.phone.substr(1)}`}>
          <Button size="sm" leftIcon={<FiPhoneCall size={14} />}>
            Call
          </Button>
        </a>
      </Box>
    </Box>
  )
}

export default SalesOfficerDetails
