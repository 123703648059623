import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '6px',
    padding: '1px 6px',
    fontWeight: 'medium',
    textTransform: 'capitalize',
  },
}
