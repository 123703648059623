import {
  Button,
  AlertDialog as ChakraAlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react'
import React from 'react'

interface AlertDialogProps {
  isOpen: boolean
  headerText?: string
  bodyText?: string
  confirmButton: {
    text?: string
    action: () => void
    isLoading?: boolean
  }
  cancelButton: {
    text?: string
    action: () => void
  }
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  isOpen,
  headerText,
  bodyText,
  confirmButton,
  cancelButton,
}) => {
  const cancelRef = React.useRef()
  return (
    <>
      <ChakraAlertDialog
        isOpen={isOpen}
        onClose={cancelButton.action}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" pb={0}>
            {headerText || 'confirmAction'}
          </AlertDialogHeader>

          <AlertDialogBody>
            {bodyText || `Are you sure? You can't undo this action afterwards.`}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              // onClick={onClose}
              onClick={cancelButton.action}
              isDisabled={confirmButton.isLoading}
            >
              {cancelButton.text || 'Cancel'}
            </Button>
            <Button
              colorScheme="critical"
              onClick={confirmButton.action}
              isDisabled={confirmButton.isLoading}
              ml={3}
            >
              {confirmButton.text || 'Confirm'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </ChakraAlertDialog>
    </>
  )
}

export default AlertDialog
