import React from 'react'

import { Button, Spinner, Flex, Text } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'
import DistributorsTable from '../components/DistributorsTable'

import useFetch from 'src/core/hooks/useFetch'
import useFilter from 'src/core/hooks/useFilter'
import usePermission from 'src/core/hooks/usePermission'

import { IDistributor } from '../distributor.type'

import {
  DistributorService,
  DistributorServiceProps,
} from '../distributor.service'

import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'
import { quickPluralize } from 'src/core/helpers/filters.helper'

const distributorService = new DistributorService()

const Distributors: React.FC = () => {
  const { userCan } = usePermission()

  const { filters, setFilters } = useFilter('distributors')

  const [distributors, isLoading] = useFetch<
    IDistributor[],
    DistributorServiceProps
  >(distributorService, 'fetch', null, userCan('view_distributors'))

  const filteredDistributors = React.useMemo(() => {
    let filtered = distributors || []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((distributor) => {
          return (
            basicSearch(distributor.name, filters.search) ||
            basicSearch(distributor.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [distributors, filters.search])

  return (
    <Layout
      headerProps={{ title: 'Distributors' }}
      permission="view_distributors"
    >
      <Card>
        <CardHeader as={Flex} justifyContent="space-between">
          <Flex alignItems="center">
            <Text>{quickPluralize(filteredDistributors, 'distributor')}</Text>
            <SearchInput
              placeholder="Search distributors"
              value={filters.search}
              onChange={(search) => setFilters({ search, page: 1 })}
              isDisabled={isLoading || !filteredDistributors}
              ml={3}
            />
          </Flex>

          {userCan('create_distributor') && (
            <Link to={route('distributor_create')}>
              <Button
                size="sm"
                leftIcon={<FiPlus size={16} />}
                colorScheme="primary"
              >
                Create Distributor
              </Button>
            </Link>
          )}
        </CardHeader>
        <CardBody p={0}>
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <DistributorsTable
              distributors={filteredDistributors}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </CardBody>
      </Card>
    </Layout>
  )
}

export default Distributors
