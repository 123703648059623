import React from 'react'
import { Select } from '@chakra-ui/react'
import { FilterMenu } from 'src/core/components'
import {
  IPurchaseOrderFilters,
  PurchaseOrderPartyType,
  PurchaseOrderTag,
} from '../purchaseOrder.type'
import { PurchaseOrderPartyAutocomplete } from './PurchaseOrderPartyAutoComplete'

interface IProps {
  filters: IPurchaseOrderFilters
  setFilters: (filters: IPurchaseOrderFilters) => void
  activeFilters: string[]
}

export const PurchaseOrdersTableFilter: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
}) => {
  const resetFilters = (): void => {
    setFilters({
      tag: '*',
      source_type: '*',
      destination_type: '*',
      sources: '*',
      destinations: '*',
    })
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length
            ? `Filtered by: ${activeFilters.join(', ')}`
            : 'Filters'
        }
        resetButtonProps={{ onClick: resetFilters }}
      >
        <FilterMenu.Section label="Tag">
          <Select
            size="sm"
            value={filters.tag || ''}
            onChange={({ target }: any) => {
              setFilters({ tag: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value={PurchaseOrderTag.PurchaseOrder}>
              Purchase Order
            </option>
            <option value={PurchaseOrderTag.Receipt}>Receipt</option>
          </Select>
        </FilterMenu.Section>

        {/* Source Type */}
        <FilterMenu.Section label="Source type">
          <Select
            size="sm"
            value={filters.source_type || '*'}
            onChange={({ target }: any) => {
              setFilters({ source_type: target.value, page: 1, sources: '*' })
            }}
          >
            <option value="*" label="All" />
            <option value={PurchaseOrderPartyType.Distributor}>
              Distributor
            </option>
            <option value={PurchaseOrderPartyType.Seller}>Hub</option>
            <option value={PurchaseOrderPartyType.SalesOfficer}>
              Delivery Associate
            </option>
          </Select>
        </FilterMenu.Section>

        {/* Source */}
        <FilterMenu.Section label="Source">
          <PurchaseOrderPartyAutocomplete
            size="sm"
            value={filters.sources?.[0]}
            partyType={filters.source_type}
            onChange={(source) => {
              setFilters({ sources: [source] })
            }}
          />
        </FilterMenu.Section>

        {/* Destination Type */}
        <FilterMenu.Section label="Destination type">
          <Select
            size="sm"
            value={filters.destination_type || '*'}
            onChange={({ target }: any) => {
              setFilters({
                destination_type: target.value,
                page: 1,
                destinations: '*',
              })
            }}
          >
            <option value="*" label="All" />
            <option value={PurchaseOrderPartyType.Distributor}>
              Distributor
            </option>
            <option value={PurchaseOrderPartyType.Seller}>Hub</option>
            <option value={PurchaseOrderPartyType.SalesOfficer}>
              Delivery Associate
            </option>
          </Select>
        </FilterMenu.Section>

        {/* Destination */}
        <FilterMenu.Section label="Destination">
          <PurchaseOrderPartyAutocomplete
            size="sm"
            value={filters.destinations?.[0]}
            partyType={filters.destination_type}
            onChange={(destination) => {
              setFilters({ destinations: [destination] })
            }}
          />
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}
