import React, { useState } from 'react'

import { Spinner, Tabs, TabList, Tab } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { Card } from 'react-bootstrap'

import {
  Layout,
  SearchInput,
  PerPageSelect,
  ExportButton,
} from 'src/core/components'
import FulfilmentFilters from '../components/Fulfilment/FulfilmentFilters'
import FulfilmentTable from '../components/Fulfilment/FulfilmentTable'

import useFetch from 'src/core/hooks/useFetch'

import { OrderService } from '../services'
import { basicSearch } from 'src/core/helpers/search.helper'

const orderService = new OrderService()

const Fulfilment: React.FC<any> = () => {
  const tableRef = React.useRef()

  const [tabIndex, setTabIndex] = useState<number>(0)

  const [filters, setFilters] = useState<any>({
    manufacturers: null,
    locations: null,
    search: null,
    page: 1,
    per_page: 50,
  })

  const [matrix, isLoading] = useFetch(orderService, 'fetchMatrixFulfilment', [
    tabIndex === 1,
  ])

  const filteredMatrix = React.useMemo(() => {
    let { columns, data } = matrix || {
      columns: [],
      data: [],
    }

    let manufacturers = filters.manufacturers
    manufacturers = manufacturers?.length
      ? manufacturers.map((options) => {
          return options.value
        })
      : []
    if (manufacturers?.length) {
      // Filter manufacturers column
      columns = columns.filter((item) => {
        return manufacturers.includes(item._id)
      })

      // For each location
      data = data.map((item) => {
        const temp = { ...item } // to avoid leaving a reference

        // Filter and return new manufacturers list
        temp.manufacturers = temp.manufacturers.filter((item) => {
          return manufacturers.includes(item._id)
        })

        return temp
      })
    }

    let locations = filters.locations
    locations = locations?.length
      ? locations.map((options) => {
          return options.value
        })
      : []
    if (locations?.length) {
      data = data.filter((item) => {
        return (
          locations.includes(item?._id) || locations.includes(item?.location_id)
        )
      })
    }

    if (filters.search) {
      data = data.filter((item) => {
        return (
          basicSearch(item?.name, filters.search) ||
          basicSearch(item?.phone, filters.search) ||
          basicSearch(item?.location?.name, filters.search)
        )
      })
    }

    return { columns, data }
  }, [matrix, filters.manufacturers, filters.locations, filters.search])

  return (
    <Layout headerProps={{ title: 'Order Fulfilment' }}>
      <Card>
        <Card.Header style={{ paddingBottom: '1px' }}>
          <Tabs
            defaultIndex={tabIndex}
            onChange={(tabIndex: any) => {
              setTabIndex(tabIndex)
            }}
          >
            <TabList border={0}>
              <Tab>Locations</Tab>
              <Tab>Buyers</Tab>
            </TabList>
          </Tabs>
        </Card.Header>
        <Card.Header>
          <div className="d-flex align-items-center">
            {filteredMatrix?.data.length
              ? `${filteredMatrix?.data.length} ${pluralize(
                  'entries',
                  filteredMatrix?.data.length
                )}`
              : '0 entries'}

            <div className="mx-2">
              <FulfilmentFilters filters={filters} setFilters={setFilters} />
            </div>

            <SearchInput
              value={filters.search}
              onChange={(search) => {
                setFilters({ ...filters, search })
              }}
            />
          </div>

          {/* Export button */}
          <div className="d-flex">
            <PerPageSelect
              value={filters.per_page}
              onChange={(per_page) => setFilters({ ...filters, per_page })}
            />

            <div className="ml-2">
              <ExportButton
                filename={`matrix-fulfilment-${tabIndex}`}
                tableRef={tableRef}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {isLoading ? (
            <div className="d-flex justify-content-center py-5">
              <Spinner color="primary.600" />
            </div>
          ) : (
            <FulfilmentTable
              matrix={filteredMatrix}
              filters={{ ...filters, tabIndex }}
              setFilters={setFilters}
              tableRef={tableRef}
            />
          )}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default Fulfilment
