export enum Role {
  // Suplias staff roles
  Admin = 'administrator.administrator',
  Staff = 'manager.staff',
  Accountant = 'manager.accountant',
  Buyer = 'manager.buyer',
  WarehouseOfficer = 'manager.warehouse_officer',
  CustomerSupport = 'manager.customer_support',

  // Seller staff roles
  SellerManager = 'sales_officer.manager',
  SellerRetailManager = 'sales_officer.rem',
  SellerRep = 'sales_officer.rep',
  SellerBA = 'sales_officer.ba',

  // Others
  Agent = 'agent.agent',
}

export const supliasStaff = [
  Role.Admin,
  Role.Staff,
  Role.WarehouseOfficer,
  Role.Accountant,
]

export const sellerStaff = [Role.SellerManager, Role.SellerRep, Role.SellerBA]

export const everyone = [...supliasStaff, ...sellerStaff]
