import React from 'react'
import useToast from './useToast'

const useOnline = (): any => {
  const { toast, closeAllToasts } = useToast()
  const [isOnline, setIsOnline] = React.useState(navigator.onLine)

  React.useEffect(() => {
    window.addEventListener('online', () => {
      setIsOnline(true)
      closeAllToasts()
    })
    window.addEventListener('offline', () => {
      toast({
        status: 'error',
        description: `You're not connected to the internet.`,
        isClosable: true,
      })
    })
  }, [toast, closeAllToasts])

  return isOnline
}

export default useOnline
