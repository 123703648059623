import React from 'react'

import { Button, Spinner } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { Container, Card } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import BrandsTable from '../components/BrandsTable'
import BrandsTableFilter from '../components/BrandsTableFilter'

import useFetch from 'src/core/hooks/useFetch'
import usePermission from 'src/core/hooks/usePermission'
import useFilterBrands from '../hooks/useFilterBrands'

import { BrandService, BrandServiceProps } from '../brand.service'
import { IBrand } from '../brand.type'
import { route } from 'src/core/helpers/route.helper'

const brandService = new BrandService()

const Brands: React.FC = () => {
  const { userCan } = usePermission()

  const [brands, isLoading, , setBrands] = useFetch<
    IBrand[],
    BrandServiceProps
  >(brandService, 'fetch')

  const { filteredBrands, filters, setFilters, activeFilters, setPage } =
    useFilterBrands(brands)

  return (
    <Layout headerProps={{ title: 'Brands' }}>
      <Container>
        <Card>
          <Card.Header>
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div>
                  {filteredBrands
                    ? `${filteredBrands.length} ${pluralize(
                        'brand',
                        filteredBrands.length
                      )}`
                    : '0 brands'}
                </div>
                <div className="ml-3">
                  <SearchInput
                    placeholder="Search brands..."
                    value={filters.search}
                    onChange={(search) => setFilters({ search, page: 1 })}
                    isDisabled={!filteredBrands}
                  />
                </div>
              </div>

              {userCan('create_brand') && (
                <div>
                  <Link to={route('brand_create')}>
                    <Button
                      size="sm"
                      leftIcon={<FiPlus size={16} />}
                      colorScheme="primary"
                    >
                      Create Brand
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </Card.Header>

          <Card.Header>
            <BrandsTableFilter
              filters={filters}
              setFilters={setFilters}
              activeFilters={activeFilters}
            />
          </Card.Header>

          <Card.Body className="px-0 py-0">
            {isLoading && (
              <div className="d-flex justify-content-center py-5">
                <Spinner color="primary.600" />
              </div>
            )}

            {!isLoading && brands?.length ? (
              <BrandsPageContext.Provider
                value={{
                  brands,
                  setBrands,
                  filteredBrands,
                  filters,
                  setFilters,
                  activeFilters,
                  page: filters.page,
                  setPage,
                }}
              >
                <BrandsTable />
              </BrandsPageContext.Provider>
            ) : null}
          </Card.Body>
        </Card>
      </Container>
    </Layout>
  )
}

export const BrandsPageContext = React.createContext(null)
export default Brands
