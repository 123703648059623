import React from 'react'

import { Switch, Spinner, Flex, Text } from '@chakra-ui/react'

import useMounted from 'src/core/hooks/useMounted'
import useToast from 'src/core/hooks/useToast'

import { IArea } from '../area.type'
import { ILocation } from 'src/applets/location'

import { AreaService } from '../area.service'

const areaService = new AreaService()

interface IProps {
  area: IArea
  location: ILocation
  areaLocation: any
}

const AreaLocationToggle: React.FC<IProps> = ({ area, location, ...props }) => {
  const isMounted = useMounted()
  const { addToast } = useToast()

  const [updating, setUpdating] = React.useState<boolean>(false)
  const [areaLocation, setAreaLocation] = React.useState<any>()

  React.useEffect(() => {
    setAreaLocation(props.areaLocation)
  }, [props.areaLocation])

  const updateAreaLocation = (): void => {
    setUpdating(true)

    const status = areaLocation?.status === 'active' ? 'inactive' : 'active'

    const values: any = {
      area_id: area._id,
      location_id: location._id,
      manufacturer_id: area.manufacturer_id,
      status,
    }

    let type = 'create'
    if (areaLocation) {
      type = 'update'
      values._id = areaLocation._id
    }

    areaService
      .updateLocation(type, values)
      .then((_id) => {
        const updated = { _id, ...areaLocation, status }
        isMounted.current && setAreaLocation(updated)

        addToast('Location successfully updated.', {
          appearance: 'success',
        })
      })
      .catch((error) => {
        addToast(error.message, { appearance: 'error' })
        throw error
      })
      .finally(() => {
        isMounted.current && setUpdating(false)
      })
  }

  return (
    <>
      <Flex
        bg={areaLocation?.status !== 'active' ? 'gray.50' : 'white'}
        border="1px solid"
        borderColor="gray.100"
        borderRadius="10px"
        padding="20px"
        minH="100%"
        transition="all 400ms"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontWeight="medium" mr={2}>
          {location.name}
        </Text>

        {updating ? (
          <Flex>
            <Spinner size="sm" color="primary.600" />
          </Flex>
        ) : (
          <Switch
            size="sm"
            id={location._id}
            name={location._id}
            isChecked={areaLocation?.status === 'active'}
            onChange={() => updateAreaLocation()}
          />
        )}
      </Flex>
    </>
  )
}

export default AreaLocationToggle
