import React from 'react'

import { Button, Box, Text, Flex } from '@chakra-ui/react'
import { Card } from 'react-bootstrap'
import { FiCopy, FiEdit3 } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { route } from 'src/core/helpers/route.helper'

import AreaDuplicateForm from './AreaDuplicateForm'
import { IArea } from '../area.type'

interface IProps {
  area: IArea
}

const AreaDetails: React.FC<IProps> = ({ area }) => {
  const history = useHistory()
  const [duplicateMode, setDuplicateMode] = React.useState<boolean>(false)

  return (
    <>
      <Card>
        <Card.Body>
          <Box mb={4}>
            <Text fontSize="lg" fontWeight="medium">
              {area.name}
            </Text>
          </Box>

          <Box mb={4}>
            <Text fontSize="sm" fontWeight="medium" color="gray.600">
              Manufacturer
            </Text>
            <Text>{area.manufacturer.name}</Text>
          </Box>

          <Box mb={4}>
            <Text fontSize="sm" fontWeight="medium" color="gray.600">
              Distributor
            </Text>
            <Text>{area?.seller?.name}</Text> <br />
          </Box>

          <Flex alignItems="center">
            <Button
              size="sm"
              leftIcon={<FiEdit3 />}
              onClick={() => {
                history.push(
                  route('area_update', {
                    id: area._id,
                  })
                )
              }}
            >
              Edit
            </Button>

            <Button
              ml={3}
              size="sm"
              leftIcon={<FiCopy size={16} />}
              onClick={() => setDuplicateMode(true)}
            >
              Duplicate
            </Button>
          </Flex>
        </Card.Body>
      </Card>

      <AreaDuplicateForm
        area={area}
        open={duplicateMode}
        onOpen={setDuplicateMode}
      />
    </>
  )
}

export default AreaDetails
