export const warehouseLang = {
  shipment: 'Shipment',
  stock: 'Stock Transfer',
  shipment_in: 'Receive Shipment',
  shipment_out: 'Reject Shipment',
  stock_in: 'Return Stock',
  stock_out: 'Issue Stock',
  current_inventory: 'Current Inventory',
  shipment_received: 'Shipment Received',
  shipment_rejected: 'Shipment Rejected',
  stock_issued: 'Stock Issued',
  stock_returned: 'Stock Returned',
  sales: 'Sales',
}
