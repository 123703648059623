import { CoreService, CoreServiceProps } from 'src/core/services'
import { IWarehouseSalesReport } from '.'
import { IWarehouse } from './warehouse.type'

export class WarehouseService extends CoreService<IWarehouse> {
  constructor() {
    super('warehouse')
  }

  getValue(warehouseId): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const cacheKey = `warehouse_value_${warehouseId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .get(`warehouse/value?_id=${warehouseId}`)
            .then(({ data }) => {
              if (data.code === 200) {
                const products = Object.values(data?.data?.products || {})
                this.cache.set(cacheKey, { ...data.data, products })
                resolve({ ...data.data, products })
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchProducts(warehouseId): Promise<any[]> {
    const formData = new FormData()
    formData.append('warehouse_id', warehouseId)

    return new Promise((resolve, reject) => {
      const cacheKey = `warehouse_products_${warehouseId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`warehouse_product/read_by_warehouse_available`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateProducts(type, payload): Promise<any> {
    this.model = 'warehouse_product'

    return new Promise((resolve, reject) => {
      super
        [type](payload)
        .then((data) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => {
          /** Reset to original model */
          this.model = 'warehouse'
        })
    })
  }

  fetchLogs(warehouseId): Promise<any[]> {
    const formData = new FormData()
    formData.append('warehouse_id', warehouseId)

    return new Promise((resolve, reject) => {
      const cacheKey = `warehouse_log_${warehouseId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`warehouse_log/read_by_warehouse`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchReports(params): Promise<any[]> {
    const formData = new FormData()
    formData.append('warehouse_ids[]', params.warehouse_id)
    formData.append('context', params.context)
    formData.append('from_timestamp', params.from_timestamp)
    formData.append('to_timestamp', params.to_timestamp)

    return new Promise((resolve, reject) => {
      const cacheKey = `warehouse_reports_${Object.values(params).join('_')}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http.post(`warehouse_log/report`, formData).then(({ data }) => {
            if (data.code === 200) {
              let entries = []
              const products = Object.values(data.data)

              products.forEach((product: any) => {
                const productEntries = product.entries.map((entry) => {
                  return { ...entry, product: product.product }
                })
                entries = [...entries, ...productEntries]
              })

              this.cache.set(cacheKey, entries)
              resolve(entries)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchSalesReport(
    params
  ): Promise<{ total: number; products: IWarehouseSalesReport[] }> {
    const formData = new FormData()
    formData.append('warehouse_ids[]', params.warehouse_id)
    formData.append('from_timestamp', params.from_timestamp)
    formData.append('to_timestamp', params.to_timestamp)

    return new Promise((resolve, reject) => {
      const cacheKey = `warehouse_sales_reports_${Object.values(params).join(
        '_'
      )}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http.post(`warehouse_log/sales`, formData).then(({ data }) => {
            if (data.code === 200) {
              const products = Object.values(data?.data?.products || {})
              data.data = { ...data.data, products }
              this.cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export const warehouseService = new WarehouseService()
export type WarehouseServiceProps = CoreServiceProps | keyof WarehouseService
