import React from 'react'
import getObjectPath from 'lodash/get'

export interface IExportableColumn<T = any> {
  label: string
  accessor: string
  selected?: boolean
  type?: 'default' | 'currency'
  modifier?: (value: any, payload: T) => string
}

export interface IUseExportableColumns {
  columns: IExportableColumn[]
  toggleColumn: (label: string) => void
  data?: any[]
}

export const useExportable = (
  dataSet,
  initialColumns
): IUseExportableColumns => {
  const [columns, setColumns] =
    React.useState<IExportableColumn[]>(initialColumns)

  const toggleColumn: IUseExportableColumns['toggleColumn'] = (label) => {
    const newColumns = columns.map((column) => {
      if (column.label === label) {
        column.selected = column?.selected ? false : true
      }
      return column
    })

    setColumns(newColumns)
  }

  // Sync columns with initialColumns
  React.useEffect(() => {
    setColumns(initialColumns)
  }, [initialColumns])

  const data = React.useMemo(() => {
    let data = dataSet || []

    if (data?.length && columns?.length) {
      data = data.map((payload) => {
        const newItem = {}
        const selectedColumns = columns.filter((column) => {
          return column?.selected
        })

        selectedColumns.forEach((column) => {
          let value = getObjectPath(payload, column.accessor, '-')
          if (column?.modifier) {
            value = column.modifier(value, payload)
          }
          newItem[column.label] = value
        })

        return newItem
      })
    }

    return data
  }, [dataSet, columns])

  return { columns, toggleColumn, data }
}

export default useExportable
