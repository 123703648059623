import React from 'react'

import { Button, VStack, StackDivider } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import {
  FormikField,
  FormikRadioGroup,
  FormStack,
  ImageUploader,
} from 'src/core/components'
import useToast from 'src/core/hooks/useToast'

import { manufacturerService } from '../manufacturer.service'
import { uploadService } from 'src/applets/upload'
import { IManufacturer } from '../manufacturer.type'
import { IStoreState } from 'src/bootstrap/store/types'
import { route } from 'src/core/helpers/route.helper'

interface IProps extends RouteComponentProps {
  type?: string
  manufacturer?: IManufacturer
  onUpdate?: (updatedValues: IManufacturer) => void
}

const ManufacturerForm: React.FC<IProps> = ({
  type,
  manufacturer,
  onUpdate,
}) => {
  const history = useHistory()
  const { addToast } = useToast()
  const user = useSelector((state: IStoreState) => state.user)

  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const formConfig = {
    initialValues: {
      name: manufacturer?.name || '',
      status: manufacturer?.status || 'active',
      email: manufacturer?.email || '',
      address: manufacturer?.address || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
      email: Yup.string().required('Email field is required.'),
      address: Yup.string().required('Address field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        ...values,
      }

      if (type === 'update') {
        finalValues._id = manufacturer._id
        finalValues.user_id = manufacturer.user_id // ask Stephen why the hell this is happening
        finalValues.upload_id = manufacturer.upload_id
      } else {
        finalValues.user_id = user.user_id // you hear me, ask Stephen
      }

      manufacturerService[type]({ ...finalValues })
        .then((manufacturerId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Manufacturer successfully updated.', {
              appearance: 'success',
            })
            onUpdate(finalValues)
          } else {
            addToast('Manufacturer successfully created.', {
              appearance: 'success',
            })
            history.push(route('manufacturer_update', { id: manufacturerId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const onUpload = (uploadData): Promise<void> => {
    return new Promise((resolve) => {
      uploadService
        .upload(uploadData, `manufacturer_${manufacturer._id}`)
        .then((uploadId) => {
          const updatedManufacturer = {
            _id: manufacturer._id,
            user_id: manufacturer.user_id,
            name: manufacturer.name,
            status: manufacturer.status,
            email: manufacturer.email,
            address: manufacturer.address,
            upload_id: uploadId,
          }

          manufacturerService
            .update(updatedManufacturer)
            .then(() => {
              setIsUploading(false)

              onUpdate(updatedManufacturer)
              addToast('Image upload successful.', {
                appearance: 'success',
              })
              onUpdate(updatedManufacturer)
              resolve()
            })
            .catch((error) => {
              setIsUploading(false)
              addToast(error.message, { appearance: 'error' })
              resolve()
            })
        })
        .catch((error) => {
          setIsUploading(false)
          addToast(error.message, { appearance: 'error' })
          resolve()
        })
    })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            <FormStack label="Status" isRequired>
              <FormikRadioGroup
                name="status"
                options={[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]}
              />
            </FormStack>

            {/* Image */}
            {type === 'update' && manufacturer ? (
              <FormStack label="Image" helperText="Manufacturer logo">
                <ImageUploader
                  uploadPath={
                    manufacturer.upload ? manufacturer.upload.path : ''
                  }
                  onUpload={onUpload}
                />
              </FormStack>
            ) : null}

            {/* Name */}
            <FormStack
              label="Name"
              helperText="Name of Manufacturer"
              isRequired
            >
              <FormikField name="name" type="text" placeholder="ex. Dufil" />
            </FormStack>

            {/* Email */}
            <FormStack
              label="Email"
              helperText="Manufacturer official email"
              isRequired
            >
              <FormikField name="email" type="email" />
            </FormStack>

            {/* Address */}
            <FormStack
              label="Address"
              helperText="Manufacturer HQ office address"
              isRequired
            >
              <FormikField as="textarea" name="address" type="text" rows={3} />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={
              formik.isSubmitting ||
              !formik.dirty ||
              !formik.isValid ||
              isUploading
            }
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Manufacturer' : 'Update Manufacturer'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

ManufacturerForm.defaultProps = {
  type: 'create',
}

export default withRouter(ManufacturerForm)
