import React from 'react'

import { useFetch } from 'src/core/hooks'
import {
  salesOfficerService,
  SalesOfficerServiceProps,
} from '../salesOfficer.service'
import { ISalesOfficer, SalesOfficerRole } from '../salesOfficer.type'

type IUseFetchBuyers = [
  ISalesOfficer[],
  boolean,
  React.Dispatch<ISalesOfficer[]>
]

export const useFetchSalesOfficers = (
  role: SalesOfficerRole,
  condition = true
): IUseFetchBuyers => {
  const [rawSalesOfficers, isLoading, , setSaleOfficers] = useFetch<
    ISalesOfficer[],
    SalesOfficerServiceProps
  >(salesOfficerService, 'fetch', null, condition)

  const salesOfficers = React.useMemo(() => {
    if (rawSalesOfficers?.length) {
      return role
        ? rawSalesOfficers.filter((salesOfficer) => {
            return salesOfficer.role === role
          })
        : rawSalesOfficers
    }

    return []
  }, [rawSalesOfficers, role])

  return [salesOfficers, isLoading, setSaleOfficers]
}
