import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik, ErrorMessage, Form } from 'formik'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import {
  FormikField,
  FormikRadioGroup,
  FormStack,
  Autocomplete,
} from 'src/core/components'

import useFetch from 'src/core/hooks/useFetch'
import useToast from 'src/core/hooks/useToast'

import {
  DistributorService,
  DistributorServiceProps,
} from '../distributor.service'
import { SellerService, SellerServiceProps, ISeller } from 'src/applets/seller'

import { IDistributor } from '../distributor.type'
import { IStoreState } from 'src/bootstrap/store/types'

import { refactorObjects } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'

const distributorService = new DistributorService()
const sellerService = new SellerService()

interface DistributorFormProps {
  type?: 'create' | 'update'
  distributor?: IDistributor
  onUpdate?: (updatedValues: IDistributor) => void
}

const DistributorForm: React.FC<DistributorFormProps> = ({
  type,
  distributor,
  onUpdate,
}) => {
  const history = useHistory()
  const { addToast } = useToast()
  const user = useSelector((state: IStoreState) => state.user)

  const [sellers] = useFetch<ISeller[], SellerServiceProps>(
    sellerService,
    'fetch'
  )
  const sellerOptions = refactorObjects(sellers)

  const [distributorSeller] = useFetch<any, DistributorServiceProps>(
    distributorService,
    'fetchSeller',
    [distributor?._id],
    !!distributor
  )

  const formConfig = {
    initialValues: {
      name: distributor?.name || '',
      status: distributor?.status || 'active',
      address: distributor?.address || '',
      seller:
        distributor && distributorSeller
          ? {
              value: distributorSeller.seller_id,
              label: distributorSeller.seller?.name,
            }
          : null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
      address: Yup.string().required('Address field is required.'),
      seller: Yup.mixed().required('Seller field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const distributorValues: any = {
        name: values.name,
        address: values.address,
        status: values.status,
      }

      if (type === 'update') {
        distributorValues._id = distributor._id
        distributorValues.user_id = distributor.user_id
      } else {
        distributorValues.user_id = user.user_id
      }

      let newDistributorId

      distributorService[type](distributorValues)
        .then((distributorId) => {
          newDistributorId = distributorId

          let sellerUpdateType = 'create'

          const sellerValues: any = {
            distributor_id: distributor?._id || newDistributorId,
            seller_id: values.seller.value,
            status: 'active',
          }

          if (distributorSeller?._id) {
            sellerUpdateType = 'update'
            sellerValues._id = distributorSeller._id
          }

          return distributorService.updateSeller(sellerUpdateType, sellerValues)
        })
        .then(() => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Distributor successfully updated.', {
              appearance: 'success',
            })
            onUpdate(distributorValues)
          } else {
            addToast('Distributor successfully created.', {
              appearance: 'success',
            })
            history.push(route('distributor_update', { id: newDistributorId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Name */}
            <FormStack label="Name" helperText="Name of distributor" isRequired>
              <FormikField name="name" type="text" />
            </FormStack>

            {/* Address */}
            <FormStack
              label="Address"
              helperText="Distributor HQ office address"
              isRequired
            >
              <FormikField as="textarea" name="address" rows={3} />
            </FormStack>

            {/* Hub */}
            <FormStack label="Hub" helperText="Distributor hub" isRequired>
              <Autocomplete
                name="manager"
                options={sellerOptions}
                value={values.seller}
                placeholder="Select hub"
                onBlur={() => {
                  formik.setFieldTouched('seller', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('seller', selected)
                }}
                isInvalid={touched.seller && !!errors.seller}
                isDisabled={!sellerOptions.length}
              />
              <ErrorMessage
                name="seller"
                component="span"
                className="invalid-feedback"
              />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Distributor' : 'Update Distributor'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

DistributorForm.defaultProps = {
  type: 'create',
}

export default DistributorForm
