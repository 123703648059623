import { CoreService, CoreServiceProps } from 'src/core/services'
import { ISalesOfficer } from './salesOfficer.type'

export class SalesOfficerService extends CoreService<ISalesOfficer> {
  constructor() {
    super('sales_officer')
  }
}

export const salesOfficerService = new SalesOfficerService()
export type SalesOfficerServiceProps = CoreServiceProps
