import React from 'react'

import { Button, Heading, Checkbox, Image, useTheme } from '@chakra-ui/react'
import { Container, Box, Flex } from '@chakra-ui/layout'
import { Formik, Form, Field } from 'formik'
import { FiAtSign, FiLock } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'

import { FormikField, FormStack, Card } from 'src/core/components'

import useToast from 'src/core/hooks/useToast'
import { UserService } from '../services'
import { IUser } from '../types'
import { AUTH, SET_USER } from 'src/bootstrap/store/actionTypes'
import { IStoreState } from 'src/bootstrap/store/types'
import { route } from 'src/core/helpers/route.helper'

import Logo from 'src/core/assets/images/suplias-logo.svg'
import LinesBackdrop from 'src/core/assets/images/bg-lines.png'

const userService = new UserService()

const Login: React.FC = () => {
  const { colors } = useTheme()
  const { addToast } = useToast()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(
    (state: IStoreState) => !!state.auth.token
  )

  const formConfig = {
    initialValues: {
      email: '',
      hash: '',
      remember: true,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Email field is required.')
        .email('Email must be valid.'),
      hash: Yup.string().required('Password field is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      userService
        .doLogin(values)
        .then((user: IUser) => {
          setSubmitting(false)
          dispatch({ type: SET_USER, payload: user })
          dispatch({ type: AUTH, payload: { token: user.token } })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
        })
    },
  }

  if (isAuthenticated) {
    return <Redirect to={route('dashboard')} />
  }

  return (
    <Box
      as="section"
      bg={`url(${LinesBackdrop})`}
      backgroundPosition="center bottom"
    >
      <Container py="80px" maxW="430px">
        <Flex flexDirection="column" alignItems="center" mb={30}>
          <Image width="100px" alt="Suplias Logo" src={Logo} />
          <Heading
            as="h4"
            fontSize="2xl"
            fontWeight="medium"
            color="gray.600"
            mt={25}
          >
            Log in to Suplias
          </Heading>
        </Flex>

        <Card padding="20px">
          <Formik
            initialValues={formConfig.initialValues}
            validationSchema={formConfig.validationSchema}
            validateOnMount={true}
            onSubmit={formConfig.onSubmit}
          >
            {({ ...formik }) => (
              <Form onSubmit={formik.handleSubmit}>
                <FormStack orientation="vertical">
                  <FormikField
                    name="email"
                    type="email"
                    prepend={<FiAtSign color={colors.gray['600']} size={16} />}
                    placeholder="Email"
                  />
                </FormStack>

                <FormStack orientation="vertical">
                  <FormikField
                    name="hash"
                    type="password"
                    prepend={<FiLock color={colors.gray['600']} size={16} />}
                    placeholder="Password"
                  />
                </FormStack>

                <Field
                  as={Checkbox}
                  name="remember"
                  isChecked={formik.values.remember}
                  colorScheme="primary"
                  mt={3}
                  display="none"
                >
                  Keep me logged in
                </Field>

                <Button
                  type="submit"
                  colorScheme="primary"
                  mt={5}
                  width="100%"
                  isDisabled={formik.isSubmitting || !formik.isValid}
                  isLoading={formik.isSubmitting}
                  loadingText="Logging you in"
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Box>
  )
}

export default Login
