/**
 *
 * @param searchIn
 * @param searchFor
 */
export const basicSearch = (searchIn: string, searchFor: string): boolean => {
  if (searchIn && typeof searchIn === 'string') {
    let regex
    try {
      regex = new RegExp(searchFor, 'i')
    } catch (error) {
      regex = null
    }
    return regex ? searchIn.search(regex) >= 0 : false
  }
  return false
}
