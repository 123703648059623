import React from 'react'

import { Button, Divider, SimpleGrid, Box } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { OrderGroupService } from '../services'
import { FormikField } from 'src/core/components'

const orderGroupService = new OrderGroupService()

interface IProps {
  type?: 'order' | 'order_group'
  order: any
  setOrder: (arg) => void
  onUpdate: (order: any) => void
}

const OrderMetaForm: React.FC<IProps> = ({ order, setOrder, onUpdate }) => {
  const [meta, setMeta] = React.useState<any>({ ...order?.meta_obj })

  React.useEffect(() => {
    setMeta({ ...order?.meta_obj })
  }, [order?.meta_obj])

  const formConfig = {
    initialValues: {
      discount: meta?.discount?.value || '',
      discount_reason: meta?.discount?.meta || '',
      returns: meta?.returns?.value || '',
      returns_reason: meta?.returns?.meta || '',
      payment_method: meta?.payment_method?.value || '',
      payment_cash: meta?.payment_cash?.value || '',
      payment_transfer: meta?.payment_transfer?.value || '',
      comment: meta?.comment?.value || '',
    },
    validationSchema: Yup.object({
      discount: Yup.number(),
      discount_reason: Yup.string().when('discount', {
        is: (value) => !!value,
        then: Yup.string().required('Discount reason field is required'),
        otherwise: Yup.string().nullable(),
      }),
      returns: Yup.number().positive(),
      returns_reason: Yup.string().when('returns', {
        is: (value) => !!value,
        then: Yup.string().required('Returns reason field is required'),
        otherwise: Yup.string().nullable(),
      }),
      payment_method: Yup.string(),
      payment_cash: Yup.number().when('payment_method', {
        is: (value) => ['cash', 'cash_transfer'].includes(value),
        then: Yup.number().required('Cash amount field is required'),
        otherwise: Yup.number().nullable(),
      }),
      payment_transfer: Yup.number().when('payment_method', {
        is: (value) => ['transfer', 'cash_transfer'].includes(value),
        then: Yup.number().required('Transfer amount field is required'),
        otherwise: Yup.number().nullable(),
      }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const metaArray = []

      Object.keys(values).forEach((key) => {
        let meta = ''
        if (['discount', 'returns'].includes(key)) {
          meta = values[`${key}_reason`]
          delete values[`${key}_reason`]
        }

        metaArray.push({
          tag: key,
          value: values[key],
          meta,
        })
      })

      orderGroupService.updateMeta(order._id, metaArray).then(() => {
        setSubmitting(false)

        const newMeta = []

        metaArray.forEach((meta) => {
          newMeta[meta.tag] = meta
        })

        onUpdate({
          ...order,
          meta_obj: { ...meta, ...newMeta },
        })
      })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ values, handleChange, handleSubmit, ...formik }) => (
        <Modal
          size="lg"
          isOpen={order ? true : false}
          onClose={() => setOrder(null)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Order #{order?.ref} ({order?.buyer?.name})
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <SimpleGrid columns={[1, 2]} spacing="15px">
                {/* discount */}
                <Box>
                  <FormikField type="number" name="discount" label="Discount" />
                </Box>

                {/* discount_reason */}
                <Box>
                  <FormikField
                    as="select"
                    name="discount_reason"
                    label="Discount Reason"
                    isDisabled={!values.discount}
                  >
                    <option value="">Select one</option>
                    <option value="promo">Promo</option>
                    <option value="delivery_discount">Delivery Discount</option>
                    <option value="product_discount">Product Discount</option>
                    <option value="custom_discount">Custom Discount</option>
                    <option value="delivery_fee">Delivery Fee</option>
                  </FormikField>
                </Box>

                {/* returns */}
                <Box>
                  <FormikField
                    type="number"
                    name="returns"
                    label="Returns"
                    prepend="NGN"
                  />
                </Box>

                {/* returns_reason */}
                <Box>
                  <FormikField
                    as="select"
                    name="returns_reason"
                    label="Returns Reason"
                    isDisabled={!values.returns}
                  >
                    <option value="">Select one</option>
                    <option value="rejected_products">Rejected Products</option>
                    <option value="wrong_products">Wrong Products</option>
                    <option value="other">Other</option>
                  </FormikField>
                </Box>
              </SimpleGrid>

              <Divider my={5} />

              {/* payment_method */}
              <SimpleGrid spacing="15px">
                <Box className="mb-3">
                  <FormikField
                    as="select"
                    name="payment_method"
                    label="Payment Method"
                    value={values.payment_method}
                    onChange={(event) => {
                      const { value } = event.target
                      if (value === 'cash') {
                        formik.setFieldValue('payment_transfer', 0)
                      } else if (value === 'transfer') {
                        formik.setFieldValue('payment_cash', 0)
                      }

                      handleChange(event)
                    }}
                  >
                    <option value="">Select one</option>
                    <option value="cash">Cash</option>
                    <option value="transfer">Transfer</option>
                    {meta?.payment_method?.value === 'cash_transfer' && (
                      <option value="cash_transfer">Cash & Transfer</option>
                    )}
                  </FormikField>
                </Box>
              </SimpleGrid>

              {/* amounts */}
              <SimpleGrid columns={[1, 2]} spacing="15px">
                {[
                  { id: 'payment_cash', label: 'Cash Amount' },
                  { id: 'payment_transfer', label: 'Transfer Amount' },
                ].map((option) => (
                  <Box key={option.id}>
                    <FormikField
                      prepend="NGN"
                      type="number"
                      label={option.label}
                      name={option.id}
                      isDisabled={
                        !values.payment_method ||
                        (option.id === 'payment_cash'
                          ? values.payment_method === 'transfer'
                          : values.payment_method === 'cash')
                      }
                      isRequired
                    />
                  </Box>
                ))}
              </SimpleGrid>

              <Divider my={5} />

              {/* comment */}
              <SimpleGrid spacing="15px">
                <Box>
                  <FormikField label="Comment" as="textarea" name="comment" />
                </Box>
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" onClick={() => setOrder(null)}>
                Cancel
              </Button>

              <Button
                ml={3}
                colorScheme="primary"
                isDisabled={
                  formik.isSubmitting || !formik.dirty || !formik.isValid
                }
                onClick={() => handleSubmit()}
                isLoading={formik.isSubmitting}
                loadingText="Updating"
              >
                Update
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Formik>
  )
}

OrderMetaForm.defaultProps = {
  type: 'order_group',
}

export default OrderMetaForm
