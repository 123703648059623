import { lazy } from 'react'

import { IRoute } from 'src/bootstrap/routes'

import Fulfilment from './pages/Fulfilment.page'
import { OrderReconciliation } from './pages/OrderReconciliation'
import OrderValueSummary from './pages/OrderValueSummary.page'
import OrderSummary from './pages/OrderSummary.page'

const OrderGroup = lazy(() => {
  return import(
    /* webpackChunkName: "order_group_page" */ './pages/OrderGroup.page'
  )
})
const OrderGroups = lazy(() => {
  return import(
    /* webpackChunkName: "order_groups_page" */ './pages/OrderGroups.page'
  )
})
const PendingOrders = lazy(() => {
  return import(
    /* webpackChunkName: "pending_orders_page" */ './pages/PendingOrders.page'
  )
})

export const orderRoutes: IRoute[] = [
  {
    id: 'order_fulfilment',
    path: '/order-fulfilment',
    component: Fulfilment,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'order_groups',
    path: '/order-groups',
    component: OrderGroups,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'order_group',
    path: '/order-groups/:id',
    component: OrderGroup,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'pending_orders',
    path: '/pending-orders',
    component: PendingOrders,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'order_value_summary',
    path: '/order-value-summary',
    component: OrderValueSummary,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'order_summary',
    path: '/order-summary',
    component: OrderSummary,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'order_reconciliation',
    path: '/order-reconciliation',
    component: OrderReconciliation,
    requiresAuth: true,
    exact: true,
  },
]

export default orderRoutes
