import React from 'react'

import { Button, Flex, Text, Box, Divider } from '@chakra-ui/react'

import { FormField } from 'src/core/components'
import useMounted from 'src/core/hooks/useMounted'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { undoSlugify } from 'src/core/helpers/filters.helper'

import { IOrderGroup } from '../../order.type'

interface IProps {
  orderGroup: IOrderGroup
  setOrderGroup: (values) => void
  updateAmount: (amount: number) => Promise<any>
  canUpdate?: boolean
}

const OrderGroupTotal: React.FC<IProps> = ({
  orderGroup,
  setOrderGroup,
  updateAmount,
  canUpdate,
}) => {
  const isMounted = useMounted()

  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)
  const [amount, setAmount] = React.useState<number>()

  const chargesBreakdown = React.useMemo(() => {
    let charges_array: any = Object.values(orderGroup?.charges)

    if (charges_array?.length) {
      charges_array = charges_array.sort((a: any, b: any) => {
        return a.index - b.index
      })

      const total_charge = charges_array.shift()
      charges_array.push(total_charge)

      return charges_array
    }
    return []
  }, [orderGroup])

  const doUpdateAmount = (amount): void => {
    setIsUpdating(true)

    updateAmount(amount)
      .then((newAmount) => {
        if (isMounted.current) {
          setOrderGroup({ amount: newAmount })
          setEditMode(false)
        }
      })
      .finally(() => {
        isMounted.current && setIsUpdating(false)
      })
  }

  return (
    <Flex justifyContent="flex-end">
      <Box width="70%" opacity={isUpdating ? 0.5 : 1}>
        {/* Subtotal row with edit */}
        <Flex justifyContent="space-between">
          {!editMode ? (
            <LineItem
              label="Subtotal"
              value={orderGroup?.subtotal}
              onDoubleClick={() => {
                canUpdate && setAmount(orderGroup.subtotal)
                canUpdate && setEditMode(true)
              }}
            />
          ) : (
            <Flex justifyContent="space-between" width="100%">
              <FormField
                name="quantity"
                type="number"
                value={amount}
                prepend="NGN"
                onChange={({ target }) => {
                  setAmount(Number(target.value))
                }}
              />

              <Flex ml={2}>
                {!isUpdating && (
                  <Button
                    variant="outline"
                    onClick={() => {
                      setAmount(orderGroup.amount)
                      setEditMode(false)
                    }}
                  >
                    Cancel
                  </Button>
                )}

                <Button
                  colorScheme="primary"
                  ml={2}
                  onClick={() =>
                    doUpdateAmount(amount + orderGroup.delivery_fee)
                  }
                  isDisabled={
                    isUpdating ||
                    isNaN(amount) ||
                    !amount ||
                    amount === orderGroup.amount - orderGroup.delivery_fee
                  }
                  isLoading={isUpdating}
                  loadingText="Saving"
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>

        {/* Delivery fees  */}
        {chargesBreakdown.map((charge: any) => (
          <React.Fragment key={charge._id}>
            {charge.value !== 0 && (
              <LineItem
                color="gray.600"
                label={charge.title}
                value={charge.value}
              />
            )}
          </React.Fragment>
        ))}

        {/* Custom discount */}
        {!!orderGroup.discount && (
          <LineItem
            color="gray.600"
            label={undoSlugify(orderGroup.discount_reason)}
            value={orderGroup.discount}
          />
        )}

        <Divider borderColor="gray.100" mt={3} mb={2} />

        {/* Invoice total row with edit */}
        <LineItem
          fontWeight="medium"
          label="Invoice Total"
          value={orderGroup.total}
        />

        {/* Invoice total row with edit */}
        {orderGroup.buyer?.ledgers?.wallet?.balance ? (
          <LineItem
            label="Outstanding"
            value={~(orderGroup.buyer?.ledgers?.wallet?.balance || 0)}
          />
        ) : null}

        <Divider borderColor="gray.100" mt={3} mb={2} />

        {/* Amoutn due */}
        <LineItem
          label="Amount Due"
          value={
            orderGroup.total +
            Math.ceil(orderGroup.buyer?.ledgers?.wallet?.balance || 0)
          }
          fontWeight="semibold"
          valueFontSize="lg"
          showCurrency
        />
      </Box>
    </Flex>
  )
}

const LineItem: React.FC<any> = (props) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" w="100%" mt={2}>
      <Text
        color={props.color}
        fontWeight={props.fontWeight}
        textTransform="capitalize"
      >
        {props.label}
      </Text>
      <Text
        color={props.value >= 0 ? props.color : 'critical.600'}
        fontWeight={props.fontWeight}
        fontSize={props.valueFontSize}
        textAlign="right"
      >
        {props.showCurrency && 'NGN'} {formatCurrency(props.value)}
      </Text>
    </Flex>
  )
}

OrderGroupTotal.defaultProps = {
  canUpdate: true,
}

export default OrderGroupTotal
