import React from 'react'

import { Autocomplete, AutocompleteProps } from 'src/core/components'
import { useFetch } from 'src/core/hooks'
import { sortByKey } from 'src/core/helpers'

import { IDistributor } from '../distributor.type'
import { distributorService } from '../distributor.service'

interface IProps extends AutocompleteProps {
  exclude?: string[]
  disableSelectAll?: boolean
}
export const DistributorAutocomplete: React.FC<IProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [distributors] = useFetch<IDistributor[]>(distributorService, 'fetch')

  const distributorOptions = React.useMemo(() => {
    if (distributors?.length) {
      const options = []

      if (!props.isMulti && !props.disableSelectAll) {
        options.push({ value: '*', label: 'All Distributors' })
      }

      const filtered = distributors
        .filter((distributor) => {
          return (
            distributor.status === 'active' &&
            !props?.exclude?.includes(distributor?._id)
          )
        })
        .map((distributor) => ({
          value: distributor._id,
          label: distributor.name,
        }))

      return [...options, ...sortByKey(filtered, 'label')]
    }

    return []
  }, [distributors, props.isMulti, props.disableSelectAll, props?.exclude])

  return (
    <Autocomplete
      size="sm"
      options={distributorOptions}
      value={value}
      placeholder={props.placeholder || 'Select Distributor'}
      onChange={(selected) => onChange(selected)}
      isActive={value?.value !== '*'}
      isDisabled={!distributorOptions || !distributorOptions.length}
      {...props}
    />
  )
}
