import Brands from './pages/Brands.page'
import BrandEditor from './pages/BrandEditor.page'

import { IRoute } from 'src/bootstrap/routes'

export const brandRoutes: IRoute[] = [
  {
    id: 'brands',
    path: '/brands',
    component: Brands,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'brand_create',
    path: '/brands/create',
    component: BrandEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'brand_update',
    path: '/brands/:id/update',
    component: BrandEditor,
    requiresAuth: true,
    exact: true,
  },
]

export default brandRoutes
