import React from 'react'

import { Avatar, Button, Box, Flex, Heading, Text } from '@chakra-ui/react'
import { FiUser, FiPhoneCall } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { IDistributorOfficer } from '../distributorOfficer.type'
import { route } from 'src/core/helpers/route.helper'

interface IProps {
  distributorOfficer: IDistributorOfficer
}

const DistributorOfficerDetails: React.FC<IProps> = ({
  distributorOfficer,
}) => {
  return (
    <>
      <DistributorOfficerIntro distributorOfficer={distributorOfficer} />
    </>
  )
}

interface SectionProps {
  distributorOfficer: IDistributorOfficer
}

const DistributorOfficerIntro: React.FC<SectionProps> = ({
  distributorOfficer,
}) => {
  return (
    <Box textAlign="center">
      <Flex justifyContent="center">
        <Avatar name={distributorOfficer?.name || 'Buyer'} />
      </Flex>

      <Heading as="h6" size="md" fontWeight="semibold" mt={3}>
        {distributorOfficer?.name || distributorOfficer?.phone}
      </Heading>

      <Text color="gray.600">{distributorOfficer?.phone}</Text>

      <Box mt={2}>
        <Link
          to={route('distributor_officer_update', {
            id: distributorOfficer._id,
          })}
        >
          <Button size="sm" leftIcon={<FiUser size={14} />} mr={3}>
            Edit
          </Button>
        </Link>

        <a href={`tel:+234${distributorOfficer?.phone.substr(1)}`}>
          <Button size="sm" leftIcon={<FiPhoneCall size={14} />}>
            Call
          </Button>
        </a>
      </Box>
    </Box>
  )
}

export default DistributorOfficerDetails
