import React from 'react'

import { Avatar, Button, Box, Flex, Heading, Text } from '@chakra-ui/react'
import { FiUser, FiMapPin } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { IDistributor } from '../distributor.type'
import { route } from 'src/core/helpers/route.helper'

interface IProps {
  distributor: IDistributor
}

const DistributorDetails: React.FC<IProps> = ({ distributor }) => {
  return (
    <>
      <DistributorIntro distributor={distributor} />
    </>
  )
}

interface SectionProps {
  distributor: IDistributor
}

const DistributorIntro: React.FC<SectionProps> = ({ distributor }) => {
  return (
    <Box textAlign="center">
      <Flex justifyContent="center">
        <Avatar name={distributor?.name || 'Buyer'} />
      </Flex>

      <Heading as="h6" size="md" fontWeight="semibold" mt={3}>
        {distributor?.name || distributor?.address}
      </Heading>

      <Text color="gray.600">{distributor?.address}</Text>

      <Box mt={2}>
        <Link
          to={route('distributor_update', {
            id: distributor._id,
          })}
        >
          <Button size="sm" leftIcon={<FiUser size={14} />} mr={3}>
            Edit
          </Button>
        </Link>

        <a
          href={`https://www.google.com/maps/search/?api=1&query=${distributor?.address}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button size="sm" leftIcon={<FiMapPin size={14} />}>
            Locate
          </Button>
        </a>
      </Box>
    </Box>
  )
}

export default DistributorDetails
