import React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router'

import { ColumnLayout, Layout } from 'src/core/components'
import { route } from 'src/core/helpers'
import { useFetch, usePermission } from 'src/core/hooks'

import WarehouseDetails from '../components/WarhouseDetails'
import { WarehouseProducts } from '../components/WarehouseProduct/WarehouseProducts'
import { warehouseService, WarehouseServiceProps } from '../warehouse.service'
import { IWarehouse } from '../warehouse.type'

const WarehouseProductsPage: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: warehouse_id }: any = useParams()

  const [warehouse, isLoading] = useFetch<IWarehouse, WarehouseServiceProps>(
    warehouseService,
    'fetchById',
    [warehouse_id],
    userCan('update_warehouse')
  )

  return (
    <Layout
      headerProps={{
        title: `${warehouse?.parent.name || '-'} Warehouse`,
        backText: 'Warehouses',
        onBack: () => history.push(route('warehouses')),
      }}
      permission="view_warehouses"
      bodyProps={{ padding: 0, overflowY: 'hidden' }}
    >
      <ColumnLayout templateColumns="2fr 1fr">
        <ColumnLayout.Main padding={0} position="relative">
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && !!warehouse ? (
            <WarehouseProducts warehouse={warehouse} />
          ) : null}
        </ColumnLayout.Main>
        <ColumnLayout.Right bg="white">
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner size="sm" color="primary.600" />
            </Flex>
          )}

          {!isLoading && !!warehouse ? (
            <WarehouseDetails warehouse={warehouse} />
          ) : null}
        </ColumnLayout.Right>
      </ColumnLayout>
    </Layout>
  )
}

export default WarehouseProductsPage
