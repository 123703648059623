import React from 'react'

import { Button, VStack, StackDivider } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import {
  Autocomplete,
  FormikField,
  FormikRadioGroup,
  FormStack,
} from 'src/core/components'

import useMounted from 'src/core/hooks/useMounted'
import useToast from 'src/core/hooks/useToast'

import { route } from 'src/core/helpers/route.helper'
import { LocationService } from 'src/applets/location'
import { WalletService } from 'src/applets/wallet'

import { AgentService } from '../agent.service'
import { IAgent } from '../agent.type'

interface AgentFormProps {
  type?: string
  agent?: IAgent
  onUpdate?: (updatedValues: IAgent) => void
}

const agentService = new AgentService()
const locationService = new LocationService()
const walletService = new WalletService()

export const AgentForm: React.FC<AgentFormProps> = ({
  type,
  agent,
  onUpdate,
}) => {
  const history = useHistory()
  const isMounted = useMounted()
  const { addToast } = useToast()

  const [banks, setBanks] = React.useState<any[]>([])
  const [locations, setLocations] = React.useState<any[]>([])

  React.useEffect(() => {
    isMounted.current = true

    locationService.fetch().then((locations) => {
      const activeLocations = locations
        .filter((location) => location.status === 'active')
        .map((location) => ({
          value: location._id,
          label: location.name,
        }))
      isMounted.current && setLocations(activeLocations)
    })

    walletService.fetchBanks().then((banks) => {
      const activeBanks = banks.map((bank) => ({
        value: bank.code,
        label: bank.name,
      }))
      isMounted.current && setBanks(activeBanks)
    })
  }, [isMounted])

  const formConfig = {
    initialValues: {
      name: agent?.name || '',
      phone: agent?.phone || '',
      status: agent?.status || 'active',
      location: agent
        ? {
            value: agent.location._id,
            label: agent.location.name,
          }
        : '',
      source: agent?.source || '',
      bank_code: agent
        ? {
            value: agent.bank,
            label: agent.bank_name,
          }
        : '',
      account: agent?.account || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Store name field is required.')
        .min(3, 'Store name must be at least 3 characters.'),
      phone: Yup.string()
        .required('Phone number field is required.')
        .matches(
          /^[0][7-9][0-1][0-9]{8}$/,
          'Phone number must be valid. Ex. 08174381261'
        ),
      location: Yup.string().required('Location field is required'),
      source: Yup.string().required('Source field is required'),
      bank_code: Yup.string().when('source', {
        is: 'AGENCY',
        then: Yup.string().nullable(),
        otherwise: Yup.string().required('Bank Name field is required'),
      }),
      account: Yup.string().when('source', {
        is: 'AGENCY',
        then: Yup.string().nullable(),
        otherwise: Yup.string()
          .required('Account No. field is required')
          .min(10, 'Account No. is not valid')
          .max(10, 'Account No. is not valid'),
      }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      let finalValues: any = {}

      if (agent) {
        finalValues._id = agent._id
        finalValues.user_id = agent.user_id
      }

      finalValues = {
        ...finalValues,
        name: values.name,
        phone: values.phone,
        status: values.status,
        source: values.source,
        bank: values.source === 'AGENCY' ? '-' : values.bank_code.value,
        account: values.source === 'AGENCY' ? '-' : values.account,
        location_id: values.location.value,
      }

      agentService[type](finalValues)
        .then((data) => {
          setSubmitting(false)
          // resetForm()

          if (type === 'update') {
            addToast('Agent successfully updated.', { appearance: 'success' })
            onUpdate(finalValues)
          } else {
            addToast('Agent successfully created.', { appearance: 'success' })
            history.push(route('agent_update', { id: data._id }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Phone */}
            <FormStack label="Phone" helperText="Agent phone number" isRequired>
              <FormikField
                name="phone"
                type="text"
                prepend="+234"
                placeholder="ex. 08149108781"
                readOnly={type === 'update'}
              />
            </FormStack>

            {/* Name */}
            <FormStack label="Name" helperText="Agent name" isRequired>
              <FormikField
                name="name"
                type="text"
                placeholder="ex. John Afolabi"
              />
            </FormStack>

            {/* Location */}
            <FormStack label="Location" helperText="Agent location" isRequired>
              <Autocomplete
                name="location"
                options={locations}
                value={values.location}
                placeholder="Select location"
                onBlur={() => {
                  formik.setFieldTouched('location', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('location', selected)
                }}
                isInvalid={touched.location && errors.location ? true : false}
                isDisabled={!locations.length}
              />
            </FormStack>

            {/* Source */}
            <FormStack
              label="Source"
              helperText="How did you hear about Suplias?"
              isRequired
            >
              <FormikField as="select" name="source">
                <option value="">Select source</option>
                <option value="BUYER">Buyer</option>
                <option value="AGENCY">Agency</option>
                <optgroup label="Online">
                  <option value="WHATSAPP">WhatsApp</option>
                  <option value="FACEBOOK">Facebook</option>
                  <option value="TWITTER">Twitter</option>
                </optgroup>
                <optgroup label="Suplias Staff">
                  <option value="RICHARD">Richard</option>
                  <option value="OPEYEMI">Opeyemi</option>
                </optgroup>
                <optgroup label="Others">
                  <option value="SMS">SMS</option>
                </optgroup>
              </FormikField>
            </FormStack>

            {values.source && values.source !== 'AGENCY' && (
              <>
                {/* Bank name */}
                <FormStack
                  label="Bank"
                  helperText="Select bank name"
                  isRequired
                >
                  <Autocomplete
                    name="bank_code"
                    options={banks}
                    value={values.bank_code}
                    placeholder="Select bank"
                    onBlur={() => {
                      formik.setFieldTouched('bank_code', true)
                    }}
                    onChange={(selected) => {
                      formik.setFieldValue('bank_code', selected)
                    }}
                    isInvalid={
                      touched.bank_code && errors.bank_code ? true : false
                    }
                    isDisabled={!banks.length}
                  />
                </FormStack>

                {/* Account number */}
                <FormStack
                  label="NUBAN Account No."
                  helperText={`10-digit bank account number`}
                  isRequired
                >
                  <FormikField
                    name="account"
                    type="text"
                    placeholder="ex. 0000000000"
                  />
                </FormStack>
              </>
            )}
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'signup' ? 'Sign Up' : 'Update'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

AgentForm.defaultProps = {
  type: 'signup',
}
