import React from 'react'

import { Badge, Button } from '@chakra-ui/react'
import { useHistory } from 'react-router'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Thead, Tbody, Tr, Th, Td } from 'src/core/components/Table'
import { route, paginate, trans } from 'src/core/helpers'

import { IManager, IManagerFilters } from '../manager.type'

interface IProps {
  managers: IManager[]
  filters: IManagerFilters
  setFilters: (filters: IManagerFilters) => void
  setPage: (page: number) => void
}

const ManagersTable: React.FC<IProps> = ({ managers, filters, setPage }) => {
  const history = useHistory()

  const paginatedManagers = React.useMemo(() => {
    return managers ? paginate(managers, filters.page, filters.per_page) : []
  }, [filters.page, filters.per_page, managers])

  const openManager = (manager): void =>
    history.push(
      route('manager_update', {
        id: manager._id,
      })
    )

  return (
    <>
      {!paginatedManagers.length ? (
        <ErrorBox summary="No managers found" my={50} />
      ) : null}

      {paginatedManagers?.length ? (
        <>
          <Table isFit>
            <Thead>
              <Tr>
                <Th textAlign="center">#</Th>
                <Th>Name</Th>
                <Th>Role</Th>
                <Th>Phone</Th>
                <Th>Email</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedManagers.map((manager, index) => (
                <Tr key={manager._id} className="default">
                  <Td textAlign="center">
                    {index + 1 + (filters.page - 1) * filters.per_page}
                  </Td>
                  <Td>{manager.name}</Td>
                  <Td>{trans(`manager.${manager.role}`) || '-'}</Td>
                  <Td>{manager.phone}</Td>
                  <Td>{manager.email}</Td>
                  <Td>
                    <Badge
                      colorScheme={
                        manager.status === 'active' ? 'success' : 'critical'
                      }
                    >
                      {manager.status}
                    </Badge>
                  </Td>
                  <Td>
                    <Button
                      mr="-px"
                      size="sm"
                      variant="outline"
                      onClick={() => openManager(manager)}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Pagination
            my={5}
            itemsPerPage={filters.per_page}
            totalItems={managers.length}
            currentPage={filters.page}
            onPageChange={setPage}
            justifyContent="center"
          />
        </>
      ) : null}
    </>
  )
}

export default ManagersTable
