import React from 'react'

import { Checkbox, Text, Spinner, Button, useTheme } from '@chakra-ui/react'
import { Flex, SimpleGrid, Box } from '@chakra-ui/layout'
import { FiCheck, FiChevronRight } from 'react-icons/fi'

import {
  ErrorBox,
  Card,
  CardBody,
  FolderTab,
  FolderTabs,
} from 'src/core/components'
import { quickPluralize } from 'src/core/helpers/filters.helper'
import useToast from 'src/core/hooks/useToast'

import { ForeignerProductsTable } from './ForeignerProductsTable'
import { useForeignerProducts } from '../../hooks'

interface IProps {
  allProducts: any[]
  foreigner: any & {
    products: any[]
    model: string
    service: any
  }
}

/**
 * Manges {model}_product models.
 * For adding or removing products
 **/
export const ForeignerProducts: React.FC<IProps> = (props) => {
  const { toast } = useToast()

  const [view, setView] = React.useState<'all' | 'selected'>('all')

  const [activeManufacturer, setActiveManufacturer] = React.useState<any>()
  const [activeBrand, setActiveBrand] = React.useState<any>()

  const [isCreating, setIsCreating] = React.useState<boolean>(false)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)

  const {
    isLoading,
    manufacturers,
    brands,
    products,
    toggleManufacturerStatus,
    toggleBrandStatus,
    toggleProductStatus,
    getBrandStateProps,
    getManufacturerStateProps,
    resetProducts,
  } = useForeignerProducts(props.allProducts, props.foreigner.products)

  // Set first manufacturer as active
  React.useEffect(() => {
    manufacturers?.length && setActiveManufacturer(manufacturers[0])
  }, [manufacturers])

  const activeManufacturerBrands = React.useMemo(() => {
    return activeManufacturer
      ? brands.filter(
          (brand) => brand.manufacturer_id === activeManufacturer._id
        )
      : brands
  }, [activeManufacturer, brands])

  const activeBrandProducts = React.useMemo(() => {
    return activeBrand
      ? products.filter((product) => product.brand_id === activeBrand._id)
      : products
  }, [activeBrand, products])

  const checkedProducts = React.useMemo(() => {
    return products?.length
      ? products.filter((product) => product?.foreigner?.status === 'active')
      : []
  }, [products])

  const changes = React.useMemo(() => {
    if (products?.length) {
      const changes = products.filter(({ has_changed }) => has_changed)
      return changes
    }

    return []
  }, [products])

  const saveChanges = (): void => {
    const items = changes.map((product) => ({
      ...product?.foreigner,
      product_id: product._id,
      [`${props.foreigner.model}_id`]: props.foreigner?._id,
    }))

    const newItems = items.filter((product) => {
      return !product?._id && product.status === 'active'
    })
    const updatedItems = items.filter((product) => product?._id)

    if (newItems?.length) {
      setIsCreating(true)
      props.foreigner.service
        .updateProducts('createBulk', newItems)
        .then(() => {
          toast({
            description: `${props.foreigner.name} products successfully updated.`,
            status: 'success',
          })
          resetProducts(newItems.map(({ product_id }: any) => product_id))
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
        })
        .finally(() => {
          setIsCreating(false)
        })
    }

    if (updatedItems?.length) {
      setIsUpdating(true)
      props.foreigner.service
        .updateProducts('updateBulk', updatedItems)
        .then(() => {
          toast({
            description: `${props.foreigner.name} products successfully updated.`,
            status: 'success',
          })
          resetProducts(updatedItems.map(({ product_id }: any) => product_id))
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
        })
        .finally(() => {
          setIsUpdating(false)
        })
    }
  }

  return (
    <>
      <Flex justifyContent="space-between">
        <FolderTabs defaultIndex={view} onChange={(index) => setView(index)}>
          <FolderTab index="all" title="All Products" />
          <FolderTab
            index="selected"
            title={`Selected Products (${checkedProducts?.length || '0'})`}
          />
        </FolderTabs>

        <Button
          size="sm"
          colorScheme="primary"
          leftIcon={<FiCheck size={16} />}
          isDisabled={!changes?.length || isCreating || isUpdating}
          onClick={saveChanges}
          isLoading={isCreating || isUpdating}
          loadingText="Saving"
        >
          Save {quickPluralize(changes, 'Change')}
        </Button>
      </Flex>

      {view === 'all' && (
        <Card
          as={SimpleGrid}
          columns={isLoading ? 1 : 3}
          borderTopLeftRadius={0}
        >
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && !manufacturers?.length ? (
            <ErrorBox summary="No products found" my={50} />
          ) : null}

          {!isLoading && manufacturers?.length ? (
            <>
              {/* Manufacturers */}
              <Box>
                <CardBody maxHeight="450px" overflowY="scroll">
                  <Text
                    variant="tinyTitle"
                    textTransform="uppercase"
                    letterSpacing={0.4}
                    mb={5}
                  >
                    Manufacturers
                  </Text>

                  {manufacturers.map((manufacturer) => (
                    <ParentItem
                      key={manufacturer._id}
                      item={manufacturer}
                      isActive={manufacturer._id === activeManufacturer?._id}
                      setActive={(manufacturer) => {
                        setActiveManufacturer(manufacturer)
                        setActiveBrand(null)
                      }}
                      onChange={toggleManufacturerStatus}
                      // Adds isChecked & isIndeterminate
                      {...getManufacturerStateProps(manufacturer)}
                    />
                  ))}
                </CardBody>
              </Box>

              {/* Brands */}
              <Box borderLeft="1px solid" borderColor="gray.100">
                <CardBody maxHeight="450px" overflowY="scroll">
                  <Text
                    variant="tinyTitle"
                    textTransform="uppercase"
                    letterSpacing={0.4}
                    mb={5}
                  >
                    {activeManufacturer?.name} Brands
                  </Text>

                  {activeManufacturerBrands.map((brand) => (
                    <ParentItem
                      key={brand._id}
                      item={brand}
                      isActive={brand._id === activeBrand?._id}
                      setActive={setActiveBrand}
                      onChange={toggleBrandStatus}
                      // Adds isChecked & isIndeterminate
                      {...getBrandStateProps(brand)}
                    />
                  ))}
                </CardBody>
              </Box>

              {/* Products */}
              <Box borderLeft="1px solid" borderColor="gray.100">
                {activeBrand ? (
                  <CardBody maxHeight="450px" overflowY="scroll">
                    <Text
                      variant="tinyTitle"
                      textTransform="uppercase"
                      letterSpacing={0.4}
                      mb={5}
                    >
                      {activeBrand?.name} Products
                    </Text>

                    {activeBrandProducts.map((product) => {
                      const isChecked = product.foreigner?.status === 'active'
                      return (
                        <Flex
                          key={product._id}
                          alignItems="center"
                          borderRadius="6px"
                          padding="6px 10px"
                          cursor="default"
                          marginBottom="2px"
                          width="100%"
                          onClick={() => toggleProductStatus(product)}
                        >
                          <Checkbox
                            id={product._id}
                            name={product.name}
                            onChange={() => toggleProductStatus(product)}
                            isChecked={isChecked}
                          />
                          <Text ml={3}>{product.name}</Text>
                        </Flex>
                      )
                    })}
                  </CardBody>
                ) : null}
              </Box>
            </>
          ) : null}
        </Card>
      )}

      {view === 'selected' && (
        <Card overflow="clip">
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && !products?.length ? (
            <ErrorBox summary="No products found" my={50} />
          ) : null}

          {!isLoading && products?.length ? (
            <ForeignerProductsContext.Provider
              value={{
                foreigner: props.foreigner,
                products: checkedProducts,
                toggleProductStatus,
              }}
            >
              <ForeignerProductsTable />
            </ForeignerProductsContext.Provider>
          ) : null}
        </Card>
      )}
    </>
  )
}

const ParentItem: React.FC<any> = ({
  item,
  isActive,
  setActive,
  onChange,
  isChecked,
  isIndeterminate,
}) => {
  const { colors } = useTheme()

  return (
    <Flex
      bg={isActive ? 'primary.50' : 'transparent'}
      alignItems="center"
      justifyContent="space-between"
      borderRadius="6px"
      padding="6px 10px"
      cursor="default"
      marginBottom="2px"
      transition="all 300ms"
      _hover={{
        bg: isActive ? 'primary.50' : 'gray.50',
      }}
    >
      <Flex alignItems="center" width="100%">
        <Checkbox
          id={item._id}
          name={item.name}
          onChange={() => onChange(item, isChecked ? 'deselect' : 'select')}
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
        />

        <Text ml={3} onClick={() => setActive(item)} width="100%">
          {item.name}
        </Text>
      </Flex>

      <FiChevronRight
        color={isActive ? colors.primary['600'] : colors.gray['400']}
        onClick={() => setActive(item)}
      />
    </Flex>
  )
}

export const ForeignerProductsContext = React.createContext(null)
