import React from 'react'

import { Stack, Box, useTheme } from '@chakra-ui/react'
import { Card } from 'react-bootstrap'

import { Layout, ErrorBox } from 'src/core/components'
import NotificationForm from '../components/NotificationForm'

const NotificationWizard: React.FC = () => {
  const { sizes } = useTheme()

  return (
    <Layout
      headerProps={{ title: 'Send Notification' }}
      permission="send_notification"
    >
      <Stack direction={['column', 'column', 'row']} spacing={sizes.gutter.md}>
        <Box flex={8 / 12}>
          <Card>
            <Card.Header>
              <Card.Title>Notification Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <NotificationForm />
            </Card.Body>
          </Card>
        </Box>
        <Box flex={4 / 12}>
          <Card>
            <Card.Header>
              <Card.Title>Notification Preview</Card.Title>
            </Card.Header>
            <Card.Body className="pt-5 pb-4">
              <ErrorBox summary="No notification preview available." />
            </Card.Body>
          </Card>
        </Box>
      </Stack>
    </Layout>
  )
}

export default NotificationWizard
