import Area from './pages/Area.page'
import Areas from './pages/Areas.page'
import AreaEditor from './pages/AreaEditor.page'

import { IRoute } from 'src/bootstrap/routes'

export const areaRoutes: IRoute[] = [
  {
    id: 'areas',
    path: '/areas',
    component: Areas,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'area_create',
    path: '/areas/create',
    component: AreaEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'area_view',
    path: '/areas/:id',
    component: Area,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'area_update',
    path: '/areas/:id/update',
    component: AreaEditor,
    requiresAuth: true,
    exact: true,
  },
]

export default areaRoutes
