import { CoreService, CoreServiceProps } from 'src/core/services'
import { IStoreType } from './storeType.type'

export class StoreTypeService extends CoreService<IStoreType> {
  constructor() {
    super('store_type')
  }
}

export const storeTypeService = new StoreTypeService()
export type StoreTypeServiceProps = CoreServiceProps | keyof StoreTypeService
export default StoreTypeService
