import React from 'react'

export const PrintStyler: React.FC = () => {
  return (
    <style type="text/css" media="print">
      {`
				@media print {
					@page {
						size: auto;
					}
					
					body {
						padding: 0;
						margin: 0;
						text-rendering: optimizeLegibility;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
					}

					* {
						font-size: 13px;
						line-height: 20px;
						font-family: Inter, 'Arial';
					}

					table {
						width: 100%;
					}
					
					th,
					tr td,
					tr.print-border td,
					table {
						border-top: 1px dashed #959595;
						border-collapse: collapse;
					}

					th {
						text-transform: capitalize;
						font-size: 80% !important;
					}

					.print-brand {
						padding: 8px 0;
					}
					.print-brand img {
						text-align: center;
						max-width: 90px;
						width: inherit;
					}
					.print-brand span {
						margin-top: 10px;
						font-size: 15px;
					}
					.print-logo-text {
						font-size: 28px;
					}

					.print-header {
						padding: 4px 0;
					}
					.print-header span {
						display: block;
						text-align: left;
					}

					.print-footer span {
						display: block;
						text-align: center;
						font-weight: 600;
						padding: 6px 0;
					}
					
					td.print-description,
					th.print-description {
						width: 48%;
						max-width: 100px;
						text-align: left;
						word-wrap: break-word;
						vertical-align: top;
					}
					
					td.print-quantity,
					th.print-quantity {
						text-align: center;
						word-break: break-all;
						vertical-align: top;
					}

					td.print-rate,
					th.print-rate {
						text-align: right;
						word-break: break-all;
						vertical-align: top;
					}
					
					td.print-amount,
					th.print-amount {
						text-align: right;
						word-break: break-all;
						vertical-align: top;
					}
					
					.print-block {
						display: block;
					}

					.print-left {
						text-align: left;
						align-content: left;
					}

					.print-center {
						text-align: center;
						align-content: center;
					}

					.print-right {
						text-align: right;
						align-content: right;
					}

					.print-capitalize {
						text-transform: capitalize;
					}
					
					@media print {
						.print-hidden,
						.print-hidden * {
								display: none !important;
						}

						.print-transparent {
							opacity: 0;
						}
					}
				}
			`}
    </style>
  )
}

export default PrintStyler
