import { CoreService, CoreServiceProps } from 'src/core/services'
import { ICategory } from './category.type'

export class CategoryService extends CoreService<ICategory> {
  constructor() {
    super('category')
  }

  fetchProducts(categoryId): Promise<any[]> {
    const formData = new FormData()
    formData.append('category_id', categoryId)

    return new Promise((resolve, reject) => {
      const cacheKey = `category_products_${categoryId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`category_product/read_by_category`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateProducts(type, payload): Promise<any> {
    this.model = 'category_product'

    return new Promise((resolve, reject) => {
      super
        [type](payload)
        .then((data) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => {
          /** Reset to original model */
          this.model = 'category'
        })
    })
  }
}

export const categoryService = new CategoryService()
export type CategoryServiceProps = CoreServiceProps | keyof CategoryService
