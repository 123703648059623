import React from 'react'

import { Autocomplete, AutocompleteProps } from 'src/core/components'
import { DistributorAutocomplete } from 'src/applets/distributor'
import {
  SalesOfficerAutocomplete,
  SalesOfficerRole,
} from 'src/applets/salesOfficer'
import { SellerAutocomplete } from 'src/applets/seller'

import { PurchaseOrderPartyType } from '../purchaseOrder.type'

export interface PurchaseOrderPartyAutocompleteType extends AutocompleteProps {
  partyType?: PurchaseOrderPartyType | '*'
  disableSelectAll?: boolean
}

type Type = PurchaseOrderPartyAutocompleteType

export const PurchaseOrderPartyAutocomplete: React.FC<Type> = (props) => {
  const { partyType, ...autocompleteProps } = props

  switch (partyType) {
    case PurchaseOrderPartyType.Distributor:
      return <DistributorAutocomplete {...autocompleteProps} />
    case PurchaseOrderPartyType.SalesOfficer:
      return (
        <SalesOfficerAutocomplete
          role={SalesOfficerRole.Rep}
          {...autocompleteProps}
        />
      )
    case PurchaseOrderPartyType.Seller:
      return <SellerAutocomplete {...autocompleteProps} />
    default:
      return <Autocomplete size={autocompleteProps.size || 'sm'} value="" />
  }
}
