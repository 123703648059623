import React from 'react'

import { Badge, Button } from '@chakra-ui/react'
import { Table } from 'react-bootstrap'
import { ErrorBox, Pagination } from 'src/core/components'

import { IDistributor } from '../distributor.type'

import { paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import { Text } from '@chakra-ui/layout'
import { Link } from 'react-router-dom'

interface IProps {
  distributors: IDistributor[]
  filters: any
  setFilters: (filters: any) => void
}

const DistributorsTable: React.FC<IProps> = ({
  distributors,
  filters,
  setFilters,
}) => {
  const paginatedDistributors = React.useMemo(() => {
    return distributors ? paginate(distributors, filters.page) : []
  }, [filters.page, distributors])

  return (
    <>
      {!paginatedDistributors.length ? (
        <ErrorBox summary="No distributors found" my={50} />
      ) : null}

      {paginatedDistributors.length ? (
        <div className="table-wrapper padded">
          <Table hover responsive>
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Name</th>
                <th>Seller</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedDistributors.map((distributor, index) => (
                <tr key={distributor._id}>
                  <td className="align-middle text-center">
                    {index + 1 + (filters.page - 1) * 10}
                  </td>
                  <td className="align-middle">
                    <Text>{distributor.name}</Text>
                  </td>
                  <td className="align-middle">
                    {distributor?.seller?.name || '-'}
                  </td>
                  <td className="align-middle">
                    <Badge
                      colorScheme={
                        distributor.status === 'active' ? 'success' : 'critical'
                      }
                    >
                      {distributor.status}
                    </Badge>
                  </td>
                  <td>
                    <Link
                      to={route('distributor', {
                        id: distributor._id,
                        tab: 'balance',
                      })}
                    >
                      <Button size="sm" variant="outline">
                        View
                      </Button>
                    </Link>
                    <Link
                      to={route('distributor_products', {
                        id: distributor._id,
                      })}
                    >
                      <Button size="sm" variant="outline" ml={2}>
                        Products
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}

      <Pagination
        my={5}
        itemsPerPage={10}
        totalItems={distributors.length}
        currentPage={filters.page}
        onPageChange={(page) => setFilters({ page })}
      />
    </>
  )
}

export default DistributorsTable
