import React from 'react'
import { Flex, Box, Text, HStack, Spinner } from '@chakra-ui/react'
import { FiArrowRight } from 'react-icons/fi'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { Layout } from 'src/core/components'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'src/core/components/Card'

import {
  formatCurrency,
  quickPluralize,
  route,
  timestampToDate,
  trans,
  useQuery,
} from 'src/core/helpers'
import {
  purchaseOrderConfigs,
  PurchaseOrderForm,
  PurchaseOrderTag,
} from 'src/applets/purchaseOrder'
import { usePurchaseOrder } from 'src/applets/purchaseOrder/hooks/usePurchaseOrder'
import { PurchaseOrderItems } from 'src/applets/purchaseOrder/components/PurchaseOrderItems'
import { InventoryManagerSteps } from '../components/Inventory'
import { ExecutePurchaseOrderButtons } from 'src/applets/purchaseOrder/components/ExecutePurchaseOrderButtons'

export enum Step {
  Create,
  AddProducts,
  Complete,
}

const InventoryManager: React.FC = () => {
  const history = useHistory()
  const { type, direction }: any = useParams()
  const purchaseOrderId = useQuery().get('purchase_order_id')

  const [step, setStep] = React.useState<Step>(Step.Create)

  const usePurchaseOrderHook = usePurchaseOrder(purchaseOrderId)
  const { isLoading, purchaseOrder, purchaseOrderItems } = usePurchaseOrderHook

  React.useEffect(() => {
    if (purchaseOrderId && purchaseOrder?.tag === PurchaseOrderTag.Receipt) {
      setStep(Step.Complete)
    } else if (purchaseOrderId?.trim?.()) {
      setStep(Step.AddProducts)
    } else {
      setStep(Step.Create)
    }
  }, [purchaseOrderId, purchaseOrder?.tag])

  const initialValues = React.useMemo(() => {
    let _initialValues: any = {}

    if (['in', 'out'].includes(direction)) {
      _initialValues.source_type = purchaseOrderConfigs[type][direction][0]
      _initialValues.destination_type = purchaseOrderConfigs[type][direction][1]
    } else {
      _initialValues = null
    }

    return _initialValues
  }, [type, direction])

  return (
    <Layout
      headerProps={{
        title: trans(`warehouse.${type}_${direction}`),
      }}
      permission={`create_${type}_${direction}`}
    >
      <Box height="100%" maxW="750px" mx="auto" mb="100px">
        {/* Step Tracker */}
        <InventoryManagerSteps
          type={type}
          direction={direction}
          isCreate={step === Step.Create}
          isAddProducts={step === Step.AddProducts}
          isComplete={step === Step.Complete}
        />

        <Card mt={3}>
          {step === Step.Create && (
            <>
              <CardHeader>
                <Text fontWeight="medium">Transfer Details</Text>
              </CardHeader>
              <CardBody pt={0}>
                <PurchaseOrderForm
                  initialValues={initialValues}
                  onCreate={(newPurchaseOrderId) => {
                    history.push(
                      route(
                        'inventory_manager',
                        { type, direction },
                        { purchase_order_id: newPurchaseOrderId }
                      )
                    )
                  }}
                />
              </CardBody>
            </>
          )}

          {step === Step.AddProducts && (
            <Box>
              {!isLoading && purchaseOrder ? (
                <CardHeader>
                  <HStack spacing={2} justifyContent="space-between">
                    <Box>
                      <Text fontWeight="medium">
                        {trans(`warehouse.${type}_${direction}`)}
                      </Text>

                      <Text fontSize="xs" color="gray.600">
                        {timestampToDate(purchaseOrder.timestamp)}
                      </Text>
                    </Box>

                    <HStack ml={3}>
                      <Text fontWeight="medium" color="info.600">
                        {purchaseOrder?.source_obj?.name}
                      </Text>{' '}
                      <Box color="gray.600">
                        <FiArrowRight />
                      </Box>
                      <Text fontWeight="medium" color="success.600">
                        {purchaseOrder?.destination_obj?.name}
                      </Text>
                    </HStack>
                  </HStack>
                </CardHeader>
              ) : null}

              <CardBody>
                <PurchaseOrderItems {...usePurchaseOrderHook} />
              </CardBody>

              {!isLoading && purchaseOrderItems?.length ? (
                <CardFooter>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box>
                      <Text noOfLines={1}>
                        <Text as="span" color="gray.600">
                          Transfer
                        </Text>
                        <Text as="span" ml={1} fontWeight="medium">
                          {quickPluralize(
                            purchaseOrderItems?.length,
                            'product'
                          )}
                        </Text>
                        <Text as="span" color="gray.600" ml={1}>
                          to
                        </Text>
                        <Text as="span" ml={1} fontWeight="medium">
                          {purchaseOrder?.destination_obj?.name || '-'}
                        </Text>
                      </Text>
                      <Text>
                        <Text as="span" color="gray.600">
                          Total Amount:
                        </Text>{' '}
                        <Text as="span" fontWeight="medium">
                          NGN {formatCurrency(purchaseOrder.amount)}
                        </Text>
                      </Text>
                    </Box>

                    {/* Execute PO button */}
                    <Flex>
                      <ExecutePurchaseOrderButtons {...usePurchaseOrderHook} />
                    </Flex>
                  </Flex>
                </CardFooter>
              ) : null}
            </Box>
          )}

          {step === Step.Complete && (
            <CardBody>
              {isLoading ? (
                <Flex justifyContent="center" py={50}>
                  <Spinner size="sm" color="primary.600" />
                </Flex>
              ) : (
                <Box width="400px" mx="auto">
                  <Text>
                    This transfer has been completed.{' '}
                    <Link
                      to={route('purchase_order', { id: purchaseOrder?._id })}
                    >
                      <Text as="span" color="primary.600">
                        View Receipt
                      </Text>
                    </Link>{' '}
                  </Text>
                </Box>
              )}
            </CardBody>
          )}
        </Card>
      </Box>
    </Layout>
  )
}

export default InventoryManager
