import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import DistributorForm from '../components/DistributorForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import {
  DistributorService,
  DistributorServiceProps,
} from '../distributor.service'
import { IDistributor } from '../distributor.type'
import { route } from 'src/core/helpers/route.helper'

const distributorService = new DistributorService()

const DistributorEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: distributor_id }: any = useParams()

  const permission = distributor_id
    ? 'update_distributor'
    : 'create_distributor'

  const [distributor, isLoading, , setDistributor] = useFetch<
    IDistributor,
    DistributorServiceProps
  >(
    distributorService,
    'fetchById',
    [distributor_id],
    !!distributor_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Distributor'
          : distributor?.name || 'New Distributor',
        backText: 'Distributors',
        onBack: () => history.push(route('distributors')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={distributor_id ? 'update' : 'create'}
        model={distributor}
        isLoading={isLoading}
      >
        {distributor_id ? (
          <DistributorForm
            type="update"
            distributor={distributor}
            onUpdate={(updatedValues) => {
              setDistributor({ ...distributor, ...updatedValues })
            }}
          />
        ) : (
          <DistributorForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default DistributorEditor
