import React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router'

import { ErrorBox, Layout } from 'src/core/components'
import { route } from 'src/core/helpers'
import { useFetch, usePermission } from 'src/core/hooks'

import { buyerService, BuyerServiceProps, IBuyer } from 'src/applets/buyer'
import {
  IRoutePlan,
  RoutePlanner,
  routePlanService,
  RoutePlanServiceProps,
} from 'src/applets/routePlan'

import {
  salesOfficerService,
  SalesOfficerServiceProps,
} from '../salesOfficer.service'
import { ISalesOfficer } from '../salesOfficer.type'

const SalesOfficerBuyers: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: category_id }: any = useParams()

  const [salesOfficer] = useFetch<ISalesOfficer, SalesOfficerServiceProps>(
    salesOfficerService,
    'fetchById',
    [category_id],
    userCan('update_category')
  )

  const [buyers, isLoadingBuyers] = useFetch<IBuyer[], BuyerServiceProps>(
    buyerService,
    'fetch'
  )

  const [routePlans, isLoadingRoutePlans] = useFetch<
    IRoutePlan[],
    RoutePlanServiceProps
  >(
    routePlanService,
    'fetchBySalesOfficer',
    [salesOfficer?._id],
    !!salesOfficer
  )

  const isLoading = isLoadingBuyers || isLoadingRoutePlans

  return (
    <Layout
      headerProps={{
        title: `${salesOfficer?.name || 'Sales Officer'} Retailers`,
        backText: 'Sales Officers',
        onBack: () => history.push(route('sales_officers')),
      }}
    >
      {isLoading && (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      )}

      {!isLoading && !buyers?.length ? (
        <ErrorBox summary="No retailers found" my={50} />
      ) : null}

      {!isLoading && buyers?.length ? (
        <RoutePlanner
          allBuyers={buyers}
          routePlans={routePlans}
          salesOfficer={salesOfficer}
        />
      ) : null}
    </Layout>
  )
}

export default SalesOfficerBuyers
