import React from 'react'

import { Button, Spinner, Flex, Box } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'
import PurchaseOrdersTable from '../components/PurchaseOrdersTable'

import { route } from 'src/core/helpers'
import { useFetch, usePermission } from 'src/core/hooks'

import { IPurchaseOrder } from '../purchaseOrder.type'
import {
  purchaseOrderService,
  PurchaseOrderServiceProps,
} from '../purchaseOrder.service'
import { PurchaseOrdersTableFilter } from '../components/PurchaseOrdersTableFilter'
import { useFilterPurchaseOrders } from '../hooks/useFilterPurchaseOrders'

const PurchaseOrders: React.FC = () => {
  const { userCan } = usePermission()

  const [purchaseOrders, isLoading] = useFetch<
    IPurchaseOrder[],
    PurchaseOrderServiceProps
  >(purchaseOrderService, 'fetch', [], userCan('view_purchase_orders'))

  const {
    filters,
    setFilters,
    setPage,
    activeFilters,
    filteredPurchaseOrders,
  } = useFilterPurchaseOrders(purchaseOrders)

  return (
    <Layout
      headerProps={{
        title: `Purchase Orders (${filteredPurchaseOrders?.length || '0'})`,
      }}
      permission="view_purchase_orders"
    >
      <Card>
        {/* Filters */}
        <CardHeader
          as={Flex}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <SearchInput
              placeholder="Search purchase orders"
              value={filters.search}
              onChange={(search) => setFilters({ search, page: 1 })}
              isDisabled={isLoading || !filteredPurchaseOrders}
            />

            <Box ml={3}>
              <PurchaseOrdersTableFilter
                filters={filters}
                setFilters={setFilters}
                activeFilters={activeFilters}
              />
            </Box>
          </Flex>

          {userCan('create_purchase_order') && (
            <Link to={route('purchase_order_create')}>
              <Button
                ml={3}
                size="sm"
                leftIcon={<FiPlus size={16} />}
                colorScheme="primary"
              >
                Create Purchase Order
              </Button>
            </Link>
          )}
        </CardHeader>

        <CardBody p={0}>
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <PurchaseOrdersTable
              purchaseOrders={filteredPurchaseOrders}
              filters={filters}
              setFilters={setFilters}
              setPage={setPage}
            />
          )}
        </CardBody>
      </Card>
    </Layout>
  )
}

export default PurchaseOrders
