import React from 'react'

import { Flex, Box, Spinner, Text } from '@chakra-ui/react'

import {
  ErrorBox,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'src/core/components'
import useMounted from 'src/core/hooks/useMounted'

import ProductTagForm from './ProductTagForm'
import { IProduct, IProductTag } from '../../product.type'
import { ProductService } from '../../product.service'

const productService = new ProductService()

interface IProps {
  product: IProduct
}

export const ProductTags: React.FC<IProps> = ({ product }) => {
  const isMounted = useMounted()
  const [productTags, setProductTags] = React.useState<IProductTag[]>()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(true)
  const [lastUpdated, setLastUpdated] = React.useState<Date>()

  /**
   * Fetch tags for this products
   */
  React.useEffect(() => {
    setIsUpdating(true)

    productService
      .fetchTags(product._id)
      .then((tags) => {
        isMounted.current && setProductTags(tags)
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false)
          setIsUpdating(false)
        }
      })
  }, [product._id, lastUpdated, isMounted])

  return (
    <Card>
      <CardHeader>
        <Text fontWeight="medium">Product Tags</Text>
      </CardHeader>
      <CardBody opacity={isUpdating ? '0.6' : '1'}>
        {isLoading && (
          <Flex justifyContent="center" py={30}>
            <Spinner size="sm" color="primary.600" />
          </Flex>
        )}

        {!isLoading && !productTags?.length ? (
          <ErrorBox summary="No product tags found for this product." my={30} />
        ) : null}

        {!isLoading && productTags?.length
          ? productTags.map((tag, index) => (
              <Box key={tag._id} mb={index !== productTags.length - 1 ? 2 : 0}>
                <ProductTagForm
                  type="update"
                  product={product}
                  productTag={tag}
                  productTags={productTags}
                  onUpdate={() => setLastUpdated(new Date())}
                />
              </Box>
            ))
          : null}
      </CardBody>
      <CardFooter>
        <div>
          {/* Product tag form */}
          {!isLoading && (
            <>
              <ProductTagForm
                product={product}
                productTags={productTags}
                onUpdate={() => setLastUpdated(new Date())}
              />
            </>
          )}
        </div>
      </CardFooter>
    </Card>
  )
}

export default ProductTags
