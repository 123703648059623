import React from 'react'

import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import {
  ResponsiveContainer,
  PieChart,
  Legend,
  Pie,
  Cell,
  Tooltip,
} from 'recharts'

import {
  ErrorBox,
  Row,
  Column,
  Card,
  CardHeader,
  CardBody,
} from 'src/core/components'

import useMounted from 'src/core/hooks/useMounted'

import { DeviceService } from 'src/applets/device'

const deviceService = new DeviceService()

const DeviceVersionCharts: React.FC = () => {
  const isMounted = useMounted()

  const [isLoading, setIsLoading] = React.useState(true)
  const [devices, setDevices] = React.useState<any>([])
  const [zones, setZones] = React.useState<any>([])

  React.useEffect(() => {
    const fetchDevices = (): void => {
      setIsLoading(true)

      deviceService
        .fetchFragmentation()
        .then(({ zones, in_zone_devices }) => {
          isMounted.current && setDevices(in_zone_devices)
          isMounted.current && setZones(zones)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    fetchDevices()
  }, [isMounted])

  const zoneChartColors = ['#148e55', '#d8003c']

  const deviceChartColors = [
    '#d8003c',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#148e55',
  ]

  return (
    <Row mt={5}>
      <Column size={6}>
        <Card>
          <CardHeader>
            <Text fontWeight="medium">User Zones</Text>
          </CardHeader>
          <CardBody>
            {!isLoading && zones.length ? (
              <>
                <ResponsiveContainer height={300}>
                  <PieChart onMouseEnter={() => ({})}>
                    <Legend verticalAlign="top" />
                    <Tooltip />
                    <Pie
                      data={zones}
                      isAnimationActive={false}
                      dataKey="value"
                      legendType="circle"
                      innerRadius="35%"
                      outerRadius="70%"
                      fill="#8884d8"
                      label
                    >
                      {zones.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={zoneChartColors[index]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </>
            ) : null}

            {!isLoading && !zones.length ? (
              <Box my={50}>
                <ErrorBox summary="No device version data found" />
              </Box>
            ) : null}

            {isLoading && (
              <Flex justifyContent="center" py={50}>
                <Spinner color="primary.600" />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Column>

      <Column size={6}>
        <Card>
          <CardHeader>
            <Text fontWeight="medium">User Device Versions</Text>
          </CardHeader>
          <CardBody>
            {!isLoading && devices.length ? (
              <>
                <ResponsiveContainer height={300}>
                  <PieChart onMouseEnter={() => ({})}>
                    <Legend verticalAlign="top" />
                    <Tooltip />
                    <Pie
                      data={devices}
                      isAnimationActive={false}
                      dataKey="value"
                      legendType="circle"
                      innerRadius="35%"
                      outerRadius="70%"
                      fill="#8884d8"
                      label
                    >
                      {devices.map((device, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            device.name === 'unknown'
                              ? '#9da5b0'
                              : deviceChartColors[
                                  index % deviceChartColors.length
                                ]
                          }
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </>
            ) : null}

            {!isLoading && !devices.length ? (
              <Box my={50}>
                <ErrorBox summary="No device version data found" />
              </Box>
            ) : null}

            {isLoading && (
              <Flex justifyContent="center" py={50}>
                <Spinner color="primary.600" />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Column>
    </Row>
  )
}

export default DeviceVersionCharts
