import React from 'react'

import { Checkbox, Text, Spinner, Button, useTheme } from '@chakra-ui/react'
import { Flex, SimpleGrid, Box } from '@chakra-ui/layout'
import { FiCheck, FiChevronRight } from 'react-icons/fi'

import { ErrorBox, FolderTab, FolderTabs } from 'src/core/components'
import { Card, CardBody } from 'src/core/components/Card'
import { quickPluralize } from 'src/core/helpers'
import { useToast } from 'src/core/hooks'

import { IBuyer } from 'src/applets/buyer'
import { ISalesOfficer } from 'src/applets/salesOfficer'

import { RoutePlannerBuyersTable } from './RoutePlannerBuyersTable'
import { routePlanService } from '../routePlan.service'
import { IRoutePlan } from '../routePlan.type'
import { useRoutePlanner } from '../hooks/useRoutePlanner'

interface IProps {
  allBuyers: IBuyer[]
  routePlans: IRoutePlan[]
  salesOfficer: ISalesOfficer
}

export const RoutePlanner: React.FC<IProps> = (props) => {
  const { toast } = useToast()

  const [view, setView] = React.useState<'all' | 'selected'>('all')

  const [activeLocation, setActiveLocation] = React.useState<any>()

  const [isCreating, setIsCreating] = React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

  const {
    isLoading,
    locations,
    buyers,
    toggleLocationStatus,
    toggleBuyerStatus,
    getLocationStateProps,
    resetBuyers,
  } = useRoutePlanner(props?.allBuyers, props?.routePlans)

  // Set first location as active
  React.useEffect(() => {
    locations?.length && setActiveLocation(locations[0])
  }, [locations])

  const activeLocationBuyers = React.useMemo(() => {
    return activeLocation
      ? buyers.filter((buyer) => buyer.location_id === activeLocation._id)
      : buyers
  }, [activeLocation, buyers])

  const checkedBuyers = React.useMemo(() => {
    return buyers?.length
      ? buyers.filter((buyer: any) => buyer?.route_plan?.status === 'active')
      : []
  }, [buyers])

  const changes = React.useMemo(() => {
    if (buyers?.length) {
      const changes = buyers.filter(({ has_changed }) => has_changed)
      return changes
    }

    return []
  }, [buyers])

  const saveChanges = (): void => {
    const routePlans = changes.map((buyer) => ({
      buyer_id: buyer._id,
      sales_officer_id: props.salesOfficer?._id,
      day: 0,
      index: 0,
      ...buyer?.route_plan,
    }))

    const newRoutePlans = routePlans.filter((plan: any) => {
      return !plan._id && plan.status === 'active'
    })
    const deletedRoutePlans = routePlans.filter((plan: any) => {
      return plan._id && plan.status === 'inactive'
    })

    if (newRoutePlans?.length) {
      setIsCreating(true)
      routePlanService
        .createBulk(newRoutePlans)
        .then(() => {
          toast({
            description: `${props?.salesOfficer?.name} retailers successfully updated.`,
            status: 'success',
          })
          resetBuyers(newRoutePlans.map(({ buyer_id }: any) => buyer_id))
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
        })
        .finally(() => {
          setIsCreating(false)
        })
    }

    if (deletedRoutePlans?.length) {
      setIsDeleting(true)

      routePlanService
        .deleteBulk(deletedRoutePlans)
        .then(() => {
          toast({
            description: `${props?.salesOfficer?.name} retailers successfully updated.`,
            status: 'success',
          })
          resetBuyers(deletedRoutePlans.map(({ buyer_id }: any) => buyer_id))
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
        })
        .finally(() => {
          setIsDeleting(false)
        })
    }
  }

  return (
    <>
      <Flex justifyContent="space-between">
        <FolderTabs defaultIndex={view} onChange={(index) => setView(index)}>
          <FolderTab index="all" title="All Buyers" />
          <FolderTab
            index="selected"
            title={`Selected Buyers (${checkedBuyers?.length || '0'})`}
          />
        </FolderTabs>

        <Button
          size="sm"
          colorScheme="primary"
          leftIcon={<FiCheck size={16} />}
          isDisabled={!changes?.length || isCreating || isDeleting}
          onClick={saveChanges}
          isLoading={isCreating || isDeleting}
          loadingText="Saving"
        >
          Save {quickPluralize(changes, 'Change')}
        </Button>
      </Flex>

      {view === 'all' && (
        <Card
          as={SimpleGrid}
          columns={isLoading ? 1 : 2}
          borderTopLeftRadius={0}
        >
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && !locations?.length ? (
            <ErrorBox summary="No retailers found" my={50} />
          ) : null}

          {!isLoading && locations?.length ? (
            <>
              {/* Locations */}
              <Box borderLeft="1px solid" borderColor="gray.100">
                <CardBody maxHeight="450px" overflowY="scroll">
                  <Text
                    variant="tinyTitle"
                    textTransform="uppercase"
                    letterSpacing={0.4}
                    mb={5}
                  >
                    Locations
                  </Text>

                  {locations.map((location) => (
                    <ParentItem
                      key={location._id}
                      item={location}
                      isActive={location._id === activeLocation?._id}
                      setActive={setActiveLocation}
                      onChange={toggleLocationStatus}
                      // Adds isChecked & isIndeterminate
                      {...getLocationStateProps(location)}
                    />
                  ))}
                </CardBody>
              </Box>

              {/* Buyers */}
              <Box borderLeft="1px solid" borderColor="gray.100">
                {activeLocation ? (
                  <CardBody maxHeight="450px" overflowY="scroll">
                    <Text
                      variant="tinyTitle"
                      textTransform="uppercase"
                      letterSpacing={0.4}
                      mb={5}
                    >
                      {activeLocation?.name} Buyers
                    </Text>

                    {activeLocationBuyers.map((buyer: any) => {
                      const isChecked = buyer.route_plan?.status === 'active'
                      return (
                        <Flex
                          key={buyer._id}
                          alignItems="center"
                          borderRadius="6px"
                          padding="6px 10px"
                          cursor="default"
                          marginBottom="2px"
                          width="100%"
                          onClick={() => toggleBuyerStatus(buyer)}
                        >
                          <Checkbox
                            id={buyer._id}
                            name={buyer.phone}
                            onChange={() => toggleBuyerStatus(buyer)}
                            isChecked={isChecked}
                          />
                          <Text ml={3}>
                            {buyer.name || '-'} ({buyer.phone})
                          </Text>
                        </Flex>
                      )
                    })}
                  </CardBody>
                ) : null}
              </Box>
            </>
          ) : null}
        </Card>
      )}

      {view === 'selected' && (
        <Card overflow="clip">
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && !buyers?.length ? (
            <ErrorBox summary="No retailers found" my={50} />
          ) : null}

          {!isLoading && buyers?.length ? (
            <RoutePlannerBuyersTable
              buyers={checkedBuyers}
              toggleBuyerStatus={toggleBuyerStatus}
            />
          ) : null}
        </Card>
      )}
    </>
  )
}

const ParentItem: React.FC<any> = ({
  item,
  isActive,
  setActive,
  onChange,
  isChecked,
  isIndeterminate,
}) => {
  const { colors } = useTheme()

  return (
    <Flex
      bg={isActive ? 'primary.50' : 'transparent'}
      alignItems="center"
      justifyContent="space-between"
      borderRadius="6px"
      padding="6px 10px"
      cursor="default"
      marginBottom="2px"
      transition="all 300ms"
      _hover={{
        bg: isActive ? 'primary.50' : 'gray.50',
      }}
    >
      <Flex alignItems="center" width="100%">
        <Checkbox
          id={item._id}
          name={item.name}
          onChange={() => onChange(item, isChecked ? 'deselect' : 'select')}
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
        />

        <Text ml={3} onClick={() => setActive(item)} width="100%">
          {item.name}
        </Text>
      </Flex>

      <FiChevronRight
        color={isActive ? colors.primary['600'] : colors.gray['400']}
        onClick={() => setActive(item)}
      />
    </Flex>
  )
}
