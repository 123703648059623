import React from 'react'
import { useTheme } from '@chakra-ui/system'
import { Stack, StackProps, Box, BoxProps } from '@chakra-ui/layout'

interface RowProps extends StackProps {
  gutter?: 'sm' | 'md' | 'lg'
}
export const Row: React.FC<RowProps> = ({ children, gutter, ...props }) => {
  const { sizes } = useTheme()

  return (
    <Stack
      direction={['column', 'column', 'row']}
      spacing={sizes.gutter[gutter || 'md']}
      {...props}
    >
      {children}
    </Stack>
  )
}

interface ColumnProps extends BoxProps {
  size?: number
  sizes?: [number, number, number]
}
export const Column: React.FC<ColumnProps> = ({ children, size, ...props }) => {
  return (
    <Box flex={size || 12 / 12} {...props}>
      {children}
    </Box>
  )
}
