import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import { FormStack, FormikField, FormikRadioGroup } from 'src/core/components'
import useToast from 'src/core/hooks/useToast'

import { StoreTypeService } from '../storeType.service'
import { IStoreType } from '../storeType.type'
import { route } from 'src/core/helpers/route.helper'

const storeTypeService = new StoreTypeService()

interface FormProps {
  type?: string
  storeType?: IStoreType
  onUpdate?: (updatedValues: IStoreType) => void
}

const StoreTypeForm: React.FC<FormProps> = ({ type, storeType, onUpdate }) => {
  const { addToast } = useToast()
  const history = useHistory()

  const formConfig = {
    initialValues: {
      name: storeType?.name || '',
      description: storeType?.description || '',
      status: storeType?.status || 'active',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      description: Yup.string()
        .required('Description field is required.')
        .min(3, 'Description must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        ...values,
      }

      if (type === 'update') {
        finalValues._id = storeType._id
      }

      storeTypeService[type](finalValues)
        .then((storeTypeId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Store type successfully updated.', {
              appearance: 'success',
            })
            onUpdate(finalValues)
          } else {
            addToast('Store type successfully created.', {
              appearance: 'success',
            })
            history.push(route('StoreType_update', { id: storeTypeId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Name */}
            <FormStack label="Name" helperText="Store type name" isRequired>
              <FormikField
                name="name"
                type="text"
                placeholder="ex. Food Store"
              />
            </FormStack>

            {/* Name */}
            <FormStack
              label="Description"
              helperText="Store type description"
              isRequired
            >
              <FormikField as="textarea" name="description" />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Store Type' : 'Update Store Type'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

StoreTypeForm.defaultProps = {
  type: 'create',
}

export default StoreTypeForm
