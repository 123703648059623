import React from 'react'
import { Select } from '@chakra-ui/react'
import { FilterMenu } from 'src/core/components'
import { IManagerFilters, ManagerRole } from '../manager.type'
import { trans } from 'src/core/helpers'

interface IProps {
  filters: IManagerFilters
  setFilters: (filters: IManagerFilters) => void
  activeFilters: string[]
}

export const ManagersTableFilter: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
}) => {
  const resetFilters = (): void => {
    setFilters({ status: '*', role: '*' })
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length
            ? `Filtered by: ${activeFilters.join(', ')}`
            : 'Filters'
        }
        resetButtonProps={{ onClick: resetFilters }}
      >
        <FilterMenu.Section label="Status">
          <Select
            size="sm"
            value={filters.status || ''}
            onChange={({ target }: any) => {
              setFilters({ status: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value="active" label="Active" />
            <option value="inactive" label="Inactive" />
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Role">
          <Select
            size="sm"
            value={filters.role || ''}
            onChange={({ target }: any) => {
              setFilters({ role: target.value })
            }}
          >
            <option value="*" label="All" />
            {Object.values(ManagerRole).map((value) => (
              <option key={value} value={value}>
                {trans(`manager.${value}`)}
              </option>
            ))}
          </Select>
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}
