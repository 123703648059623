import React from 'react'

import { Button, Spinner, Flex, Text, Box } from '@chakra-ui/react'
import { Card } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import SellersTable from '../components/SellersTable'

import useFetch from 'src/core/hooks/useFetch'
import useFilter from 'src/core/hooks/useFilter'
import usePermission from 'src/core/hooks/usePermission'

import { ISeller } from '../seller.type'

import { SellerService, SellerServiceProps } from '../seller.service'

import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'
import { quickPluralize } from 'src/core/helpers/filters.helper'

const sellerService = new SellerService()

const Sellers: React.FC = () => {
  const { userCan } = usePermission()

  const { filters, setFilters } = useFilter('sellers')

  const [sellers, isLoading] = useFetch<ISeller[], SellerServiceProps>(
    sellerService,
    'fetch',
    null,
    userCan('view_sellers')
  )

  const filteredSellers = React.useMemo(() => {
    let filtered = sellers || []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((seller) => {
          return (
            basicSearch(seller.name, filters.search) ||
            basicSearch(seller.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [sellers, filters.search])

  return (
    <Layout headerProps={{ title: 'Hubs' }} permission="view_sellers">
      <Card>
        <Card.Header>
          <Flex alignItems="center">
            <Text>{quickPluralize(filteredSellers, 'hub')}</Text>

            <Box ml={3}>
              <SearchInput
                placeholder="Search hubs"
                value={filters.search}
                onChange={(search) => setFilters({ search, page: 1 })}
                isDisabled={isLoading || !filteredSellers}
              />
            </Box>
          </Flex>

          {userCan('create_seller') && (
            <Link to={route('seller_create')}>
              <Button
                size="sm"
                leftIcon={<FiPlus size={16} />}
                colorScheme="primary"
              >
                Create Hub
              </Button>
            </Link>
          )}
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <SellersTable
              sellers={filteredSellers}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default Sellers
