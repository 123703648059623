import React from 'react'

import { Autocomplete, AutocompleteProps } from 'src/core/components'

import useFetch from 'src/core/hooks/useFetch'
import { ILocation } from 'src/applets/location'

import { locationService } from 'src/applets/location'
import { sortByKey } from 'src/core/helpers/filters.helper'

interface IProps extends AutocompleteProps {
  disableSelectAll?: boolean
}

export const LocationAutocomplete: React.FC<IProps> = ({
  value,
  onChange,
  placeholder,
  ...props
}) => {
  const [locations] = useFetch<ILocation[]>(locationService, 'fetch')
  const locationOptions = React.useMemo(() => {
    if (locations?.length) {
      const options = []

      if (!props.isMulti && !props.disableSelectAll) {
        options.push({ value: '*', label: 'All Locations' })
      }

      const filtered = locations
        .filter((location) => location.status === 'active')
        .map((location) => ({
          value: location._id,
          label: location.name,
        }))

      return [...options, ...sortByKey(filtered, 'label')]
    }

    return []
  }, [locations, props.isMulti, props.disableSelectAll])

  return (
    <Autocomplete
      size="sm"
      options={locationOptions}
      value={value || ''}
      placeholder={placeholder || 'Select location'}
      onChange={(selected) => onChange(selected)}
      isActive={value && value.value !== '*' ? true : false}
      isDisabled={!locationOptions || !locationOptions.length}
      {...props}
    />
  )
}

export default LocationAutocomplete
