import React from 'react'

import { FilterMenu } from 'src/core/components'
import { BrandAutocomplete } from 'src/applets/brand'
import { ManufacturerAutocomplete } from 'src/applets/manufacturer'
import { IWarehouseProductFilters } from '../../warehouse.type'

interface IProps {
  filters: IWarehouseProductFilters
  setFilters: (filters: IWarehouseProductFilters) => void
  activeFilters: string[]
}
export const WarehouseProductsTableFilter: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
}) => {
  const resetFilters = (): void => {
    setFilters({
      ...filters,
      manufacturers: '*',
      brands: '*',
    })
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length
            ? `Filtered by: ${activeFilters.join(', ')}`
            : 'Filters'
        }
        resetButtonProps={{ onClick: resetFilters }}
      >
        {/* Manufacturer */}
        <FilterMenu.Section label="Manufacturers">
          <ManufacturerAutocomplete
            value={filters.manufacturers}
            onChange={(manufacturers) => {
              setFilters({
                ...filters,
                manufacturers,
                brands: '*',
              })
            }}
            disableSelectAll={true}
            isMulti
          />
        </FilterMenu.Section>

        <FilterMenu.Section label="Brands">
          <BrandAutocomplete
            value={filters.brands}
            onChange={(brands) => {
              setFilters({ ...filters, brands })
            }}
            manufacturers={
              filters?.manufacturers !== '*' && filters?.manufacturers?.length
                ? filters?.manufacturers.map(({ value }) => value)
                : null
            }
            disableSelectAll={true}
            isMulti
          />
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}
