import React from 'react'

import { FormLabel, FormControl } from '@chakra-ui/form-control'
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/input'
import { InputAddonProps, InputProps } from '@chakra-ui/react'
import { Select } from '@chakra-ui/select'
import { Textarea } from '@chakra-ui/textarea'

import { Autocomplete } from './Autocomplete'

export interface FormFieldProps extends Omit<InputProps, 'as'> {
  as?: 'select' | 'autocomplete' | 'textarea'
  label?: string
  prepend?: string | JSX.Element
  prependProps?: InputAddonProps
  append?: string | JSX.Element
  appendProps?: InputAddonProps
  [value: string]: any
}

export const FormField: React.FC<FormFieldProps> = ({
  as,
  label,
  prepend,
  prependProps,
  append,
  appendProps,
  children,
  isRequired,
  ...props
}) => {
  const Tag: any =
    as === 'textarea'
      ? Textarea
      : as === 'select'
      ? Select
      : as === 'autocomplete'
      ? Autocomplete
      : Input

  return (
    <>
      {!!label && (
        <FormControl mb={0} isRequired={isRequired}>
          <FormLabel mb={1}>{label}</FormLabel>
        </FormControl>
      )}

      <InputGroup size={props?.size}>
        {!!prepend && (
          <InputLeftAddon {...prependProps}>{prepend}</InputLeftAddon>
        )}

        <Tag {...props}>{children}</Tag>

        {!!append && (
          <InputRightAddon {...appendProps}>{append}</InputRightAddon>
        )}
      </InputGroup>
    </>
  )
}

export default FormField
