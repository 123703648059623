import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import useMounted from 'src/core/hooks/useMounted'
import { useQueryParams } from 'src/core/hooks/useQueryParams'

import { IBrand, IBrandFilters } from '../brand.type'
import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'
import { parseQueryParam, serializeQueryParam } from 'src/core/helpers'

interface FilterBrandsHook {
  filteredBrands: IBrand[]
  filters: IBrandFilters
  setFilters: (filters: IBrandFilters) => void
  activeFilters: string[]
  page: number
  setPage: (page: number) => void
}
const useFilterBrands = (brands: any): FilterBrandsHook => {
  const history = useHistory()
  const isMounted = useMounted()

  const [filters, setFilters] = useState<any>({
    status: useQueryParams('status', '*'),
    manufacturers: parseQueryParam(useQueryParams('manufacturers', '')),
    page: Number(useQueryParams('page', 1)),
    search: useQueryParams('search'),
  })

  const updateQueryParams = React.useCallback(
    ({ page, manufacturers, ...params }) => {
      history.push(
        route('brands', null, {
          ...params,
          manufacturers: serializeQueryParam(manufacturers),
          page: page === 1 ? null : page,
        })
      )
    },
    [history]
  )

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      setFilters({
        status: query.get('status') || '*',
        manufacturers: parseQueryParam(query.get('manufacturers')),
        page: Number(query.get('page')) || 1,
        search: query.get('search') || null,
      })
    })

    return () => {
      stopListening() // stop listening for route change
    }
  }, [history, isMounted])

  const filteredBrands = React.useMemo(() => {
    let filtered: IBrand[] = brands || []

    if (filtered.length) {
      if (filters?.status !== '*') {
        filtered = filtered.filter((brand) => {
          return brand.status === filters.status
        })
      }

      if (filters?.search) {
        filtered = filtered.filter((brand) => {
          return basicSearch(brand.name, filters.search)
        })
      }

      if (filters?.manufacturers !== '*') {
        const list = filters.manufacturers?.map?.(({ value }) => {
          return value
        })
        if (list) {
          filtered = filtered.filter((brand) => {
            return list.includes(brand.manufacturer._id)
          })
        }
      }
    }

    return filtered
  }, [brands, filters.status, filters.search, filters.manufacturers])

  const activeFilters = React.useMemo(() => {
    const activeFilters: string[] = []

    if (filters?.status !== '*') {
      activeFilters.push(`Status (${filters.status})`)
    }

    if (filters?.manufacturers !== '*') {
      const list = filters.manufacturers?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Manufacturers (${list})`)
    }

    return activeFilters
  }, [filters])

  return {
    filteredBrands,
    filters,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, ...updated, page: 1 })
    },
    activeFilters,
    page: filters.page,
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
  }
}

export default useFilterBrands
