import React from 'react'

import { Button, Spinner, Flex, Box, Text } from '@chakra-ui/react'
import { Alert, AlertDescription } from '@chakra-ui/alert'
import pluralize from 'pluralize'
import { FiPlus } from 'react-icons/fi'

import { FolderTab } from 'src/core/components'
import usePermission from 'src/core/hooks/usePermission'
import { ProductPicker, OrderProduct } from 'src/applets/product'

import OrderTotal from './OrderTotal'
import { OrderContext } from '../../contexts'
import { OrderTag } from '../../order.type'

export const OrderItems: React.FC = () => {
  const { userCan } = usePermission()

  const {
    order,
    setOrder,
    orderItems,
    addOrderItem,
    updateOrderItem,
    deleteOrderItem,
    isLoadingItems,
    isUpdatingItems,
    updateTag,
    isUpdating,
  } = React.useContext(OrderContext)

  // Product picker modal
  const [pickerModal, setPickerModal] = React.useState<boolean>(false)

  return (
    <>
      {[OrderTag.Cancelled, OrderTag.Deleted].includes(order.tag) && (
        <Alert status="warning" mb={2}>
          <AlertDescription>
            {`This ${order?.manufacturer?.name} order has been ${order.tag}. You can still add items to it, but don't forget to change the status.`}{' '}
            <Text
              as="a"
              fontWeight="medium"
              cursor={isUpdating ? 'not-allowed' : 'pointer'}
              display="inline"
              opacity={isUpdating ? '0.5' : '1'}
              onClick={() => {
                !isUpdating &&
                  updateTag(OrderTag.Completed).then(() => {
                    setOrder({ ...order, tag: OrderTag.Completed })
                  })
              }}
            >
              {isUpdating ? 'Restoring...' : 'Restore'}
            </Text>
          </AlertDescription>
        </Alert>
      )}

      {isLoadingItems && (
        <Flex justifyContent="center" py={50}>
          <Spinner size="sm" color="primary.600" />
        </Flex>
      )}

      {!isLoadingItems && !orderItems?.length ? (
        <>
          {order && userCan('update_order') ? (
            <Box
              border="1px solid"
              borderColor="gray.100"
              padding="0 6px 6px 6px"
              borderRadius="6px"
            >
              <Box py={2}>
                <Flex justifyContent="center">
                  <Button
                    size="sm"
                    variant="ghost"
                    leftIcon={<FiPlus size={16} />}
                    onClick={() => setPickerModal(true)}
                    isDisabled={isLoadingItems || isUpdatingItems}
                  >
                    Add {order?.manufacturer?.name} Product
                  </Button>
                </Flex>
              </Box>
            </Box>
          ) : null}
        </>
      ) : null}

      {!isLoadingItems && orderItems?.length ? (
        <>
          <Flex alignItems="center" justifyContent="space-between">
            <FolderTab
              title={`${order?.manufacturer?.name}`}
              meta={`${orderItems.length} ${pluralize(
                'Item',
                orderItems.length
              )}`}
            />

            {order && userCan('update_order') ? (
              <Button
                size="sm"
                variant="ghost"
                leftIcon={<FiPlus size={16} />}
                onClick={() => setPickerModal(true)}
                isDisabled={isLoadingItems || isUpdatingItems}
              >
                Add Product
              </Button>
            ) : null}
          </Flex>

          <Box border="1px solid" borderColor="gray.100" borderRadius="6px">
            {orderItems.map((item, index) => (
              <OrderProduct
                key={index}
                item={item}
                parent={order}
                setParent={setOrder}
                updateItem={updateOrderItem}
                deleteItem={deleteOrderItem}
              />
            ))}

            {/* Total row with edit */}
            <OrderTotal canUpdate={userCan('update_order')} />
          </Box>
        </>
      ) : null}

      {/* Add product button */}
      {order && orderItems && userCan('update_order') ? (
        <ProductPicker
          isOpen={pickerModal}
          location={order.buyer.location_id}
          manufacturer={order.manufacturer_id}
          selectedItems={orderItems || []}
          addItem={(item) =>
            addOrderItem(item).then((values) => {
              setOrder(values)
            })
          }
          closeModal={() => setPickerModal(false)}
        />
      ) : null}
    </>
  )
}

OrderItems.defaultProps = {
  nested: false,
}

export default OrderItems
