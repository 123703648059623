import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { FormikField, FormikRadioGroup, FormStack } from 'src/core/components'
import useToast from 'src/core/hooks/useToast'

import { ICohort } from '../cohort.type'
import { CohortService } from '../cohort.service'
import { route } from 'src/core/helpers/route.helper'

const cohortService = new CohortService()

interface CohortFormProps {
  type?: string
  cohort?: ICohort
  onUpdate?: (updatedValues: ICohort) => void
}

const CohortForm: React.FC<CohortFormProps> = ({ type, cohort, onUpdate }) => {
  const history = useHistory()
  const { addToast } = useToast()

  const formConfig = {
    initialValues: {
      title: cohort ? cohort.title : '',
      tag: cohort ? cohort.tag : '',
      status: cohort ? cohort.status : 'active',
      description: cohort ? cohort.description : '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Title field is required.')
        .min(3, 'Title must be at least 3 characters.'),
      description: Yup.string().required('Description field is required.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        title: values.title,
        tag: '',
        status: values.status,
        description: values.description,
      }

      if (type === 'update') {
        finalValues._id = cohort._id
      }

      cohortService[type]({ ...finalValues })
        .then((cohortId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Cohort successfully updated.', { appearance: 'success' })
            onUpdate(finalValues)
          } else {
            addToast('Cohort successfully created.', { appearance: 'success' })
            history.push(route('cohort_update', { id: cohortId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Title */}
            <FormStack label="Title" helperText="Title of cohort" isRequired>
              <FormikField
                name="title"
                type="text"
                placeholder="ex. New Users"
              />
            </FormStack>

            {/* Description */}
            <FormStack
              label="Description"
              helperText="Description of cohort"
              isRequired
            >
              <FormikField as="textarea" name="description" rows={3} />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Cohort' : 'Update Cohort'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

CohortForm.defaultProps = {
  type: 'create',
}

export default CohortForm
