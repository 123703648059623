import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import ManagerForm from '../components/ManagerForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { ManagerService, ManagerServiceProps } from '../manager.service'
import { IManager } from '../manager.type'
import { route } from 'src/core/helpers/route.helper'

const managerService = new ManagerService()

const ManagerEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: manager_id }: any = useParams()

  const permission = manager_id ? 'update_manager' : 'signup_manager'

  const [manager, isLoading, , setManager] = useFetch<
    IManager,
    ManagerServiceProps
  >(
    managerService,
    'fetchById',
    [manager_id],
    !!manager_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Manager' : manager?.name || 'New Manager',
        backText: 'Managers',
        onBack: () => history.push(route('managers')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={manager_id ? 'update' : 'create'}
        model={manager}
        isLoading={isLoading}
        isUserModel
      >
        {manager_id ? (
          <ManagerForm
            type="update"
            manager={manager}
            onUpdate={(updatedValues) => {
              setManager({ ...manager, ...updatedValues })
            }}
          />
        ) : (
          <ManagerForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default ManagerEditor
