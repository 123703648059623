import Managers from './pages/Managers.page'
import ManagerEditor from './pages/ManagerEditor.page'

import { IRoute } from 'src/bootstrap/routes'

export const managerRoutes: IRoute[] = [
  {
    id: 'managers',
    path: '/managers',
    component: Managers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'manager_signup',
    path: '/managers/signup',
    component: ManagerEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'manager_update',
    path: '/managers/:id/update',
    component: ManagerEditor,
    requiresAuth: true,
    exact: true,
  },
]

export default managerRoutes
