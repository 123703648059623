import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import BrandForm from '../components/BrandForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { BrandService, BrandServiceProps } from '../brand.service'
import { IBrand } from '../brand.type'
import { route } from 'src/core/helpers/route.helper'

const brandService = new BrandService()

const BrandEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: brand_id }: any = useParams()

  const permission = brand_id ? 'update_brand' : 'signup_brand'

  const [brand, isLoading, , setBrand] = useFetch<IBrand, BrandServiceProps>(
    brandService,
    'fetchById',
    [brand_id],
    !!brand_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Brand' : brand?.name || 'New Brand',
        backText: 'Brands',
        onBack: () => history.push(route('brands')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={brand_id ? 'update' : 'create'}
        model={brand}
        isLoading={isLoading}
      >
        {brand_id ? (
          <BrandForm
            type="update"
            brand={brand}
            onUpdate={(updatedValues) => {
              setBrand({ ...brand, ...updatedValues })
            }}
          />
        ) : (
          <BrandForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default BrandEditor
