import React from 'react'

import { format, fromUnixTime } from 'date-fns'

import { Pagination, ErrorBox, PrintStyler } from 'src/core/components'
import { Table, Thead, Tbody, Th, Tr, Td } from 'src/core/components/Table'

import { paginate } from 'src/core/helpers/filters.helper'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { Text } from '@chakra-ui/layout'

interface IProps {
  items: any
  grandTotal: number
  params: any
  tableRef: any
  tableWrapperRef: any
}

export const OrderValueSummaryTable: React.FC<IProps> = ({
  items,
  grandTotal,
  params,
  tableRef,
  tableWrapperRef,
}) => {
  const PER_PAGE = items?.length
  const [page, setPage] = React.useState<number>(1)

  const paginatedItems = React.useMemo(() => {
    return items ? paginate(items, page, PER_PAGE) : []
  }, [page, items, PER_PAGE])

  return (
    <>
      {!paginatedItems.length ? (
        <ErrorBox summary="Set filters to fetch order sales" my={50} />
      ) : null}

      {paginatedItems.length ? (
        <>
          <div ref={tableWrapperRef}>
            <PrintStyler />

            <Table tableRef={tableRef} cellPadding="2px" isFit hasBorders>
              <Thead>
                <Tr>
                  <Th colSpan={7}>
                    <Text fontSize="sm">
                      {params?.sales_officer?.label || ''} Sales by{' '}
                      <Text as="span" textTransform="capitalize">
                        {params.sales_by}
                      </Text>{' '}
                      for{' '}
                      {format(
                        fromUnixTime(params.from_timestamp),
                        'MMM d; h:mmaaa'
                      )}
                      {' - '}
                      {format(
                        fromUnixTime(params.to_timestamp),
                        'MMM d; h:mmaaa'
                      )}
                    </Text>
                  </Th>
                </Tr>
                <Tr>
                  <Th align="left" textTransform="capitalize">
                    {params.sales_by}
                  </Th>
                  <Th align="right" isNumeric>
                    Cases
                  </Th>
                  <Th align="right" isNumeric>
                    Units
                  </Th>
                  <Th align="right" isNumeric>
                    Amount
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedItems.map((item, index) => {
                  return item.type === 'product' ? (
                    <Tr key={index}>
                      <Td>
                        <Text as="b" fontWeight="medium">
                          {item[params.sales_by].name}
                        </Text>
                      </Td>
                      <Td isNumeric className="print-center">
                        {item.type === 'product'
                          ? Math.floor(item.cases)
                          : null}
                      </Td>
                      <Td isNumeric className="print-center">
                        {item.type === 'product' ? item.units : null}
                      </Td>
                      <Td isNumeric className="print-right">
                        {formatCurrency(item.total)}
                      </Td>
                    </Tr>
                  ) : (
                    <Tr key={index} className="active">
                      <Td>
                        <Text as="b" fontWeight="medium">
                          {item[params.sales_by].name} Total
                        </Text>
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td align="right" isNumeric>
                        <Text as="b" fontWeight="medium">
                          {formatCurrency(item.total)}
                        </Text>
                      </Td>
                    </Tr>
                  )
                })}

                <Tr>
                  <Td colSpan={4}></Td>
                </Tr>
                <Tr className="active">
                  <Td>
                    <b className="font-weight-medium">Total</b>
                  </Td>
                  <Td></Td>
                  <Td></Td>
                  <Td align="right" isNumeric>
                    <b className="font-weight-medium">
                      {formatCurrency(grandTotal)}
                    </b>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </div>

          <Pagination
            my={5}
            itemsPerPage={PER_PAGE}
            totalItems={items?.length}
            currentPage={page}
            onPageChange={(page) => setPage(page)}
          />
        </>
      ) : null}
    </>
  )
}
