import React from 'react'
import { refactorObjects } from 'src/core/helpers/filters.helper'

/**
 * Refactor your object
 * @param data
 * @param pairs  ex: [ ['value', '_id'], ['label', 'name'] ]
 * @returns
 */
export const useRefactorObjects = <
  T = any,
  S = { value: string; label: string }
>(
  data: T[],
  pairs?: [string, string][]
): S[] => {
  const options = React.useMemo(() => {
    return data?.length ? refactorObjects(data, pairs) : []
  }, [data, pairs])

  return options
}

export default useRefactorObjects
