import React from 'react'

import { Button, Box } from '@chakra-ui/react'

import useToast from 'src/core/hooks/useToast'
import { asset } from 'src/core/helpers/file.helper'
import { ImageBox, ImageBoxProps } from 'src/core/components/Image/ImageBox'

interface Props {
  uploadPath?: string
  onUpload: ({ file }: any) => Promise<any>
  imageProps?: ImageBoxProps
}
export const ImageUploader: React.FC<Props> = ({
  uploadPath,
  onUpload,
  imageProps,
}) => {
  const { toast } = useToast()

  const fileInput = React.useRef<any>()

  const [uploadData, setUploadData] = React.useState<{ file: any }>()
  const [imagePreview, setImagePreview] = React.useState<any>()

  /**
   * If current image in image preview has been uploaded
   * or is in the process
   */
  const [isUploaded, setIsUploaded] = React.useState<boolean>(false)
  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const handleFileInputChange = (event): void => {
    event.preventDefault()

    const reader = new FileReader()
    const file = event.target.files[0]

    reader.onloadend = async () => {
      const type = file.type.split('/').pop().toLowerCase()
      const allowedTypes = ['jpeg', 'jpg', 'png']

      if (!allowedTypes.includes(type)) {
        toast({
          description: 'Please select a valid image file.',
          status: 'error',
        })
      } else if (file.size > 4024000) {
        toast({
          description: 'Max Upload size is 4MB only.',
          status: 'error',
        })
      } else {
        setIsUploaded(false)
        setImagePreview(reader.result)
        setUploadData({ file })
        // do the actual upload
      }
    }

    reader.readAsDataURL(file)
  }

  return (
    <Box>
      <input
        type="file"
        className="hidden-input"
        accept="image/jpg, image/jpeg, image/png"
        onChange={handleFileInputChange}
        ref={fileInput}
      />

      {uploadPath || imagePreview ? (
        <Box mb={4}>
          <ImageBox
            src={imagePreview ? imagePreview : asset(uploadPath)}
            width="120px"
            {...imageProps}
          />
        </Box>
      ) : null}

      <Button
        size="sm"
        variant="outline"
        onClick={() => fileInput.current.click()}
        isDisabled={isUploading}
      >
        {uploadPath ? 'Change Image' : 'Upload Image'}
      </Button>

      <Button
        size="sm"
        colorScheme="success"
        ml={2}
        onClick={() => {
          setIsUploading(true)
          onUpload(uploadData).finally(() => {
            setIsUploaded(true)
            setIsUploading(false)
          })
        }}
        isDisabled={
          !uploadData || !uploadData.file || isUploading || isUploaded
        }
        isLoading={isUploading}
        loadingText="Saving"
      >
        Save
      </Button>
    </Box>
  )
}

export default ImageUploader
