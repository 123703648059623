import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik, ErrorMessage } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import {
  Autocomplete,
  FormikRadioGroup,
  FormikField,
  FormStack,
} from 'src/core/components'

import useMounted from 'src/core/hooks/useMounted'
import useToast from 'src/core/hooks/useToast'
import { ILocation } from 'src/applets/location'

import { LocationService } from '../location.service'

import { route } from 'src/core/helpers/route.helper'

const locationService = new LocationService()

interface IProps {
  type?: 'create' | 'update'
  location?: ILocation
  onUpdate?: (updatedValues: ILocation) => void
}

const LocationForm: React.FC<IProps> = ({ type, location, onUpdate }) => {
  const history = useHistory()
  const isMounted = useMounted()
  const { addToast } = useToast()

  const [lgas, setLgas] = React.useState<any>([])
  const [states, setStates] = React.useState<any>([])

  React.useEffect(() => {
    const fetchStates = (): void => {
      locationService.fetchStates().then((states) => {
        states = states.map((state) => ({
          value: state.name,
          label: state.name,
        }))

        isMounted.current && setStates(states)
      })
    }

    const fetchLgas = (): void => {
      locationService.fetchLGAs().then((lgaGroups) => {
        const lgas_array = []

        lgaGroups.forEach((lgaGroup) => {
          lgaGroup.lgas.forEach((lga) => {
            lgas_array.push({
              value: lga,
              label: lga,
              state: lgaGroup.state,
            })
          })
        })

        isMounted.current && setLgas(lgas_array)
      })
    }

    fetchStates()
    fetchLgas()
  }, [isMounted, location])

  const formConfig = {
    initialValues: {
      name: location?.name || '',
      status: location?.status || 'active',
      state: location
        ? {
            value: location.state,
            label: location.state,
          }
        : null,
      lga: location
        ? {
            value: location.lga,
            label: location.lga,
          }
        : null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
      state: Yup.mixed().required('State field is required.'),
      lga: Yup.mixed().required('LGA field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        ...values,
        state: values.state.value,
        lga: values.lga.value,
      }

      if (type === 'update') {
        finalValues._id = location._id
      }

      locationService[type](finalValues)
        .then((locationId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Location successfully updated.', {
              appearance: 'success',
            })
            onUpdate(finalValues)
          } else {
            addToast('Location successfully created.', {
              appearance: 'success',
            })
            history.push(route('location_update', { id: locationId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Name */}
            <FormStack label="Name" helperText="Name of location" isRequired>
              <FormikField name="name" type="text" placeholder="ex. Agege" />
            </FormStack>

            {/* State */}
            <FormStack label="State" helperText="Location state" isRequired>
              <Autocomplete
                name="state"
                options={states}
                value={values.state}
                placeholder="Select state"
                onBlur={() => {
                  formik.setFieldTouched('state', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('state', selected)
                }}
                isInvalid={touched.state && errors.state ? true : false}
                isDisabled={!states.length}
              />
              <ErrorMessage
                name="state"
                component="span"
                className="invalid-feedback"
              />
            </FormStack>

            {/* LGA */}
            <FormStack label="LGA" helperText="Location LGA" isRequired>
              <Autocomplete
                name="lga"
                options={lgas.filter((lga) => {
                  return values.state ? lga.state === values.state.value : []
                })}
                value={values.lga}
                placeholder="Select lga"
                onBlur={() => {
                  formik.setFieldTouched('lga', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('lga', selected)
                }}
                isInvalid={touched.lga && errors.lga ? true : false}
                isDisabled={!values.state || !lgas.length}
              />
              <ErrorMessage
                name="lga"
                component="span"
                className="invalid-feedback"
              />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Location' : 'Update Location'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

LocationForm.defaultProps = {
  type: 'create',
}

export default LocationForm
