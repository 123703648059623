import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik, ErrorMessage, Form } from 'formik'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import { FormikRadioGroup, FormStack, Autocomplete } from 'src/core/components'

import useFetch from 'src/core/hooks/useFetch'
import useToast from 'src/core/hooks/useToast'

import { WarehouseService } from '../warehouse.service'
import { SellerService, SellerServiceProps, ISeller } from 'src/applets/seller'

import { IWarehouse, WarehouseType } from '../warehouse.type'

import { refactorObjects } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import {
  ISalesOfficer,
  salesOfficerService,
  SalesOfficerServiceProps,
} from 'src/applets/salesOfficer'
import {
  distributorService,
  DistributorServiceProps,
  IDistributor,
} from 'src/applets/distributor'

const warehouseService = new WarehouseService()
const sellerService = new SellerService()

interface WarehouseFormProps {
  type?: 'create' | 'update'
  warehouse?: IWarehouse
  onUpdate?: (updatedValues: IWarehouse) => void
}

const WarehouseForm: React.FC<WarehouseFormProps> = ({
  type,
  warehouse,
  onUpdate,
}) => {
  const history = useHistory()
  const { addToast } = useToast()
  const parentTypeOptions = [
    { value: WarehouseType.DA, label: 'Sales Officer' },
    { value: WarehouseType.Seller, label: 'Seller' },
    { value: WarehouseType.Distributor, label: 'Distributor' },
  ]

  const [salesOfficers] = useFetch<ISalesOfficer[], SalesOfficerServiceProps>(
    salesOfficerService,
    'fetch'
  )
  const salesOfficerOptions = refactorObjects(salesOfficers)

  const [sellers] = useFetch<ISeller[], SellerServiceProps>(
    sellerService,
    'fetch'
  )
  const sellerOptions = refactorObjects(sellers)

  const [distributors] = useFetch<IDistributor[], DistributorServiceProps>(
    distributorService,
    'fetch'
  )
  const distributorOptions = refactorObjects(distributors)

  const parentOptions = React.useCallback(
    (parent_type) => {
      switch (parent_type) {
        case WarehouseType.DA:
          return salesOfficerOptions
        case WarehouseType.Seller:
          return sellerOptions
        case WarehouseType.Distributor:
          return distributorOptions
        default:
          return []
      }
    },
    [salesOfficerOptions, sellerOptions, distributorOptions]
  )

  const parentObject = React.useCallback(
    (parent_type, parent_id) => {
      switch (parent_type) {
        case WarehouseType.DA:
          return salesOfficers.find(
            (salesOfficer) => salesOfficer._id === parent_id
          )
        case WarehouseType.Seller:
          return sellers.find((seller) => seller._id === parent_id)
        case WarehouseType.Distributor:
          return distributors.find(
            (distributor) => distributor._id === parent_id
          )
        default:
          return null
      }
    },
    [salesOfficers, sellers, distributors]
  )

  const formConfig = {
    initialValues: {
      status: warehouse?.status || 'active',
      parent_type:
        parentTypeOptions.find(
          (option) => option.value === warehouse?.parent_type
        ) || parentTypeOptions[0],
      parent: warehouse?.parent
        ? {
            value: warehouse.parent._id,
            label: warehouse.parent.name,
          }
        : null,
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Status field is required.'),
      parent_type: Yup.mixed().required('Warehouse type is required.'),
      parent: Yup.mixed().required('Warehouse owner is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const warehouseValues: any = {
        status: values.status,
        parent_type: values.parent_type.value,
        parent_id: values.parent.value,
      }

      if (type === 'update') {
        warehouseValues._id = warehouse._id
      }

      let newWarehouseId

      warehouseService[type](warehouseValues)
        .then((warehouseId) => {
          newWarehouseId = warehouseId

          warehouseValues._id = warehouse?._id || newWarehouseId
          warehouseValues.parent = parentObject(
            values.parent_type.value,
            values.parent.value
          )

          setSubmitting(false)

          if (type === 'update') {
            addToast('Warehouse successfully updated.', {
              appearance: 'success',
            })
            onUpdate(warehouseValues)
          } else {
            addToast('Warehouse successfully created.', {
              appearance: 'success',
            })
            history.push(route('warehouse_update', { id: newWarehouseId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Parent Type */}
            <FormStack label="Warehouse Type" alignItems="center" isRequired>
              <Autocomplete
                name="parent_type"
                value={values.parent_type}
                options={parentTypeOptions}
                placeholder="Select type of warehouse owner"
                onBlur={() => {
                  formik.setFieldTouched('parent_type', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('parent_type', selected)
                  formik.setFieldValue('parent', null)
                }}
              />
              <ErrorMessage
                name="parent_type"
                component="span"
                className="invalid-feedback"
              />
            </FormStack>

            {/* Warehouse Owner - same as parent on model */}
            <FormStack
              label="Warehouse Owner"
              helperText="Warehouse owner"
              isRequired
            >
              <Autocomplete
                name="parent"
                options={parentOptions(values?.parent_type.value)}
                value={values.parent}
                placeholder="Select warehouse owner"
                onBlur={() => {
                  formik.setFieldTouched('parent', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('parent', selected)
                }}
                isInvalid={touched.parent && !!errors.parent}
                isDisabled={!parentOptions(values.parent_type.value).length}
              />
              <ErrorMessage
                name="parent"
                component="span"
                className="invalid-feedback"
              />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Warehouse' : 'Update Warehouse'}
          </Button>

          {type === 'update' && formik.dirty && (
            <Button
              colorScheme="gray"
              mt={5}
              ml={2}
              isDisabled={formik.isSubmitting || !formik.dirty}
              onClick={() => formik.resetForm()}
            >
              Discard Changes
            </Button>
          )}
        </Form>
      )}
    </Formik>
  )
}

WarehouseForm.defaultProps = {
  type: 'create',
}

export default WarehouseForm
