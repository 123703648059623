import Agents from './pages/Agents.page'
import AgentEditor from './pages/AgentEditor.page'
import AgentsReport from './pages/AgentsReport.page'

import { IRoute } from 'src/bootstrap/routes'

export const agentRoutes: IRoute[] = [
  {
    id: 'agents',
    path: '/agents',
    component: Agents,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'agent_signup',
    path: '/agents/signup',
    component: AgentEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'agent_update',
    path: '/agents/:id/update',
    component: AgentEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'agents_report',
    path: '/agents/report',
    component: AgentsReport,
    requiresAuth: true,
    exact: true,
  },
]

export default agentRoutes
