import React from 'react'

import { Select } from '@chakra-ui/react'
import { fromUnixTime, getUnixTime, set } from 'date-fns'

import { DateRangeInput, FilterMenu } from 'src/core/components'
import {
  InventoryReportContext,
  InventoryReportFiltersType,
  WarehouseType,
} from '../warehouse.type'
import { WarehouseAutocomplete } from '../components'
import { trans } from 'src/core/helpers'

interface IProps {
  filters: InventoryReportFiltersType
  setFilters: (filters: InventoryReportFiltersType) => void
  activeFilters: string[]
  isLoading: boolean
  resetEntries: VoidFunction
}

export const InventoryReportFilter: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
  isLoading,
  resetEntries,
}) => {
  const [params, setParams] = React.useState<any>(filters)
  /** Watch for when filters are changed */
  const [pristine, setPristine] = React.useState<boolean>(true)
  const [excludedWarehouseTypes, setExcludedWarehouseTypes] = React.useState<
    WarehouseType[]
  >([])

  React.useEffect(() => {
    setPristine(false)
  }, [params])

  const handleFilter = (): void => {
    resetEntries()
    setPristine(true)
    setFilters({
      ...params,
    })
  }

  const resetFilters = (): void => {
    const tempFilters = {
      warehouses: '*',
      context: '',
      from_timestamp: null,
      to_timestamp: null,
    }
    setFilters(tempFilters as any)
    setParams(tempFilters)
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length ? `Filters (${activeFilters.length})` : 'Filters'
        }
        menuListStyle={{ width: '400px' }}
        resetButtonProps={{ onClick: resetFilters }}
        refreshButtonProps={{
          onClick: handleFilter,
          isDisabled:
            isLoading ||
            !params.context ||
            (params.context !== InventoryReportContext.Current &&
              !params.from_timestamp) ||
            (params.context !== InventoryReportContext.Current &&
              !params.to_timestamp) ||
            params.warehouses === '*' ||
            !params?.warehouses?.length ||
            pristine,
          isLoading: isLoading,
        }}
      >
        <FilterMenu.Section label="Report Type">
          <Select
            size="sm"
            value={params.context || ''}
            onChange={({ target }: any) => {
              switch (target.value) {
                // list only seller warehouses
                case InventoryReportContext.Received:
                case InventoryReportContext.Rejected:
                case InventoryReportContext.Issued:
                case InventoryReportContext.Sales:
                case InventoryReportContext.Returned:
                  setExcludedWarehouseTypes([
                    WarehouseType.DA,
                    WarehouseType.Distributor,
                  ])
                  break

                // list only DA warehouses
                case InventoryReportContext.Current:
                  setExcludedWarehouseTypes([WarehouseType.Distributor])
                  break

                // list all warehouses
                default:
                  setExcludedWarehouseTypes([])
                  break
              }
              setParams({
                ...params,
                context: target.value,
                page: 1,
                warehouses: '*', // reset warehouses select-list
              })
            }}
          >
            <option value="" label="Select Report Type" />
            {Object.values(InventoryReportContext).map((value, index) => (
              <option key={index} value={value}>
                {trans(`warehouse.${value}`)}
              </option>
            ))}
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Warehouse">
          <WarehouseAutocomplete
            size="sm"
            value={params.warehouses?.[0]}
            onChange={(warehouse) => {
              setParams({ ...params, warehouses: [warehouse] })
            }}
            exclude={excludedWarehouseTypes}
            disableSelectAll
          />
        </FilterMenu.Section>

        {params.context !== InventoryReportContext.Current && (
          <FilterMenu.Section label="Date">
            <DateRangeInput
              start={params.from_timestamp}
              end={params.to_timestamp}
              onChange={(start_date, end_date) => {
                setParams({
                  ...params,
                  from_timestamp: getUnixTime(
                    set(fromUnixTime(start_date), {
                      hours: 0,
                      minutes: 0,
                      seconds: 0,
                    })
                  ),
                  to_timestamp: getUnixTime(
                    set(fromUnixTime(end_date), {
                      hours: 23,
                      minutes: 59,
                      seconds: 0,
                    })
                  ),
                })
              }}
            />
          </FilterMenu.Section>
        )}
      </FilterMenu>
    </>
  )
}
