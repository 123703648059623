import { CoreService, CoreServiceProps } from 'src/core/services'
import {
  ILedger,
  ILedgerLog,
  ILedgerOrderGroup,
  LedgerHead,
  LedgerSubhead,
} from './ledger.type'

export class LedgerService extends CoreService {
  constructor() {
    super('ledger')
  }

  fetchByOwnerSpecific(payload: {
    head: LedgerHead
    subhead: LedgerSubhead
    owner: string
  }): Promise<ILedgerLog> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      const cacheKey = `ledger_${payload.head}_${payload.subhead}_${payload.owner}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`ledger/read_by_owner_specific`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data?.data?.length) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchLogs(ledgerId): Promise<ILedgerLog> {
    return new Promise((resolve, reject) => {
      const cacheKey = `ledger_logs_${ledgerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .get(`ledger_log/read_by_ledger/?ledger_id=${ledgerId}`)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  deposit(payload: any): Promise<any> {
    const formData = new FormData()
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(
            `ledger_log/${payload.ledger_id ? 'deposit' : 'deposit_to'}`,
            formData
          )
          .then(({ data }) => {
            if (data.code === 200) {
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  createVirtualAccount(payload): Promise<any> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`ledger_tag/virtual_account_create`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  fetchReport(payload: {
    head: LedgerHead
    subhead: LedgerSubhead
  }): Promise<ILedger[]> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      const cacheKey = `${payload.head}_${payload.subhead}_reconciliation_report`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post('ledger_log/reconciliation_report', formData)
            .then(({ data }) => {
              if (data.code === 200) {
                const ledgers: ILedger[] = []
                Object.keys(data?.data).forEach((key) => {
                  ledgers.push(data?.data[key])
                })
                if (ledgers.length) this.cache.set(cacheKey, ledgers)
                resolve(ledgers)
              } else {
                reject({ message: data.message })
              }
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchOrderGroupReconciliation(ref: string): Promise<ILedgerOrderGroup> {
    const formData = new FormData()
    formData.append('ref', ref)

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post('ledger_log/order_group_reconciliation', formData)
          .then(({ data }) => {
            if (data.code === 200) {
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default LedgerService
export const ledgerService = new LedgerService()
export type LedgerServiceProps = CoreServiceProps | keyof LedgerService
