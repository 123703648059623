import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'src/core/components/Table'

interface IProps {
  tableRef: any
  products: any[]
  months: any[]
  columnActions: any
}

const SalesHistoryTable: React.FC<IProps> = ({
  tableRef,
  products,
  months,
  columnActions,
}) => {
  const selectedMonths = months.filter((month) => month.checked)
  const { getThProps } = columnActions

  return (
    <Table tableRef={tableRef} isFit hasBorders>
      <Thead>
        <Tr>
          <Th {...getThProps('name')} isSticky>
            Name
          </Th>
          {selectedMonths.map((month, index) => (
            <Th key={index} textAlign="center" {...getThProps(month.code)}>
              {month.code}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {products.map((product) => (
          <Tr key={product._id}>
            <Td isSticky>{product.name}</Td>
            {selectedMonths.map((month, index) => (
              <Td key={index} textAlign="center">
                {product?.[month.code] || '0'}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default SalesHistoryTable
