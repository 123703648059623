import React from 'react'

import { Autocomplete, AutocompleteProps } from 'src/core/components'
import { useFetch } from 'src/core/hooks'
import { sortByKey } from 'src/core/helpers'

import { ISalesOfficer, SalesOfficerRole } from '../salesOfficer.type'
import { salesOfficerService } from '../salesOfficer.service'
interface IProps extends AutocompleteProps {
  role?: SalesOfficerRole
  exclude?: string[]
  disableSelectAll?: boolean
}
export const SalesOfficerAutocomplete: React.FC<IProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [salesOfficers] = useFetch<ISalesOfficer[]>(
    salesOfficerService,
    'fetch'
  )

  const salesOfficerOptions = React.useMemo(() => {
    if (salesOfficers?.length) {
      const options = []

      if (!props.isMulti && !props.disableSelectAll) {
        options.push({ value: '*', label: 'All Sales Officers' })
      }

      const filtered = salesOfficers
        .filter((salesOfficer) => {
          const hasRole = props?.role ? salesOfficer.role === props.role : true

          return (
            hasRole &&
            salesOfficer.status === 'active' &&
            !props?.exclude?.includes(salesOfficer?._id)
          )
        })
        .map((salesOfficer) => ({
          value: salesOfficer._id,
          label: salesOfficer.name,
        }))

      return [...options, ...sortByKey(filtered, 'label')]
    }

    return []
  }, [
    salesOfficers,
    props.isMulti,
    props.role,
    props.disableSelectAll,
    props?.exclude,
  ])

  return (
    <Autocomplete
      size="sm"
      options={salesOfficerOptions}
      value={value}
      placeholder={props.placeholder || 'Select Sales Officer'}
      onChange={(selected) => onChange(selected)}
      isActive={value?.value !== '*'}
      isDisabled={!salesOfficerOptions || !salesOfficerOptions.length}
      {...props}
    />
  )
}
