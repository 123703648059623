import React, { useMemo } from 'react'
import { Button } from '@chakra-ui/button'
import { Box, Divider, Flex, Heading, HStack, Text } from '@chakra-ui/layout'
import { Tag } from '@chakra-ui/tag'
import { Stat, StatLabel, StatNumber } from '@chakra-ui/stat'
import { FiPhoneCall, FiPlus, FiUser } from 'react-icons/fi'

import { ErrorBox } from 'src/core/components'
import { Table, Td, Thead, Tbody, Tr } from 'src/core/components/Table'
import { formatCurrency, route, timestampToDate } from 'src/core/helpers'
import { ILedgerLog } from 'src/applets/ledger'
import { LedgerLogForm } from './LedgerLogForm'
import { ILedgerReportBuyer, ILedgerReportSalesOfficer } from '../ledger.type'
import { Avatar } from '@chakra-ui/avatar'
import { Link } from 'react-router-dom'
import { InventoryReportContext } from 'src/applets/warehouse'

interface IProps {
  /** User ID */
  ledger: ILedgerReportBuyer | ILedgerReportSalesOfficer
  ledgerOrders: { [ref: string]: ILedgerLog[] }
  updateLedger: (arg: ILedgerReportBuyer | ILedgerReportSalesOfficer) => void
}
export const LedgerDetails: React.FC<IProps> = ({
  ledger,
  ledgerOrders,
  updateLedger,
}) => {
  const [showForm, setShowForm] = React.useState<boolean>(false)

  const colorCode = (value): string => {
    return value < 0 ? 'critical.600' : value > 0 ? 'success.600' : 'body'
  }

  const ledgerLogs = useMemo(() => {
    const orderLogs = []
    Object.keys(ledgerOrders).forEach((orderRef) => {
      orderLogs.push({
        ref: orderRef,
        logs: ledgerOrders[orderRef],
      })
    })
    return orderLogs
  }, [ledgerOrders])

  return (
    <>
      {!ledger && !ledgerLogs?.length ? (
        <ErrorBox summary={`No logs found`} my={50} />
      ) : null}

      {ledger && ledgerLogs?.length ? (
        <>
          <Box textAlign="center">
            <Flex justifyContent="center">
              <Avatar name={ledger?.owner_object?.name || 'Buyer'} />
            </Flex>

            <Heading as="h6" size="md" fontWeight="semibold" mt={3}>
              {ledger?.owner_object?.name || ledger?.owner_object?.phone}
            </Heading>

            <Text color="gray.600">{ledger?.owner_object?.phone}</Text>

            <Box mt={2}>
              <Link
                to={route('sales_officer_update', {
                  id: ledger?.owner_object._id,
                })}
              >
                <Button size="sm" leftIcon={<FiUser size={14} />} mr={3}>
                  Edit
                </Button>
              </Link>

              <a href={`tel:+234${ledger?.owner_object?.phone.substr(1)}`}>
                <Button size="sm" leftIcon={<FiPhoneCall size={14} />} mr={3}>
                  Call
                </Button>
              </a>

              <Button
                size="sm"
                leftIcon={<FiPlus size={16} />}
                onClick={() => {
                  setShowForm(true)
                }}
              >
                New Deposit
              </Button>
              <LedgerLogForm
                ledger={showForm ? ledger : null}
                onCreate={(ledgerLog) => {
                  ledger.balance = ledger.balance + ledgerLog.value
                  if (
                    ledger.ledger_logs.orders[ledgerLog.description]?.length
                  ) {
                    ledger.ledger_logs.orders[ledgerLog.description].unshift(
                      ledgerLog
                    )
                  } else {
                    ledger.ledger_logs.orders = Object.assign(
                      { ...ledger.ledger_logs.orders },
                      { [ledgerLog.description]: [ledgerLog] }
                    )
                  }
                  updateLedger(ledger)
                  setShowForm(false)
                }}
                onClose={() => setShowForm(false)}
              />
            </Box>
          </Box>

          <Divider borderColor="gray.200" my={5} />

          <Box px="5">
            <HStack justifyContent="space-around">
              <Stat>
                <StatLabel color="gray.600">Outstanding</StatLabel>
                <StatNumber fontSize="lg" color={colorCode(ledger.balance)}>
                  NGN {formatCurrency(ledger.balance)}
                </StatNumber>
              </Stat>

              <Stat>
                <StatLabel color="gray.600">Orders</StatLabel>
                <StatNumber fontSize="lg">{ledgerLogs?.length}</StatNumber>
              </Stat>
            </HStack>
          </Box>

          <Divider borderColor="gray.200" mt={5} />

          <Table size="sm">
            {ledgerLogs?.map((ledgerOrder) => (
              <React.Fragment key={ledgerOrder.ref}>
                <Thead>
                  <Tr>
                    <Td wordBreak="break-word" colSpan={5}>
                      {Object.values(InventoryReportContext).includes(
                        ledgerOrder?.logs[0]?.tag
                      ) ? (
                        <Link
                          to={route(
                            'purchase_order',
                            { id: ledgerOrder.ref },
                            { is_ref: 'true' }
                          )}
                          title={'PO #' + ledgerOrder.ref}
                        >
                          <Text title={'PO #' + ledgerOrder.ref}>
                            {'PO #' + ledgerOrder.ref}
                          </Text>
                        </Link>
                      ) : (
                        <>
                          {ledgerOrder.ref.indexOf('|') < 0 &&
                          ledgerOrder.ref.match(/[A-Z0-9]/g) &&
                          ledgerOrder.ref.split('').length === 8 ? (
                            <Link
                              to={route(
                                'order_group',
                                { id: ledgerOrder.ref },
                                { is_ref: 'true' }
                              )}
                              title={'Order #' + ledgerOrder.ref}
                            >
                              <Text title={'Order #' + ledgerOrder.ref}>
                                {'Order #' + ledgerOrder.ref}
                              </Text>
                            </Link>
                          ) : (
                            ledgerOrder.ref.split('|').join(' | ')
                          )}
                        </>
                      )}
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {ledgerOrder.logs?.map((log, idx) => (
                    <Tr key={idx}>
                      <Td>{timestampToDate(log.created)}</Td>
                      <Td>
                        <Tag
                          size="sm"
                          colorScheme={
                            log.tag === 'order'
                              ? 'orange'
                              : log.tag === 'cash'
                              ? 'success'
                              : 'gray'
                          }
                        >
                          {log.tag}
                        </Tag>
                      </Td>

                      <Td color={colorCode(log.value)} isNumeric>
                        {formatCurrency(log.value)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </React.Fragment>
            ))}
          </Table>
        </>
      ) : null}
    </>
  )
}
