import React from 'react'
import { useHistory } from 'react-router'

import {
  route,
  basicSearch,
  parseQueryParam,
  serializeQueryParam,
  undoSlugify,
} from 'src/core/helpers'
import { useMounted, useQueryParams } from 'src/core/hooks'

import {
  InventoryReportFiltersType,
  UseFilterInventoryReportType,
} from '../warehouse.type'

const PER_PAGE = 20

export const useFilterInventoryReport = (
  entries: any[]
): UseFilterInventoryReportType => {
  const history = useHistory()
  const isMounted = useMounted()

  const [filters, setFilters] = React.useState<InventoryReportFiltersType>({
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', PER_PAGE)),
    search: useQueryParams('search'),
    context: useQueryParams('context', ''),
    warehouses: parseQueryParam(useQueryParams('warehouses', '')),
    products: parseQueryParam(useQueryParams('products', '')),
    from_timestamp: Number(useQueryParams('from_timestamp', null)),
    to_timestamp: Number(useQueryParams('to_timestamp', null)),
  })

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      isMounted.current &&
        setFilters({
          page: Number(query.get('page')) || 1,
          per_page: Number(query.get('per_page')) || PER_PAGE,
          search: query.get('search') || null,
          context: (query.get('context') || '') as any,
          warehouses: parseQueryParam(query.get('warehouses')),
          from_timestamp: Number(query.get('from_timestamp')) || null,
          to_timestamp: Number(query.get('to_timestamp')) || null,
        })
    })
    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const updateQueryParams = React.useCallback(
    ({ warehouses, ...params }) => {
      history.push(
        route('inventory_report', null, {
          ...params,
          page: params.page === 1 ? null : params.page,
          per_page: params.per_page === PER_PAGE ? null : params.per_page,
          warehouses: serializeQueryParam(warehouses),
        })
      )
    },
    [history]
  )

  const filteredEntries = React.useMemo(() => {
    let filtered = entries || []

    if (filtered.length) {
      if (filters?.products !== '*' && filters?.products?.[0]?.value !== '*') {
        const list = filters.products?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ product_id }) => {
            return list.includes(product_id)
          })
        }
      }

      if (filters.search) {
        filtered = filtered.filter((purchaseOrder) => {
          return basicSearch(purchaseOrder.ref, filters.search)
        })
      }
    }

    return filtered
  }, [entries, filters.search, filters.products])

  const activeFilters = React.useMemo(() => {
    const activeFilters = []
    const { context, products, warehouses, from_timestamp } = filters

    if (context) {
      activeFilters.push(`Context (${undoSlugify(context)})`)
    }

    if (products && products !== '*') {
      const list = products?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Product (${list})`)
    }

    if (warehouses && warehouses !== '*') {
      const list = warehouses?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Warehouses (${list})`)
    }

    if (from_timestamp) {
      activeFilters.push('Date')
    }

    return activeFilters
  }, [filters])

  return {
    filters,
    activeFilters,
    setFilters: (updated) => {
      updateQueryParams({ page: 1, per_page: PER_PAGE, ...filters, ...updated })
    },
    filteredEntries,
  }
}
