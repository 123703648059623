import React from 'react'

import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { Layout } from 'src/core/components'
import ProductForm from '../components/ProductForm'
import ProductsUpload from '../components/ProductsUpload'

import { route, useQuery } from 'src/core/helpers/route.helper'

const ProductCreate: React.FC = () => {
  const history = useHistory()

  const type = useQuery().get('type')
  const brand_id = useQuery().get('brand_id')
  const brand_name = useQuery().get('brand_name')

  return (
    <Layout
      headerProps={{
        title: type === 'bulk' ? 'Upload Products' : 'Create Product',
        backText: 'Products',
        onBack: () => history.push(route('products')),
      }}
      permission="create_product"
    >
      <Card>
        <Card.Header>
          <Card.Title>
            {type === 'bulk'
              ? `Upload ${brand_name} Products`
              : 'Product Details'}
          </Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          {type === 'bulk' && brand_id ? (
            <ProductsUpload brand={{ _id: brand_id, name: brand_name }} />
          ) : (
            <ProductForm />
          )}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default ProductCreate
