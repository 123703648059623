import { CoreService, CoreServiceProps } from 'src/core/services'

export class AreaService extends CoreService {
  constructor() {
    super('area')
  }

  fetchSalesOfficer(areaId): Promise<any[]> {
    const formData = new FormData()
    formData.append('area_id', areaId)

    return new Promise((resolve, reject) => {
      const cacheKey = `area_sales_officer_${areaId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`sales_officer_area/read_by_area`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data.data.length) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateSalesOfficer(type, data): Promise<void> {
    this.model = 'sales_officer_area'

    return new Promise((resolve, reject) => {
      super
        [type](data)
        .then(() => resolve())
        .catch((error) => reject(error))
        .finally(() => {
          /** Reset to original model */
          this.model = 'area'
        })
    })
  }

  fetchLocations(areaId): Promise<any[]> {
    const formData = new FormData()
    formData.append('area_id', areaId)

    return new Promise((resolve, reject) => {
      const cacheKey = `area_locations_${areaId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`location_area/read_by_area`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  creatBulkLocations(params, areaLocations: any): Promise<void> {
    const formData = new FormData()

    Object.keys(params).forEach((key) => {
      formData.append(key, params[key])
    })

    areaLocations.forEach((locationId) => {
      formData.append('location_id[]', locationId)
    })

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`location_area/add_bulk_location`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve()
            } else {
              areaLocations?.length
                ? reject({ message: data.message })
                : resolve()
            }
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  updateLocation(type, areaLocation): Promise<any> {
    this.model = 'location_area'

    return new Promise((resolve, reject) => {
      super
        [type](areaLocation)
        .then((data) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => {
          /** Reset to original model */
          this.model = 'area'
        })
    })
  }
}

export type AreaServiceProps = CoreServiceProps | keyof CoreServiceProps
