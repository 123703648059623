import React from 'react'

export const useGroupOrders = (
  orders,
  type: 'buyer' | 'sales_officer'
): any => {
  const groupedOrders = React.useMemo(() => {
    if (!orders || !orders.length) return []
    else {
      const grouped: any = {}

      if (type === 'sales_officer') {
        grouped.unattached = {
          _id: 'unattached',
          name: 'Unattached',
          phone: '-',
          order_count: 0,
          order_total: 0,
          order_case_count_total: 0,
          orders: [],
        }
      }

      orders.forEach((order) => {
        const deliveryFee = order.charges?.DELIVERY_FEE?.value || 0
        const orderSubtotal = order.orders?.value?.completed || 0
        const orderTotal = orderSubtotal + deliveryFee

        if (order?.[type]) {
          if (!grouped[order[type]._id]) {
            grouped[order[type]._id] = {
              _id: order[type]._id,
              name: order[type].name,
              phone: order[type].phone,
              order_count: 1,
              order_total: orderTotal,
              order_case_count_total: order?.meta_obj?.item_case_count,
              orders: [{ ...order, first_row: true }],
            }
          } else {
            grouped[order[type]._id].orders.push({ ...order, first_row: false })
            grouped[order[type]._id].order_count += 1
            grouped[order[type]._id].order_total += orderTotal
            grouped[order[type]._id].order_case_count_total +=
              order?.meta_obj?.item_case_count
          }
        } else {
          grouped.unattached.orders.push({
            ...order,
            first_row: grouped.unattached.order_count ? false : true,
          })
          grouped.unattached.order_count += 1
          grouped.unattached.order_total += orderTotal
          grouped.unattached.order_case_count_total +=
            order?.meta_obj?.item_case_count
        }
      })

      return Object.values(grouped)
    }
  }, [orders, type])

  return groupedOrders
}

export default useGroupOrders
