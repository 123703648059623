import { IModel } from 'src/core/types'
import { IBuyer } from '../buyer'
import { IOrderGroup } from '../order'
import { ISalesOfficer } from '../salesOfficer'

export enum LedgerHead {
  Buyer = 'buyer',
  SalesOfficer = 'sales_officer',
  Distributor = 'distributor',
  DistributorOfficer = 'distributor_officer',
}

export enum LedgerSubhead {
  Wallet = 'wallet',
}

export enum LedgerLogTag {
  Cash = 'cash',
  Transfer = 'transfer',
  Order = 'order',
  Signup = 'signup',
}

export interface ILedger extends IModel {
  head: LedgerHead
  subhead: LedgerSubhead
  owner: string
  balance: number
}

export interface ILedgerTag extends IModel {
  parent_id: string
  parent_type: string
  tag: string
  value: string
}

export interface ILedgerTagsDict {
  external_reference?: ILedgerTag
  virtual_account?: ILedgerTag
}

export interface ILedgerReport extends ILedger {
  oldest: number
  ledger_logs: any
}

export interface ILedgerReportBuyer extends ILedgerReport {
  owner_object: IBuyer & { ledger_tags?: ILedgerTagsDict }
}

export interface ILedgerReportSalesOfficer extends ILedgerReport {
  owner_object: ISalesOfficer & { ledger_tags?: ILedgerTagsDict }
}

export interface ILedgerLog extends IModel {
  readonly value: number
  readonly description: string
  readonly tag: LedgerLogTag
  readonly ledger: ILedger
}

export interface ILedgerOrderGroup {
  amount: number
  charges: number
  buyer: IBuyer
  payment_method: string
  sales_officer: ISalesOfficer
  order: IOrderGroup
  buyer_ledger_logs: ILedgerLog[]
  sales_officer_ledger_logs: ILedgerLog[]
  buyer_possibles: ILedgerLog[]
  sales_officer_possibles: ILedgerLog[]
}
