import React from 'react'

import { Button, Spinner, Flex, Box, Text, Badge } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { Table, Card } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'

import { Layout, Pagination, SearchInput, ErrorBox } from 'src/core/components'

import usePermission from 'src/core/hooks/usePermission'
import useFilter from 'src/core/hooks/useFilter'
import useMounted from 'src/core/hooks/useMounted'
import { IArea } from '../area.type'

import { AreaService } from '../area.service'

import { paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'

const areaService = new AreaService()

const Areas: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [areas, setAreas] = React.useState<IArea[]>()

  const { filters, setFilters } = useFilter('areas')

  React.useEffect(() => {
    const fetchAreas = (): void => {
      areaService
        .fetch()
        .then((areas) => {
          isMounted.current && setAreas(areas)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    fetchAreas()
  }, [isMounted])

  const filteredAreas = React.useMemo(() => {
    let filtered = areas || []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((area) => {
          return (
            basicSearch(area.name, filters.search) ||
            basicSearch(area.manufacturer.name, filters.search) ||
            basicSearch(area?.seller?.name, filters.search)
          )
        })
      }
    }

    return filtered
  }, [areas, filters.search])

  const paginatedAreas = React.useMemo(() => {
    return filteredAreas ? paginate(filteredAreas, filters.page) : []
  }, [filters.page, filteredAreas])

  const open = (routeId, area): void => {
    history.push(route(routeId, { id: area._id }))
  }

  return (
    <Layout headerProps={{ title: 'Areas' }} permission="view_areas">
      <Card>
        <Card.Header>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div>
                {filteredAreas
                  ? `${filteredAreas.length} ${pluralize(
                      'area',
                      filteredAreas.length
                    )}`
                  : '0 areas'}
              </div>
              <div className="ml-3">
                <SearchInput
                  placeholder="Search areas..."
                  value={filters.search}
                  onChange={(search) => setFilters({ search, page: 1 })}
                  isDisabled={!filteredAreas}
                />
              </div>
            </div>

            {userCan('create_area') && (
              <div>
                <Link to={route('area_create')}>
                  <Button
                    size="sm"
                    leftIcon={<FiPlus size={16} />}
                    colorScheme="primary"
                  >
                    Create Area
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && !paginatedAreas.length ? (
            <Box my={50}>
              <ErrorBox summary="No areas found" />
            </Box>
          ) : null}

          {!isLoading && paginatedAreas.length ? (
            <Box className="table-wrapper padded">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="sticky">Name</th>
                    <th>Distrbutor</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedAreas.map((area, index) => (
                    <tr key={area._id}>
                      <td className="align-middle">
                        {index + 1 + (filters.page - 1) * 10}
                      </td>
                      <td className="align-middle">
                        <Text>{area.name}</Text>
                        <Text fontSize="sm" color="gray.600">
                          {area.manufacturer.name}
                        </Text>
                      </td>
                      <td className="align-middle">
                        {area?.seller?.name || '-'}
                      </td>
                      <td className="align-middle">
                        <Badge
                          colorScheme={
                            area.status === 'active' ? 'success' : 'danger'
                          }
                        >
                          {area.status}
                        </Badge>
                      </td>

                      <td className="align-middle">
                        <Flex>
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => open('area_update', area)}
                          >
                            Edit
                          </Button>

                          <Button
                            size="sm"
                            ml={2}
                            variant="outline"
                            onClick={() => open('area_view', area)}
                          >
                            Locations
                          </Button>
                        </Flex>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          ) : null}

          <Pagination
            my={5}
            itemsPerPage={10}
            totalItems={filteredAreas.length}
            currentPage={filters.page}
            onPageChange={(page) => setFilters({ page })}
          />
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default Areas
