import React from 'react'

import {
  Badge,
  Button,
  Text,
  Flex,
  Spinner,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import { FiCheck, FiX } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Thead, Th, Tr, Td } from 'src/core/components/Table'

import {
  route,
  formatCurrency,
  timestampToDate,
  timestampToTime,
} from 'src/core/helpers'
import { IUseSort, usePermission } from 'src/core/hooks'

import OrderMetaForm from '../OrderMetaForm'
import { OrderGroupsTableContext } from '../../contexts'
import { useUpdateOrder } from '../../hooks'
import { IOrderGroup, OrderTag } from '../../order.type'

interface OrderGroupsTheadProps {
  parent: any
  filters: any
  getThProps: IUseSort['getThProps']
}
export const OrderGroupsThead: React.FC<OrderGroupsTheadProps> = ({
  parent,
  filters,
  getThProps,
}) => {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>

        {/* Status: sortable */}
        <Th {...getThProps('tag')}>Status</Th>

        <Th>Ref ID</Th>

        {/* Modified: sortable */}
        <Th {...getThProps('modified')}>Date</Th>

        {parent !== 'buyer' && (
          <>
            <Th {...getThProps('buyer.name')} isSticky>
              Retailer
            </Th>

            <Th {...getThProps('location.name')}>Location</Th>
          </>
        )}

        <Th {...getThProps('sales_officer.name')}>Delivery Associate</Th>

        <Th {...getThProps('manufacturers')}>Manufacturers</Th>

        <Th {...getThProps('cases')}>Cases</Th>

        <Th {...getThProps('amount')} isNumeric>
          Invoice Due (NGN)
        </Th>

        {filters.show_details && (
          <>
            <Th>Source</Th>
            <Th {...getThProps('discount')} isNumeric>
              Discount
            </Th>
            <Th {...getThProps('discount_reason')}>Discount Reason</Th>
            <Th {...getThProps('payment_method')} isNumeric>
              Payment Method
            </Th>
            <Th {...getThProps('cash')} isNumeric>
              Cash (NGN)
            </Th>
            <Th {...getThProps('transfer')} isNumeric>
              Transfer (NGN)
            </Th>
            <Th {...getThProps('paid')} isNumeric>
              Total Paid (NGN)
            </Th>
            <Th {...getThProps('outstanding')} isNumeric>
              Outstanding (NGN)
            </Th>
            {/* <Th>Confirmed</Th> */}
            {/* <Th>Rating</Th> */}
            {/* <Th>Review</Th> */}
            <Th>Comment</Th>
          </>
        )}

        <Th>Actions</Th>
        <Th></Th>
      </Tr>
    </Thead>
  )
}

interface OrderGroupsTrProps {
  index: number
  orderGroup: IOrderGroup
}
export const OrderGroupsTr: React.FC<OrderGroupsTrProps> = ({
  index,
  orderGroup,
}) => {
  const { userCan } = usePermission()

  const { orderGroups, updateOrderGroups, filters, parent } = React.useContext(
    OrderGroupsTableContext
  )

  const { updateTag, isUpdating } = useUpdateOrder(orderGroup, true)

  const [metaMode, setMetaMode] = React.useState<boolean>(false)

  const doUpdateTag = (newTag): void => {
    updateTag(newTag).then(() => {
      updateOrderGroups({ ...orderGroup, tag: newTag }, orderGroups)
    })
  }

  const hasBeenModified = orderGroup.total !== orderGroup.amount

  return (
    <>
      <Tr key={orderGroup._id}>
        <Td>
          {index + 1 + (filters.page - 1) * 10}{' '}
          {hasBeenModified && (
            <Text as="b" color="warning.600">
              {' '}
              *
            </Text>
          )}
        </Td>

        {/* Status */}
        <Td>
          <Badge
            colorScheme={
              orderGroup.tag === 'completed'
                ? 'success'
                : ['pending', 'delivered'].includes(orderGroup.tag)
                ? 'warning'
                : 'critical'
            }
          >
            {orderGroup.tag}
          </Badge>
        </Td>

        {/* Order ref */}
        <Td>
          <Link to={`${route('order_group', { id: orderGroup._id })}`}>
            #{orderGroup.ref}
          </Link>
        </Td>

        {/* Date */}
        <Td>
          {timestampToDate(orderGroup.modified)}
          <Text fontSize="sm" color="gray.600" display="block">
            {timestampToTime(orderGroup.modified)}
          </Text>
        </Td>

        {/* Buyer */}
        {parent !== 'buyer' && (
          <>
            <Td
              title={
                orderGroup?.buyer?.agent
                  ? `Agent: ${orderGroup?.buyer?.agent?.name}`
                  : 'Agent:-'
              }
              isSticky
            >
              <Flex alignItems="center">
                <Link
                  to={route('buyer', {
                    id: orderGroup?.buyer?._id,
                    tab: 'orders',
                  })}
                >
                  {orderGroup?.buyer?.name || 'N/A'}
                </Link>
                {orderGroup?.buyer_is_new && (
                  <Badge ml={1} colorScheme="teal">
                    New
                  </Badge>
                )}
              </Flex>
              <Text fontSize="sm" color="gray.600" display="block">
                {orderGroup?.buyer?.phone || 'N/A'}
              </Text>
            </Td>

            <Td>{orderGroup?.buyer?.location?.name || 'N/A'}</Td>
          </>
        )}

        {/* Delivery Associate */}
        <Td>{orderGroup.sales_officer_name}</Td>

        {/* Manufacturers */}
        <Td textAlign="center">{orderGroup.manufacturers}</Td>

        {/* Cases */}
        <Td textAlign="center">{orderGroup.cases}</Td>

        {/* Amount */}
        <Td isNumeric>
          {hasBeenModified && (
            <Text as="span" color="warning.500" fontWeight="bold">
              *{' '}
            </Text>
          )}
          <Text as="span" fontWeight="medium">
            {formatCurrency(orderGroup.total)}
          </Text>
          {hasBeenModified && (
            <Text fontSize="sm" color="gray.600" display="block">
              vs {formatCurrency(orderGroup.amount)}
            </Text>
          )}
        </Td>

        {filters.show_details && (
          <>
            {/* source */}
            <Td>{orderGroup.source}</Td>

            {/* discount */}
            <Td isNumeric>
              <Text color={orderGroup.discount < 0 ? 'danger.600' : 'body'}>
                {formatCurrency(orderGroup.discount)}
              </Text>
            </Td>

            <Td>{orderGroup.discount_reason}</Td>

            {/* payment_method */}
            <Td>
              <Text textTransform="capitalize">
                {orderGroup.payment_method === 'cash_transfer'
                  ? 'Cash & Transfer'
                  : orderGroup?.payment_method || '-'}
              </Text>
            </Td>

            {/* cash */}
            <Td isNumeric>{formatCurrency(orderGroup.cash)}</Td>

            {/* transfer */}
            <Td isNumeric>{formatCurrency(orderGroup.transfer)}</Td>

            {/* paid */}
            <Td isNumeric>{formatCurrency(orderGroup.paid)}</Td>

            {/* outstanding */}
            <Td isNumeric>
              <Text
                color={
                  orderGroup.outstanding < 0 ? 'critical.600' : 'success.600'
                }
              >
                {formatCurrency(orderGroup.outstanding)}
              </Text>
            </Td>

            {/* confirmed */}
            {/* <Td >
              <span>{metaObj?.confirmed?.value || metaObj?.confirmed || '-'}</span>
            </Td> */}

            {/* rating */}
            {/* <Td >
              <span>{metaObj?.rating?.value || metaObj?.rating || '-'}</span>
            </Td> */}

            {/* review */}
            {/* <Td className="align-middle wrap">
              <span>{metaObj?.review?.value || metaObj?.review || '-'}</span>
            </Td> */}

            {/* comment */}
            <Td>
              <Text w="200px">{orderGroup.comment}</Text>
            </Td>
          </>
        )}

        <Td>
          {userCan('update_order') && (
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                setMetaMode(true)
              }}
            >
              Edit Payment
            </Button>
          )}
        </Td>

        {/* Quick order tag update */}
        {userCan('update_order_tag') && (
          <Td>
            {isUpdating ? (
              <Flex justifyContent="center">
                <Spinner size="sm" color="primary.600" />
              </Flex>
            ) : (
              <>
                {orderGroup.tag === OrderTag.Pending ? (
                  <Flex alignItems="center" justifyContent="center">
                    <Tooltip label="Complete order">
                      <IconButton
                        size="sm"
                        variant="ghost"
                        colorScheme="success"
                        aria-label="Mark complete"
                        icon={<FiCheck size={16} />}
                        onClick={() => doUpdateTag(OrderTag.Completed)}
                      />
                    </Tooltip>

                    <Tooltip label="Cancel order">
                      <IconButton
                        ml={2}
                        size="sm"
                        variant="ghost"
                        colorScheme="critical"
                        aria-label="Mark cancelled"
                        icon={<FiX size={16} />}
                        onClick={() => doUpdateTag(OrderTag.Cancelled)}
                      />
                    </Tooltip>
                  </Flex>
                ) : null}
              </>
            )}
          </Td>
        )}
      </Tr>

      <OrderMetaForm
        type="order_group"
        order={metaMode ? orderGroup : null}
        setOrder={(order) => !order && setMetaMode(false)}
        onUpdate={(updated) => {
          updateOrderGroups({ ...orderGroup, ...updated }, orderGroups)
          setMetaMode(false)
        }}
      />
    </>
  )
}
