import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useMounted, useQueryParams } from 'src/core/hooks'
import {
  route,
  basicSearch,
  parseQueryParam,
  serializeQueryParam,
} from 'src/core/helpers'

import { beautifyProduct } from '../product.helper'
import { IUseFilterProducts } from '../product.type'

export const useFilterProducts = (
  products: any,
  routeId = 'products',
  routeParams?: any
): IUseFilterProducts => {
  const isMounted = useMounted()
  const history = useHistory()

  const [filters, setFilters] = useState<any>({
    status: useQueryParams('status', '*'),
    show_details: useQueryParams('show_details', false),
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', 10)),
    search: useQueryParams('search'),
    manufacturers: parseQueryParam(useQueryParams('manufacturers', '*')),
    brands: parseQueryParam(useQueryParams('brands', '*')),
  })

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      setFilters({
        status: query.get('status') || '*',
        show_details: query.get('show_details') || false,
        page: Number(query.get('page')) || 1,
        per_page: Number(query.get('per_page')) || 10,
        search: query.get('search') || null,
        manufacturers: parseQueryParam(query.get('manufacturers')),
        brands: parseQueryParam(query.get('brands')),
      })
    })

    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const updateQueryParams = React.useCallback(
    ({ manufacturers, brands, ...query }) => {
      history.push(
        route(routeId, routeParams, {
          ...query,
          page: query.page === 1 ? null : query.page,
          manufacturers: serializeQueryParam(manufacturers),
          brands: serializeQueryParam(brands),
        })
      )
    },
    [history, routeId, routeParams]
  )

  const filteredProducts = React.useMemo(() => {
    let filtered = products || []

    if (filtered.length) {
      /**
       * Add sellers [array] and compound_status
       */
      filtered = filtered.map((product) => {
        return beautifyProduct(product)
      })

      if (filters?.status !== '*') {
        filtered = filtered.filter((product) => {
          return product.compound_status === filters.status
        })
      }

      if (filters?.brands !== '*') {
        const list = filters.brands?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ brand }) => list.includes(brand?._id))
        }
      } else if (filters?.manufacturers !== '*') {
        const list = filters.manufacturers?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ manufacturer }) => {
            return list.includes(manufacturer?._id)
          })
        }
      }

      if (filters.search) {
        filtered = filtered.filter((product) => {
          return (
            basicSearch(product.name, filters.search) ||
            basicSearch(product.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [products, filters])

  const activeFilters = React.useMemo(() => {
    const activeFilters: string[] = []
    const { status, brands, manufacturers } = filters

    if (status !== '*') {
      activeFilters.push(`Status (${filters.status})`)
    }

    if (brands !== '*') {
      const list = brands?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Brands (${list})`)
    }

    if (manufacturers !== '*') {
      const list = manufacturers?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Manufacturers (${list})`)
    }

    return activeFilters
  }, [filters])

  return {
    filters,
    activeFilters,
    filteredProducts,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, ...updated, page: 1 })
    },
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
  }
}

export default useFilterProducts
