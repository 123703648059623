import React from 'react'

import { Button, Badge, Box, Text, Flex, Spinner } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'
import { Table, Card } from 'react-bootstrap'

import { Layout, SearchInput, ErrorBox, Pagination } from 'src/core/components'

import useMounted from 'src/core/hooks/useMounted'
import useFilter from 'src/core/hooks/useFilter'
import usePermission from 'src/core/hooks/usePermission'

import { storeTypeService } from '../storeType.service'
import { IStoreType } from '../storeType.type'

import { quickPluralize, paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'

const StoreTypes: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [storeTypes, setStoreTypes] = React.useState<IStoreType[]>()

  const { filters, setFilters } = useFilter('store_types')

  React.useEffect(() => {
    const fetchStoreTypes = (): void => {
      storeTypeService
        .fetch()
        .then((storeTypes) => {
          isMounted.current && setStoreTypes(storeTypes)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_store_types') && fetchStoreTypes()
  }, [isMounted, userCan])

  const filteredStoreTypes = React.useMemo(() => {
    let filtered = storeTypes || []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((storeType) => {
          return (
            basicSearch(storeType.name, filters.search) ||
            basicSearch(storeType.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [storeTypes, filters.search])

  const paginatedStoreTypes = React.useMemo(() => {
    return filteredStoreTypes ? paginate(filteredStoreTypes, filters.page) : []
  }, [filters.page, filteredStoreTypes])

  return (
    <Layout
      headerProps={{ title: 'Store Types' }}
      permission="view_store_types"
    >
      <Card>
        <Card.Header>
          <Flex alignItems="center">
            <Text>{quickPluralize(filteredStoreTypes, 'store type')}</Text>

            <Box ml={3}>
              <SearchInput
                placeholder="Search store types"
                value={filters.search}
                onChange={(search) => setFilters({ search, page: 1 })}
                isDisabled={isLoading || !filteredStoreTypes}
              />
            </Box>
          </Flex>

          {userCan('create_store_type') && (
            <Link to={route('store_type_create')}>
              <Button
                size="sm"
                leftIcon={<FiPlus size={16} />}
                colorScheme="primary"
              >
                Create Store Type
              </Button>
            </Link>
          )}
        </Card.Header>
        <Card.Body className="px-0 py-0">
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && !paginatedStoreTypes.length ? (
            <ErrorBox summary="No store types found" my={50} />
          ) : null}

          {!isLoading && paginatedStoreTypes.length ? (
            <div className="table-wrapper padded">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="sticky">Name</th>
                    <th className="sticky">Description</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedStoreTypes.map((storeType, index) => (
                    <tr
                      key={storeType._id}
                      className="cursor-pointer"
                      onClick={() =>
                        history.push(
                          route('store_type_update', {
                            id: storeType._id,
                          })
                        )
                      }
                    >
                      <td className="align-middle text-center">
                        {index + 1 + (filters.page - 1) * 10}
                      </td>
                      <td className="align-middle">{storeType.name}</td>
                      <td className="align-middle">
                        <Text noOfLines={1}>{storeType.description}</Text>
                      </td>
                      <td className="align-middle">
                        <Badge
                          colorScheme={
                            storeType.status === 'active'
                              ? 'success'
                              : 'critical'
                          }
                        >
                          {storeType.status}
                        </Badge>
                      </td>
                      <td className="align-middle">
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => {
                            history.push(
                              route('store_type_update', {
                                id: storeType._id,
                              })
                            )
                          }}
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : null}

          <Pagination
            my={5}
            itemsPerPage={10}
            totalItems={filteredStoreTypes.length}
            currentPage={filters.page}
            onPageChange={(page) => setFilters({ page })}
          />
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default StoreTypes
