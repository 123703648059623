import React from 'react'

import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { fromUnixTime, getUnixTime } from 'date-fns'
import DatePicker from 'react-datepicker'

interface DateInputProps {
  size?: 'sm' | 'md'
  /** Unix timestamp */
  value: number
  onChange: (date: number) => void
  showTimeSelect?: boolean
  isDisabled?: boolean
  format?: string
}

export const DateInput: React.FC<DateInputProps> = (props) => {
  const { value, onChange, ...rest } = props
  const date = value ? fromUnixTime(value) : null

  return (
    <Flex w="100%">
      <DatePicker
        selected={date}
        maxDate={new Date()}
        onChange={(newDate: Date) => {
          onChange(getUnixTime(newDate))
        }}
        placeholderText="Date"
        dateFormat={
          props.format
            ? props.format
            : props.showTimeSelect
            ? 'dd/MM/yyyy h:mm aa'
            : 'dd/MM/yyyy'
        }
        customInput={
          <CustomInput
            date={date}
            size={props.size}
            isDisabled={props.isDisabled}
          />
        }
        {...rest}
      />
    </Flex>
  )
}

interface DateRangeInputProps {
  size?: 'sm' | 'md'
  /** Unix timestamp */
  start: number
  /** Unix timestamp */
  end: number
  onChange: (start: number, end: number) => void
  showTimeSelect?: boolean
  isDisabled?: boolean
}

export const DateRangeInput: React.FC<DateRangeInputProps> = (props) => {
  const { start, end, onChange, ...rest } = props

  const startDate = start ? fromUnixTime(start) : null
  const endDate = end ? fromUnixTime(end) : null

  return (
    <Flex w="100%">
      <DatePicker
        selected={startDate}
        onChange={(newStartDate: Date) => {
          const newEndDate = newStartDate > endDate ? null : endDate
          onChange(getUnixTime(newStartDate), getUnixTime(newEndDate))
        }}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        placeholderText="Start date"
        dateFormat={props.showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
        customInput={
          <CustomInput
            size={props.size}
            date={startDate}
            isDisabled={props.isDisabled}
            left
          />
        }
        {...rest}
      />
      <DatePicker
        selected={endDate}
        onChange={(newEndDate: Date) => {
          onChange(getUnixTime(startDate), getUnixTime(newEndDate))
        }}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={new Date()}
        isDisabled={!startDate}
        placeholderText="End date"
        dateFormat={props.showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
        customInput={
          <CustomInput
            size={props.size}
            date={endDate}
            isDisabled={props.isDisabled || !startDate}
            right
          />
        }
        {...rest}
      />
    </Flex>
  )
}

interface CustomInputProps {
  size?: 'sm' | 'md'
  date?: Date
  left?: boolean
  right?: boolean
  placeholder?: string
  isDisabled?: boolean
}
const CustomInput = React.forwardRef<any, CustomInputProps>(
  ({ value, onClick, ...props }: any, ref: any) => {
    return (
      <Flex
        ref={ref}
        bg={props.isDisabled ? 'gray.50' : '#ffffff'}
        border="1px solid"
        borderColor="gray.200"
        borderLeftWidth={props.right ? 0 : '1px'}
        borderRadius={
          props?.right ? '0 6px 6px 0' : props?.left ? '6px 0 0 6px' : '6px'
        }
        alignItems="center"
        lineHeight={0}
        px="12px"
        width="100%"
        height={props.size === 'sm' ? '34px' : '42px'}
        onClick={!props.isDisabled ? onClick : null}
        _hover={{
          borderColor: 'gray.300',
        }}
      >
        <Tooltip label={value || props?.placeholder} aria-label="Date tooltip">
          <Text
            color={value ? 'black' : 'gray.500'}
            lineHeight="20px"
            overflow="scroll"
            noOfLines={1}
          >
            {value || props?.placeholder}
          </Text>
        </Tooltip>
      </Flex>
    )
  }
)
CustomInput.displayName = 'CustomInput'
CustomInput.defaultProps = {
  size: 'sm',
}
