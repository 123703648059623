import React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router'

import { ColumnLayout, Layout } from 'src/core/components'
import { Card, CardBody } from 'src/core/components/Card'
import { route } from 'src/core/helpers'

import { PurchaseOrderDetails } from '../components/PurchaseOrderDetails'
import { PurchaseOrderItems } from '../components/PurchaseOrderItems'
import { usePurchaseOrder } from '../hooks/usePurchaseOrder'
import { useQueryParams } from 'src/core/hooks'

const PurchaseOrder: React.FC = () => {
  const history = useHistory()
  const { id: purchase_order_id }: any = useParams()
  const isRef = useQueryParams('is_ref')

  const usePurchaseOrderHook = usePurchaseOrder(
    purchase_order_id,
    isRef === 'true'
  )
  const { isLoading, purchaseOrder } = usePurchaseOrderHook

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Purchase Order' : `#${purchaseOrder?.ref || '-'}`,
        backText: 'Purchase Orders',
        onBack: () => history.push(route('purchase_orders')),
      }}
      permission="view_purchase_orders"
      bodyProps={{ padding: 0, overflowY: 'hidden' }}
    >
      <ColumnLayout templateColumns="2fr 1fr">
        <ColumnLayout.Main>
          <Card>
            <CardBody>
              <PurchaseOrderItems {...usePurchaseOrderHook} />
            </CardBody>
          </Card>
        </ColumnLayout.Main>
        <ColumnLayout.Right bg="white">
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner size="sm" color="primary.600" />
            </Flex>
          )}

          {!isLoading && !!purchaseOrder ? (
            <PurchaseOrderDetails {...usePurchaseOrderHook} />
          ) : null}
        </ColumnLayout.Right>
      </ColumnLayout>
    </Layout>
  )
}

export default PurchaseOrder
