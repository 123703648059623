import { chunkArray } from 'src/core/helpers'
import { CoreService, CoreServiceProps } from 'src/core/services'
import { IPurchaseOrder, IPurchaseOrderItem } from './purchaseOrder.type'

export class PurchaseOrderService<
  T = IPurchaseOrder,
  S = IPurchaseOrderItem
> extends CoreService<T> {
  constructor() {
    super('purchase_order')
  }

  fetchByRef(ref: string): Promise<T> {
    return new Promise((resolve, reject) => {
      const cacheKey = `${this.model}_${ref}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .get(`${this.model}/read_by_ref/?ref=${ref}`)
            .then(({ data }) => {
              if (data.code === 200 && data?.data?.[0]) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchItems(purchaseOrderId: string): Promise<S[]> {
    return new Promise((resolve, reject) => {
      const cacheKey = `${this.model}_items_${purchaseOrderId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .get(
              `purchase_order_item/read_by_purchase_order/?purchase_order_id=${purchaseOrderId}`
            )
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  upsertItem(payload: S): Promise<any> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`purchase_order_item/upsert`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  upsertItemBulk(payloads: S[], chunkSize = 125): Promise<any> {
    this.model = 'purchase_order_item'
    const chunks = chunkArray<S>(payloads, chunkSize)

    return new Promise((resolve) => {
      this.bulkAction(chunks, 'create_bulk').then(({ succeeded, failed }) => {
        this.model = 'purchase_order'
        resolve({ succeeded, failed })
      })
    })
  }

  removeItem(details: { _id: string }): Promise<void> {
    const formData = new FormData()
    formData.append('_id', details._id)

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`purchase_order_item/remove`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve()
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  execute(payload: {
    _id: string
    commit: boolean
    error_on_empty: boolean
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData()

      formData.append('_id', payload._id)
      formData.append('commit', JSON.stringify(payload.commit))
      formData.append('error_on_empty', JSON.stringify(payload.error_on_empty))

      try {
        this.http.post(`${this.model}/execute`, formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export const purchaseOrderService = new PurchaseOrderService()
export type PurchaseOrderServiceProps =
  | CoreServiceProps
  | keyof PurchaseOrderService
export default PurchaseOrderService
