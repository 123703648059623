import React from 'react'
import { useTheme } from '@chakra-ui/system'
import { FiCheck, FiX } from 'react-icons/fi'

interface IconProps {
  checked?: boolean
  color?: string
  className?: string
  onClick?: (arg) => void
}

export const TableCellMarkIcon: React.FC<IconProps> = ({
  checked,
  color,
  ...rest
}) => {
  const { colors } = useTheme()

  return (
    <>
      {checked ? (
        <span {...rest}>
          <FiCheck size={20} color={color || colors.success['600']} />
        </span>
      ) : (
        <span {...rest}>
          <FiX size={20} color={color || colors.critical['600']} />
        </span>
      )}
      {/* Hidden text for export sheet */}
      <span className="d-none">{checked ? 'Yes' : 'No'}</span>
    </>
  )
}

export default TableCellMarkIcon
