import React from 'react'

import { Autocomplete, AutocompleteProps } from 'src/core/components'
import { useFetch } from 'src/core/hooks'
import { sortByKey } from 'src/core/helpers'

import { ISeller } from '../seller.type'
import { sellerService } from '../seller.service'
interface IProps extends AutocompleteProps {
  exclude?: string[]
  disableSelectAll?: boolean
}
export const SellerAutocomplete: React.FC<IProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [sellers] = useFetch<ISeller[]>(sellerService, 'fetch')

  const sellerOptions = React.useMemo(() => {
    if (sellers?.length) {
      const options = []

      if (!props.isMulti && !props.disableSelectAll) {
        options.push({ value: '*', label: 'All Hubs' })
      }

      const filtered = sellers
        .filter((seller) => {
          return (
            seller.status === 'active' && !props?.exclude?.includes(seller?._id)
          )
        })
        .map((seller) => ({
          value: seller._id,
          label: seller.name,
        }))

      return [...options, ...sortByKey(filtered, 'label')]
    }

    return []
  }, [sellers, props.isMulti, props.disableSelectAll, props?.exclude])

  return (
    <Autocomplete
      size="sm"
      options={sellerOptions}
      value={value}
      placeholder={props.placeholder || 'Select Hub'}
      onChange={(selected) => onChange(selected)}
      isActive={value?.value !== '*'}
      isDisabled={!sellerOptions || !sellerOptions.length}
      {...props}
    />
  )
}
