import React from 'react'

import {
  useTheme,
  Flex,
  Heading,
  Box,
  HStack,
  Tag,
  TagLeftIcon,
  TagLabel,
} from '@chakra-ui/react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { FiChevronRight, FiDatabase, FiInbox } from 'react-icons/fi'

import { IStoreState } from 'src/bootstrap/store/types'

export interface HeaderProps {
  title: string
  metaTitle?: string
  backText?: string
  onBack?: () => void
}

export const Header: React.FC<HeaderProps> = ({
  title,
  metaTitle,
  ...props
}) => {
  const { colors, sizes } = useTheme()
  const { env, sidebarOpen, appVersion } = useSelector(
    (state: IStoreState) => ({
      env: state.global.env,
      appVersion: state.global.version,
      sidebarOpen: state.config.sidebarOpen,
    })
  )

  const hasBack = props.onBack && props.backText

  return (
    <>
      <Box
        as="header"
        position="fixed"
        width={`calc(100% - ${
          sizes.layout[sidebarOpen ? 'sidebarWidth' : 'miniSidebarWidth']
        })`}
        top={0}
        zIndex={99}
        overflowX="hidden"
        transition="all 400ms"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTitle ? metaTitle : title} - Suplias Distro</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
        <Flex
          bg="white"
          padding="18px 20px"
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="gray.100"
          height="layout.headerHeight"
        >
          <Flex alignItems="center">
            <HStack spacing="5px" alignItems="center">
              <Heading
                fontSize="lg"
                fontWeight="semibold"
                cursor="default"
                transition="all 300ms"
                _hover={{ color: 'primary.600' }}
                onClick={props.onBack}
              >
                {props.backText}
              </Heading>

              {hasBack && (
                <FiChevronRight color={colors.gray['500']} size={16} />
              )}

              <Heading
                title={title}
                fontSize="lg"
                noOfLines={1}
                fontWeight="semibold"
                color={hasBack ? 'gray.600' : ''}
              >
                {title}
              </Heading>
            </HStack>
          </Flex>

          <HStack alignItems="center" spacing={2}>
            <Tag
              size="sm"
              variant="subtle"
              colorScheme={
                env === 'development'
                  ? 'critical'
                  : env === 'staging'
                  ? 'warning'
                  : env === 'production'
                  ? 'success'
                  : 'gray'
              }
            >
              <TagLeftIcon boxSize="12px" as={FiDatabase} />
              <TagLabel>
                {env} v{appVersion}
              </TagLabel>
            </Tag>
            <FiInbox size={20} color={colors.gray['600']} title="Inbox" />
          </HStack>
        </Flex>
      </Box>

      <Box height="layout.headerHeight"></Box>
    </>
  )
}

export default Header
