import Sellers from './pages/Sellers.page'
import SellerEditor from './pages/SellerEditor.page'
import SellerManufacturers from './pages/SellerManufacturers.page'

import { IRoute } from 'src/bootstrap/routes'

export const sellerRoutes: IRoute[] = [
  {
    id: 'sellers',
    path: '/sellers',
    component: Sellers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'seller_create',
    path: '/sellers/create',
    component: SellerEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'seller_update',
    path: '/sellers/:id/update',
    component: SellerEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'seller_manufacturers',
    path: '/sellers/:id/manufacturers',
    component: SellerManufacturers,
    requiresAuth: true,
    exact: true,
  },
]

export default sellerRoutes
