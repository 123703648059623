import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import DistributorOfficerForm from '../components/DistributorOfficerForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import {
  DistributorOfficerService,
  DistributorOfficerServiceProps,
} from '../distributorOfficer.service'
import { IDistributorOfficer } from '../distributorOfficer.type'
import { route } from 'src/core/helpers/route.helper'

const distributorOfficerService = new DistributorOfficerService()

const DistributorOfficerEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: distributor_id }: any = useParams()

  const permission = distributor_id
    ? 'update_distributor_officer'
    : 'signup_distributor_officer'

  const [distributorOfficer, isLoading, , setDistributorOfficer] = useFetch<
    IDistributorOfficer,
    DistributorOfficerServiceProps
  >(
    distributorOfficerService,
    'fetchById',
    [distributor_id],
    !!distributor_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Distributor Officer'
          : distributorOfficer?.name || 'New Distributor Officer',
        backText: 'Distributor Officers',
        onBack: () => history.push(route('distributor_officers')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={distributor_id ? 'update' : 'create'}
        model={distributorOfficer}
        isLoading={isLoading}
        isUserModel
      >
        {distributor_id ? (
          <DistributorOfficerForm
            type="update"
            distributorOfficer={distributorOfficer}
            onUpdate={(updatedValues) => {
              setDistributorOfficer({ ...distributorOfficer, ...updatedValues })
            }}
          />
        ) : (
          <DistributorOfficerForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default DistributorOfficerEditor
