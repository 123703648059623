import React from 'react'

import { Badge, Button } from '@chakra-ui/react'
import { Table } from 'react-bootstrap'
import { ErrorBox, Pagination } from 'src/core/components'

import { IDistributorOfficer } from '../distributorOfficer.type'
import { paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import { Link } from 'react-router-dom'

interface IProps {
  distributorOfficers: IDistributorOfficer[]
  filters: any
  setFilters: (filters: any) => void
}

const DistributorOfficersTable: React.FC<IProps> = ({
  distributorOfficers,
  filters,
  setFilters,
}) => {
  const paginatedDistributorOfficers = React.useMemo(() => {
    return distributorOfficers
      ? paginate(distributorOfficers, filters.page)
      : []
  }, [filters.page, distributorOfficers])

  return (
    <>
      {!paginatedDistributorOfficers.length ? (
        <ErrorBox summary="No distributor officers found" my={50} />
      ) : null}

      {paginatedDistributorOfficers.length ? (
        <div className="table-wrapper padded">
          <Table hover responsive>
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Name</th>
                <th>Status</th>
                <th>Role</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedDistributorOfficers.map((distributorOfficer, index) => (
                <tr key={distributorOfficer._id}>
                  <td className="align-middle text-center">
                    {index + 1 + (filters.page - 1) * 10}
                  </td>
                  <td className="align-middle">{distributorOfficer.name}</td>
                  <td className="align-middle">
                    <Badge
                      colorScheme={
                        distributorOfficer.status === 'active'
                          ? 'success'
                          : 'critical'
                      }
                    >
                      {distributorOfficer.status}
                    </Badge>
                  </td>
                  <td className="align-middle text-capitalize">
                    {distributorOfficer.role}
                  </td>
                  <td className="align-middle">{distributorOfficer.phone}</td>
                  <td className="align-middle">{distributorOfficer.email}</td>
                  <td>
                    <Link
                      to={route('distributor_officer', {
                        id: distributorOfficer._id,
                        tab: 'balance',
                      })}
                    >
                      <Button size="sm" variant="outline">
                        View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}

      <Pagination
        my={5}
        itemsPerPage={10}
        totalItems={distributorOfficers.length}
        currentPage={filters.page}
        onPageChange={(page) => setFilters({ page })}
      />
    </>
  )
}

export default DistributorOfficersTable
