import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import useMounted from 'src/core/hooks/useMounted'
import { useQueryParams } from 'src/core/hooks/useQueryParams'

import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'
import {
  ISalesOfficer,
  ISalesOfficerFilters,
  SalesOfficerRole,
} from '../salesOfficer.type'
import { LedgerSubhead } from 'src/applets/ledger'
import { parseQueryParam, serializeQueryParam, trans } from 'src/core/helpers'

interface FilterSalesOfficersHook {
  filteredSalesOfficers: ISalesOfficer[]
  filters: ISalesOfficerFilters
  setFilters: (filters: ISalesOfficerFilters) => void
  activeFilters: string[]
  page: number
  setPage: (page: number) => void
}

export const useFilterSalesOfficers = (
  salesOfficers: any
): FilterSalesOfficersHook => {
  const history = useHistory()
  const isMounted = useMounted()

  const [filters, setFilters] = useState<any>({
    status: useQueryParams('status', 'active'),
    role: useQueryParams('role', SalesOfficerRole.Rep),
    sellers: parseQueryParam(useQueryParams('sellers', '')),
    page: Number(useQueryParams('page', 1)),
    search: useQueryParams('search'),
  })

  const updateQueryParams = ({ page, sellers, ...params }): void => {
    history.push(
      route('sales_officers', null, {
        ...params,
        sellers: serializeQueryParam(sellers),
        page: page === 1 ? null : page,
      })
    )
  }

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      setFilters({
        status: query.get('status') || '*',
        role: query.get('role') || SalesOfficerRole.Manager,
        sellers: parseQueryParam(query.get('sellers')),
        page: Number(query.get('page')) || 1,
        search: query.get('search') || null,
      })
    })

    return () => {
      stopListening() // stop listening for route change
    }
  }, [history, isMounted])

  const filteredSalesOfficers = React.useMemo(() => {
    let filtered: ISalesOfficer[] = salesOfficers || []

    if (filtered.length) {
      filtered = filtered.map((salesOfficer) => {
        let walletBalance = 0

        if (salesOfficer?.ledgers) {
          const walletLedger = salesOfficer?.ledgers?.[LedgerSubhead.Wallet]

          if (walletLedger) {
            walletBalance = walletLedger.balance
          }
        }

        return { ...salesOfficer, wallet_balance: walletBalance }
      })

      if (filters?.status !== '*') {
        filtered = filtered.filter((salesOfficer) => {
          return salesOfficer.status === filters.status
        })
      }

      if (filters?.role !== '*') {
        filtered = filtered.filter((salesOfficer) => {
          return salesOfficer.role === filters.role
        })
      }

      if (filters?.search) {
        filtered = filtered.filter((salesOfficer) => {
          return basicSearch(salesOfficer.name, filters.search)
        })
      }

      if (filters?.sellers !== '*') {
        const list = filters.sellers?.map?.(({ value }) => value)
        if (list) {
          filtered = filtered.filter(({ seller_id }) => {
            return list.includes(seller_id)
          })
        }
      }
    }

    return filtered
  }, [
    salesOfficers,
    filters.status,
    filters.search,
    filters.sellers,
    filters.role,
  ])

  const activeFilters = React.useMemo(() => {
    const activeFilters: string[] = []

    if (filters?.status !== '*') {
      activeFilters.push(`Status (${filters.status})`)
    }

    if (filters?.role !== '*') {
      activeFilters.push(`Role (${trans(`sales_officer.${filters.role}`)})`)
    }

    if (filters?.sellers !== '*') {
      const list = filters?.sellers?.map?.(({ label }) => label).join(', ')
      list && activeFilters.push(`Seller (${list})`)
    }

    return activeFilters
  }, [filters])

  return {
    filteredSalesOfficers,
    filters,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, ...updated, page: 1 })
    },
    page: filters.page,
    activeFilters,
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
  }
}

export default useFilterSalesOfficers
