import React from 'react'

import { Spinner, Flex, Box } from '@chakra-ui/react'
import { FiCreditCard, FiFileText } from 'react-icons/fi'
import { useParams, useHistory } from 'react-router-dom'

import {
  Layout,
  ErrorBox,
  FolderTabs,
  FolderTab,
  Card,
  ColumnLayout,
} from 'src/core/components'
import { route } from 'src/core/helpers/route.helper'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'
import { LedgerHead, LedgerLogs, LedgerSubhead } from 'src/applets/ledger'

import DistributorDetails from '../components/DistributorDetails'
import { distributorService } from '../distributor.service'
import { IDistributor } from '../distributor.type'

export enum PageTab {
  Balance = 'balance',
  BankAccount = 'bank-account',
}

const Distributor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()

  const { id: distributor_id, tab: tabIndex }: any = useParams()

  const [distributor, isLoading] = useFetch<IDistributor>(
    distributorService,
    'fetchById',
    [distributor_id],
    userCan('view_distributors')
  )

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Distributor'
          : distributor?.name || 'Distributor not found',
        backText: 'Distributors',
        onBack: () => history.push(route('distributors')),
      }}
      permission="view_distributors"
      bodyProps={{ padding: 0, overflowY: 'hidden' }}
    >
      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : (
        <>
          {distributor ? (
            <ColumnLayout>
              <ColumnLayout.Main>
                <FolderTabs
                  defaultIndex={tabIndex}
                  onChange={(tabIndex) => {
                    history.push(
                      route('distributor', {
                        id: distributor._id,
                        tab: tabIndex,
                      })
                    )
                  }}
                >
                  <FolderTab
                    index={PageTab.Balance}
                    title="Balance History"
                    leftIcon={<FiCreditCard />}
                  />
                  <FolderTab
                    index={PageTab.BankAccount}
                    title="Bank Details"
                    leftIcon={<FiFileText />}
                  />
                </FolderTabs>

                <Card
                  overflow="clip"
                  borderTopLeftRadius={tabIndex === PageTab.Balance ? 0 : null}
                >
                  {tabIndex === PageTab.Balance ? (
                    <LedgerLogs
                      owner={distributor?._id}
                      head={LedgerHead.Distributor}
                      subhead={LedgerSubhead.Wallet}
                    />
                  ) : tabIndex === PageTab.BankAccount ? (
                    <Box py={10}>
                      <ErrorBox summary="No bank account details found." />
                    </Box>
                  ) : null}
                </Card>
              </ColumnLayout.Main>

              <ColumnLayout.Right bg="#fff">
                <DistributorDetails distributor={distributor} />
              </ColumnLayout.Right>
            </ColumnLayout>
          ) : (
            <ErrorBox
              title="Distributor officer not found!"
              summary="Check the URL and try again."
              my={50}
            />
          )}
        </>
      )}
    </Layout>
  )
}

export default Distributor
