import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import LocationForm from '../components/LocationForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import { LocationService, LocationServiceProps } from '../location.service'
import { ILocation } from '../location.type'
import { route } from 'src/core/helpers/route.helper'

const locationService = new LocationService()

const LocationEditor: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: location_id }: any = useParams()

  const permission = location_id ? 'update_location' : 'create_location'

  const [location, isLoading, , setLocation] = useFetch<
    ILocation,
    LocationServiceProps
  >(
    locationService,
    'fetchById',
    [location_id],
    !!location_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading ? 'Location' : location?.name || 'New Location',
        backText: 'Locations',
        onBack: () => history.push(route('locations')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={location_id ? 'update' : 'create'}
        model={location}
        isLoading={isLoading}
      >
        {location_id ? (
          <LocationForm
            type="update"
            location={location}
            onUpdate={(updatedValues) => {
              setLocation({ ...location, ...updatedValues })
            }}
          />
        ) : (
          <LocationForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default LocationEditor
