import { CoreService, CoreServiceProps } from 'src/core/services'
import { IDistributor } from './distributor.type'

export class DistributorService extends CoreService<IDistributor> {
  constructor() {
    super('distributor')
  }

  fetchProducts(distributorId): Promise<any[]> {
    const formData = new FormData()
    formData.append('distributor_id', distributorId)

    return new Promise((resolve, reject) => {
      const cacheKey = `distributor_products_${distributorId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`distributor_product/read_by_distributor`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateProducts(type, payload): Promise<any> {
    this.model = 'distributor_product'

    return new Promise((resolve, reject) => {
      super
        [type](payload)
        .then((data) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => {
          /** Reset to original model */
          this.model = 'distributor'
        })
    })
  }

  fetchSeller(distributorId): Promise<any[]> {
    const formData = new FormData()
    formData.append('distributor_id', distributorId)

    return new Promise((resolve, reject) => {
      const cacheKey = `distributor_seller_${distributorId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`distributor_seller/read_by_distributor`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data.data.length) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateSeller(type, payload): Promise<void> {
    this.model = 'distributor_seller'

    return new Promise((resolve, reject) => {
      super
        [type](payload)
        .then(() => resolve())
        .catch((error) => reject(error))
        .finally(() => {
          /** Reset to original model */
          this.model = 'distributor'
        })
    })
  }
}

export const distributorService = new DistributorService()

export type DistributorServiceProps =
  | CoreServiceProps
  | keyof DistributorService
