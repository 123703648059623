import React, { useState } from 'react'

import { useMounted, useToast } from 'src/core/hooks'

import { IPurchaseOrder, IPurchaseOrderItem } from '../purchaseOrder.type'
import { purchaseOrderService } from '../purchaseOrder.service'

type Action = (item: IPurchaseOrderItem) => Promise<any>
export interface IUsePurchaseOrderItems {
  purchaseOrderItems: IPurchaseOrderItem[]
  setPurchaseOrderItems: React.Dispatch<IPurchaseOrderItem[]>
  addPurchaseOrderItem: Action
  updatePurchaseOrderItem: Action
  deletePurchaseOrderItem: Action
  isLoadingItems: boolean
  isUpdatingItems: boolean
}

export const usePurchaseOrderItems = (
  purchaseOrder: IPurchaseOrder
): IUsePurchaseOrderItems => {
  const isMounted = useMounted()

  const { toast } = useToast()

  const [isLoadingItems, setIsLoadingItems] = useState<boolean>(true)
  const [isUpdatingItems, setIsUpdatingItems] = useState<boolean>(true)

  const [purchaseOrderItems, setPurchaseOrderItems] =
    useState<IPurchaseOrderItem[]>()

  React.useEffect(() => {
    const fetchOrderGroupItems = (): void => {
      setIsUpdatingItems(true)

      purchaseOrderService
        .fetchItems(purchaseOrder?._id)
        .then((items) => {
          isMounted.current && setPurchaseOrderItems(items.reverse())
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
        })
        .finally(() => {
          isMounted.current && setIsLoadingItems(false)
          isMounted.current && setIsUpdatingItems(false)
        })
    }

    purchaseOrder?._id && fetchOrderGroupItems()
  }, [isMounted, toast, purchaseOrder])

  const addPurchaseOrderItem: Action = (item: IPurchaseOrderItem) => {
    const payload = {
      ...item,
      quantity_accepted: item.quantity,
      product_id: item.product._id,
      purchase_order_id: purchaseOrder._id,
    }

    return new Promise((resolve, reject) => {
      purchaseOrderService
        .upsertItem(payload)
        .then(() => {
          toast({
            description: 'Product added to purchase order!',
            status: 'success',
          })

          const newAmount = Number(purchaseOrder.amount) + Number(item.amount)
          resolve({ amount: newAmount })
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
          reject()
        })
    })
  }

  const updatePurchaseOrderItem: Action = (item) => {
    const payload = {
      ...item,
      quantity_accepted: item.quantity,
    }

    delete payload.product

    return new Promise((resolve, reject) => {
      purchaseOrderService
        .upsertItem(payload)
        .then(() => {
          toast({
            description: 'Purchase order product updated',
            status: 'success',
          })
          resolve(item)
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
          reject()
        })
    })
  }

  const deletePurchaseOrderItem: Action = (item: IPurchaseOrderItem) => {
    const newAmount = Number(purchaseOrder.amount) - Number(item.amount)

    return new Promise((resolve, reject) => {
      purchaseOrderService
        .removeItem({ _id: item._id })
        .then(() => {
          toast({
            description: 'Product removed from purchase order.',
            status: 'success',
          })
          resolve({ amount: newAmount })
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
          reject()
        })
    })
  }

  return {
    purchaseOrderItems,
    setPurchaseOrderItems,
    addPurchaseOrderItem,
    updatePurchaseOrderItem,
    deletePurchaseOrderItem,
    isLoadingItems,
    isUpdatingItems,
  }
}
