import React from 'react'

import { Button, Select, IconButton, Input, Spinner } from '@chakra-ui/react'
import { Flex, Box } from '@chakra-ui/layout'
import { FiTrash2 } from 'react-icons/fi'

import useMounted from 'src/core/hooks/useMounted'
import useToast from 'src/core/hooks/useToast'

import { IProduct, IProductTag, ProductTag as Tag } from '../../product.type'
import { productService } from '../../product.service'

interface IProps {
  type?: 'create' | 'update'
  product: IProduct
  productTag?: IProductTag
  /** Tags that have already been added */
  productTags?: IProductTag[]
  onUpdate: () => void
}

export const ProductTagForm: React.FC<IProps> = ({
  type,
  product,
  productTag,
  productTags,
  onUpdate,
}) => {
  const { addToast } = useToast()
  const isMounted = useMounted()
  const [isLoading, setIsLoading] = React.useState<any>(false)

  const [formValues, setFormValues] = React.useState<any>({
    tag: productTag ? productTag.tag : '',
    value: productTag ? productTag.value : '',
    product_id: product._id,
  })

  /**
   * Create or update tag
   *
   *  @param values
   */
  const handleSubmit = React.useCallback(
    (values: IProductTag): void => {
      setIsLoading(true)
      const finalValues: any = { ...values }

      if (type === 'update') {
        finalValues._id = productTag._id
      }

      productService
        .updateTag(type, finalValues)
        .then(() => {
          onUpdate()

          if (type === 'create' && isMounted.current) {
            setFormValues({
              tag: '',
              value: '',
              product_id: product._id,
            })
          }

          addToast(`Product tag successfully ${type}d.`, {
            appearance: 'success',
          })
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    },
    [addToast, onUpdate, product, productTag, type, isMounted]
  )

  /**
   * Delete product tag
   *
   * @param productTagId
   */
  const handleDelete = React.useCallback(
    (productTagId: string): void => {
      setIsLoading(true)

      productService
        .deleteTag(productTagId)
        .then(() => {
          onUpdate()
          addToast(`Product tag successfully deleted.`, {
            appearance: 'success',
          })
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    },
    [addToast, onUpdate, isMounted]
  )

  /**
   * Product tags should be unique.
   * Unset tags are tags that have not been set. **grin!
   */
  const unsetTags = React.useMemo(() => {
    const setTags: Tag[] = productTags.map((tag) => tag.tag)

    /** All unset excluding current selection */
    const unsetTags: Tag[] = Object.values(Tag).filter((tag) => {
      return (
        (type === 'create' && tag === formValues.tag) ||
        (type === 'update' && tag === productTag.tag) ||
        !setTags.includes(tag)
      )
    })

    return unsetTags
  }, [type, formValues, productTag, productTags])

  /**
   * Checks if form values are valid.
   */
  const validForm = React.useMemo(() => {
    let isValid: boolean

    // Check if values exist
    isValid = formValues.tag && formValues.value

    // Updating? Check if values have changed
    if (
      type === 'update' &&
      formValues.tag === productTag.tag &&
      formValues.value === productTag.value
    ) {
      isValid = false
    }

    return isValid
  }, [type, formValues, productTag])

  return (
    <Flex as="form" alignItems="center">
      {/* Tag */}
      <Select
        size="sm"
        name="tag"
        value={formValues.tag}
        onChange={({ target }) =>
          setFormValues({ ...formValues, tag: target.value })
        }
      >
        <option value="">Select tag</option>
        {unsetTags.map((tag, index) => (
          <option key={index} value={tag}>
            {tag}
          </option>
        ))}
      </Select>

      {/* Value */}
      <Input
        size="sm"
        name="value"
        ml={2}
        value={formValues.value}
        placeholder="Tag value"
        onChange={({ target }) =>
          setFormValues({ ...formValues, value: target.value })
        }
      />

      {isLoading ? (
        <Box ml={3}>
          <Spinner size="sm" color="primary.600" />
        </Box>
      ) : type === 'create' ? (
        <Button
          size="sm"
          colorScheme="primary"
          width="100%"
          ml={3}
          isDisabled={!validForm}
          onClick={() => validForm && handleSubmit(formValues)}
        >
          Add
        </Button>
      ) : type === 'update' ? (
        <>
          <Button
            size="sm"
            ml={2}
            variant="outline"
            width="100%"
            isDisabled={!validForm}
            onClick={() => validForm && handleSubmit(formValues)}
          >
            Update
          </Button>

          <IconButton
            aria-label="Delete"
            size="sm"
            variant="ghost"
            colorScheme="critical"
            ml={2}
            icon={<FiTrash2 />}
            onClick={() => handleDelete(productTag._id)}
          />
        </>
      ) : null}
    </Flex>
  )
}

ProductTagForm.defaultProps = {
  type: 'create',
}

export default ProductTagForm
