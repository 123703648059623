import React from 'react'
import { sortByKey } from 'src/core/helpers/filters.helper'

interface IOptions {
  accessor: string
  dir: 'asc' | 'desc'
}

type IGetThProps = (accessor: string) => {
  isSortable: true
  sortDir: 'asc' | 'desc'
  onClick: VoidFunction
}

export interface IUseSort {
  toggleDir: (accessor: string) => void
  getThProps: IGetThProps
  sorted: {
    /** Sorted data */
    data: any
    /** Sorted at timestamp */
    timestamp: Date
  }
}

export const useSort = (data: any, options: IOptions): IUseSort => {
  const [sortBy, setSortBy] = React.useState<IOptions>(options)
  /**
   * useMemo does not refresh sorted buyers when sort
   * params change. We have to track that ourselves
   **/
  const [sortedAt, setSortedAt] = React.useState<Date>(new Date())

  const toggleDir = (accessor): void => {
    setSortBy({
      accessor,
      dir:
        sortBy.accessor !== accessor
          ? 'desc'
          : sortBy.dir === 'desc'
          ? 'asc'
          : 'desc',
    })
  }

  const getThProps: IGetThProps = (accessor) => {
    return {
      isSortable: true,
      sortDir: sortBy.accessor === accessor ? sortBy.dir : null,
      onClick: () => toggleDir(accessor),
    }
  }

  const sortedData = React.useMemo(() => {
    if (data?.length && sortBy?.accessor) {
      setSortedAt(new Date())
      return sortByKey(data, sortBy.accessor, sortBy.dir)
    }

    return data
  }, [data, sortBy])

  return {
    toggleDir,
    sorted: { data: sortedData, timestamp: sortedAt },
    getThProps,
  }
}

export default useSort
