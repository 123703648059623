import React from 'react'

import { Button, Flex, Box, Spinner, Text } from '@chakra-ui/react'
import { FiPlus, FiClock } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  Layout,
  SearchInput,
  ExportButton,
  PerPageSelect,
  ToggleDetailsButton,
  useExportable,
} from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'
import { Role } from 'src/bootstrap/permissions/roles'
import { quickPluralize } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'
import usePermission from 'src/core/hooks/usePermission'
import { IStoreState } from 'src/bootstrap/store/types'

import ProductFilters from '../components/ProductsTable/ProductsTableFilter'
import ProductsTable from '../components/ProductsTable/ProductsTable'
import SellerProductsTable from '../components/SellerProduct/SellerProductsTable'
import useProducts from '../hooks/useProducts'
import useFilterProducts from '../hooks/useFilterProducts'
import { formatCurrency } from 'src/core/helpers'

const exportableColumns = [
  { label: 'Name', accessor: 'name', selected: true },
  { label: 'Manufacturer', accessor: 'manufacturer.name', selected: true },
  { label: 'Brand', accessor: 'brand.name', selected: true },
  {
    label: 'Sellers',
    accessor: 'sellers',
    selected: false,
    modifier: (sellers) => {
      return sellers?.length
        ? sellers
            .map(({ seller, price }) => {
              return `${seller.name} @ ${formatCurrency(price)}`
            })
            .join(', ')
        : '-'
    },
  },
]

const Products: React.FC = () => {
  const { userCan } = usePermission()

  const user = useSelector((state: IStoreState) => state.user)

  const tableRef = React.useRef()

  const [products, setProducts, isLoading] = useProducts(
    user.role === Role.SellerManager ? 'seller' : 'global',
    user?.seller_id
  )

  const { filters, activeFilters, filteredProducts, setFilters, setPage } =
    useFilterProducts(products)

  // Add dynamic price column
  React.useEffect(() => {
    const hasPriceColumn = exportableColumns.some((column) => {
      return column.label === 'Price'
    })

    if (!hasPriceColumn) {
      exportableColumns.push(
        user.role === Role.SellerManager
          ? {
              label: 'Price',
              accessor: 'sellers',
              selected: true,
              modifier: (sellers) => {
                const currentSeller = sellers.find((product) => {
                  return product.seller_id === user.seller_id
                })

                return currentSeller?.price
              },
            }
          : { label: 'Price', accessor: 'price', selected: true }
      )
    }
  }, [user.role, user.seller_id])

  const exportable = useExportable(filteredProducts, exportableColumns)

  return (
    <Layout headerProps={{ title: 'Products' }} permission="view_products">
      <Card>
        <CardHeader
          as={Flex}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <Text>{quickPluralize(filteredProducts, 'product')}</Text>

            <Box ml={3}>
              <SearchInput
                placeholder="Search products"
                value={filters.search}
                onChange={(search) => {
                  setFilters({ ...filters, search, page: 1 })
                }}
                isDisabled={isLoading || !filteredProducts}
              />
            </Box>
          </Flex>

          {userCan('create_product') && (
            <Flex>
              <Link to={route('products_history')}>
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<FiClock size={16} />}
                >
                  Sales History
                </Button>
              </Link>

              <Link to={route('product_create')} className="ml-2">
                <Button
                  size="sm"
                  leftIcon={<FiPlus size={16} />}
                  colorScheme="primary"
                >
                  Create Product
                </Button>
              </Link>
            </Flex>
          )}
        </CardHeader>

        <CardHeader
          as={Flex}
          justifyContent="space-between"
          alignItems="center"
        >
          <ProductFilters
            filters={filters}
            setFilters={setFilters}
            activeFilters={activeFilters}
          />

          <Flex alignItems="center">
            <PerPageSelect
              value={filters.per_page}
              options={[10, 20, 50, 75, 100, 150, 200, 250, 300]}
              onChange={(per_page) => setFilters({ ...filters, per_page })}
            />

            <Box ml={2}>
              <ToggleDetailsButton
                isShowing={filters.show_details}
                onClick={() =>
                  setFilters({
                    ...filters,
                    show_details: !filters.show_details,
                  })
                }
                isDisabled={isLoading}
              />
            </Box>

            {/* Export button */}
            <ExportButton
              ml={2}
              filename="suplias-products"
              exportData={exportable?.data}
              exportableColumns={exportable.columns}
              toggleExportableColumn={exportable.toggleColumn}
              isDisabled={!exportable?.data?.length || isLoading}
            />
          </Flex>
        </CardHeader>

        <CardBody padding={0}>
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && products?.length ? (
            <ProductsPageContext.Provider
              value={{
                products,
                setProducts,
                filteredProducts,
                filters,
                setFilters,
                page: filters.page,
                setPage,
                tableRef,
              }}
            >
              {user.role === Role.SellerManager ? (
                <SellerProductsTable />
              ) : (
                <ProductsTable />
              )}
            </ProductsPageContext.Provider>
          ) : null}
        </CardBody>
      </Card>
    </Layout>
  )
}

export const ProductsPageContext = React.createContext(null)
export default Products
