import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Text: ComponentStyleConfig = {
  variants: {
    tinyTitle: {
      fontSize: 'sm',
      color: 'gray.600',
      fontWeight: 'medium',
    },
  },
}
