import React from 'react'

import { Autocomplete, AutocompleteProps } from 'src/core/components'
import { useFetch } from 'src/core/hooks'
import { sortByKey } from 'src/core/helpers'

import { manufacturerService } from '../manufacturer.service'
import { IManufacturer } from '../manufacturer.type'

interface IProps extends AutocompleteProps {
  exclude?: string[]
  disableSelectAll?: boolean
}

export const ManufacturerAutocomplete: React.FC<IProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [manufacturers] = useFetch<IManufacturer[]>(
    manufacturerService,
    'fetch'
  )

  const manufacturerOptions = React.useMemo(() => {
    if (manufacturers?.length) {
      const options = []

      if (!props.isMulti && !props.disableSelectAll) {
        options.push({ value: '*', label: 'All Manufactuers' })
      }

      const filtered = manufacturers
        .filter((manufacturer) => {
          return (
            manufacturer.status === 'active' &&
            !props?.exclude?.includes(manufacturer?._id)
          )
        })
        .map((manufacturer) => ({
          value: manufacturer._id,
          label: manufacturer.name,
        }))

      return [...options, ...sortByKey(filtered, 'label')]
    }

    return []
  }, [manufacturers, props.isMulti, props.disableSelectAll, props?.exclude])

  return (
    <Autocomplete
      size="sm"
      options={manufacturerOptions}
      value={value}
      placeholder={props.placeholder || 'Select Manufacturer'}
      onChange={(selected) => onChange(selected)}
      isActive={value?.value !== '*'}
      isDisabled={!manufacturerOptions || !manufacturerOptions.length}
      {...props}
    />
  )
}

export default ManufacturerAutocomplete
