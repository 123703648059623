import { DeliveryHandler, DeliveryIssue, DeliveryStatus } from './order.type'

export const orderLang = {
  [DeliveryStatus.DeliveredOnTime]: 'Delivered Within 24hrs',
  [DeliveryStatus.DeliveredLate]: 'Delivered After 24hrs',
  [DeliveryStatus.NotDelivered]: 'Not Delivered',

  [DeliveryIssue.CustomerUnavailable]: 'Customer Unavailable',
  [DeliveryIssue.ProductUnavailable]: 'Product Unavailable',
  [DeliveryIssue.OrderRejected]: 'Order Rejected',
  [DeliveryIssue.RepVanUnavailable]: 'Rep/Van Unavailable',

  [DeliveryHandler.Suplias]: 'Suplias',
  [DeliveryHandler.Betty]: 'Betty Distribution',
}
