import pluralize from 'pluralize'
import getObjectPath from 'lodash/get'

/**
 * Create refactor objects of an array.
 *
 * @param data
 * @param pairs - new key, accessor pairs to be created
 * @returns
 */
export const refactorObjects = (
  data: any[],
  pairs: [string, string][] = [
    ['value', '_id'],
    ['label', 'name'],
  ]
): any[] => {
  if (data?.length) {
    return data.map((item) => {
      const option = {}

      pairs.forEach(([key, accessor]: [string, string]) => {
        option[key] = item[accessor]
      })

      return option
    })
  }

  return []
}

/**
 * Paginate array of data
 *
 * @param data
 * @param page - current page
 * @param perPage
 */
export const paginate = (data: any[], page = 1, perPage = 10): any[] => {
  const indexOfLastData = page * perPage
  const indexOfFirstData = indexOfLastData - perPage
  return data.slice(indexOfFirstData, indexOfLastData)
}

/**
 * Conver fraction to percentage
 *
 * @param number
 */
export const toPercentage = (number: any): string => {
  return (number / 100).toLocaleString('en', { style: 'percent' })
}

export const slugify = (input): string => {
  if (input) {
    // make lower case and trim
    let slug = input.toLowerCase().trim()

    // replace invalid chars with dashes
    // slug = slug.replace(/[^a-z0-9-]/g, '-');

    slug = slug.replace(/_+/g, ' ') // Replace _ with ' '
    slug = slug.replace(/\s+/g, '-') // Replace spaces with -
    slug = slug.replace(/[^\w-]+/g, '') // Remove all non-word chars

    slug = slug.replace(/--+/g, '-') // Replace multiple - with single -
    slug = slug.replace(/^-+/, '') // Trim - from start of text
    slug = slug.replace(/-+$/, '') // Trim - from end of text

    return encodeURIComponent(slug)
  }

  return input
}

export const undoSlugify = (slug): string => {
  if (!slug) throw new Error('undoSlugify requires a slug param.')
  const words = slug.replace(/_+/g, ' ').split('-')

  for (let i = 0; i < words.length; i++) {
    const word = words[i]
    words[i] = word.charAt(0).toUpperCase() + word.slice(1)
  }

  return words.join(' ')
}

/**
 * Sort array of objects by key
 *
 * @param data
 * @param accessor - can be a deeply nested key
 * @param sort
 */
export const sortByKey = (
  data: any[],
  accessor: string,
  sort: 'asc' | 'desc' = 'asc'
): any[] => {
  return data.sort(function (a, b) {
    let aValue = getObjectPath(a, accessor)
    let bValue = getObjectPath(b, accessor)

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sort === 'asc' ? aValue - bValue : bValue - aValue
    } else {
      aValue = aValue ? aValue.toString().toLowerCase().trim() : false
      bValue = bValue ? bValue.toString().toLowerCase().trim() : false

      if (aValue < bValue) return sort === 'desc' ? 1 : -1
      if (aValue > bValue) return sort === 'desc' ? -1 : 1
    }

    return 0
  })
}

export const ordinalSuffix = (number: number): string => {
  const digits = [number % 10, number % 100]
  const ordinals = ['st', 'nd', 'rd', 'th']
  const oPattern = [1, 2, 3, 4]
  const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]

  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? number + ordinals[digits[0] - 1]
    : number + ordinals[3]
}

/**
 * Split array into chunks
 *
 * @param array
 * @param chunkSize
 */
export const chunkArray = <T>(array: T[], chunkSize = 1): T[][] => {
  // This prevents infinite loops
  if (chunkSize < 1) throw new Error('Chunk size must be positive')

  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

export const quickPluralize = (
  items: any,
  title: string,
  showOnlyText?: boolean
): string => {
  const length = typeof items === 'number' ? items : items?.length
  return showOnlyText
    ? pluralize(title, length)
    : `${length} ${pluralize(title, length)}`
}

/**
 * Converts a query parameter list of comma separated
 * value~label pairs to array of objects with value and
 * label keys.
 * Returns '*' if nothing.
 * @returns
 */
export const serializeQueryParam = (
  values: {
    value: string
    label: string
  }[]
): any => {
  let serialized = ''

  if (values?.length) {
    serialized = values
      ?.map?.(({ value, label }) => {
        return `${value}~${label}`
      })
      .join(',')
  }

  return serialized
}

/**
 * Converts a query parameter list of comma separated
 * value~label pairs to array of objects with value and
 * label keys.
 * Returns '*' if nothing.
 * @returns
 */
export const parseQueryParam = (list: string): any => {
  let options: any = '*'

  if (list && /([~]+)/.test(list)) {
    options = list?.split(',')?.map((item) => {
      const split = item.replace(/\[|\]/g, '').split('~')
      return { value: split?.[0], label: split?.[1] }
    })
  }

  return options
}
