import React from 'react'

import { Button } from '@chakra-ui/react'
import { BsFileText } from 'react-icons/bs'

interface IProps {
  label?: string
  documentRef: any
  isDisabled?: boolean
}

export const PrintButton: React.FC<IProps> = ({
  label,
  documentRef,
  isDisabled,
}) => {
  if (!documentRef) throw new Error('Element ref required')

  const printFrameRef = React.useRef<any>()

  const handlePrint = (): void => {
    const content = documentRef.current
    const printWindow = printFrameRef?.current?.contentWindow
    printWindow.document.open()
    printWindow.document.write(content.innerHTML)
    printWindow.document.close()
    printWindow.focus()
    printWindow.document.margin = 0
    printWindow.print()
  }

  return (
    <>
      <iframe
        ref={printFrameRef}
        title="Print Area"
        style={{
          height: '0px',
          width: '0px',
          position: 'absolute',
          opacity: 0,
        }}
      ></iframe>

      <Button
        size="sm"
        variant="outline"
        title="Print"
        leftIcon={<BsFileText size={18} />}
        isDisabled={isDisabled || !documentRef}
        onClick={handlePrint}
      >
        {label || 'Print'}
      </Button>
    </>
  )
}

export default PrintButton
