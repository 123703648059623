import React from 'react'
import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { FiAlertCircle, FiXCircle } from 'react-icons/fi'

import { IQuantityInput } from 'src/core/components'
import { EditForm, AcceptForm } from './OrderProductForms'
import { Product, Quantity, Amount, Options } from './OrderProductParts'

interface OrderProductProps {
  item: any
  parent: any
  updateItem: (item: any) => Promise<any>
  deleteItem?: (item: any) => Promise<any>
  setParent?: (order: any) => void
  isEditable?: boolean
  isAmountEditable?: boolean
  isAcceptable?: boolean
  quantityInputProps?: IQuantityInput
  outOfStock: boolean
  quantityAvailable: number
}

export const OrderProduct: React.FC<OrderProductProps> = (props) => {
  const { item, parent, isAcceptable } = props

  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [acceptMode, setAcceptMode] = React.useState<boolean>(isAcceptable)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)
  const [isPristine, setIsPristine] = React.useState<boolean>(true)

  const [localItem, setLocalItem] = React.useState<any>(item)
  const [updatedRate, setUpdatedRate] = React.useState<number>()
  const [updatedQuantity, setUpdatedQuantity] = React.useState<number>()
  const [updatedQuantityAccepted, setUpdatedQuantityAccepted] =
    React.useState<number>()

  // Sync props.item with localItem
  React.useEffect(() => {
    setLocalItem(item)
  }, [item])

  // Sync props.isAcceptable with acceptMode
  React.useEffect(() => {
    setAcceptMode(isAcceptable)
    setUpdatedQuantityAccepted(localItem.quantity_accepted)
  }, [isAcceptable, localItem.quantity_accepted])

  const computed = React.useMemo(() => {
    const _oldRate =
      localItem.amount / (localItem.quantity / localItem.product.case_count)

    const _quantity = editMode ? updatedQuantity : localItem?.quantity
    const _amount = editMode
      ? (updatedRate || _oldRate) * (_quantity / localItem.product.case_count)
      : localItem.amount
    const _newRate = _amount / (_quantity / localItem.product.case_count)

    return {
      oldRate: isFinite(_oldRate) ? _oldRate : 0,
      newRate: isFinite(_newRate) ? _newRate : 0,
      cases: Math.floor(_quantity / localItem.product.case_count),
      units: _quantity % localItem.product.case_count,
      amount: _amount,
    }
  }, [updatedQuantity, updatedRate, localItem, editMode])

  const handleUpdate = (): void => {
    setIsUpdating(true)

    const updatedParentAmount =
      parent.amount - localItem.amount + computed.amount

    const updatedItem = {
      ...localItem,
      amount: computed.amount,
    }

    if (acceptMode) {
      updatedItem.quantity_accepted = updatedQuantityAccepted
    } else {
      updatedItem.quantity = updatedQuantity
    }

    props
      .updateItem(updatedItem)
      .then(() => {
        setEditMode(false) // Close edit form
        setLocalItem(updatedItem)
        props.setParent({ amount: updatedParentAmount }) // Update order amount
      })
      .finally(() => {
        setIsUpdating(false)
        setIsPristine(true)
      })
  }

  const handleDelete = (): void => {
    setIsDeleting(true)
    props
      .deleteItem(item)
      .then((parent) => {
        props.setParent(parent)
      })
      .finally(() => {
        setIsDeleting(false)
      })
  }

  return (
    <Box
      bg={editMode ? 'gray.50' : '#fff'}
      padding="8px 12px"
      borderBottom="1px solid"
      borderColor="gray.100"
      borderRadius="6px"
      overflow="hidden"
      _hover={{ bg: 'gray.50', '.meta': { display: 'block' } }}
      _focusWithin={{ bg: 'gray.50', '.meta': { display: 'block' } }}
    >
      <OrderProductContext.Provider
        value={{
          item: localItem,
          setItem: setLocalItem,
          updatedQuantity,
          setUpdatedQuantity,
          updatedQuantityAccepted,
          setUpdatedQuantityAccepted,
          updatedRate,
          setUpdatedRate,
          isPristine,
          setIsPristine,
          isUpdating,
          setIsUpdating,
          handleUpdate,
          isDeleting,
          setIsDeleting,
          handleDelete,
          editMode,
          setEditMode,
          acceptMode,
          setAcceptMode,
          computed,
          orderProductProps: props,
        }}
      >
        {props.outOfStock && (
          <Flex
            px={2}
            py={1}
            mb={1}
            borderRadius="md"
            border="1px solid"
            alignItems="center"
            bg={props.quantityAvailable ? 'warning.50' : 'critical.50'}
            color={props.quantityAvailable ? 'warning.600' : 'critical.600'}
            borderColor={
              props.quantityAvailable ? 'warning.100' : 'critical.100'
            }
          >
            {props.quantityAvailable ? (
              <>
                <FiAlertCircle />
                <Text fontSize="sm" ml={2}>
                  {(
                    props.quantityAvailable / localItem.product.case_count
                  ).toFixed(1)}{' '}
                  Available (Cases)
                </Text>
              </>
            ) : (
              <>
                <FiXCircle />
                <Text fontSize="sm" ml={2}>
                  Out of Stock
                </Text>
              </>
            )}
          </Flex>
        )}

        <Grid templateColumns="3fr 2fr 2fr" gap={3} alignItems="center">
          <Flex flexDir="column">
            <Product />
          </Flex>

          <Flex justifyContent="flex-end">
            <Quantity />
          </Flex>

          <Flex
            fontWeight="medium"
            alignItems="center"
            justifyContent="flex-end"
          >
            {acceptMode ? (
              <AcceptForm />
            ) : (
              <>
                <Amount />
                <Options />
              </>
            )}
          </Flex>
        </Grid>

        {/* Form */}
        {editMode && !acceptMode ? <EditForm /> : null}
      </OrderProductContext.Provider>
    </Box>
  )
}

OrderProduct.defaultProps = {
  isEditable: true,
}

interface IOrderProductContext {
  item: any
  setItem: React.Dispatch<any>
  updatedQuantity: number
  setUpdatedQuantity: React.Dispatch<number>
  updatedQuantityAccepted: number
  setUpdatedQuantityAccepted: React.Dispatch<number>
  updatedRate: number
  setUpdatedRate: React.Dispatch<number>
  isPristine: boolean
  setIsPristine: React.Dispatch<boolean>
  isUpdating: boolean
  setIsUpdating: React.Dispatch<boolean>
  handleUpdate: VoidFunction
  isDeleting: boolean
  setIsDeleting: React.Dispatch<boolean>
  handleDelete: VoidFunction
  editMode: boolean
  setEditMode: React.Dispatch<boolean>
  acceptMode: boolean
  setAcceptMode: React.Dispatch<boolean>
  computed: any
  orderProductProps: OrderProductProps
}
export const OrderProductContext =
  React.createContext<IOrderProductContext>(null)
