import { http, secureRandomString } from 'src/core/helpers'

export class UploadService {
  upload(uploadData, fileName): Promise<string> {
    fileName = fileName ? secureRandomString() : secureRandomString()

    const formData = new FormData()
    formData.append('file', uploadData.file)
    formData.append('tag', fileName)
    formData.append('title', fileName)
    formData.append('overwrite', 'true')

    return new Promise((resolve, reject) => {
      try {
        http.post('upload/upload', formData).then(({ data }) => {
          if (data.code === 200) resolve(data.data)
          // upload ID
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default UploadService
export const uploadService = new UploadService()
export type UploadServiceProps = keyof UploadService
