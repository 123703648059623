import React from 'react'

import { Button, Spinner, Flex, Box, Text } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import { Card, CardHeader, CardBody } from 'src/core/components/Card'
import LocationsTable from '../components/LocationsTable'

import { route, quickPluralize } from 'src/core/helpers'
import { useFetch, usePermission } from 'src/core/hooks'

import { ILocation } from '../location.type'
import { locationService, LocationServiceProps } from '../location.service'
import { LocationsTableFilter } from '../components/LocationsTableFilter'
import { useFilterLocations } from '../hooks/useFilterLocations'

const Locations: React.FC = () => {
  const { userCan } = usePermission()

  const [locations, isLoading] = useFetch<ILocation[], LocationServiceProps>(
    locationService,
    'fetch',
    [],
    userCan('view_locations')
  )

  const { filters, setFilters, activeFilters, filteredLocations } =
    useFilterLocations(locations)

  return (
    <Layout headerProps={{ title: 'Locations' }} permission="view_locations">
      <Card>
        <CardHeader
          as={Flex}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <Text>{quickPluralize(filteredLocations, 'location')}</Text>

            <Box ml={3}>
              <SearchInput
                placeholder="Search locations"
                value={filters.search}
                onChange={(search) => setFilters({ search, page: 1 })}
                isDisabled={isLoading || !filteredLocations}
              />
            </Box>
          </Flex>

          {userCan('create_location') && (
            <Link to={route('location_create')}>
              <Button
                size="sm"
                leftIcon={<FiPlus size={16} />}
                colorScheme="primary"
              >
                Create Location
              </Button>
            </Link>
          )}
        </CardHeader>

        {/* Filters */}
        <CardHeader>
          <LocationsTableFilter
            filters={filters}
            setFilters={setFilters}
            activeFilters={activeFilters}
          />
        </CardHeader>

        <CardBody p={0}>
          {isLoading ? (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          ) : (
            <LocationsTable
              locations={filteredLocations}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </CardBody>
      </Card>
    </Layout>
  )
}

export default Locations
