import React from 'react'

import { Flex, Text, Button, Input } from '@chakra-ui/react'
import { FiMinus, FiPlus } from 'react-icons/fi'
import { debounce } from 'lodash'
import { useToast } from 'src/core/hooks'

export interface IQuantityInput {
  /** in units */
  value?: number
  mpu?: number
  /** Maximum purchasable unit */
  mxpu?: number
  interval?: number
  caseCount?: number
  unit?: 'unit' | 'case'
  onChange?: (value: number) => void
  isValueEditable?: boolean
  isDisabled?: boolean
}

export const QuantityInput: React.FC<IQuantityInput> = (props) => {
  const { toast } = useToast()

  const { value, caseCount, isValueEditable } = props

  const [localUnit, setLocalUnit] = React.useState<'unit' | 'case'>('unit')
  const [localValue, setLocalValue] = React.useState<any>(props.value)

  const isUnit = localUnit === 'unit'
  const minValue = isUnit ? props.mpu : props.mpu / caseCount
  const maxValue = isUnit ? props.mxpu : props.mxpu / caseCount
  let interval = minValue || 1
  if (props.interval) {
    interval = isUnit ? props.interval : props.interval / caseCount
  }

  /**
   * Sync props.unit to localUnit
   */
  React.useEffect(() => {
    setLocalUnit(props.unit)
  }, [props.unit])

  /**
   * Sync props.value to localValue
   */
  React.useEffect(() => {
    if (!isNaN(value)) {
      const _value = localUnit === 'unit' ? value : value / caseCount
      setLocalValue(parseFloat(_value.toFixed(1)))
    } else {
      setLocalValue(0)
    }
  }, [value, localUnit, caseCount, minValue, maxValue])

  const commitChange = (value): void => {
    const isInUnit = localUnit === 'unit'
    const units = isInUnit ? value : value * caseCount
    const minUnits = isInUnit ? minValue : minValue * caseCount
    const maxUnits = isInUnit ? maxValue : maxValue * caseCount

    if (value < minValue || (maxValue && value > maxValue)) {
      setLocalValue(Number((maxValue || minValue).toFixed(isInUnit ? 0 : 1)))
      props.onChange(Number((maxUnits || minUnits).toFixed()))
      toast({
        status: 'error',
        description: maxValue
          ? `Quantity must be between ${minValue} - ${maxValue}`
          : `Quantity must not be less than ${minValue}`,
      })
    } else {
      props.onChange(Number(units.toFixed()))
    }
  }

  const increment = (event): void => {
    event.preventDefault()
    const newValue = Number(localValue) + Number(interval)
    commitChange(newValue > maxValue ? maxValue : newValue)
  }

  const decrement = (event): void => {
    event.preventDefault()
    const newValue = Number(localValue) - Number(interval)
    commitChange(newValue < minValue ? minValue : newValue)
  }

  const handleChange = ({ target }): void => {
    let value

    try {
      value = eval(target.value)
    } catch {
      value = target.value
    }

    commitChange(typeof value === 'string' ? value.replace(/\D/g, '') : value)
  }

  const handleDebouncedChange = debounce(handleChange, 1500)

  const onChange = (event): void => {
    setLocalValue(event.target.value)

    // regex for mathematical expression
    const regex = /(?:(?:^|[-+_*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/

    if (!isNaN(Number(event.target.value))) handleChange(event)
    else if (regex.test(event.target.value)) handleDebouncedChange(event)
  }

  const onBlur = (event): void => {
    // regex for mathematical expression
    const regex = /(?:(?:^|[-+_*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/
    if (regex.test(event.target.value)) handleChange(event)
  }

  return (
    <Flex>
      <Button
        size="sm"
        variant="outline"
        borderRadius="6px 0 0 6px"
        mr="-1px"
        onClick={decrement}
        isDisabled={props.isDisabled || localValue <= minValue}
        data-testid="decrement"
      >
        <FiMinus />
      </Button>
      <Flex
        position="relative"
        background="#fff"
        border="1px solid"
        borderColor="gray.100"
      >
        <Input
          px="3px"
          pt={0}
          size="sm"
          height="20px"
          borderRadius={0}
          _focus={{ outline: '0', boxShadow: 'none' }}
          value={localValue || '0'}
          width="5ch"
          textAlign="center"
          border={0}
          fontWeight="bold"
          onChange={onChange}
          onBlur={onBlur}
          readOnly={!isValueEditable || props.isDisabled}
          data-testid="value"
        />
        <Text
          w="100%"
          bottom={0}
          cursor="default"
          color="gray.600"
          fontSize="10px"
          textAlign="center"
          position="absolute"
          onClick={() => {
            setLocalUnit(localUnit === 'unit' ? 'case' : 'unit')
          }}
        >
          {`${localUnit}${Number(localValue) !== 1 ? 's' : ''}`}
        </Text>
      </Flex>
      <Button
        size="sm"
        variant="outline"
        borderRadius="0 6px 6px 0"
        ml="-1px"
        onClick={increment}
        isDisabled={props.isDisabled || localValue >= maxValue}
        data-testid="increment"
      >
        <FiPlus />
      </Button>
    </Flex>
  )
}

QuantityInput.defaultProps = {
  unit: 'unit',
  mpu: 1,
  caseCount: 1,
}

export default QuantityInput
