import React from 'react'
import { Badge, Button, Flex } from '@chakra-ui/react'
import { useHistory } from 'react-router'

import { Thead, Tr, Th, Td } from 'src/core/components/Table'
import { route } from 'src/core/helpers/route.helper'
import {
  ISalesOfficer,
  ISalesOfficerFilters,
  SalesOfficerRole,
} from '../salesOfficer.type'
import { formatCurrency, trans } from 'src/core/helpers'

interface SalesOfficerTheadProps {
  filters: ISalesOfficerFilters
  getThProps: any
}
export const SalesOfficerThead: React.FC<SalesOfficerTheadProps> = ({
  filters,
  getThProps,
}) => {
  return (
    <Thead>
      <Tr>
        <Th textAlign="center">#</Th>
        <Th isSticky>Name</Th>
        <Th>Status</Th>
        <Th>Role</Th>
        <Th>Phone</Th>
        <Th>Email</Th>
        {filters.role === SalesOfficerRole.Rep && (
          <Th {...getThProps('wallet_balance')} isNumeric>
            Wallet Balance (NGN)
          </Th>
        )}
        <Th>Actions</Th>
      </Tr>
    </Thead>
  )
}

interface SalesOfficerTrProps {
  salesOfficer: ISalesOfficer
  filters: ISalesOfficerFilters
  index: number
}
export const SalesOfficerTr: React.FC<SalesOfficerTrProps> = ({
  salesOfficer,
  filters,
  index,
}) => {
  const history = useHistory()

  const openSalesOfficer = (salesOfficer): void => {
    history.push(
      route('sales_officer', {
        id: salesOfficer._id,
        tab: 'balance',
      })
    )
  }

  const openSalesOfficerBuyers = (salesOfficer): void => {
    history.push(route('sales_officer_buyers', { id: salesOfficer._id }))
  }

  const colorCode = (value): string => {
    return value < 0 ? 'critical.600' : value > 0 ? 'success.600' : 'body'
  }

  return (
    <Tr>
      <Td textAlign="center">{index + 1 + (filters.page - 1) * 10}</Td>
      <Td isSticky>{salesOfficer.name}</Td>
      <Td>
        <Badge
          colorScheme={
            salesOfficer.status === 'active' ? 'success' : 'critical'
          }
        >
          {salesOfficer.status}
        </Badge>
      </Td>

      <Td>{trans(`sales_officer.${salesOfficer.role}`) || '-'}</Td>
      <Td>{salesOfficer.phone}</Td>
      <Td>{salesOfficer.email}</Td>

      {filters.role === SalesOfficerRole.Rep && (
        <Td color={colorCode(salesOfficer.wallet_balance)} isNumeric>
          {formatCurrency(salesOfficer.wallet_balance) || '-'}
        </Td>
      )}

      <Td>
        <Flex alignItems="center">
          <Button
            size="sm"
            variant="outline"
            onClick={() => openSalesOfficer(salesOfficer)}
          >
            View
          </Button>

          <Button
            ml={3}
            size="sm"
            variant="outline"
            onClick={() => openSalesOfficerBuyers(salesOfficer)}
          >
            Retailers
          </Button>
        </Flex>
      </Td>
    </Tr>
  )
}
