import SalesOfficer from './pages/SalesOfficer.page'
import SalesOfficers from './pages/SalesOfficers.page'
import SalesOfficerEditor from './pages/SalesOfficerEditor.page'

import { IRoute } from 'src/bootstrap/routes'
import SalesOfficerBuyers from './pages/SalesOfficerBuyers.page'

export const salesOfficerRoutes: IRoute[] = [
  {
    id: 'sales_officers',
    path: '/sales-officers',
    component: SalesOfficers,
    requiresAuth: true,
    exact: true,
  },

  {
    id: 'sales_officer_signup',
    path: '/sales-officers/signup',
    component: SalesOfficerEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'sales_officer_update',
    path: '/sales-officers/:id/update',
    component: SalesOfficerEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'sales_officer_buyers',
    path: '/sales-officers/:id/buyers',
    component: SalesOfficerBuyers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'sales_officer',
    path: '/sales-officers/:id/:tab',
    component: SalesOfficer,
    requiresAuth: true,
    exact: true,
  },
]

export default salesOfficerRoutes
