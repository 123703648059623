import { CoreService } from 'src/core/services'
import { INotification } from './notification.type'

export class NotificationService extends CoreService {
  constructor() {
    super('notification')
  }

  sendToBuyers(notification: INotification): Promise<void> {
    const formData = new FormData()

    Object.keys(notification).forEach((key) => {
      formData.append(key, notification[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`notification/poll_buyers_for`, formData)
          .then(({ data }) => {
            if (data.code === 200) resolve()
            else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  sendOrdersEmail(payload: {
    type: string
    timestamp: number
    seller_id?: string
  }): Promise<void> {
    const formData = new FormData()

    formData.append('commit', 'true')
    formData.append('timestamp', payload.timestamp.toString())
    payload?.seller_id && formData.append('seller_id', payload.seller_id)

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`notification/send_${payload.type}`, formData)
          .then(({ data }) => {
            if (data.code === 200) resolve()
            else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export const notificationService = new NotificationService()
export default NotificationService
