import React from 'react'
import { Text } from '@chakra-ui/react'
import getObjectPath from 'lodash/get'
import { Link } from 'react-router-dom'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Thead, Tbody, Tr, Th, Td } from 'src/core/components/Table'
import {
  formatCurrency,
  paginate,
  route,
  timestampToDate,
} from 'src/core/helpers'
import {
  InventoryReportContext,
  InventoryReportFiltersType,
} from '../../warehouse.type'
import { IWarehouseSalesReport } from '../..'

interface PropsType {
  entries: any[]
  filters: InventoryReportFiltersType
  setFilters: (filters: InventoryReportFiltersType) => void
}
export const InventoryReportTable: React.FC<PropsType> = (props) => {
  const { entries, filters, setFilters } = props

  const paginatedEntries = React.useMemo(() => {
    return entries ? paginate(entries, filters.page, filters.per_page) : []
  }, [filters.page, filters.per_page, entries])

  return (
    <>
      {!paginatedEntries.length ? (
        <ErrorBox summary="No entries found" my={50} />
      ) : null}

      {paginatedEntries?.length ? (
        <>
          {filters?.context === InventoryReportContext.Current ? (
            <CurrentInventoryTable
              entries={paginatedEntries}
              filters={filters}
            />
          ) : filters?.context === InventoryReportContext.Sales ? (
            <SalesInventoryTable data={paginatedEntries} filters={filters} />
          ) : (
            <HistoricalInventoryTable
              entries={paginatedEntries}
              filters={filters}
            />
          )}

          <Pagination
            my={5}
            itemsPerPage={filters.per_page}
            totalItems={entries.length}
            currentPage={filters.page}
            onPageChange={(page) => setFilters({ page })}
            justifyContent="center"
          />
        </>
      ) : null}
    </>
  )
}

const CurrentInventoryTable: React.FC<any> = ({ entries, filters }) => {
  return (
    <Table isFit>
      <Thead>
        <Tr>
          <Th textAlign="center">#</Th>
          {!!filters.warehouses?.[0]?.label && <Th>Warehouse</Th>}
          <Th>Product</Th>
          <Th isNumeric>Quantity (Cases)</Th>
          {!!entries?.[0]?.total && <Th isNumeric>Value</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {entries.map((entry, index) => (
          <Tr key={entry.product._id}>
            <Td textAlign="center">
              {index + 1 + (filters.page - 1) * filters.per_page}
            </Td>
            {!!filters.warehouses?.[0]?.label && (
              <Td>{filters.warehouses[0].label.replace(/\([A-Z\s]+\)/, '')}</Td>
            )}
            <Td>
              <Link
                to={route('product_update', { id: entry.product._id })}
                title={entry.product.name}
              >
                <Text isTruncated maxW={280} title={entry.product.name}>
                  {entry.product.name}
                </Text>
              </Link>
            </Td>
            <Td isNumeric>
              {(entry.quantity / entry.product.case_count).toFixed(2)}
            </Td>
            {!!entry.total && <Td isNumeric>{formatCurrency(entry.total)}</Td>}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const HistoricalInventoryTable: React.FC<any> = ({ entries, filters }) => {
  let significantOther = null

  switch (filters.context) {
    case InventoryReportContext.Received:
      significantOther = {
        header: 'Manufacturer/Distributor',
        accessor: 'purchase_order.source_obj.name',
      }
      break
    case InventoryReportContext.Rejected:
      significantOther = {
        header: 'Manufacturer/Distributor',
        accessor: 'purchase_order.destination_obj.name',
      }
      break
    case InventoryReportContext.Issued:
      significantOther = {
        header: 'DA',
        accessor: 'purchase_order.destination_obj.name',
      }
      break
    case InventoryReportContext.Returned:
      significantOther = {
        header: 'DA',
        accessor: 'purchase_order.source_obj.name',
      }
      break
    default:
      significantOther = null
      break
  }

  return (
    <Table isFit>
      <Thead>
        <Tr>
          <Th textAlign="center">#</Th>
          <Th>Date</Th>
          <Th>Warehouse</Th>
          {significantOther && <Th>{significantOther?.header}</Th>}
          <Th>Product</Th>
          <Th isNumeric>Quantity (Cases)</Th>
          <Th isNumeric>Price</Th>
          <Th isNumeric>Value</Th>
          <Th>Ref.</Th>
        </Tr>
      </Thead>
      <Tbody>
        {entries.map((entry, index) => (
          <Tr key={`${entry?.product?._id}`}>
            <Td textAlign="center">
              {index + 1 + (filters.page - 1) * filters.per_page}
            </Td>
            <Td>{timestampToDate(entry.created)}</Td>
            <Td>
              {filters.warehouses?.[0]?.label
                ? filters.warehouses[0].label.replace(/\([A-Z\s]+\)/, '')
                : '-'}
            </Td>
            {significantOther && (
              <Td>{getObjectPath(entry, significantOther?.accessor, '-')}</Td>
            )}
            <Td>
              <Link
                to={route('product_update', { id: entry.product._id })}
                title={entry.product.name}
              >
                <Text isTruncated maxW={280} title={entry.product.name}>
                  {entry.product.name}
                </Text>
              </Link>
            </Td>
            <Td isNumeric>
              {(entry.quantity / entry.product.case_count).toFixed(2)}
            </Td>
            <Td isNumeric>
              {[
                InventoryReportContext.Received,
                InventoryReportContext.Rejected,
                InventoryReportContext.Current,
              ].includes(filters.context)
                ? formatCurrency(entry.cost_price)
                : formatCurrency(entry.sell_price)}
            </Td>
            <Td isNumeric>
              {formatCurrency(
                entry.cost_price * (entry.quantity / entry.product.case_count)
              )}
            </Td>
            <Td>
              <Link
                to={route(
                  'purchase_order',
                  { id: entry.ref },
                  { is_ref: 'true' }
                )}
                title={entry.ref}
              >
                <Text title={entry.ref}>{entry.ref}</Text>
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const SalesInventoryTable: React.FC<{
  data: IWarehouseSalesReport[]
  filters
}> = ({ data, filters }) => {
  return (
    <Table isFit>
      <Thead>
        <Tr>
          <Th textAlign="center">#</Th>
          <Th isSticky>Product</Th>
          <Th isNumeric>Iss. Qty (Cases)</Th>
          <Th isNumeric>Iss. Value</Th>
          <Th isNumeric>Ret. Qty (Cases)</Th>
          <Th isNumeric>Ret. Value</Th>
          <Th isNumeric>Quantity (Cases)</Th>
          <Th isNumeric>Sales</Th>
          <Th isNumeric>Revenue</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item, index) => (
          <Tr key={`${item?.product?._id}`}>
            <Td textAlign="center">
              {index + 1 + (filters.page - 1) * filters.per_page}
            </Td>
            <Td isSticky>
              <Link
                to={route('product_update', { id: item?.product?._id })}
                title={item?.product?.name}
              >
                <Text isTruncated maxW={280} title={item?.product?.name}>
                  {item?.product?.name}
                </Text>
              </Link>
            </Td>
            <Td isNumeric>
              {(item.issued / item.product.case_count).toFixed(2)}
            </Td>
            <Td isNumeric>{formatCurrency(item.issued_value)}</Td>
            <Td isNumeric>
              {(item.returned / item.product.case_count).toFixed(2)}
            </Td>
            <Td isNumeric>{formatCurrency(item.returned_value)}</Td>
            <Td isNumeric>
              {(item.quantity / item.product.case_count).toFixed(2)}
            </Td>
            <Td isNumeric>{formatCurrency(item.total)}</Td>
            <Td isNumeric>{formatCurrency(item.revenue)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
