import React from 'react'

import { Checkbox, Flex } from '@chakra-ui/react'

import { ErrorBox, Pagination, PerPageSelect } from 'src/core/components'
import { CardHeader } from 'src/core/components/Card'
import { useExportable, ExportButton } from 'src/core/components/Export'
import { Table, Tbody, Thead, Tr, Th, Td } from 'src/core/components/Table'
import { paginate } from 'src/core/helpers'
import { useSort } from 'src/core/hooks'
import { IBuyer } from 'src/applets/buyer'

const exportableColumns = [
  { label: 'Buyer', accessor: 'buyer.name', selected: true },
  { label: 'Sales Officer', accessor: 'sales_officer.name', selected: true },
  { label: 'Day', accessor: 'day', selected: true },
  { label: 'Week', accessor: 'week', selected: true },
]

interface IProps {
  buyers: IBuyer[]
  toggleBuyerStatus: (buyer: IBuyer) => void
}

export const RoutePlannerBuyersTable: React.FC<IProps> = ({
  buyers,
  toggleBuyerStatus,
}) => {
  const tableRef = React.useRef()

  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)

  const exportable = useExportable(buyers, exportableColumns)

  const { getThProps, sorted: sortedProducts } = useSort(buyers, {
    accessor: 'name',
    dir: 'asc',
  })

  const paginatedBuyers = React.useMemo(() => {
    const { data, timestamp } = sortedProducts
    return data || timestamp ? paginate(data, page, perPage) : []
  }, [page, perPage, sortedProducts])

  return (
    <>
      <CardHeader as={Flex} justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <PerPageSelect
            value={perPage}
            options={[10, 20, 50, 75, 100, 150, 200, 250, 300]}
            onChange={setPerPage}
          />

          {/* Export button */}
          <ExportButton
            ml={2}
            filename="route-plan-buyers"
            exportData={exportable?.data}
            exportableColumns={exportable.columns}
            toggleExportableColumn={exportable.toggleColumn}
            isDisabled={!exportable?.data?.length}
          />
        </Flex>
      </CardHeader>

      {!paginatedBuyers.length ? (
        <ErrorBox summary="No products found" my={50} />
      ) : (
        <Table tableRef={tableRef} isFit hasBorders>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>#</Th>
              <Th>Name</Th>
              <Th>Phone</Th>
              <Th>Locations</Th>
              <Th {...getThProps('day')}>Day</Th>
              <Th>Week</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedBuyers.map((buyer, index) => (
              <Tr key={buyer._id}>
                <Td textAlign="center">
                  <Checkbox
                    id={buyer._id}
                    name={buyer.name}
                    onChange={() => toggleBuyerStatus(buyer)}
                    isChecked={buyer.route_plan.status}
                  />
                </Td>
                <Td>{index + 1 + (page - 1) * perPage}</Td>
                <Td>{buyer.name || '-'}</Td>
                <Td>{buyer.phone}</Td>
                <Td>{buyer?.location?.name || '-'}</Td>
                <Td>{buyer.route_plan.day || '-'}</Td>
                <Td>{buyer.route_plan.week || '-'}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      <Pagination
        my={5}
        itemsPerPage={perPage}
        totalItems={buyers.length}
        currentPage={page}
        onPageChange={setPage}
      />
    </>
  )
}
