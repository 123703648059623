import React from 'react'

import { IBrand } from 'src/applets/brand'
import { IManufacturer } from 'src/applets/manufacturer'
import { IProduct } from 'src/applets/product'

type StateProps = { isChecked: boolean; isIndeterminate: boolean }

export interface IUseForeignerProducts {
  isLoading: boolean
  manufacturers: IManufacturer[]
  brands: IBrand[]
  products: (IProduct & { foreigner: any; has_changed: boolean })[]
  toggleManufacturerStatus: (
    manufacturer: any,
    type: 'select' | 'deselect'
  ) => void
  toggleBrandStatus: (brand: any, type: 'select' | 'deselect') => void
  toggleProductStatus: (product: any) => void
  getManufacturerStateProps: (manufacturer: any) => StateProps
  getBrandStateProps: (brand: IBrand) => StateProps
  /** Reset to pristine state */
  resetProducts: (productIds: string[]) => void
}

export const useForeignerProducts = <T = any>(
  allProducts: IProduct[],
  foreignerProducts: T[]
): IUseForeignerProducts => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const [manufacturers, setManufacturers] = React.useState<IManufacturer[]>()
  const [brands, setBrands] = React.useState<IBrand[]>()
  const [products, setProducts] =
    React.useState<IUseForeignerProducts['products']>()

  /**
   * Generate dictionary manufacturers & brands from {allProducts}
   * Find matching routePlans for each product
   * Add {has_changed} to each product to track changes
   */
  React.useEffect(() => {
    if (allProducts?.length) {
      /**
       * Dictionary of manufacturers and brands
       * found in allProducts
       */
      const manufacturersDict = {}
      const brandsDict = {}

      const tempProducts = allProducts.map((product) => {
        if (!manufacturersDict[product?.manufacturer?._id]) {
          manufacturersDict[product?.manufacturer?._id] = product?.manufacturer
        }

        if (!brandsDict[product?.brand?._id]) {
          brandsDict[product?.brand?._id] = product?.brand
        }

        const foreignerProductMatch = foreignerProducts?.length
          ? foreignerProducts.find((foreignerProduct: T | any) => {
              return product._id === foreignerProduct.product_id
            })
          : null

        return {
          ...product,
          foreigner: foreignerProductMatch || { status: 'inactive' },
          has_changed: false,
        }
      })

      setManufacturers(Object.values(manufacturersDict))
      setBrands(Object.values(brandsDict))
      setProducts(tempProducts)
      setIsLoading(false)
    }
  }, [allProducts, foreignerProducts])

  /** Reset to pristine state */
  const resetProducts = (productIds): void => {
    const tempProducts = products.map((product) => {
      return productIds.includes(product._id)
        ? { ...product, has_changed: false }
        : product
    })

    setProducts(tempProducts)
  }

  const toggleProductStatus = (product): void => {
    const tempProducts = products.map((_product) => {
      if (_product._id === product._id) {
        _product.has_changed = true
        _product.foreigner.status =
          _product.foreigner.status === 'active' ? 'inactive' : 'active'
      }
      return _product
    })

    setProducts(tempProducts)
  }

  const toggleBrandStatus = (brand, type: 'select' | 'deselect'): void => {
    const tempProducts = products.map((product) => {
      if (product.brand_id === brand._id) {
        product.has_changed = true
        product.foreigner.status = type === 'select' ? 'active' : 'inactive'
      }
      return product
    })

    setProducts(tempProducts)
  }

  const toggleManufacturerStatus = (
    manufacturer,
    type: 'select' | 'deselect'
  ): void => {
    const tempProducts = products.map((product) => {
      if (product.manufacturer._id === manufacturer._id) {
        product.has_changed = true
        product.foreigner.status = type === 'select' ? 'active' : 'inactive'
      }
      return product
    })

    setProducts(tempProducts)
  }

  const getBrandStateProps = (brand): StateProps => {
    const all = products.filter((product) => product.brand._id === brand._id)
    const active = all.filter(({ foreigner }) => {
      return foreigner?.status === 'active'
    })

    return {
      isChecked: active?.length === all?.length,
      isIndeterminate: active?.length && active?.length !== all?.length,
    }
  }

  const getManufacturerStateProps = (manufacturer): StateProps => {
    const all = products.filter((product) => {
      return product.manufacturer._id === manufacturer._id
    })
    const active = all.filter(({ foreigner }) => {
      return foreigner?.status === 'active'
    })

    return {
      isChecked: active?.length === all?.length,
      isIndeterminate: active?.length && active?.length !== all?.length,
    }
  }

  return {
    isLoading,
    manufacturers,
    brands,
    products,
    toggleManufacturerStatus,
    toggleBrandStatus,
    toggleProductStatus,
    getBrandStateProps,
    getManufacturerStateProps,
    resetProducts,
  }
}

export default useForeignerProducts
