import { lazy } from 'react'

import BuyerEditor from './pages/BuyerEditor.page'
import { IRoute } from 'src/bootstrap/routes'

// Enable code splitting
const Buyer = lazy(() => {
  return import(/* webpackChunkName: "buyer_page" */ './pages/Buyer.page')
})
const Buyers = lazy(() => {
  return import(/* webpackChunkName: "buyers_page" */ './pages/Buyers.page')
})

export const buyerRoutes: IRoute[] = [
  {
    id: 'buyers',
    path: '/buyers',
    component: Buyers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'buyer_update',
    path: '/buyers/:id/update',
    component: BuyerEditor,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'buyer',
    path: '/buyers/:id/:tab',
    component: Buyer,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'buyer_signup',
    path: '/buyers/signup',
    component: BuyerEditor,
    requiresAuth: true,
    exact: true,
  },
]

export default buyerRoutes
