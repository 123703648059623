import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Layout, EditorLayout } from 'src/core/components'
import ManufacturerForm from '../components/ManufacturerForm'
import usePermission from 'src/core/hooks/usePermission'
import useFetch from 'src/core/hooks/useFetch'

import {
  ManufacturerService,
  ManufacturerServiceProps,
} from '../manufacturer.service'
import { IManufacturer } from '../manufacturer.type'
import { route } from 'src/core/helpers/route.helper'

const manufacturerService = new ManufacturerService()

const ManufacturerUpdate: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { id: manufacturer_id }: any = useParams()

  const permission = manufacturer_id
    ? 'update_manufacturer'
    : 'create_manufacturer'

  const [manufacturer, isLoading, , setManufacturer] = useFetch<
    IManufacturer,
    ManufacturerServiceProps
  >(
    manufacturerService,
    'fetchById',
    [manufacturer_id],
    !!manufacturer_id && userCan(permission)
  )

  return (
    <Layout
      headerProps={{
        title: isLoading
          ? 'Manufacturer'
          : manufacturer?.name || 'New Manufacturer',
        backText: 'Manufacturers',
        onBack: () => history.push(route('manufacturers')),
      }}
      permission={permission}
    >
      <EditorLayout
        type={manufacturer_id ? 'update' : 'create'}
        model={manufacturer}
        isLoading={isLoading}
      >
        {manufacturer_id ? (
          <ManufacturerForm
            type="update"
            manufacturer={manufacturer}
            onUpdate={(updatedValues) => {
              setManufacturer({ ...manufacturer, ...updatedValues })
            }}
          />
        ) : (
          <ManufacturerForm />
        )}
      </EditorLayout>
    </Layout>
  )
}

export default ManufacturerUpdate
