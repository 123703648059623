import React, { useContext } from 'react'

import { Flex, Text } from '@chakra-ui/layout'
import { Badge, Button, Box } from '@chakra-ui/react'
import { useHistory } from 'react-router'

import {
  ErrorBox,
  Pagination,
  StatusSwitch,
  ImageBox,
} from 'src/core/components'
import { Table, Thead, Tbody, Th, Tr, Td } from 'src/core/components/Table'
import { asset } from 'src/core/helpers/file.helper'
import { paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'

import { BrandService } from '../brand.service'
import { IBrand } from '../brand.type'
import { BrandsPageContext } from '../pages/Brands.page'

const brandService = new BrandService()

const BrandsTable: React.FC = () => {
  const {
    filteredBrands: brands,
    filters,
    page,
    setPage,
  } = useContext(BrandsPageContext)

  const paginatedBrands = React.useMemo(() => {
    return brands ? paginate(brands, filters.page) : []
  }, [filters.page, brands])

  return (
    <>
      {!paginatedBrands.length ? (
        <ErrorBox summary="No brands found" my={50} />
      ) : (
        <>
          <Box className="table-wrapper padded">
            <Table isFit>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th className="text-center">--</Th>
                  <Th className="sticky">Name</Th>
                  <Th>Status</Th>
                  <Th>Products</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedBrands.map((brand, index) => (
                  <BrandsTableRow key={index} index={index} brand={brand} />
                ))}
              </Tbody>
            </Table>
          </Box>

          <Pagination
            my={5}
            itemsPerPage={10}
            totalItems={brands.length}
            currentPage={page}
            onPageChange={setPage}
          />
        </>
      )}
    </>
  )
}

const BrandsTableRow: React.FC<{ brand: IBrand; index: number }> = ({
  brand,
  index,
}) => {
  const history = useHistory()

  const { brands, setBrands, filters } = useContext(BrandsPageContext)

  const openBrand = (brand): void => {
    history.push(
      route('brand_update', {
        id: brand._id,
      })
    )
  }

  return (
    <Tr key={brand._id}>
      <Td>{index + 1 + (filters.page - 1) * 10}</Td>
      <Td className="align-middle text-center">
        <Flex justifyContent="center">
          <ImageBox src={brand.upload ? asset(brand.upload.path) : ''} />
        </Flex>
      </Td>
      <Td>
        <Text cursor="default" onClick={() => openBrand(brand)}>
          {brand.name}
        </Text>

        <Text fontSize="sm" color="gray.600">
          {brand.manufacturer.name}
        </Text>
      </Td>
      <Td>
        <Badge colorScheme={brand.status === 'active' ? 'success' : 'critical'}>
          {brand.status}
        </Badge>
      </Td>
      <Td>
        <Button
          size="sm"
          variant="outline"
          onClick={() => {
            history.push(
              route('products', null, {
                brand_id: brand._id,
                brand_name: brand.name,
              })
            )
          }}
        >
          View
        </Button>

        <Button
          size="sm"
          variant="outline"
          ml={2}
          onClick={() => {
            history.push(
              route('product_create', null, {
                type: 'bulk',
                brand_id: brand._id,
                brand_name: brand.name,
              })
            )
          }}
        >
          Upload
        </Button>
      </Td>
      <Td>
        <StatusSwitch
          index={index}
          label="Brand"
          item={brand}
          list={brands}
          setList={setBrands}
          service={brandService}
          permission={'update_brand'}
        />
      </Td>
    </Tr>
  )
}

export default BrandsTable
