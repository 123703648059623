import React from 'react'

import { Select, Text } from '@chakra-ui/react'

import { FilterMenu } from 'src/core/components'
import { SellerAutocomplete } from 'src/applets/seller'

import { ISalesOfficerFilters, SalesOfficerRole } from '../salesOfficer.type'
import { trans } from 'src/core/helpers'

interface IProps {
  filters: ISalesOfficerFilters
  setFilters: (filters: ISalesOfficerFilters) => void
  activeFilters: string[]
}

const SalesOfficersTableFilter: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
}) => {
  const resetFilters = (): void => {
    setFilters({
      status: '*',
      role: '*',
      sellers: '*',
    })
  }

  const activeFiltersText = activeFilters.length
    ? `Filtered by: ${activeFilters.join(', ')}`
    : 'Filters'

  return (
    <>
      <FilterMenu
        toggleButtonProps={{
          title: activeFiltersText,
        }}
        toggleButtonContent={
          <Text textTransform="capitalize" isTruncated>
            {activeFiltersText}
          </Text>
        }
        resetButtonProps={{ onClick: resetFilters }}
      >
        <FilterMenu.Section label="Status">
          <Select
            size="sm"
            value={filters.status || ''}
            onChange={({ target }: any) => {
              setFilters({ status: target.value })
            }}
          >
            <option value="*" label="All" />
            <option value="active" label="Active" />
            <option value="inactive" label="Inactive" />
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Role">
          <Select
            size="sm"
            value={filters.role || ''}
            onChange={({ target }: any) => {
              setFilters({ role: target.value })
            }}
          >
            <option value="*" label="All" />
            {Object.values(SalesOfficerRole).map((value) => (
              <option key={value} value={value}>
                {trans(`sales_officer.${value}`)}
              </option>
            ))}
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Seller">
          <SellerAutocomplete
            value={filters.sellers?.[0]}
            onChange={(seller) => {
              setFilters({ sellers: [seller] })
            }}
            disableSelectAll
          />
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}

export default SalesOfficersTableFilter
