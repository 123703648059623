import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router'

import * as Yup from 'yup'

import {
  FormikField,
  FormikRadioGroup,
  FormStack,
  ImageUploader,
} from 'src/core/components'
import useToast from 'src/core/hooks/useToast'

import { categoryService } from '../category.service'
import { uploadService } from 'src/applets/upload'
import { ICategory } from '../category.type'
import { route } from 'src/core/helpers/route.helper'

interface CategoryFormProps {
  type?: string
  category?: ICategory
  onUpdate?: (updatedValues: ICategory) => void
}

const CategoryForm: React.FC<CategoryFormProps> = ({
  type,
  category,
  onUpdate,
}) => {
  const history = useHistory()
  const { addToast } = useToast()

  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const formConfig = {
    initialValues: {
      name: category?.name || '',
      meta: category?.meta || '',
      status: category?.status || 'active',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
      meta: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        name: values.name,
        meta: values.meta,
        status: values.status,
        upload_id:
          category?.upload_id || process.env.REACT_APP_DEFAULT_UPLOAD_ID,
      }

      if (type === 'update') {
        finalValues._id = category._id
      }

      categoryService[type](finalValues)
        .then((categoryId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Category successfully updated.', {
              appearance: 'success',
            })
            onUpdate(values)
          } else {
            addToast('Category successfully created.', {
              appearance: 'success',
            })
            history.push(route('category_update', { id: categoryId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const onUpload = (uploadData): Promise<void> => {
    return new Promise((resolve) => {
      uploadService
        .upload(uploadData, `category_${category._id}`)
        .then((uploadId) => {
          const updatedCategory = {
            _id: category._id,
            name: category.name,
            meta: category.meta,
            status: category.status,
            upload_id: uploadId,
          }

          categoryService
            .update(updatedCategory)
            .then(() => {
              setIsUploading(false)

              onUpdate(updatedCategory)
              addToast('Image upload successful.', {
                appearance: 'success',
              })
              resolve()
            })
            .catch((error) => {
              setIsUploading(false)
              addToast(error.message, { appearance: 'error' })
              resolve()
            })
        })
        .catch((error) => {
          setIsUploading(false)
          addToast(error.message, { appearance: 'error' })
          resolve()
        })
    })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Image */}
            {type === 'update' && category ? (
              <FormStack label="Image" helperText="Category image">
                <ImageUploader
                  uploadPath={category.upload ? category.upload.path : null}
                  onUpload={onUpload}
                />
              </FormStack>
            ) : null}

            {/* Category status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Name */}
            <FormStack label="Name" helperText="Name of category" isRequired>
              <FormikField name="name" type="text" />
            </FormStack>

            {/* Category meta */}
            <FormStack label="Meta" helperText="Meta of category" isRequired>
              <FormikField name="meta" type="text" />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={
              formik.isSubmitting ||
              !formik.dirty ||
              !formik.isValid ||
              isUploading
            }
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Category' : 'Update Category'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

CategoryForm.defaultProps = {
  type: 'create',
}

export default CategoryForm
