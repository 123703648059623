import { coreLang } from 'src/core/lang'
import { orderLang } from 'src/applets/order'
import { salesOfficerLang } from 'src/applets/salesOfficer'
import { managerLang } from 'src/applets/manager'
import { warehouseLang } from 'src/applets/warehouse'

export const lang = {
  core: coreLang,
  order: orderLang,
  sales_officer: salesOfficerLang,
  manager: managerLang,
  warehouse: warehouseLang,
}
