import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ChakraProvider } from '@chakra-ui/react'

import { store, persistor } from 'src/bootstrap/store'
import { routes } from 'src/bootstrap/routes'
import { theme } from 'src/bootstrap/theme'
import App from './bootstrap/App'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading="Loading..." persistor={persistor}>
      <ChakraProvider theme={theme}>
        <App routes={routes} />
      </ChakraProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
